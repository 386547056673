import React from 'react';
import classnames from 'classnames';

type Props = {
  value: { id: string; label: string };
};

const DeckStatus = ({ value }: Props) => (
  <div className="deck-status">
    <div className={classnames('dot', value.label.toLowerCase().replaceAll(' ', '-'))} />
    {value.label}
  </div>
);

export default DeckStatus;
