import React from 'react';
import { DynamicForm } from 'components';

type Props = {
  handler: any;
  layoutType?: string;
};

const SlideForm = ({ handler, layoutType }: Props) => (
  <div className="slide-form">
    {!!layoutType && <h6>{layoutType}</h6>}
    <DynamicForm handler={handler} />
  </div>
);

export default SlideForm;
