/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import BrandingThemeForm from 'components/dynamicInputs/brandingThemeForm';
import Button from 'components/common/button';
import { Plus } from 'react-feather';
import { Props } from '../types';
import { CommonProps } from 'components/dynamicInputs/types';
import EmailTemplateForm from 'components/dynamicInputs/emailTemplateForm';
import useApi, { Methods } from 'hooks/useApi';
import { useLocalStorage } from 'react-use';
// import { LogoContext } from 'components/common/toolbar';

const EmailTemplate = ({ onChange }: CommonProps) => {
  const [selectedId, setSelectedId] = useState<number>(0);
  const [template, setTemplate] = useState<any>();
  // const {logo, addLogo} = useContext(LogoContext);
  const fetchTemplate = useApi();
  const [user] = useLocalStorage('user', { id: 0 });

  const temp = {
    templates: [
      {
        id: 1,
        name: 'Default Template',
        isDefault: false,
        subjectText: 'Synclink Shared',
        titleText: 'We Have A Great Proposal For You',
        bodyText:
          'According all our previous conversations, we have prepared a DECK for you, so you could know us better and ',
        createdBy: ''
      }
    ]
  };

  useEffect(() => {
    // setTemplate(temp);
    fetchTemplate.fetchData({ endpoint: '/decks/getEmailTemplates', method: Methods.POST });
  }, []);

  useEffect(() => {
    // console.log("response: ",fetchThemes.response);
    if (fetchTemplate.response !== undefined) {
      let templates: {
        id: any;
        name: any;
        isDefault: any;
        subjectText: any;
        titleText: any;
        bodyText: any;
        createdBy: any;
      }[] = [];
      fetchTemplate.response?.emailTemplates?.map((key: any) => {
        // console.log(key);
        templates.push({
          id: key.id,
          name: key.email_template,
          isDefault: key.default,
          subjectText: key.subject,
          titleText: key.title,
          bodyText: key.body,
          createdBy: key.created_by
        });
      });
      const temp = { templates: templates };
      console.log(temp);
      setTemplate(temp);
    }
  }, [fetchTemplate.response]);

  const handleOnChange = (data: any) => {
    //setThemes(data);
    console.log('On change:========', data);
    for (const item of template.templates) {
      if (item.id == data.id) {
        console.log(item.id);
        item.name = data.name;
        item.bodyText = data.bodyText;
        item.titleText = data.titleText;
        item.subjectText = data.subjectText;
        item.isDefault = data.isDefault;
        item.createdBy = data.createdBy;
        break;
      }
    }
    console.log(template.templates);
    setTemplate(template);
    localStorage.setItem('bodyText', data.bodyText);
    localStorage.setItem('titleText', data.titleText);
    localStorage.setItem('subjectText', data.subjectText);
    onChange('close');
  };

  const handleAddTheme = () => {
    console.log(template);
    let id = template.templates.length;
    let temp = template;
    temp.templates.push({
      id: 0,
      name: 'New Template',
      isDefault: false,
      subjectText: '',
      titleText: '',
      bodyText: '',
      createdBy: user?.id
    });
    setTemplate(temp);
    console.log('-------', selectedId);
    setSelectedId(id);
    console.log('-------', template);
  };

  useEffect(() => {
    console.log('==========', template);
  }, [selectedId]);

  return (
    <div className="search-information">
      <div className="row">
        <div className="label">
          <Button text={'Add Template'} onClick={handleAddTheme} small={true} /> <br />
          {template?.templates?.map((key: any, index: number) => (
            <div className="label-container-email" key={key.id} onClick={() => setSelectedId(index)}>
              {index == selectedId && <span className="selected-theme">{key.name}</span>}
              {index != selectedId && <span>{key.name}</span>}
            </div>
          ))}
        </div>
        <div className="value">
          {template?.templates?.map(
            (key: any, index: number) =>
              index == selectedId && (
                // eslint-disable-next-line react/jsx-key
                <EmailTemplateForm
                  key={key.id}
                  value={key}
                  index={selectedId}
                  onChange={data => handleOnChange(data)}
                />
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailTemplate;
