import joi from 'joi';
import { InputsEnum, TextInputTypes } from 'components/dynamicInputs/types';

export default {
  title: {
    value: 'Letter of Engagement',
    label: 'Title',
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    limit: 60,
    joi: joi.string().required()
  },
  content: {
    value: 'Let’s get started!',
    label: 'Description',
    input: InputsEnum.REACH_TEXT,
    limit: 550,
    joi: joi.string().required()
  },
  image: {
    value: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/assets/loe.svg`
  },
  loe: {
    value: null,
    input: InputsEnum.FILE_PICKER,
    joi: joi.string().required()
  }
};
