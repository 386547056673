import React from 'react';
import { TabsSwitcher } from 'components';
import Base from '../base';
import { Props } from '../types';
import Overall from './overall';
// import Breakdown from './breakdown';

const DeckAnalytics = ({ closing, onClose, params }: Props) => {
  return (
    <Base header={`Insights - ${params.title}`} closing={closing} onClose={onClose} size="lg">
      <div className="modal-deck-analytics">
        <TabsSwitcher
          data={[
            {
              title: 'Overall',
              component: <Overall deckId={params.deckId} />
            }
            // {
            //   title: 'Breakdown',
            //   component: <Breakdown />
            // }
          ]}
        />
      </div>
    </Base>
  );
};

export default DeckAnalytics;
