import React, { MutableRefObject, ReactElement, Suspense, useEffect, useRef, useState } from 'react';
import useWindowSize from '@revolt-digital/use-window-size';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Grid, Navigation, Pagination } from 'swiper';
import { CSSSelector, SwiperOptions } from 'swiper/types';
import classnames from 'classnames';
import lazies from '../../../constants/lazies';
import Button from '../button';

// Import Swiper styles
//import 'swiper/css/grid';

SwiperCore.use([Navigation]);

type NavRefType = HTMLElement | CSSSelector | null | undefined;

type BreakpointsType = {
  [width: number]: SwiperOptions;
};

type Props = {
  children: ReactElement[];
  slidesPerView?: number | 'auto';
  spaceBetween?: number;
  variant?: 'default' | 'contained';
  controlSize?: 'small' | 'medium' | 'large';
  loop?: boolean;
  controlsVariant?: 'link' | 'alternative';
  paginationType: 'bullets' | 'fraction' | 'avatar';
  breakpoints?: BreakpointsType;
  sidesSpaces?: boolean;
  grid?: {
    rows?: number;
    fill?: 'row' | 'column';
  };
  swiperHeight?: number;
  slideHeight?: number;
  initialSlide?: number;
  dynamicBullets?: boolean;
  dynamicMainBullets?: number;
  slidesPerGroup?: number | 1;
};

type BulletsPaginationProps = {
  bulletsNavRef: MutableRefObject<HTMLElement>;
  paginationType: string;
};

type FractionPaginationProps = {
  currentIndex: number;
  total: number;
  paginationType: string;
};

const BulletsPagination = ({ bulletsNavRef, paginationType }: BulletsPaginationProps) => {
  return (
    <div
      className={classnames('bullets-pagination', {
        'show-pagination': paginationType.includes('bullets')
      })}
    >
      <span ref={bulletsNavRef} />
    </div>
  );
};

const FractionPagination = ({ currentIndex, total, paginationType }: FractionPaginationProps) => {
  return (
    <div
      className={classnames('fraction-pagination', {
        'show-pagination': paginationType.includes('fraction')
      })}
    >
      <span>
        {currentIndex + 1} / {total}
      </span>
    </div>
  );
};

const Carousel = ({
  children,
  slidesPerView,
  spaceBetween = 10,
  variant,
  controlSize = 'medium',
  loop = false,
  paginationType = 'bullets',
  controlsVariant = 'alternative',
  breakpoints,
  sidesSpaces,
  grid,
  swiperHeight,
  slideHeight,
  initialSlide = 0,
  dynamicBullets = false,
  slidesPerGroup
}: Props) => {
  const windowSize = useWindowSize();
  const navigationPrevRef = useRef<NavRefType>(null);
  const navigationNextRef = useRef<NavRefType>(null);
  const bulletsNavRef = useRef<HTMLElement>({} as HTMLElement);
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [onePageCarousel, setOnePageCarousel] = useState<boolean>(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const nodes = bulletsNavRef!.current.childNodes;
    setOnePageCarousel([0, 1].includes(nodes.length));
  }, [windowSize, children]);

  return (
    <>
      <Swiper
        initialSlide={initialSlide}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current
        }}
        onInit={swiper => {
          swiper.update();
          setSwiper(swiper);
        }}
        pagination={{
          el: bulletsNavRef?.current,
          clickable: true,
          dynamicBullets: dynamicBullets,
          dynamicMainBullets: 5
        }}
        mousewheel={{
          forceToAxis: true
        }}
        modules={[Grid, Pagination]}
        style={{
          height: `${swiperHeight}%` || 'auto',
          margin: -4,
          padding: 4
        }}
        onSnapGridLengthChange={swiper => {
          swiper.update();
          setSwiper(swiper);
        }}
        grid={grid}
        loop={loop}
        breakpoints={breakpoints}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        slidesPerGroup={slidesPerGroup}
        onSlideChange={swiper => {
          setSwiper(swiper);
          setCurrentIndex(swiper.realIndex);
        }}
      >
        {children?.map((child, index) => (
          <SwiperSlide
            style={{
              height: `${slideHeight}px` || '100%'
            }}
            key={index}
          >
            {child}
          </SwiperSlide>
        ))}
      </Swiper>

      <div
        className={classnames(
          'carousel-controls',
          variant,
          controlSize,
          { 'sides-spaces': sidesSpaces },
          { 'one-page': onePageCarousel }
        )}
      >
        <Button ref={navigationPrevRef} icon={<ChevronLeft />} variant={controlsVariant} transparent />

        <FractionPagination total={children?.length} currentIndex={currentIndex} paginationType={paginationType} />
        <BulletsPagination bulletsNavRef={bulletsNavRef} paginationType={paginationType} />

        {!window.partner && (
          <Suspense fallback={<div />}>
            <lazies.AvatarPagination
              swiper={swiper}
              paginationType={paginationType}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            >
              {children}
            </lazies.AvatarPagination>
          </Suspense>
        )}

        <Button ref={navigationNextRef} icon={<ChevronRight />} variant={controlsVariant} transparent />
      </div>
    </>
  );
};

export default Carousel;
