import React from 'react';
import { Camera, File, Edit2 } from 'react-feather';
import { Button } from 'components';
import { useModal } from 'hooks/useModal';
import { ModalsList } from 'constants/modals';
import { VideoPickerProps } from '../types';
import { useParams } from 'react-router-dom';
import defaultPreviewImage from 'assets/images/layouts/inputs/video-partner.svg';

const aspectRatio = '13/6';
const shape = 'rect';

const VideoPicker = ({ value, onChange, label }: VideoPickerProps) => {
  const modal = useModal();
  const { id: deckId } = useParams();

  const handleGalleryModal = () => {
    modal.open(
      ModalsList.GALLERY,
      (data: any) => {
        if (data) {
          onChange({ ...value, thumbnail: data.url });
        }
      },
      { deckid: deckId, shape, aspectRatio }
    );
  };

  const handleVideoRecorderClick = (isRecord: any) => {
    modal.open(
      ModalsList.VIDEO_RECORDER,
      async data => {
        if (data?.urlVideo) {
          onChange({ ...value, url: data?.urlVideo, thumbnail: data?.urlImage });
        }
      },
      { deckId, isRecord }
    );
  };

  return (
    <div className="video-picker input-wrapper">
      <label>{label}</label>
      <div className="header">
        <Button
          text="Preview Image"
          variant="alternative"
          transparent
          icon={<Edit2 />}
          onClick={handleGalleryModal}
          small
        />
      </div>
      <label htmlFor="file-upload" className="file-upload">
        <div className="fileUploadButton">
          <img src={value?.thumbnail ?? defaultPreviewImage} />
        </div>
      </label>
      <div className="action">
        <Button
          text="Record Video"
          variant="alternative"
          transparent
          icon={<Camera />}
          onClick={() => handleVideoRecorderClick(true)}
          small
        />
        <Button
          text="Upload Video"
          variant="alternative"
          transparent
          icon={<File />}
          onClick={() => handleVideoRecorderClick(false)}
          small
        />
      </div>
    </div>
  );
};

export default VideoPicker;
