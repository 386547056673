import { Methods } from '../types';

const API_URL = process.env.REACT_APP_KGP_URL;

type Config = { endpoint: string; data: any; token?: string | null; refreshToken?: string | null };

export const uploadImageProperties = async ({ endpoint, data, token, refreshToken }: Config) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('refresh-token', refreshToken!);

  let url = `${API_URL}${endpoint}`;

  const response = await fetch(url, {
    method: Methods.POST,
    headers,
    body: JSON.stringify(data)
  });

  const json = await response.json();

  return {
    url: json.url
  };
};

export const saveImage = async ({ endpoint, data, token, refreshToken }: Config) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('refresh-token', refreshToken!);

  const url = `${API_URL}${endpoint}`;

  const response = await fetch(url, {
    method: Methods.POST,
    headers,
    body: JSON.stringify(data)
  });

  return await response.json();
};

export const uploadImageToS3 = async ({ endpoint, data }: Config) => {
  let url = `${endpoint}`;

  const response = await fetch(url, {
    method: Methods.PUT,
    body: data
  });

  return { status: response.status };
};

export const fetchImages = async ({ endpoint, data, token, refreshToken }: Config) => {
  const url = `${API_URL}${endpoint}`;
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('refresh-token', refreshToken!);

  const response = await fetch(url, {
    method: Methods.POST,
    headers,
    body: JSON.stringify(data)
  });

  return await response.json();
};

export const deleteImageById = async ({ endpoint, data, token, refreshToken }: Config) => {
  const url = `${API_URL}${endpoint}`;
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('refresh-token', refreshToken!);

  const response = await fetch(url, {
    method: Methods.POST,
    headers,
    body: JSON.stringify(data)
  });

  return await response.json();
};
