import joi from 'joi';
import { InputsEnum, TextInputTypes } from 'components/dynamicInputs/types';

export default {
  title: {
    value: 'Meet your Team',
    label: 'Title',
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    limit: 40,
    joi: joi.string().required()
  },
  content: {
    value: 'Kingsley Gate Partners presents a world-class cadre of global consultants. Meet your team.',
    label: 'Description',
    input: InputsEnum.REACH_TEXT,
    type: TextInputTypes.TEXT,
    limit: 500,
    joi: joi.string().required()
  },
  members: {
    value: [],
    input: InputsEnum.INPUT_PARTNER,
    placeholder: 'Partner',
    joi: joi.object().required()
  }
};
