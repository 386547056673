/* eslint-disable react/react-in-jsx-scope */
import { LogoContext } from 'app';
import { Button, Tooltip } from 'components';
import { CommonProps } from 'components/dynamicInputs/types';
import { ModalsList } from 'constants/modals';
import { useModal } from 'hooks/useModal';
import { useContext } from 'react';
import { Settings } from 'react-feather';

const CustomTheme = ({ onChange }: CommonProps) => {
  const modal = useModal();
  const { setLogo } = useContext(LogoContext);

  const handleOpen = () => {
    modal.open(
      ModalsList.SYSTEM_SETTING,
      (data?: any) => {
        setLogo('1');
        onChange(data);
      },
      { defaultIndex: 0 }
    );
  };

  return (
    <Tooltip value="System Setting">
      <Button variant="alternative" contrast={true} icon={<Settings />} onClick={handleOpen} />
    </Tooltip>
  );
};

export default CustomTheme;
