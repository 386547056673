import React, { useState, useRef, useEffect } from 'react';
import { ControlledMenu, MenuAlign } from '@szhsin/react-menu';
import { MessageSquare, X } from 'react-feather';
import { Button, Tooltip } from 'components';
import Comment from './comment';
import { format } from 'date-fns';

type Props = {
  disabled: boolean;
  data: any[];
  sendComment: (comment: string) => Promise<void>;
  slideSelected: any;
  loading: {
    get: boolean;
    send: boolean;
  };
  align?: MenuAlign;
};

const CommentsMenu = ({ disabled, align = 'start', data, sendComment, slideSelected, loading }: Props) => {
  const ref = useRef(null);
  const skipOpen = useRef(false);
  const [open, setOpen] = useState(false);
  const [isTextareaOpen, setTextareaOpen] = useState(false);
  const [comments, setComments] = useState<any[]>([]);
  const [newComment, setNewComment] = useState('');

  const handleOpen = () => {
    !skipOpen.current && setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleNewComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewComment(event.target.value);
  };

  const handleAddComment = async () => {
    await sendComment(newComment);
    setTextareaOpen(true);
    setNewComment('');
  };

  useEffect(() => {
    setComments(data);
  }, [data]);

  return (
    <>
      <Tooltip value="Comments" disabled={open}>
        <Button
          ref={ref}
          variant="alternative"
          icon={<MessageSquare />}
          onClick={handleOpen}
          contrast={true}
          disabled={disabled}
        />
      </Tooltip>

      <ControlledMenu
        state={open ? 'open' : 'closed'}
        onClose={handleClose}
        anchorRef={ref}
        skipOpen={skipOpen}
        transition
        align={align}
        offsetX={10}
        offsetY={20}
        viewScroll="initial"
        menuClassName="comments-menu"
      >
        <div className="comment-header">
          <h3 className="body">Comments</h3>
          <Button icon={<X />} onClick={handleClose} variant="alternative" />
        </div>
        <div className="comment-body">
          {comments?.map((item: any, index) => (
            <Comment
              key={`comment-${index}`}
              deckName={item.slideName}
              client={`${item.user.first_name} ${item.user.last_name}`}
              date={`${format(new Date(item.created_at), 'PP')} ${format(new Date(item.created_at), 'p')}`}
              text={item.comment}
            />
          ))}
        </div>
        <div className="comment-footer">
          {isTextareaOpen ? (
            <Button text="ADD NEW COMMENT" onClick={() => setTextareaOpen(newComment => !newComment)} small />
          ) : (
            <div className="new-comment">
              <textarea
                placeholder={
                  !slideSelected ? 'Choose a subsection to comment' : `Add new comment in ${slideSelected.name}`
                }
                onChange={value => handleNewComment(value)}
              />
              <div className="footer">
                <Button
                  text="COMMENT"
                  onClick={handleAddComment}
                  small
                  disabled={!newComment || !slideSelected || loading.get || loading?.send}
                />
              </div>
            </div>
          )}
        </div>
      </ControlledMenu>
    </>
  );
};

export default CommentsMenu;
