import React from 'react';
import Button from 'components/common/button';
import Base from '../base';
import { Props } from '../types';

const ConfirmLeaveDeck = ({ closing, onClose }: Props) => {
  return (
    <Base
      onClose={onClose}
      closing={closing}
      size="md"
      header="You have unsaved changes"
      footer={
        <>
          <Button text="Save & continue" variant="secondary" onClick={() => onClose({ leave: true, save: true })} />
          <Button text="Discard changes" variant="secondary" onClick={() => onClose({ leave: true, save: false })} />
          <Button text="Cancel" onClick={() => onClose({ leave: false, save: false })} />
        </>
      }
    >
      <div className="modal-confirm-leave-deck">
        <p className="body-2">Leaving this page will discard any changes you&apos;ve made. Do you want to continue?</p>
      </div>
    </Base>
  );
};

export default ConfirmLeaveDeck;
