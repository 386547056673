import joi from 'joi';
import { InputsEnum, TextInputTypes } from 'components/dynamicInputs/types';

export default {
  title: {
    value: 'Source Environment',
    label: 'Title',
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    limit: 30,
    joi: joi.string().required()
  },
  content: {
    value:
      'The first step to Frame your search is to identify a list of target companies within which we will Find potential candidates for your role. Here is a list of representative target companies.',
    label: 'Description',
    input: InputsEnum.REACH_TEXT,
    limit: 350,
    joi: joi.string().required()
  },
  highlight: {
    value: '',
    label: 'Highlight',
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    limit: 50,
    joi: joi.string().required()
  },
  items: {
    value: [],
    label: 'Candidate',
    input: InputsEnum.INPUT_SOURCE_ENVIRONMENT,
    joi: joi.object().required()
  }
};
