import React from 'react';
import classnames from 'classnames';
import useForwardedRef from 'hooks/useForwardedRef';
import useRipple from 'hooks/useRipple';
import { Badge } from 'components';

type Props = {
  disabled?: boolean;
  text?: string;
  icon?: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'alternative' | 'rounded';
  contrast?: boolean;
  transparent?: boolean;
  capitalize?: boolean;
  item?: string;
  selected?: boolean;
  small?: boolean;
  tiny?: boolean;
  iconOnRight?: boolean;
  badge?: number | string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const Button = React.forwardRef(
  (
    {
      text,
      icon,
      badge,
      item,
      onClick = () => {},
      variant = 'primary',
      disabled = false,
      contrast = false,
      selected = false,
      small = false,
      tiny = false,
      transparent = false,
      capitalize = false,
      iconOnRight = false
    }: Props,
    ref
  ) => {
    const safeRef = useForwardedRef(ref);
    useRipple(safeRef);

    return (
      <button
        ref={safeRef as React.RefObject<HTMLButtonElement>}
        onClick={onClick}
        className={classnames(
          'button',
          'body-2',
          'strong',
          variant,
          { item },
          { selected },
          { transparent },
          { capitalize },
          { small },
          { tiny },
          { 'icon-only': !!icon && !text },
          { contrast }
        )}
        disabled={disabled}
      >
        {!!badge && <Badge value={badge} />}
        {!!icon && !iconOnRight && icon}
        {!!text && text}
        {!!icon && !!iconOnRight && icon}
      </button>
    );
  }
);

export default Button;
