import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import Select from 'react-select';
import useApi from 'hooks/useApi';
import useDebounce from 'hooks/useDebounce';
import { AutoCompleteProps, SelectOption } from '../types';

const AutoComplete = ({
  label = 'Autocomplete',
  name,
  endpoint,
  onChange,
  isMulti,
  value,
  customOptions,
  limit,
  nonRemoveable
}: AutoCompleteProps) => {
  const [query, setQuery] = useState('');
  const qDebounced = useDebounce(query);
  const [options, setOptions] = useState<any[]>([]);
  const [focused, setFocused] = useState(false);
  const { response, isLoading, fetchData } = useApi();
  const refSelect = useRef(null);

  const REMOVE_ACTION = 'remove-value';

  const customStyles = {
    menuPortal: (base: any) => ({ ...base, zIndex: 300 }),
    multiValueRemove: (base: any) => !!nonRemoveable && { ...base, display: 'none' }
  };

  const onInputChange = (newValue: string) => {
    setQuery(newValue);
  };

  const handleSelect = (item: SelectOption, actionMeta?: any) => {
    if (nonRemoveable && actionMeta.action === REMOVE_ACTION) {
      return;
    }

    onChange(item, actionMeta.name);
  };

  useEffect(() => {
    if (qDebounced && endpoint) {
      fetchData({ endpoint }, { q: qDebounced });
    }
  }, [qDebounced]);

  useEffect(() => {
    if (response) {
      setOptions(response?.items);
    }
  }, [response]);

  return (
    <div className="input-wrapper" ref={refSelect}>
      <div className="autocomplete_wrapper">
        <label className="label" id="placeholder-frame">
          <div
            className={classnames('text', {
              focused
            })}
          >
            {label}
          </div>
        </label>
        <Select
          className="select-container"
          classNamePrefix="autocomplete"
          menuPosition="fixed"
          menuPlacement="auto"
          closeMenuOnScroll={(e: any) => {
            return e.target.contains(refSelect.current);
          }}
          menuPortalTarget={refSelect.current}
          styles={customStyles}
          name={name}
          value={value}
          isClearable
          isSearchable
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          isLoading={isLoading}
          isOptionDisabled={() => (limit ? value.length >= limit : false)}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          placeholder={false}
          isMulti={isMulti}
          options={options.length > 0 ? options : customOptions}
          onInputChange={onInputChange}
          onChange={(item: any, actionMeta: any) => handleSelect(item, actionMeta)}
        />
      </div>
    </div>
  );
};

export default React.memo(AutoComplete);
