import React, { useState, useRef, useEffect } from 'react';
import { ControlledMenu } from '@szhsin/react-menu';
import { useLocalStorage, deleteFromStorage } from '@rehooks/local-storage';
import { X, LogOut } from 'react-feather';
import { Button } from 'components';
import { getInitials } from 'helpers/text';
import { useNotifications } from 'hooks/useNotifications';

const MyProfileMenu = () => {
  const [user] = useLocalStorage<any>('user');
  const ref = useRef(null);
  const skipOpen = useRef(false);
  const [open, setOpen] = useState(false);
  const { unsubscribe } = useNotifications();

  const handleOpen = () => {
    !skipOpen.current && setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleLogout = async () => {
    window.location.href = `${window.location.href}?logout=true`;
  };

  const logout = async () => {
    await unsubscribe();
    deleteFromStorage('token');
    deleteFromStorage('refreshToken');
    deleteFromStorage('user');
  };
  useEffect(() => {
    if (window.location.href.includes('logout')) {
      logout();
    }
  }, [window.location.href]);

  return (
    <>
      <Button
        variant="rounded"
        text={!user ? ' ' : getInitials(`${user.first_name} ${user.last_name}`)}
        onClick={handleOpen}
        ref={ref}
      />

      <ControlledMenu
        state={open ? 'open' : 'closed'}
        onClose={handleClose}
        anchorRef={ref}
        skipOpen={skipOpen}
        transition
        align="end"
        offsetX={24}
        offsetY={20}
        viewScroll="initial"
        menuClassName="my-profile-menu"
      >
        <div className="header">
          <h3 className="body">My profile</h3>
          <Button icon={<X />} onClick={handleClose} variant="alternative" />
        </div>
        <div className="content">
          {!user ? (
            '-'
          ) : (
            <>
              <div className="body">
                {user.first_name} {user.last_name}
              </div>
              <div className="body-2">{user.email}</div>

              <Button icon={<LogOut />} text="Logout" small variant="alternative" onClick={handleLogout} />
            </>
          )}
        </div>
      </ControlledMenu>
    </>
  );
};

export default MyProfileMenu;
