import React from 'react';

const BGMosaic = () => (
  <svg className="bg-mosaic" viewBox="0 0 668 768" fill="none">
    <g opacity="0.45">
      <path
        d="M0 451.188L1.45035e-06 329.564C2.14088e-06 271.658 46.9423 224.716 104.848 224.716L231.715 224.716L231.715 451.188L0 451.188Z"
        fill="#F8F8F8"
      />
      <path
        opacity="0.3"
        d="M3.19336 -8L125.866 -8C183.772 -8 230.715 38.9423 230.715 96.8485V223.715L3.19336 223.715L3.19336 -8Z"
        fill="url(#paint0_linear_2_8553)"
      />
      <path
        d="M461.188 -1.90234L461.187 119.722C461.187 177.628 414.245 224.57 356.339 224.57L229.472 224.57L229.472 -1.90235L461.188 -1.90234Z"
        fill="#ECD8E3"
      />
      <path
        d="M235.909 675.564L235.909 798.237C235.909 856.143 188.967 903.085 131.061 903.085L4.19404 903.085L4.19405 675.564L235.909 675.564Z"
        fill="#F5E9EE"
      />
      <path
        opacity="0.23"
        d="M460.285 224.716L460.285 346.341C460.285 404.247 413.343 451.189 355.437 451.189L229.619 451.189L229.619 224.716L460.285 224.716Z"
        fill="#8984EA"
      />
      <path
        d="M355.437 454.333C413.343 454.333 460.285 501.275 460.285 559.181L460.285 575.957C460.285 633.863 413.343 680.805 355.437 680.805L334.467 680.805C276.561 680.805 229.619 633.863 229.619 575.957L229.619 559.181C229.619 501.275 276.561 454.333 334.467 454.333L355.437 454.333Z"
        fill="#FCEEC8"
      />
      <path
        opacity="0.76"
        d="M460.285 224.716L460.285 346.341C460.285 404.247 507.227 451.189 565.134 451.189L692 451.189L692 337.953C692 275.414 641.303 224.716 578.764 224.716L460.285 224.716Z"
        fill="url(#paint1_linear_2_8553)"
      />
      <path
        opacity="0.5"
        d="M460.285 224.716L460.285 102.044C460.285 44.1375 507.227 -2.80489 565.134 -2.80488L692 -2.80488L692 224.716L460.285 224.716Z"
        fill="#F7CC5B"
      />
      <path
        d="M460.285 677.661L460.285 556.037C460.285 498.131 507.227 451.188 565.134 451.188L692 451.188L692 677.661L460.285 677.661Z"
        fill="#DFC8D4"
      />
      <path
        opacity="0.6"
        d="M231.618 902.28L231.618 675.808L462.285 675.808L462.285 902.28L231.618 902.28Z"
        fill="url(#paint2_linear_2_8553)"
      />
      <path
        opacity="0.32"
        d="M4.19385 675.564L4.19385 553.94C4.19385 496.034 51.1361 449.091 109.042 449.091L235.909 449.091L235.909 675.564L4.19385 675.564Z"
        fill="#C04E87"
      />
      <path
        opacity="0.6"
        d="M462.382 675.564H585.054C642.961 675.564 689.903 722.506 689.903 780.412V907.279H567.23C509.324 907.279 462.382 860.337 462.382 802.431V675.564Z"
        fill="#D1C8E9"
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear_2_8553" x1="40.5" y1="13" x2="231" y2="224" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6260F8" />
        <stop offset="1" stopColor="#5912AC" />
      </linearGradient>
      <linearGradient id="paint1_linear_2_8553" x1="470.5" y1="226" x2="692" y2="451" gradientUnits="userSpaceOnUse">
        <stop stopColor="#C793E0" />
        <stop offset="1" stopColor="#9B80D5" />
      </linearGradient>
      <linearGradient id="paint2_linear_2_8553" x1="249" y1="692" x2="462" y2="934.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6765FE" />
        <stop offset="1" stopColor="#784FEB" stopOpacity="0.78" />
      </linearGradient>
    </defs>
  </svg>
);

export default BGMosaic;
