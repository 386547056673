import React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  value: string;
};

const TableSlideId = ({ value }: Props) => {
  const navigate = useNavigate();

  return (
    <button className="table-slide-id strong" onClick={() => navigate(`/deck/${value}`)}>
      {value}
    </button>
  );
};

export default TableSlideId;
