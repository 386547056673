import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Router from './router';
import useWindowSize from '@revolt-digital/use-window-size';
import { useLocalStorage } from '@rehooks/local-storage';
import { ToastContainer } from 'react-toastify';
import ModalProvider from 'hooks/useModal';
import RefsProvider from 'hooks/useRefs';
import useQuery from 'hooks/useQuery';
import useApi, { Methods } from 'hooks/useApi';
import { useNotifications } from 'hooks/useNotifications';
// import { SINGLE_SIGN_ON, SINGLE_SIGN_ON_LOGOUT } from 'constants/general';
import { Toolbar } from 'components';

interface ILogoContext {
  logo: string;
  setLogo: Dispatch<SetStateAction<string>>;
}

export const LogoContext = React.createContext<ILogoContext>({
  logo: '0',
  setLogo: () => {}
});

let SINGLE_SIGN_ON = '';
let SINGLE_SIGN_ON_LOGOUT = '';
if (document.location.origin.includes('localhost')) {
  SINGLE_SIGN_ON = `https://galaxy-dev.auth.us-east-2.amazoncognito.com/oauth2/authorize?client_id=4udpmvte98tadol3ren9464gp5&response_type=code&scope=email+openid+profile&redirect_uri=${document.location.origin}`;
  SINGLE_SIGN_ON_LOGOUT = `https://galaxy-dev.auth.us-east-2.amazoncognito.com/login?client_id=4udpmvte98tadol3ren9464gp5&response_type=code&scope=email+openid+profile&redirect_uri=${document.location.origin}`;
} else if (document.location.origin.includes('dev')) {
  SINGLE_SIGN_ON = `https://galaxy-dev.auth.us-east-2.amazoncognito.com/oauth2/authorize?client_id=4udpmvte98tadol3ren9464gp5&response_type=code&scope=email+openid+profile&redirect_uri=${document.location.origin}`;
  SINGLE_SIGN_ON_LOGOUT = `https://galaxy-dev.auth.us-east-2.amazoncognito.com/login?client_id=4udpmvte98tadol3ren9464gp5&response_type=code&scope=email+openid+profile&redirect_uri=${document.location.origin}`;
} else {
  SINGLE_SIGN_ON = `https://auth.galaxy-2020.com/oauth2/authorize?client_id=5qupb9c75q7q89na316cjea4uc&response_type=code&scope=email+openid+profile&redirect_uri=${document.location.origin}`;
  SINGLE_SIGN_ON_LOGOUT = `https://auth.galaxy-2020.com/login?client_id=5qupb9c75q7q89na316cjea4uc&response_type=code&scope=email+openid+profile&redirect_uri=${document.location.origin}`;
}

// const SINGLE_SIGN_ON =
//   `${process.env.REACT_APP_SINGLE_SIGN_ON}client_id=${process.env.REACT_APP_CLIENT_ID}response_type=code&` +
//   `scope=${process.env.REACT_APP_SCOPE}redirect_uri=${document.location.origin}`;
// const SINGLE_SIGN_ON_LOGOUT =
//   `${process.env.REACT_APP_SINGLE_SIGN_ON_LOGOUT}client_id=${process.env.REACT_APP_CLIENT_ID}response_type=code&` +
//   `scope=${process.env.REACT_APP_SCOPE}redirect_uri=${document.location.origin}`;
const App = () => {
  const [token] = useLocalStorage('token');
  const windowSize = useWindowSize();
  const { fetchData, response } = useApi();
  const code = useQuery('code');
  const { subscribe, fetchNotifications } = useNotifications();
  const fetchBrandingThemeData = useApi();
  const [count, setCount] = useState(0);
  const [logo, setLogo] = useState('0');
  const value = { logo, setLogo };

  useEffect(() => {
    localStorage.setItem('logoUrl', '');
    fetchBrandingThemeData.fetchData({
      endpoint: `/decks/getSelectedThemeId`,
      method: Methods.POST
    });
  }, []);

  useEffect(() => {
    if (
      fetchBrandingThemeData?.response &&
      count === 0 &&
      fetchBrandingThemeData?.response?.brandingThemes.length > 0
    ) {
      let theme = fetchBrandingThemeData?.response?.brandingThemes[0];

      document.documentElement.style.setProperty('--headeer-bar-color', theme.header_bar_color);
      localStorage.setItem('headerbarColor', theme.header_bar_color);
      document.documentElement.style.setProperty('--primary-button-color', theme.primary_button_color);
      localStorage.setItem('primaryButtonColor', theme.primary_button_color);
      document.documentElement.style.setProperty('--secondary-button-color', theme.secondary_button_color);
      localStorage.setItem('secondaryButtoncolor', theme.secondary_button_color);
      document.documentElement.style.setProperty('--title-text-color', theme.title_text_color);
      localStorage.setItem('titleTextColor', theme.title_text_color);
      document.documentElement.style.setProperty('--body-text-color', theme.body_text_color);
      localStorage.setItem('bodyTextColor', theme.body_text_color);
      localStorage.setItem('logoUrl', theme.logo);
      if (theme.font === 'Satoshi') {
        document.documentElement.style.setProperty('--font', theme.font);
      } else if (theme.font === 'Bitter') {
        document.documentElement.style.setProperty('--font', 'Bitter');
      } else if (theme.font === 'OpenSans') {
        document.documentElement.style.setProperty('--font', 'OpenSans');
      } else if (theme.font === 'Roboto') {
        document.documentElement.style.setProperty('--font', 'Roboto');
      } else {
        document.documentElement.style.setProperty('--font', 'Satoshi');
      }
      setCount(1);
    }
  }, [fetchBrandingThemeData?.response]);

  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [windowSize.height]);

  useEffect(() => {
    if (code) {
      fetchData({ endpoint: '/auth/login', method: Methods.POST }, { code, url: document.location.origin });
    }
  }, [code]);

  useEffect(() => {
    if (!token && !code && !window.location.href.includes('logout')) {
      window.location.href = SINGLE_SIGN_ON;
      localStorage.setItem('hre', window.location.href);
    } else if (!token && !code && window.location.href.includes('logout')) {
      window.location.href = SINGLE_SIGN_ON_LOGOUT;
    }
  }, [token]);

  useEffect(() => {
    if (localStorage.getItem('hre') !== null && response && token) {
      window.location.href = localStorage.getItem('hre') || '';
      localStorage.removeItem('hre');
    }
  }, [response, token]);

  useEffect(() => {
    if (response && token) {
      subscribe();
    }
  }, [response, token]);

  useEffect(() => {
    !!token && fetchNotifications();
  }, [token]);

  if (!token) {
    return null;
  }

  return (
    <ModalProvider>
      <RefsProvider>
        <LogoContext.Provider value={value}>
          <div className="app">
            <Toolbar />
            <Router />
            <ToastContainer />
          </div>
        </LogoContext.Provider>
      </RefsProvider>
    </ModalProvider>
  );
};

export default App;
