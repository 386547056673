import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

type Props = {
  draggableId: string;
  index: number;
  children: React.ReactNode;
  isDragDisabled?: boolean;
};

const DragWrapper = ({ draggableId, index, children, isDragDisabled }: Props) => {
  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
      {provided => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            {children}
          </div>
        );
      }}
    </Draggable>
  );
};

export default DragWrapper;
