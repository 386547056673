/* eslint-disable react/react-in-jsx-scope */
import Button from 'components/common/button';
import { uploadImageProperties, uploadImageToS3 } from 'galleryCrop/services';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { X } from 'react-feather';
import AutoComplete from '../autoComplete';
import { brandingThemeFormProps } from '../types';
import { useLocalStorage } from '@rehooks/local-storage';
import { getFileExtension, getImageProperties, getUrlByExtension } from 'galleryCrop/utils';
import { Endpoint } from 'galleryCrop/types';
import useApi, { Methods } from 'hooks/useApi';
import { Spinner } from 'galleryCrop/components/common';
import { useToast } from 'hooks/useToast';

const BrandingThemeForm = ({ value, onChange }: brandingThemeFormProps) => {
  const [headerbarColor, setHeaderBarColor] = useState();
  const [primaryButtonColor, setPrimaryButtonColor] = useState();
  const [bodyTextColor, setBodyTextColor] = useState();
  const [titleTextColor, setTitleTextColor] = useState();
  const [secondaryButtoncolor, setSecondaryButtoncolor] = useState();
  const [textFont, setTextFont] = useState();
  const [themeName, setThemeName] = useState();
  const [logoUrl, setLogoUrl] = useState<string>();
  const [isDefault, setIsDefault] = useState<boolean>();
  const [count, setCount] = useState(0);
  const [fileData, setFileData] = useState<any[]>([]);
  const [image, setImage] = useState<any>();
  const [token] = useLocalStorage('token');
  const [refreshToken] = useLocalStorage('refreshToken');
  const imageDelete = useApi();
  const [loading, setLoading] = useState(false);
  const saveTheme = useApi();
  const [temp, setTemp] = useState<any>();
  const [createdBy, setCreatedBy] = useState();
  const [user] = useLocalStorage('user', { id: 0 });
  const [imageUpload, setImageUpload] = useState(false);
  const notify = useToast();
  const [isEnableSave, setIsEnableSave] = useState(false);

  const fonts = [
    {
      id: 1,
      name: 'Satoshi'
    },
    {
      id: 2,
      name: 'OpenSans'
    },
    {
      id: 3,
      name: 'Bitter'
    },
    {
      id: 4,
      name: 'Roboto'
    }
  ];

  useEffect(() => {
    if (count == 0 && value?.headerbarColor !== undefined) {
      setThemeName(value.name);
      setHeaderBarColor(value.headerbarColor);
      setPrimaryButtonColor(value.primaryButtonColor);
      setBodyTextColor(value.bodyTextColor);
      setTitleTextColor(value.titleTextColor);
      setSecondaryButtoncolor(value.secondaryButtoncolor);
      setTextFont(value.textFont);
      setIsDefault(value.isDefault);
      setCreatedBy(value.createdBy);
      setLogoUrl(value.logoUrl);
      setCount(1);
    }
  });

  const handleOnChange = (obj: any, type: string) => {
    console.log(obj);
    switch (type) {
      case 'headerbarColor': {
        setIsEnableSave(true);
        setHeaderBarColor(obj.target.value);
        break;
      }
      case 'primaryButtonColor': {
        setIsEnableSave(true);
        setPrimaryButtonColor(obj.target.value);
        break;
      }
      case 'bodyTextColor': {
        setIsEnableSave(true);
        setBodyTextColor(obj.target.value);
        break;
      }
      case 'titleTextColor': {
        setIsEnableSave(true);
        setTitleTextColor(obj.target.value);
        break;
      }
      case 'secondaryButtoncolor': {
        setIsEnableSave(true);
        setSecondaryButtoncolor(obj.target.value);
        break;
      }
      case 'textFont': {
        setIsEnableSave(true);
        setTextFont(obj.name);
        break;
      }
      case 'themeName': {
        setIsEnableSave(true);
        setThemeName(obj.target.value);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleSave = () => {
    setIsEnableSave(false);
    let temp = {
      id: value.id,
      name: themeName,
      headerbarColor: headerbarColor,
      primaryButtonColor: primaryButtonColor,
      bodyTextColor: bodyTextColor,
      titleTextColor: titleTextColor,
      secondaryButtoncolor: secondaryButtoncolor,
      textFont: textFont,
      logoUrl: logoUrl,
      isDefault: isDefault
    };
    setTemp(temp);
    console.log(user);
    saveTheme.fetchData(
      {
        endpoint: '/decks/createBrandingThemes',
        method: Methods.POST
      },
      {
        id: value.id,
        theme_name: themeName,
        header_bar_color: headerbarColor,
        primary_button_color: primaryButtonColor,
        body_text_color: bodyTextColor,
        title_text_color: titleTextColor,
        secondary_button_color: secondaryButtoncolor,
        font: textFont,
        logo: logoUrl === undefined ? '' : logoUrl,
        default: isDefault,
        deck_id: 1,
        created_by: createdBy,
        updated_by: user.id
      }
    );
    // onChange(temp);
  };

  useEffect(() => {
    if (saveTheme.response !== undefined) {
      notify('Changes saved successfully');
      onChange(temp);
    }
  }, [saveTheme.loaded]);

  const onDrop = (e: any) => {
    setLoading(true);
    let reader = new FileReader();
    console.log('========', e);
    //let file = e[0].File;

    reader.onloadend = async () => {
      await uploadImage(e[0], 0);
      setFileData(e);
    };
    reader.readAsDataURL(e[0]);
  };

  const uploadImage = useCallback(
    async (file: any, type: number) => {
      // setUploadImageLoading(true);
      const image = await getImageProperties(file);
      const extension = getFileExtension(file.name);

      try {
        const { url } = await uploadImageProperties({
          endpoint: Endpoint.UPLOAD_LOGO,
          data: { ...image, themeId: value.id.toString(), item: type },
          token,
          refreshToken
        });
        console.log('url', url);
        if (url) {
          // console.log("url",url);
          const { status } = await uploadImageToS3({
            endpoint: url,
            data: file
          });
          if (status === 200) {
            const shortenedUrl = getUrlByExtension(url, extension!);
            console.log(shortenedUrl);
            setLogoUrl(shortenedUrl);
            setImageUpload(true);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        // setUploadImageLoading(false);
      }
    },
    [value.id, token, refreshToken]
  );

  useEffect(() => {
    if (fileData.length) {
      setImage(URL.createObjectURL(fileData[0]));
      setIsEnableSave(true);
      setLoading(false);
    }
  }, [fileData]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/png': ['.png'] },
    multiple: false
  });

  const handleRemoveImage = (type: string) => {
    if (type === 'post' || imageUpload) {
      let temp = logoUrl?.split('/');
      let imagePath = temp !== undefined ? `${temp[3]}/${temp[4]}/${temp[5]}` : '';
      if (imagePath !== '') {
        imageDelete.fetchData({ endpoint: '/decks/deleteimage', method: Methods.POST }, { imagePath: `${imagePath}` });
      }
      setImage(undefined);
    }
    setLogoUrl('');
    setIsEnableSave(true);
  };

  return (
    <div className="branding-theme-container">
      <div className="branding-theme-form">
        <div>
          {logoUrl !== undefined && logoUrl !== '' ? (
            <>
              <label className="label-name">Logo:</label>
              &nbsp;&nbsp; <img alt="" src={logoUrl ? logoUrl : ''} />
              <div className="icon">
                <X onClick={() => handleRemoveImage('pre')} />
              </div>
            </>
          ) : (
            <>
              {!image ? (
                <>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <label className="text-name">Logo:</label>
                    <input className="input-zone" {...getInputProps()} />
                    <div className="text-center">
                      <div>
                        <p>
                          {!loading ? (
                            <strong>Browse Image</strong>
                          ) : (
                            <>
                              <strong>
                                Browse <Spinner /> Image
                              </strong>
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="text-name">
                  <label>Logo:</label>
                </div> */}
                </>
              ) : (
                <>
                  <label className="label-name">Logo:</label>
                  &nbsp;&nbsp; <img alt="" src={image} />
                  <div className="icon">
                    <X onClick={() => handleRemoveImage('post')} />
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className="input-container">
          <span className="checkbox-label">Default Theme</span>
          <input
            type="checkbox"
            checked={isDefault}
            onChange={() => {
              setIsDefault(!isDefault), setIsEnableSave(true);
            }}
          />
        </div>
        <div className="input-container">
          <div className="text1">Theme Name</div>
          <div className="color-picker">
            <input name="themeName" type="text" value={themeName} onChange={obj => handleOnChange(obj, 'themeName')} />
          </div>
        </div>
        <div className="input-container">
          <div className="text1">Header bar Color</div>
          <div className="color-picker">
            <label>
              <input
                type="color"
                value={headerbarColor}
                id="colorPicker"
                onChange={obj => handleOnChange(obj, 'headerbarColor')}
              />
              <span className="m-left">{headerbarColor}</span>
              <span> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
            </label>
          </div>
        </div>
        <div className="input-container">
          <div className="text1">Primary Button Color</div>
          <div className="color-picker">
            <label>
              <input
                type="color"
                value={primaryButtonColor}
                id="colorPicker"
                onChange={obj => handleOnChange(obj, 'primaryButtonColor')}
              />
              <span className="m-left">{primaryButtonColor}</span>
              <span> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
            </label>
          </div>
        </div>
        <div className="input-container">
          <div className="text1">Body Text Color</div>
          <div className="color-picker">
            <label>
              <input
                type="color"
                value={bodyTextColor}
                id="colorPicker"
                onChange={obj => handleOnChange(obj, 'bodyTextColor')}
              />
              <span className="m-left">{bodyTextColor}</span>
              <span> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
            </label>
          </div>
        </div>
        <div className="input-container">
          <div className="text1">Title Text Color</div>
          <div className="color-picker">
            <label>
              <input
                type="color"
                value={titleTextColor}
                id="colorPicker"
                onChange={obj => handleOnChange(obj, 'titleTextColor')}
              />
              <span className="m-left">{titleTextColor}</span>
              <span> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
            </label>
          </div>
        </div>
        <div className="input-container">
          <div className="text1">Secondary Button Color</div>
          <div className="color-picker">
            <label>
              <input
                type="color"
                value={secondaryButtoncolor}
                id="colorPicker"
                onChange={obj => handleOnChange(obj, 'secondaryButtoncolor')}
              />
              <span className="m-left">{secondaryButtoncolor}</span>
              <span> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
            </label>
          </div>
        </div>
        <AutoComplete
          name="textFont"
          label="Text Font"
          value={{ name: textFont } || {}}
          customOptions={fonts?.map((item: any) => ({
            id: item?.id,
            name: item?.name
          }))}
          onChange={obj => handleOnChange(obj, 'textFont')}
          isMulti={false}
        />

        <span className="m-left">{}</span>
      </div>

      <div className="save-button">
        <Button text={'Save'} onClick={handleSave} small={true} disabled={!isEnableSave} />
      </div>
    </div>
  );
};

export default BrandingThemeForm;
