import React from 'react';

type Props = {
  value: {
    id: string;
    label: string;
    color?: string;
  };
};

const Tag = ({ value }: Props) => {
  const style = value.color ? { backgroundColor: value.color } : {};

  return (
    <div key={value.id} className="tag body-2" style={style}>
      {value.label}
    </div>
  );
};

export default Tag;
