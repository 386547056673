/* eslint-disable react/jsx-key */
import React, { ChangeEvent, useCallback, useState } from 'react';
import InputDropdown from 'components/common/inputDropdown';
import { ArrowDown, ArrowUp, Plus } from 'react-feather';
import { v4 as uuidv4 } from 'uuid';
import clone from 'just-clone';
import Companies from './companies';
import Button from 'components/common/button';
import { useModal } from 'hooks/useModal';
import { CommonProps, RelevantExperienceValue, SubcategoryType, TextInputTypes } from '../types';
import { ModalsList } from 'constants/modals';
import { DropResult } from 'react-beautiful-dnd';
import { reorderList } from 'helpers/data';

type SubcategoryProps = {
  currentCategory: RelevantExperienceValue;
  subcategoryList: SubcategoryType[];
  value: RelevantExperienceValue[];
  slideType?: string;
} & CommonProps;

const SubCategories = ({ currentCategory, subcategoryList, onChange, value, slideType }: SubcategoryProps) => {
  const modal = useModal();
  const [selectedId, setSelectedId] = useState<string>('');
  const [sortingType, setSortingType] = useState<string>('');
  // const [data, setData] = useState<any>();
  const showDropdown = (id: string) => selectedId === id;

  const changeSubcategoryName = (e: ChangeEvent<HTMLInputElement>, subCategoryId: string) => {
    const aux = clone(value).map(category => {
      if (category.id === currentCategory.id) {
        category.subCategories.map(subCategory => {
          if (subCategory.id === subCategoryId) {
            subCategory.name = e.target.value;
          }
        });
      }
      return category;
    });

    onChange(aux);
  };

  const deleteSubCategory = (subCategoryId: string) => {
    const aux = clone(value).map(category => {
      if (category.id === currentCategory.id) {
        category.subCategories = category.subCategories.filter(subCategory => subCategory.id !== subCategoryId);
      }

      return category;
    });

    onChange(aux);
  };

  const addCompany = (subCategoryId: string) => {
    const aux = clone(value).map(category => {
      if (category.id === currentCategory.id) {
        category.subCategories.map(subCategory => {
          if (subCategory.id === subCategoryId) {
            subCategory.companies = [...subCategory.companies, { id: uuidv4(), name: '', image: '', roles: [] }];
          }
        });
      }
      return category;
    });

    onChange(aux);
  };

  const changeCompanyName = (e: ChangeEvent<HTMLInputElement>, companyId: string, subCategoryId: string) => {
    const aux = clone(value).map(category => {
      if (category.id === currentCategory.id) {
        category.subCategories.map((subCategory: any) => {
          if (subCategory.id === subCategoryId) {
            subCategory.companies.map((company: any) => {
              if (company.id === companyId) {
                company.name = e.target.value;
              }
            });
          }
        });
      }
      return category;
    });

    onChange(aux);
  };

  const importFromGalaxy = (subCategoryId: string) => {
    modal.open(ModalsList.IMPORT_COMPANIES, (data: any) => {
      if (data) {
        const aux = clone(value).map(category => {
          if (category.id === currentCategory.id) {
            category.subCategories.map(subCategory => {
              if (subCategory.id === subCategoryId) {
                subCategory.companies = [
                  ...subCategory.companies,
                  ...data
                    .filter((e: any) => {
                      return !category.companies.find(c => c.id === e.id);
                    })
                    .map((company: any) => ({
                      id: company.id,
                      name: company.name,
                      image: company.logo,
                      roles: []
                    }))
                ];
              }
            });
          }
          return category;
        });

        onChange(aux);
      }
    });
  };

  const importFromDocument = (subCategoryId: string) => {
    modal.open(
      ModalsList.IMPORT_FROM_DOCUMENT,
      (data: any[]) => {
        if (data) {
          const companies = data.reduce((acc, cur) => {
            const index = acc.findIndex((e: any) => e.name === cur.company_name);
            const role = {
              id: uuidv4(),
              name: cur.role,
              pinned: false,
              count: 1
            };

            if (index > -1) {
              acc[index].roles.push(role);
            } else {
              if (cur.subCategory) {
                acc.push({
                  id: uuidv4(),
                  name: cur.company_name,
                  image: '',
                  roles: [role],
                  subCategory: cur.subCategory
                });
              } else {
                acc.push({ id: uuidv4(), name: cur.company_name, image: '', roles: [role] });
              }
            }

            return acc;
          }, []);

          const groupBySubCategory = (companies: any) => {
            const subCategoryGroup: any[] = [];
            companies.forEach((company: any) => {
              const { subCategory } = company;
              const existedSubCategory = subCategoryGroup.find(cur => cur.name === subCategory);
              if (existedSubCategory) {
                delete company[subCategory];
                existedSubCategory.companies.push(company);
              } else {
                const { subCategory, ...rest } = company;
                const newSubCategory = {
                  id: uuidv4(),
                  name: subCategory,
                  companies: [rest]
                };
                subCategoryGroup.push(newSubCategory);
              }
            });
            return subCategoryGroup;
          };
          const subCategoriedCompany: any = groupBySubCategory(companies);

          const aux = clone(value).map(category => {
            if (category.id === currentCategory.id) {
              if (companies[0]?.subCategory) {
                subCategoriedCompany.forEach((sub: any) => {
                  const existedSubCategory = category.subCategories.find(cur => cur.name === sub.name);
                  if (existedSubCategory) {
                    existedSubCategory.companies.push(...sub.companies);
                  } else {
                    category.subCategories.push(sub);
                  }
                });
              } else {
                category.subCategories.map(subCategory => {
                  if (subCategory.id === subCategoryId) {
                    subCategory.companies = [...subCategory.companies, ...companies];
                  }
                });
              }
            }

            return category;
          });

          onChange(aux);
        }
      },
      {
        modalTitle: 'Import Relevant Experience from Document',
        columns: [
          { label: 'Company name', key: 'company_name' },
          { label: 'Role', key: 'role' }
        ]
      }
    );
  };

  const getRolesById = useCallback(
    (companyId: string, subCategoryId: string) =>
      currentCategory?.subCategories
        .find(subCategory => subCategory.id === subCategoryId)
        ?.companies.filter(company => company.id === companyId),
    [currentCategory?.companies]
  );

  const addRolesToCompany = (companyId: string, subCategoryId: string) => {
    modal.open(
      ModalsList.ADD_ROLES,
      (data: any) => {
        if (data) {
          const aux = clone(value).map(category => {
            category.subCategories.map(subCategory => {
              if (subCategory.id === subCategoryId) {
                subCategory.companies = subCategory.companies.map(company =>
                  company.id === companyId ? { ...company, roles: data.rolesList } : company
                );
              }
            });
            return category;
          });

          onChange(aux);
        }
      },
      { data: getRolesById(companyId, subCategoryId) }
    );
  };

  const deleteCompany = (companyId: string, subCategoryId: string) => {
    const aux = clone(value).map(category => {
      if (category.id === currentCategory.id) {
        category.subCategories.map(subCategory => {
          if (subCategory.id === subCategoryId) {
            subCategory.companies = subCategory.companies.filter(company => company.id !== companyId);
          }
        });
      }

      return category;
    });

    onChange(aux);
  };

  const confirmModal = (companyId: string, name: string) => {
    modal.open(
      ModalsList.DIALOG_CONFIRM,
      (confirmed: any) => {
        if (confirmed) {
          deleteSubCategory(companyId);
        }
      },
      { title: `Delete the subcategory ${name}` }
    );
  };

  const onDragEnd = (result: DropResult, subCategoryId: string) => {
    if (!result.destination) {
      return;
    }

    const orderedList = value.map(category => {
      if (category.id === currentCategory.id) {
        category.subCategories.map(subCategory => {
          if (subCategory.id === subCategoryId) {
            subCategory.companies = reorderList(subCategory.companies, result.source.index, result.destination!.index);
          }
        });
      }
      return category;
    });

    onChange(orderedList);
  };

  const sortCompanies = (sortingValue: string, subCategoriesIndex: number) => {
    const aux = clone(value).map(category => {
      if (category.id === currentCategory.id) {
        category.subCategories.map((subCategory: any, index: any) => {
          if (subCategoriesIndex === index) {
            if (subCategory.companies.length) {
              subCategory.companies.sort((a: any, b: any) => {
                let fa = a.name.toLowerCase(),
                  fb = b.name.toLowerCase();

                if (fa < fb) {
                  return sortingValue === 'ascending' ? -1 : 1;
                }
                if (fa > fb) {
                  return sortingValue === 'ascending' ? 1 : -1;
                }
                return 0;
              });
            }
          }
        });
      }
      return category;
    });
    onChange(aux);
    sortingValue === 'ascending' ? setSortingType('decending') : setSortingType('ascending');
  };

  return (
    <>
      {subcategoryList.map((subCategory: any, index: any) => (
        <div>
          <InputDropdown
            key={index}
            id={subCategory.id}
            name="name"
            placeholder="Subcategory"
            value={subCategory.name}
            onChange={changeSubcategoryName}
            confirmName={subCategory.name}
            confirmModal={confirmModal}
            setDropdownId={setSelectedId}
            showDropdown={showDropdown}
            hasDragIcon={false}
            isSubCategories={true}
            slideType={slideType}
          >
            <>
              <div className="imports">
                <Button
                  text="Import from Galaxy"
                  variant="secondary"
                  onClick={() => importFromGalaxy(subCategory.id)}
                  tiny
                />
                <Button
                  text="Import from Document"
                  variant="secondary"
                  onClick={() => importFromDocument(subCategory.id)}
                  tiny
                />
              </div>
              <Button
                text="Add company"
                tiny
                small
                variant="secondary"
                icon={<Plus />}
                onClick={() => addCompany(subCategory.id)}
              />
              <Button
                text="Sort"
                tiny
                small
                variant="secondary"
                icon={sortingType === '' ? <ArrowUp /> : sortingType === 'ascending' ? <ArrowUp /> : <ArrowDown />}
                onClick={() =>
                  sortingType === ''
                    ? sortCompanies('ascending', index)
                    : sortingType === 'ascending'
                    ? sortCompanies('ascending', index)
                    : sortCompanies('decending', index)
                }
              />
              <Companies
                key={index}
                companyList={subCategory.companies}
                onDragEnd={(result: any) => onDragEnd(result, subCategory.id)}
                changeCompanyName={(e, companyId) => changeCompanyName(e, companyId, subCategory.id)}
                deleteCompany={(companyId: string) => deleteCompany(companyId, subCategory.id)}
                addRoles={companyId => addRolesToCompany(companyId, subCategory.id)}
                type={TextInputTypes.TEXT}
                isSubCategories={true}
              />
            </>
          </InputDropdown>
        </div>
      ))}
    </>
  );
};

export default SubCategories;
