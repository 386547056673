import React from 'react';
import globalReach from 'assets/images/layouts/inputs/global-reach.svg';

const GlobalReach = () => {
  return (
    <div className="global-reach input-wrapper">
      <label>No edition available</label>
      <img src={globalReach} />
    </div>
  );
};

export default GlobalReach;
