import React from 'react';
import useFormHandler from 'hooks/useFormHandler';
import { Button, DynamicForm } from 'components';
import schema from 'schemas/modals/newSection';
import Base from '../base';
import { Props } from '../types';

const Section = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);
  const sectionTitle = params?.isEdit ? "Edit section's name" : 'Create a new section';
  const textButton = params?.isEdit ? 'Save' : 'Create';

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.disabled = true;
    onClose(formHandler.data);
  };

  return (
    <Base
      closing={closing}
      header={sectionTitle}
      onClose={onClose}
      size="md"
      footer={<Button text={textButton} disabled={!formHandler.valid} onClick={handleClick} />}
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default Section;
