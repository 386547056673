/* eslint-disable react/react-in-jsx-scope */
import classNames from 'classnames';
import { Copy, Edit2, MoreVertical, Plus, Trash2, Eye, EyeOff } from 'react-feather';
import DragIcon from 'assets/icons/DragIcon';
import { ModalsList } from 'constants/modals';
import { Values } from 'hooks/useModal';
import { DropdownButton } from 'components';
import { SlideType } from 'types/deck';

type Props = {
  sectionId: string;
  deck: any;
  child: SlideType;
  dragStartId: string;
  imagePreview: string;
  modal: Values;
  indexElement: number;
};

enum positions {
  BEFORE = 'before',
  AFTER = 'after'
}

const Slide = ({ sectionId, deck, child, dragStartId, modal, indexElement }: Props) => {
  const addSlideByIndex = (position: string) => {
    modal.open(
      ModalsList.CHOOSE_LAYOUT,
      (data: any) => {
        if (data) {
          console.log('Lay out Data, ', data);
          const index = position === positions.BEFORE ? indexElement : indexElement + 1;
          deck.addSlideByIndex(index, sectionId, data.layout.layout, data.name !== '' ? data.name : data.layout.name);
        }
      },
      { unrepeatable: deck.unrepeatable }
    );
  };

  const handleDelete = () => {
    modal.open(
      ModalsList.DIALOG_CONFIRM,
      (confirmed: any) => {
        if (confirmed) {
          deck.removeSlide(sectionId, child.uuid);
        }
      },
      { title: `Delete Slide` }
    );
  };

  const handleDuplicate = () => {
    deck.duplicateSlide(sectionId, child);
  };

  const handleVisibility = () => {
    deck.toggleSlideVisibility(sectionId, child.uuid);
  };

  const handleEditSlideName = () => {
    modal.open(ModalsList.SLIDE, (data: any) => {
      if (data) {
        deck.editSlideName(sectionId, child.uuid, data.name);
      }
    });
  };

  function getDropdownItems({ layoutId, hide }: SlideType) {
    const items = [];
    const duplicateDisabled = deck.unrepeatable.includes(layoutId);

    items.push({ text: 'Add slide before', icon: <Plus />, onClick: () => addSlideByIndex(positions.BEFORE) });
    items.push({ text: 'Add slide after', icon: <Plus />, onClick: () => addSlideByIndex(positions.AFTER) });
    items.push({ text: "Edit slide's name", icon: <Edit2 />, onClick: () => handleEditSlideName() });
    !duplicateDisabled && items.push({ text: 'Duplicate', icon: <Copy />, onClick: () => handleDuplicate() });
    items.push({ text: 'Delete', icon: <Trash2 />, onClick: () => handleDelete() });
    items.push({
      text: hide ? 'Show' : 'Hide',
      icon: hide ? <Eye /> : <EyeOff />,
      onClick: () => handleVisibility()
    });

    return items;
  }

  return (
    <div
      className={classNames('slide', {
        selected: deck.selected.sectionId === sectionId && deck.selected.slideId === child.uuid
      })}
      onClick={() => deck.selectSlide(sectionId, child.uuid)}
    >
      <div className="position">
        <div className={classNames('drag-icon', { hidden: dragStartId === child.uuid })}>
          <DragIcon />
        </div>
        <p className="body strong">{indexElement + 1}</p>
      </div>

      <div className={classNames('slide-thumbnail', { hidden: child.hide })}>
        <div className="body-2 name">{child.name}</div>
      </div>

      <div className={classNames('dropdown-wrapper', { hidden: dragStartId === child.uuid })}>
        <DropdownButton isPortal button={<MoreVertical />} items={getDropdownItems(child)} />
      </div>
    </div>
  );
};

export default Slide;
