import React, { useEffect, useState } from 'react';
import { Eye, Calendar, Clock } from 'react-feather';
import TabsSwitcher from 'components/common/tabsSwitcher';
import LineChart from 'components/common/lineChart';
import InfoChip from 'components/common/infoChip';
import useApi, { Methods } from 'hooks/useApi';
import useHellosign from 'hooks/useHellosign';

type Props = {
  deckId: string;
};

const Overall = ({ deckId }: Props) => {
  const getLoeSigned = useHellosign();
  const [loeStatus, setLoeStatus] = useState();
  const analyticsApi = useApi();

  useEffect(() => {
    getLoeSigned.fetchData({
      endpoint: `signature_request/list?query=metadata:${deckId}`,
      method: Methods.GET
    });
  }, []);

  useEffect(() => {
    if (getLoeSigned?.response?.signature_requests?.length > 0) {
      setLoeStatus(getLoeSigned?.response?.signature_requests[0]?.signatures[0]?.status_code.replace('_', ' '));
    }
  }, [getLoeSigned.response]);

  useEffect(() => {
    analyticsApi.fetchData({
      endpoint: `/analytics/overall/${deckId}`,
      method: Methods.GET
    });
  }, []);

  return (
    <div className="overall">
      <div className="info">
        <InfoChip title="Partners" value={analyticsApi.response?.data?.partnerViews} icon={<Eye />} />
        <div className="vl" />
        <InfoChip title="since opened" value={analyticsApi.response?.data?.sentDate} icon={<Calendar />} />
        <div className="vl" />
        <InfoChip title="since sent" value={analyticsApi.response?.data?.sinceSent} icon={<Calendar />} />
        <div className="vl" />
        <InfoChip title="avg time on deck" value={analyticsApi.response?.data?.avgTime} icon={<Clock />} />
      </div>
      <div className="chart">
        <TabsSwitcher
          data={[
            {
              title: 'Sections',
              component: (
                <LineChart
                  data={analyticsApi.response?.data?.section}
                  min={0}
                  max={analyticsApi?.response?.data?.section?.data[0]}
                />
              )
            },
            {
              title: 'Slides',
              component: (
                <LineChart
                  data={analyticsApi.response?.data?.slides}
                  min={0}
                  max={analyticsApi?.response?.data?.slides?.data[0]}
                />
              )
            }
          ]}
        />
      </div>
      <div className="info">
        <InfoChip title="LOE status" value={loeStatus ? loeStatus : '-'} secondary />
        <InfoChip title="Comments" value={analyticsApi?.response?.data?.comments} secondary />
        <div className="vl" />
        <InfoChip title="Region" value={analyticsApi?.response?.data?.region} secondary />
        <InfoChip title="Language" value={analyticsApi?.response?.data?.language} secondary />
        <InfoChip title="Device" value={analyticsApi?.response?.data?.device} secondary />
      </div>
    </div>
  );
};

export default Overall;
