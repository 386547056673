import joi from 'joi';
import { InputsEnum } from 'components/dynamicInputs/types';

export default {
  searchInformation: {
    value: {},
    input: InputsEnum.SEARCH_INFO,
    joi: joi.string().required()
  }
};
