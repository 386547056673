const reasons: any = {
  '1': 'Geography/Location',
  '2': 'Capital Structure',
  '3': 'Seniority',
  '4': 'Company Size/Scale',
  '5': 'Education',
  '6': 'Compensation',
  '7': 'Tenure'
};

export default reasons;
