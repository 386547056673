import React, { FC } from 'react';
import classNames from 'classnames';

type Props = {
  text?: string;
  secondary?: boolean;
  onlyIcon?: boolean;
  transparent?: boolean;
  primary?: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
};

const Button: FC<Props> = ({ text, secondary, onlyIcon, transparent, primary, icon, disabled, onClick }) => {
  return (
    <button
      className={classNames(
        'gallery-crop-button',
        { secondary },
        { transparent },
        { primary },
        { 'only-icon': onlyIcon }
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <>
        {!!icon && icon}
        {!!text && text}
      </>
    </button>
  );
};

export default Button;
