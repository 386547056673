import React, { useEffect, useState } from 'react';
import useDebounce from 'hooks/useDebounce';
import { Button, Table, Input } from 'components';
import { ComponentList, TableOptions } from 'components/common/table/types';
import { TextInputTypes } from 'components/dynamicInputs/types';
import Base from '../base';
import { Props } from '../types';

const tableOptions: TableOptions = {
  endpoint: '/candidates/search',
  searchable: false,
  columns: [
    { id: 'first_name', header: 'First name', type: ComponentList.TEXT, width: '131px', sortable: false },
    { id: 'last_name', header: 'Last name', type: ComponentList.TEXT, width: '131px', sortable: false },
    { id: 'work_email', header: 'Work email', type: ComponentList.TEXT, width: '131px', sortable: false },
    { id: 'country', header: 'Country', type: ComponentList.TEXT, width: '131px', sortable: false },
    { id: 'job_functions', header: 'Job functions', type: ComponentList.LIST, sortable: false }
  ],
  sort: {
    column: 'id',
    order: 'asc'
  },
  itemsPerPage: 100,
  hideFooter: true
};

const ImportCandidates = ({ closing, onClose }: Props) => {
  const [extraFilters, setExtraFilters] = useState([{ column: 'q', value: '' }]);
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.disabled = true;
    onClose(selected);
  };

  useEffect(() => {
    setExtraFilters([{ column: 'q', value: searchDebounced }]);
  }, [searchDebounced]);

  return (
    <Base
      className="modal-table"
      closing={closing}
      header={
        <div className="search-header">
          <h6>Import from Galaxy</h6>
          <Input type={TextInputTypes.TEXT} placeholder="Search candidate.." value={search} onChange={setSearch} />
        </div>
      }
      onClose={onClose}
      size="lg"
      footer={
        <Button
          text={!selected.length ? 'Import' : `Import (${selected.length} selected)`}
          disabled={!selected.length}
          onClick={handleClick}
        />
      }
    >
      <Table
        alternative
        options={tableOptions}
        selected={{ value: selected, change: setSelected }}
        extraFilters={extraFilters}
      />
    </Base>
  );
};

export default ImportCandidates;
