import React, { useState } from 'react';
import classnames from 'classnames';
import { Layouts, LayoutKeys, BasicLayouts } from 'constants/layouts';
import { Img } from 'components';
import { LayoutProps } from '../types';

const LayoutPicker = ({ unrepeatable, value, onChange }: LayoutProps) => {
  const [selectId, setSelectId] = useState<string>();
  return (
    <div className="input-layout-picker input-wrapper">
      <label>Layouts</label>

      <div className="grid">
        {Object.keys(Layouts).map(key => {
          const layout = Layouts[key as LayoutKeys] as any;

          if (key === BasicLayouts.COVER) {
            return null;
          }

          return (
            <div
              key={key}
              onClick={() => {
                setSelectId(key), onChange(layout);
              }}
              className={classnames({
                selected: key === value,
                selected_img: selectId === key,
                disabled: unrepeatable?.includes(key)
              })}
            >
              <Img src={layout.previewImage} />
              <p className="body-2">{layout.title}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LayoutPicker;
