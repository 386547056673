import joi from 'joi';
import { InputsEnum } from 'components/dynamicInputs/types';

export default {
  sendToClient: {
    value: [],
    input: InputsEnum.SEND_CLIENT,
    joi: joi.array().min(1).required()
  }
};
