import React from 'react';

const Shape3 = () => (
  <svg className="shape-3" width="126" height="463" viewBox="0 0 126 463" fill="none">
    <path
      opacity="0.1"
      d="M0 463L2.36113e-06 265C4.1064e-06 118.645 118.645 1.41482e-06 265 3.16009e-06L481 5.73587e-06L481 198C481 344.355 362.355 463 216 463L0 463Z"
      fill="url(#paint0_radial_2_13196)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_2_13196"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(142.408 117.712) rotate(37.6694) scale(465.502 453.213)"
      >
        <stop stopColor="#AC9FF1" />
        <stop offset="1" stopColor="#E1C4D3" />
      </radialGradient>
    </defs>
  </svg>
);

export default Shape3;
