import joi from 'joi';
import { InputsEnum } from 'components/dynamicInputs/types';

export default {
  theme: {
    value: '',
    input: InputsEnum.THEME_PICKER,
    joi: joi.string().required()
  }
};
