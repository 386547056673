import React from 'react';
import { useModal } from 'hooks/useModal';
import { ModalsList } from '../../../constants/modals';
import Expansible from '../../../components/shared/expansible';
import Sanitized from '../../shared/sanitized';
import BGMosaic from '../../shared/bgMosaic';
import { Trans } from 'components/common/trans';

type Signature = {
  name: string;
};

type Props = {
  title: string;
  content: string;
  source: string;
  selected: boolean;
  signatures: Signature[];
};

const LayoutSignatureMedia = ({ title, content, source, signatures }: Props) => {
  const modal = useModal();

  const handleSignatureClick = (partner: any) => () => {
    modal.open(ModalsList.PARTNER_PROFILE, () => {}, partner);
  };

  return (
    <div className="layout-signature">
      <BGMosaic />

      <div className="flex-wrapper">
        <div className="info">
          <div className="wrapper">
            <div className="title-class">
              <div className="title-name">
                <h5 data-event="click">{title}</h5>
              </div>
            </div>
            <Expansible classes="content body">
              <Sanitized html={content} />
            </Expansible>

            <div className="signatures">
              <p className="body">
                <Trans>Regards</Trans>,
              </p>
              <div className="content">
                {signatures?.map((signature, index) => (
                  <div key={index}>
                    <span className="signature" onClick={handleSignatureClick(signature)}>
                      {signature.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="signature-video">
          <div className="image-container">
            <img src={source} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutSignatureMedia;
