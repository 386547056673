/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState, useEffect } from 'react';
import { Play, Volume2, Pause, Square, VolumeX } from 'react-feather';
import { RecordingTimer } from 'components';

type Props = {
  videoUrl: string;
  autoPlay?: boolean;
  videoDuration?: number | null;
};

enum VideoActionControls {
  PLAY = 'Play',
  STOP = 'Stop',
  PAUSE = 'Pause',
  VOLUME = 'Volume',
  MUTE = 'MUTE'
}
let interval: ReturnType<typeof setInterval>;

const VideoPlayer = ({ videoUrl, autoPlay, videoDuration }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [playing, setPlaying] = useState(false);
  const [videoTime, setVideoTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isMuted, setMute] = useState(false);

  useEffect(() => {
    if (videoRef.current && playing) {
      interval = setInterval(function () {
        const theCurrentTime = videoRef.current?.currentTime ?? 0;

        if (Math.floor(theCurrentTime) === Math.floor(videoTime)) {
          videoHandler(VideoActionControls.STOP);
        } else {
          setCurrentTime(theCurrentTime);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [playing]);

  const handleLoadedMetadata = async () => {
    let durationTime = 0;
    const video = videoRef.current;
    if (video) {
      if (autoPlay) {
        videoHandler(VideoActionControls.PLAY);
      }

      if (video.duration === Infinity) {
        durationTime = videoDuration ?? 0;
      } else {
        durationTime = video.duration;
      }

      setVideoTime(durationTime);
    }
  };

  const videoHandler = (control: VideoActionControls) => {
    if (videoRef.current) {
      if (control === VideoActionControls.PLAY) {
        videoRef.current.play();
        setPlaying(true);
      }

      if (control === VideoActionControls.PAUSE) {
        videoRef.current.pause();
        setPlaying(false);
      }

      if (control === VideoActionControls.STOP) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
        setCurrentTime(0);
        setPlaying(false);
      }

      if (control === VideoActionControls.MUTE) {
        videoRef.current.muted = true;
        setMute(true);
      }

      if (control === VideoActionControls.VOLUME) {
        videoRef.current.muted = false;
        setMute(false);
      }
    }
  };

  return (
    <div className="video-player-container">
      <video className="video" src={videoUrl} ref={videoRef} onLoadedMetadata={handleLoadedMetadata}></video>
      {videoTime > 0 && (
        <div className="video-player-controls-wrapper">
          <div className="video-player-time-controls">
            <RecordingTimer currentTime={currentTime} durationTime={videoTime} />
          </div>
          <div className="video-player-controls">
            <button
              type="button"
              className="video-player-control"
              onClick={() => videoHandler(playing ? VideoActionControls.PAUSE : VideoActionControls.PLAY)}
            >
              {playing ? <Pause /> : <Play />}
            </button>
            <button
              type="button"
              className="video-player-control"
              onClick={() => videoHandler(VideoActionControls.STOP)}
            >
              <Square />
            </button>
            <button
              type="button"
              className="video-player-control"
              onClick={() => videoHandler(isMuted ? VideoActionControls.VOLUME : VideoActionControls.MUTE)}
            >
              {isMuted ? <VolumeX /> : <Volume2 />}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(VideoPlayer);
