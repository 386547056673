import React from 'react';

const Shape2 = () => (
  <svg className="shape-2" width="125" height="153" viewBox="0 0 125 153" fill="none">
    <path
      opacity="0.1"
      d="M0 470L3.18643e-06 202.792C4.5158e-06 91.3128 90.3714 0.941407 201.85 0.941409L480.271 0.941412L480.271 470L0 470Z"
      fill="url(#paint0_linear_2_13197)"
    />
    <defs>
      <linearGradient id="paint0_linear_2_13197" x1="-109.5" y1="56.5" x2="155" y2="386" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F7C0DA" />
        <stop offset="1" stopColor="#EB579E" stopOpacity="0.71" />
      </linearGradient>
    </defs>
  </svg>
);

export default Shape2;
