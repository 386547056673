/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import Button from 'components/common/button';
import { TabsSwitcher } from 'components';
import useApi, { Methods } from 'hooks/useApi';
import useFormHandler from 'hooks/useFormHandler';
import deckSchema from 'schemas/modals/deckInformation';
import searchSchema from 'schemas/modals/searchInformation';
import Base from '../base';
import { Props } from '../types';
import Deck from './deck';
import Contact from './contact';
import Search from './search';
import { useNavigate } from 'react-router-dom';

const CreateDeck = ({ closing, onClose, params }: Props) => {
  const fetchPartnerClients = useApi();
  const fetchCreateDeck = useApi();
  const fetchUpdateDeck = useApi();
  const [deckLabels, setDeckLabels] = useState<any>();
  const deckFormHandler = useFormHandler(deckSchema);
  const searchFormHandler = useFormHandler(searchSchema);
  const navigate = useNavigate();
  const [partners, setPartners] = useState<any>();
  const [companyId, setCompanyId] = useState<string>('');
  const [clients, setClients] = useState<any>();
  const hasPartners = searchFormHandler?.data?.searchInformation?.leadPartner?.length > 0;
  const hasProjectTitle = searchFormHandler?.data?.searchInformation?.jobTitle;
  const [galaxyId, setGalaxyId] = useState<any>();
  const [brandingThemeId, setBrandingThemeId] = useState();
  const [emailTemplateId, setEmailTemplateId] = useState();
  const [brandingThemeName, setBrandingThemeName] = useState();
  const [emailTemplateName, setEmailTemplateName] = useState();
  const [isReady, setIsReady] = useState(false);
  const fetchDefaultTheme = useApi();
  const fetchDefaultTemplete = useApi();
  const [masterTemplateName, setMasterTemplateName] = useState('');
  const [isMasterTemplatedSelected, setIsMasterTemplatedSelected] = useState(false);

  const saveDeckInfo = async () => {
    console.log('save Deck info');
    if (params.popupFrom === 'create') {
      await fetchCreateDeck.fetchData(
        { endpoint: '/decks/createTemplate', method: Methods.POST },
        { deckId: params.defaultDeckId }
      );
    } else {
      await fetchCreateDeck.fetchData(
        { endpoint: '/decks/save/slidesForGalaxyDeck', method: Methods.POST },
        { deckId: params.deckId, fromDeckId: params.defaultDeckId }
      );
    }
  };

  useEffect(() => {
    const { companyName, jobTitle, industry, region, country, jobDescription } =
      searchFormHandler.data.searchInformation;
    const tags = deckFormHandler.data.tags;
    if (fetchCreateDeck?.response?.id) {
      console.log(`deck id : ${fetchCreateDeck?.response?.id}`);
      fetchUpdateDeck.fetchData(
        {
          endpoint: `/decks/updateInformation`,
          method: Methods.POST
        },
        {
          deckid: fetchCreateDeck?.response?.id.toString(),
          search_galaxy_id: searchFormHandler.data.searchInformation.searchGalaxyId,
          job_title: jobTitle,
          company_name: companyName,
          company_galaxy_id: companyId,
          tags,
          function: searchFormHandler.data.searchInformation.function,
          industry: industry,
          region: region !== undefined ? region : '',
          country: country,
          job_description: jobDescription,
          partners: partners,
          clients: clients,
          reqType: 'create',
          branding_theme_id: brandingThemeId,
          email_template_id: emailTemplateId,
          is_master_template: isMasterTemplatedSelected,
          template_name: masterTemplateName
        }
      );
    }
  }, [fetchCreateDeck?.response]);

  useEffect(() => {
    // console.log('fetchUpdateDeck?.response :', fetchUpdateDeck?.response);
    if (fetchCreateDeck?.response?.id) {
      if (params.popupFrom === 'create') {
        navigate(`/deck/${fetchCreateDeck?.response?.id}`);
      } //else {
      //   window.location.reload();
      // }
      onClose({
        deckId: fetchCreateDeck?.response?.id
      });
    }
  }, [fetchUpdateDeck?.response]);

  useEffect(() => {
    fetchDefaultTheme.fetchData({ endpoint: '/decks/getSelectedThemeId', method: Methods.POST }, {});
    fetchDefaultTemplete.fetchData({ endpoint: '/decks/getSelectedTemplateId', method: Methods.POST }, {});
    setDeckLabels({
      deckInformation: {
        search: '',
        status: '',
        creationDate: '',
        owner: '',
        updates: ''
      }
    });
    deckFormHandler.setFullForm({
      tags: []
    });
    searchFormHandler.setFullForm({
      searchInformation: {
        jobTitle: '',
        companyName: '',
        companyId: '',
        function: '',
        industry: '',
        region: '',
        country: '',
        jobDescription: '',
        leadPartner: ''
      }
    });
  }, []);

  useEffect(() => {
    // console.log('fetchDefaultTheme?.response?.branding[0].id..', fetchDefaultTheme?.response?.brandingThemes[0].id);
    if (fetchDefaultTheme?.response) {
      // console.log('fetchDefaultTheme?.response?.branding[0].id..', fetchDefaultTheme?.response?.brandingThemes[0].id);
      setBrandingThemeId(fetchDefaultTheme?.response?.brandingThemes[0].id);
      setBrandingThemeName(fetchDefaultTheme?.response?.brandingThemes[0].theme_name);
      setIsReady(true);
    }
  }, [fetchDefaultTheme?.loaded]);

  useEffect(() => {
    if (fetchDefaultTemplete?.response) {
      setEmailTemplateId(fetchDefaultTemplete?.response?.emailTemplate[0].id);
      setEmailTemplateName(fetchDefaultTemplete?.response?.emailTemplate[0].email_template);
      setIsReady(true);
    }
  }, [fetchDefaultTemplete?.loaded]);

  useEffect(() => {
    if (searchFormHandler.data.searchInformation.searchGalaxyId !== undefined) {
      // console.log("++++++",searchFormHandler.data.searchInformation.searchGalaxyId);
      setGalaxyId(searchFormHandler.data.searchInformation.searchGalaxyId);
      setCompanyId(searchFormHandler.data.searchInformation.companyId);
      fetchPartnerClients.fetchData({
        endpoint: `/decks/partnerbyid/${searchFormHandler.data.searchInformation.searchGalaxyId}`,
        method: Methods.GET
      });
    }
  }, [searchFormHandler.data.searchInformation.searchGalaxyId]);

  useEffect(() => {
    setPartners(fetchPartnerClients.response?.partners);
    setClients(fetchPartnerClients.response?.clients);
  }, [fetchPartnerClients.response]);

  useEffect(() => {
    setPartners(partners);
    setClients(clients);
  }, [partners, clients]);

  let onChangePartner = (data: any) => {
    if (data !== undefined) {
      setPartners(data);
    }
  };

  let onChangeClient = (data: any) => {
    if (data !== undefined) {
      setClients(data);
    }
  };

  const onChangeBrandingTheme = (data: any) => {
    setBrandingThemeId(data);
  };

  const onChangeEmailTemplate = (data: any) => {
    setEmailTemplateId(data);
  };

  const onSetMasterTemplateName = (data: any) => {
    setMasterTemplateName(data);
  };

  const onSetIsMasterTemplatedSelected = (data: any) => {
    setIsMasterTemplatedSelected(data);
  };

  const onSetIsReady = (data: any) => {
    setIsReady(data);
  };

  return (
    <Base
      header="SyncLink Information"
      footer={
        <Button
          text="Save information"
          onClick={saveDeckInfo}
          disabled={
            fetchCreateDeck.isLoading ||
            !hasPartners ||
            !hasProjectTitle ||
            (isMasterTemplatedSelected && masterTemplateName === '')
          }
        />
      }
      closing={closing}
      onClose={onClose}
      size="lg"
    >
      <div className="modal-deck-information">
        <TabsSwitcher
          forceRenderTabPanel
          defaultIndex={params.defaultIndex}
          data={[
            {
              title: 'SyncLink Information',
              component: (
                <Deck
                  formHandler={deckFormHandler}
                  deckLabels={deckLabels}
                  deckId={params.deckId}
                  brandingThemeName={brandingThemeName}
                  emailTemplateName={emailTemplateName}
                  onChangeBrandingTheme={onChangeBrandingTheme}
                  onChangeEmailTemplate={onChangeEmailTemplate}
                  isReady={isReady}
                  setIsReady={onSetIsReady}
                  onSetMasterTemplateName={onSetMasterTemplateName}
                  onSetIsMasterTemplatedSelected={onSetIsMasterTemplatedSelected}
                />
              )
            },
            {
              title: 'Project Information',
              component: <Search formHandler={searchFormHandler} />
            },
            {
              title: 'Contact Information',
              component: (
                <Contact
                  clients={clients || []}
                  partners={partners || []}
                  onChangePartner={onChangePartner}
                  onChangeClients={onChangeClient}
                  companyId={companyId || ''}
                  galaxy_id={galaxyId}
                />
              )
            }
          ]}
        />
      </div>
    </Base>
  );
};

export default CreateDeck;
