import React, { useContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Portal } from 'react-portal';
import { useParams, useSearchParams } from 'react-router-dom';
import { ModalsList } from 'constants/modals';
import { useRefs } from 'hooks/useRefs';
import useDeck from 'hooks/useDeck';
import useConfirm from 'hooks/useConfirm';
import { useModal } from 'hooks/useModal';
import { DeckToolbar, LoadingLogo, SlideForm, SlidesNav, SlidePreview } from 'components';
import useApi from 'hooks/useApi';
import { LogoContext } from 'app';

const DeckPage = () => {
  const { toolbarMiddleRef } = useRefs();
  const deck = useDeck();
  const modal = useModal();
  const selectedSlide = deck.getSelectedSlide();
  const layoutType = deck.getSelectedLayoutType();
  const firstRender = useRef(true); // this is a hack to prevent the first render from triggering the useEffect below
  const [loaded, setLoaded] = useState(false);
  const { id: deckId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const isNew = searchParams.has('newDeck');
  const fetchBrandingThemeData = useApi();
  const [count, setCount] = useState(0);
  const { logo, setLogo } = useContext(LogoContext);
  const [isLanguageChanged, setIsLanguageChanged] = useState(false);
  const [isDeckUpdated, setIsDeckUpdated] = useState(false);
  const fetchFirstVisitDetail = useApi();

  useConfirm(deck.hasChanges, deck.save);

  useEffect(() => {
    console.log('DECK------', logo);
  });

  useEffect(() => {
    fetchBrandingThemeData.fetchData({
      endpoint: `/decks/getBrandingTheme/${deckId}`
    });
    fetchFirstVisitDetail.fetchData({ endpoint: `/decks/getFirstVisitDetail/${deckId}` });
    // deck.fetchDeck();
  }, []);

  useEffect(() => {
    if (fetchFirstVisitDetail.response) {
      if (fetchFirstVisitDetail.response.firstVisit) {
        modal.open(
          ModalsList.CREATE_DECK_TEMPLATE,
          (data: any) => {
            if (data) {
              deck.fetchDeck();
            }
          },
          { popupFrom: 'firstVisit', deckId: deckId }
        );
      } else {
        deck.fetchDeck();
      }
    }
  }, [fetchFirstVisitDetail?.response]);

  useEffect(() => {
    if (deck.data) {
      setLoaded(true);
    }
  }, [deck.data]);

  useEffect(() => {
    if (fetchBrandingThemeData?.response && count === 0) {
      let theme = fetchBrandingThemeData?.response?.brandingThemes[0];

      document.documentElement.style.setProperty('--headeer-bar-color', theme.header_bar_color);
      localStorage.setItem('headerbarColor', theme.header_bar_color);
      document.documentElement.style.setProperty('--primary-button-color', theme.primary_button_color);
      localStorage.setItem('primaryButtonColor', theme.primary_button_color);
      document.documentElement.style.setProperty('--secondary-button-color', theme.secondary_button_color);
      localStorage.setItem('secondaryButtoncolor', theme.secondary_button_color);
      document.documentElement.style.setProperty('--title-text-color', theme.title_text_color);
      localStorage.setItem('titleTextColor', theme.title_text_color);
      document.documentElement.style.setProperty('--body-text-color', theme.body_text_color);
      localStorage.setItem('bodyTextColor', theme.body_text_color);
      localStorage.setItem('logoUrl', theme.logo);
      if (theme.font === 'Satoshi') {
        document.documentElement.style.setProperty('--font', theme.font);
      } else if (theme.font === 'Bitter') {
        document.documentElement.style.setProperty('--font', 'Bitter');
      } else if (theme.font === 'OpenSans') {
        document.documentElement.style.setProperty('--font', 'OpenSans');
      } else if (theme.font === 'Roboto') {
        document.documentElement.style.setProperty('--font', 'Roboto');
      } else {
        document.documentElement.style.setProperty('--font', 'Satoshi');
      }
      setLogo('1');
      setCount(1);
    }
  }, [fetchBrandingThemeData?.response]);

  useEffect(() => {
    if (!selectedSlide?.uuid && firstRender.current && deck.sections[0]) {
      const slideId = deck.sections[0].slides[0].uuid;

      deck.selectSlide('0', slideId);
      firstRender.current = false;

      if (isNew) {
        modal.open(
          ModalsList.DECK_INFORMATION,
          (data: any) => {
            if (data) {
              deck.updateData({
                ...deck.data,
                job_title: data.search.searchInformation.jobTitle
              });
            }
            if (searchParams.has('newDeck')) {
              searchParams.delete('newDeck');
              setSearchParams(searchParams);
            }
          },
          { deckId: deckId, defaultIndex: 1 }
        );
      }
    }
  }, [selectedSlide?.uuid, deck.sections]);

  const onChangeLanguageState = (data: any) => {
    setIsLanguageChanged(data);
  };

  const onChangeDeckState = (data: any) => {
    setIsDeckUpdated(data);
  };

  return (
    <>
      <Portal node={toolbarMiddleRef.current}>
        <DeckToolbar
          deck={deck}
          onSave={deck.save}
          slideSelected={selectedSlide}
          onChangeLanguageState={onChangeLanguageState}
          onChangeDeckState={onChangeDeckState}
        />
      </Portal>

      <div className="page-deck">
        <div className={classnames('loading-cover', { loaded })}>
          <LoadingLogo />
        </div>

        {isLanguageChanged && (
          <div className={classnames('loading-cover')}>
            <LoadingLogo />
          </div>
        )}

        {isDeckUpdated && (
          <div className={classnames('loading-cover')}>
            <LoadingLogo />
          </div>
        )}

        {!isLanguageChanged && !isDeckUpdated && <SlidesNav deck={deck} />}

        {deck.isAnySlideSelected && !isLanguageChanged && !isDeckUpdated && (
          <>
            <SlidePreview layoutId={selectedSlide?.layoutId} raw={selectedSlide?.raw} deck={deck} />
            <SlideForm layoutType={layoutType} handler={deck.formHandler} />
          </>
        )}
      </div>
    </>
  );
};

export default DeckPage;
