import React, { createContext, Dispatch, useContext, useEffect, useState } from 'react';
import { getToken, onMessage, deleteToken } from 'firebase/messaging';
import useApi, { Methods } from './useApi';
import useEventListener from 'hooks/useEventListener';
import useIndexedDB from './useIndexedDB';
import { indexedDBConfig } from 'constants/notifications';
import { messaging, vapidKey } from 'constants/firebase';

export type NotificationType = {
  id: number;
  title: string;
  userName: string;
  candidateName: string | null;
  deckName: string;
  body: string;
  deckId: string;
  date: string;
  read: boolean;
  isNew: boolean;
  type: NotificationEnum;
};

export type Values = {
  subscribe: () => Promise<void>;
  unsubscribe: () => Promise<void>;
  setNotifications: Dispatch<React.SetStateAction<NotificationType[]>>;
  fetchNotifications: () => Promise<void>;
  notifications: NotificationType[];
};

export enum NotificationEnum {
  COMMENT = 'comment',
  LIKE = 'like',
  DISLIKE = 'dislike',
  SIGN = 'sign'
}

const NotificationContext = createContext<Values>({} as Values);

export const useNotifications = () => {
  const notif = useContext(NotificationContext);
  return notif;
};

export default ({ children }: { children: React.ReactNode }) => {
  const tokenApi = useApi();
  const notificationApi = useApi();
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const { getIndexedDB, updateById } = useIndexedDB(indexedDBConfig);

  const getNotificationsDB = async () => {
    const notifications = await getIndexedDB();
    const newNotifications = notifications.filter((notification: NotificationType) => notification.isNew);

    if (newNotifications.length) {
      newNotifications.forEach(async (notification: NotificationType) => {
        await updateById('notification', { ...notification, isNew: false });
      });

      await fetchNotifications();
    }
  };

  useEventListener('visibilitychange', getNotificationsDB);

  const subscribe = async () => {
    try {
      const token = await getToken(messaging, { vapidKey });

      await tokenApi.fetchData({ endpoint: '/notifications/save', method: Methods.POST }, { token });
    } catch (err) {
      console.error(err);
    }
  };

  const unsubscribe = async () => {
    try {
      const token = await getToken(messaging, { vapidKey });

      await deleteToken(messaging);
      await tokenApi.fetchData({ endpoint: '/notifications/delete', method: Methods.POST }, { token });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchNotifications = async () => {
    await notificationApi.fetchData({ endpoint: '/notifications', method: Methods.GET });
  };

  useEffect(() => {
    onMessage(messaging, async payload => {
      const { notification, data } = payload;

      setNotifications(prev => [...prev, { ...notification, ...data, read: false, isNew: true }] as NotificationType[]);
    });
  }, [notifications]);

  useEffect(() => {
    if (notificationApi.response) {
      setNotifications(notificationApi.response.notifications);
    }
  }, [notificationApi.response]);

  return (
    <NotificationContext.Provider
      value={{
        subscribe,
        unsubscribe,
        setNotifications,
        fetchNotifications,
        notifications
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
