import React, { useEffect, useState } from 'react';
import { Trans } from 'components/common/trans';
import classNames from 'classnames';
import { ChevronRight } from 'react-feather';
import { useModal } from 'hooks/useModal';
import { SubCategory } from '../../layouts/experience';
import { ModalsList } from '../../../constants/modals';
import Badge from '../badge';

const SubCategoryCard = ({ id, name, companies }: SubCategory) => {
  const modal = useModal();
  const [sliceNumber, setSliceNumber] = useState(3);

  const handleModalSubCategory = () => {
    if (companies.length) {
      modal.open(ModalsList.SUB_CATEGORY, () => {}, { id, name, companies });
    }
  };

  useEffect(() => {
    if (window.partner) {
      setSliceNumber(2);
    }
  }, []);

  return (
    <div className="sub-category-card" onClick={handleModalSubCategory}>
      <div
        className={classNames('card', {
          partner: window.partner
        })}
      >
        <div className="badge-container">
          <Badge text={name} />
        </div>

        <div className="card-body">
          {[...companies].slice(0, sliceNumber).map((item, index) => (
            <div key={index} className="company">
              <span className="company-name body">{item.name}</span>
              <span className="body-2 strong company-roles">+{item.roles.length} roles</span>
            </div>
          ))}
        </div>

        <div className="show-more">
          {companies.length > 0 && (
            <>
              <span className="body-2 strong">
                <Trans>Show more</Trans>
              </span>
              <ChevronRight />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubCategoryCard;
