/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import DynamicForm from 'components/common/dynamicForm';
import { Calendar, CheckCircle, Edit, Lock, Search, Tag } from 'react-feather';
import AutoComplete from 'components/dynamicInputs/autoComplete';
import useApi, { Methods } from 'hooks/useApi';
import { Theme } from 'constants/themes';
import Select from 'react-select';

const Deck = ({
  formHandler,
  deckLabels,
  brandingThemeName,
  emailTemplateName,
  onChangeBrandingTheme,
  onChangeEmailTemplate,
  isReady,
  masterTemplateName,
  onSetMasterTemplateName,
  onSetIsMasterTemplatedSelected
}: any) => {
  const { search, status, creationDate, owner, updates } = deckLabels?.deckInformation ?? 0 > 0;
  const getBrandingThemes = useApi();
  const getEmailTemplates = useApi();
  const getUserRole = useApi();
  const [options, setOptions] = useState<any>();
  const [emailoptions, setEmailOptions] = useState<any>();
  const [brandingThemeOptions, setBrandingThemeOptions] = useState<any>();
  const [emailTemplateOptions, setEmailTemplateOptions] = useState<any>();
  const [brandingThemeIdTemp, setBrandingThemeIdTemp] = useState();
  const [emailTemplateIdTemp, setEmailTemplateIdTemp] = useState();
  const [count, setCount] = useState(0);
  const [isMasterTemplatedSelected, setIsMasterTemplatedSelected] = useState(false);

  useEffect(() => {
    getBrandingThemes.fetchData({ endpoint: `/decks/getBrandingThemes`, method: Methods.POST });
    getEmailTemplates.fetchData({ endpoint: `/decks/getEmailTemplates`, method: Methods.POST });
    getUserRole.fetchData({ endpoint: `/decks/getCognitoUserRole`, method: Methods.GET });
  }, []);

  useEffect(() => {
    console.log(getBrandingThemes.response);
    if (getBrandingThemes.response !== undefined) {
      setBrandingThemeOptions(getBrandingThemes.response?.branding);
      let temp = getBrandingThemes.response?.branding.map((d: any) => ({
        value: { id: d.id },
        label: d.theme_name
      }));
      setOptions(temp);
      console.log('from temp', temp);
    }
  }, [getBrandingThemes.response]);

  const handleBrandingTheme = (e: any) => {
    console.log('in the deck: ', e);
    setBrandingThemeIdTemp(e.name);
    onChangeBrandingTheme(e.id);
  };

  const handleEmailTemplateTheme = (e: any) => {
    console.log('in the deck: ', e);
    setEmailTemplateIdTemp(e.name);
    onChangeEmailTemplate(e.id);
  };

  useEffect(() => {
    console.log('Hello1');
    if (isReady && count === 0) {
      setBrandingThemeIdTemp(brandingThemeName);
      setEmailTemplateIdTemp(emailTemplateName);
      console.log('Hello2');
      setCount(1);
    }
  }, [isReady]);

  useEffect(() => {
    console.log(getEmailTemplates.response);
    if (getEmailTemplates.response !== undefined) {
      setEmailTemplateOptions(getEmailTemplates.response?.emailTemplates);
      let temp = getEmailTemplates.response?.emailTemplates.map((d: any) => ({
        value: { id: d.id },
        label: d.email_template
      }));
      setEmailOptions(temp);
    }
  }, [getEmailTemplates.response]);

  useEffect(() => {
    console.log('masterTemplateName: ', masterTemplateName);
    if (formHandler.data.tags.length !== 0) {
      const elementSelected = formHandler.data.tags.find((element: any) => {
        return element === 11;
      });

      if (elementSelected !== undefined && elementSelected === 11) {
        setIsMasterTemplatedSelected(true);
        onSetIsMasterTemplatedSelected(true);
      } else {
        setIsMasterTemplatedSelected(false);
        onSetIsMasterTemplatedSelected(false);
      }
    } else {
      setIsMasterTemplatedSelected(false);
      onSetIsMasterTemplatedSelected(false);
    }
  });

  const onChangeInput = (event: any) => {
    onSetMasterTemplateName(event.target.value);
  };

  return (
    <div className="deck-information">
      <div className="row">
        <div className="label">
          <Search />
          <span className="body strong">Search #</span>
        </div>
        <div className="value">{search}</div>
      </div>
      <div className="row">
        <div className="label">
          <CheckCircle />
          <span className="body strong">Status</span>
        </div>
        <div className="value">{status}</div>
      </div>
      <div className="row">
        <div className="label">
          <Lock />
          <span className="body strong">Owner</span>
        </div>
        <div className="value">{owner}</div>
      </div>
      <div className="row">
        <div className="label">
          <Calendar />
          <span className="body strong">Created</span>
        </div>
        <div className="value">{creationDate}</div>
      </div>
      <div className="row">
        <div className="label">
          <Edit />
          <span className="body strong">Updated</span>
        </div>
        <div className="value">{updates}</div>
      </div>
      <div className="row">
        <div className="label">
          <Tag />
          <span className="body strong">Tags</span>
        </div>
        <div className="value">
          <DynamicForm handler={formHandler} />
        </div>
      </div>
      <br />
      {getUserRole.response !== undefined && getUserRole.response.userRole === 'admin' && isMasterTemplatedSelected && (
        <div className="row">
          <div className="label-new">
            <span className="body strong">Template Name</span>
          </div>
          <div className="value">
            <div className="theme-info">
              <input className="template-input" type="text" value={masterTemplateName} onChange={onChangeInput} />
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="label-new">
          <span className="body strong">Branding Theme</span>
        </div>
        <div className="value">
          <div className="theme-info">
            <AutoComplete
              name="brandingTheme"
              label=""
              value={{ name: brandingThemeIdTemp } || {}}
              customOptions={brandingThemeOptions?.map((item: any) => ({
                id: item?.id,
                name: item?.theme_name
              }))}
              onChange={obj => handleBrandingTheme(obj)}
              isMulti={false}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="label-new">
          <span className="body strong">Email Template</span>
        </div>
        <div className="value">
          <div className="theme-info">
            <AutoComplete
              name="emailTemplate"
              label=""
              value={{ name: emailTemplateIdTemp } || {}}
              customOptions={emailTemplateOptions?.map((item: any) => ({
                id: item?.id,
                name: item?.email_template
              }))}
              onChange={obj => handleEmailTemplateTheme(obj)}
              isMulti={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deck;
