import React, { ChangeEvent } from 'react';

type Props = {
  text?: string;
  icon?: React.ReactNode;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const FileButton = ({ text, icon, onChange }: Props) => {
  return (
    <label className="file-button gallery-crop-button secondary">
      <input onChange={onChange} type="file" accept="image/png, image/gif, image/jpeg, image/jpg, image/svg" />
      <span className="file-button-text">
        {!!icon && icon}
        {!!text && text}
      </span>
    </label>
  );
};

export default FileButton;
