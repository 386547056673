import React from 'react';

const Shape1 = () => (
  <svg className="shape-1" viewBox="0 0 377 152" fill="none">
    <path opacity="0.1" d="M-5 0H112C258.355 0 377 118.645 377 265V408H-5V0Z" fill="url(#paint0_radial_2_13198)" />
    <defs>
      <radialGradient
        id="paint0_radial_2_13198"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-6.46201 64.8117) rotate(51.95) scale(443.803 429.632)"
      >
        <stop stopColor="#2B35A8" />
        <stop offset="1" stopColor="#9E68C3" />
      </radialGradient>
    </defs>
  </svg>
);

export default Shape1;
