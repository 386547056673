import React, { useEffect, useState, useRef } from 'react';
import { Portal } from 'react-portal';
import classnames from 'classnames';
import useWindowSize from '@revolt-digital/use-window-size';
import { BasicLayouts, CompoundLayouts } from 'constants/layouts';

import Cover from 'clientSide/components/layouts/cover';
import Candidates from 'clientSide/components/layouts/candidates';
import Experience from 'clientSide/components/layouts/experience';
import ExperienceNew from 'clientSide/components/layouts/experienceNew';
import Global from 'clientSide/components/layouts/global';
import Media from 'clientSide/components/layouts/media';
import Process from 'clientSide/components/layouts/process';
import Signature from 'clientSide/components/layouts/signature';
import SourceEnvironment from 'clientSide/components/layouts/sourceEnvironment';
import Team from 'clientSide/components/layouts/team';
import Loe from 'clientSide/components/layouts/loe';
import SignatureMedia from 'clientSide/components/layouts/signatureMedia';

type Props = {
  deck?: any;
  layoutId?: string;
  raw?: any;
};

const PREVIEW_WIDTH_DESKTOP = 1040;
const PREVIEW_WIDTH_MOBILE = 414;

const SlidePreview = ({ layoutId, deck, raw = {} }: Props) => {
  const ref = useRef<any>(null);
  const windowSize = useWindowSize();
  const [mobile, setMobile] = useState(false);
  const [previewWidth, setPreviewWidth] = useState(0);
  const [rootPreviewStyle, setRootPreviewStyle] = useState({ top: 0, left: 0, transform: 'scale(0)' });
  const [rawData, setRawData] = useState(raw);
  const theme = deck!.data?.theme;

  function resizePreview() {
    let width;

    if (mobile) {
      width = (414 * ref.current.offsetHeight) / 736;
      setPreviewWidth(width);
    } else {
      width = (16 * ref.current.offsetHeight) / 9;
      setPreviewWidth(width);
    }
  }

  useEffect(() => {
    setRawData(raw);
  }, [raw, deck.data]);

  useEffect(() => {
    const rect = ref.current.children[0].getBoundingClientRect();
    const DELAY = 250;
    let aux: any = { top: rect.top, left: rect.left };

    if (mobile) {
      setTimeout(() => {
        aux.scale = `scale(${ref.current.children[0].offsetWidth / PREVIEW_WIDTH_MOBILE})`;
        setRootPreviewStyle(aux);
      }, DELAY);
    } else {
      setTimeout(() => {
        aux.transform = `scale(${ref.current.children[0].offsetWidth / PREVIEW_WIDTH_DESKTOP})`;
        setRootPreviewStyle(aux);
      }, DELAY);
    }
    setMobile(false);
  }, [previewWidth]);

  useEffect(() => {
    if (windowSize.width) {
      resizePreview();
    }
  }, [windowSize]);

  useEffect(() => {
    resizePreview();
  }, [mobile]);

  return (
    <>
      <Portal node={document.querySelector('#root')}>
        <div
          id="root-preview"
          className={classnames(theme ? theme.toLowerCase().replace('_', '-') : 'brand-1', { mobile })}
          style={rootPreviewStyle}
        >
          {
            {
              [BasicLayouts.CANDIDATES]: <Candidates {...rawData} selected={true} />,
              [BasicLayouts.COVER]: <Cover {...rawData} selected={true} />,
              [BasicLayouts.RELEVANT_EXPERIENCE]: <Experience {...rawData} selected={true} />,
              [BasicLayouts.RELEVANT_EXPERIENCE_NEW]: <ExperienceNew {...rawData} selected={true} />,
              [BasicLayouts.SOURCE_ENVIRONMENT]: <SourceEnvironment {...rawData} selected={true} />,
              [BasicLayouts.LETTER_ENGAGEMENT]: <Loe {...rawData} selected={true} />,
              [CompoundLayouts.DIVERSITY_AND_INCLUSION]: <Media {...rawData} selected={true} />,
              [CompoundLayouts.TEXT_IMAGE]: <Media {...rawData} selected={true} />,
              [CompoundLayouts.IMAGE_TEXT]: <Media {...rawData} selected={true} />,
              [CompoundLayouts.TEXT_IMAGE_SIGNATURE]: <SignatureMedia {...rawData} selected={true} />,
              [CompoundLayouts.TEXT_VIDEO]: <Media {...rawData} selected={true} />,
              [CompoundLayouts.VIDEO_TEXT]: <Media {...rawData} selected={true} />,
              [BasicLayouts.GLOBAL]: <Global {...rawData} selected={true} />,
              [BasicLayouts.SF_PROCESS]: <Process {...rawData} selected={true} />,
              [BasicLayouts.TEAM]: <Team {...rawData} selected={true} />,
              [BasicLayouts.WELCOME_LETTER]: <Signature {...rawData} selected={true} />,
              [CompoundLayouts.CLIENT_SUITE]: <Media {...rawData} selected={true} />,
              [CompoundLayouts.CANDIDATE_SUITE]: <Media {...rawData} selected={true} />
            }[layoutId as string]
          }
        </div>
      </Portal>
      <div className="slide-preview">
        <div ref={ref} className="preview">
          <div className={classnames({ mobile })} style={{ maxWidth: previewWidth }} />
        </div>
      </div>
    </>
  );
};

export default SlidePreview;
