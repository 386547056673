import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

type Props = {
  droppableId: string;
  direction?: 'vertical' | 'horizontal';
  type?: string;
  children: React.ReactNode;
  dropZoneHeight?: number;
};

const DropWrapper = ({ droppableId, direction, children, type, dropZoneHeight }: Props) => {
  const minHeight = { minHeight: dropZoneHeight };

  return (
    <Droppable droppableId={droppableId} direction={direction} type={type}>
      {provided => {
        return (
          <div style={minHeight} ref={provided.innerRef} {...provided.droppableProps}>
            {children}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};

export default DropWrapper;
