import React from 'react';
import classnames from 'classnames';
import { ButtonHTMLAttributes } from 'react';

type Props = {
  text?: string | React.ReactNode;
  icon?: React.ReactNode;
  variant?: 'default' | 'alternative' | 'link';
  disabled?: boolean;
  contrast?: boolean;
  small?: boolean;
  transparent?: boolean;
  onClick?: () => void;
  rest?: ButtonHTMLAttributes<HTMLButtonElement>;
};

const Button = React.forwardRef(
  (
    {
      text,
      icon,
      onClick = () => {},
      variant = 'default',
      contrast = false,
      disabled = false,
      small = false,
      transparent = false,
      ...rest
    }: Props,
    ref
  ) => (
    <button
      ref={ref as any}
      onClick={onClick}
      disabled={disabled}
      className={classnames(
        'button',
        'body-2',
        'strong',
        variant,
        { transparent },
        { small },
        { 'icon-only': !!icon && !text },
        { contrast: variant === 'link' && contrast }
      )}
      {...rest}
    >
      {!!icon && icon}
      {!!text && text}
    </button>
  )
);

export default Button;
