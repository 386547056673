import React from 'react';
import useFormHandler from 'hooks/useFormHandler';
import schema from 'schemas/modals/newLayout';
import { Button, DynamicForm } from 'components';
import Base from '../base';
import { Props } from '../types';

const ChooseLayout = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler({
    ...schema,
    layout: {
      ...schema.layout,
      ...params
    }
  });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.disabled = true;
    onClose(formHandler.data);
  };

  return (
    <Base
      header="Choose a layout for your new slide"
      closing={closing}
      onClose={onClose}
      size="lg"
      footer={<Button text="Done" disabled={!formHandler.isChange} onClick={handleClick} />}
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default ChooseLayout;
