enum locales {
  EN = 'en',
  ES = 'es',
  ES_MX = 'es-MX',
  PT = 'pt',
  PT_PT = 'pt-PT'
}

export const languages = [
  { value: locales.EN, label: 'English' },
  { value: locales.ES, label: 'Español' },
  { value: locales.ES_MX, label: 'Español (MX)' },
  { value: locales.PT, label: 'Português' },
  { value: locales.PT_PT, label: 'Português (PT)' }
];

export default locales;
