import Cover from 'assets/images/layouts/cover.svg';
import ImageSignature from 'assets/images/layouts/welcome-letter.svg';
import DiversityInclusion from 'assets/images/layouts/diversity-and-inclusion.svg';
import Map from 'assets/images/layouts/global.svg';
import RelevantExperience from 'assets/images/layouts/relevant-experience.svg';
import newRelevantExperience from 'assets/images/layouts/relevant-experience-new.png';
import SfProcess from 'assets/images/layouts/synclink_process_thumnail.png';
import clientSuite from 'assets/images/layouts/synclink_clientsuite_Thumnail.png';
import candidateSuite from 'assets/images/layouts/synclink_candidatesuite_thumnail.png';
import SourceEnvironment from 'assets/images/layouts/source-environment.svg';
import LetterEngagement from 'assets/images/layouts/letter-engagement.svg';
import TeamCandidate from 'assets/images/layouts/team.svg';
import Candidates from 'assets/images/layouts/candidates.svg';
import TextImage from 'assets/images/layouts/text-image.svg';
import ImageText from 'assets/images/layouts/image-text.svg';
import TextVideo from 'assets/images/layouts/text-video.svg';
import VideoText from 'assets/images/layouts/video-text.svg';
import * as schemas from 'schemas/layouts';

export enum BasicLayouts {
  CANDIDATES = 'CANDIDATES',
  COVER = 'COVER',
  RELEVANT_EXPERIENCE = 'RELEVANT_EXPERIENCE',
  RELEVANT_EXPERIENCE_NEW = 'RELEVANT_EXPERIENCE_NEW',
  LETTER_ENGAGEMENT = 'LETTER_ENGAGEMENT',
  WELCOME_LETTER = 'WELCOME_LETTER',
  TEAM = 'TEAM',
  GLOBAL = 'GLOBAL',
  SF_PROCESS = 'SF_PROCESS',
  SOURCE_ENVIRONMENT = 'SOURCE_ENVIRONMENT'
}

export enum CompoundLayouts {
  TEXT_IMAGE = 'TEXT_IMAGE',
  IMAGE_TEXT = 'IMAGE_TEXT',
  TEXT_VIDEO = 'TEXT_VIDEO',
  VIDEO_TEXT = 'VIDEO_TEXT',
  TEXT_IMAGE_SIGNATURE = 'TEXT_IMAGE_SIGNATURE',
  DIVERSITY_AND_INCLUSION = 'DIVERSITY_AND_INCLUSION',
  CLIENT_SUITE = 'CLIENT_SUITE',
  RELEVANT_EXPERIENCE = 'RELEVANT_EXPERIENCE',
  SOURCE_ENVIRONMENT = 'SOURCE_ENVIRONMENT',
  CANDIDATE_SUITE = 'CANDIDATE_SUITE'
}

export type LayoutKeys = BasicLayouts | CompoundLayouts;

export const Layouts = {
  [BasicLayouts.COVER]: {
    layout: BasicLayouts.COVER,
    previewImage: Cover,
    title: 'Cover',
    schema: schemas.cover,
    name: 'Cover'
  },
  [BasicLayouts.WELCOME_LETTER]: {
    layout: BasicLayouts.WELCOME_LETTER,
    previewImage: ImageSignature,
    title: 'Welcome Client',
    schema: schemas.welcomeLetter,
    name: 'Welcome Client'
  },
  [BasicLayouts.TEAM]: {
    layout: BasicLayouts.TEAM,
    previewImage: TeamCandidate,
    title: 'Text + Team',
    schema: schemas.team,
    name: 'Text + Team'
  },
  [BasicLayouts.CANDIDATES]: {
    layout: BasicLayouts.CANDIDATES,
    previewImage: Candidates,
    title: 'Text + Candidates',
    schema: schemas.candidates,
    name: 'Text + Candidates'
  },
  [BasicLayouts.GLOBAL]: {
    layout: BasicLayouts.GLOBAL,
    previewImage: Map,
    title: 'Text + Map',
    schema: schemas.global,
    name: 'Text + Map'
  },
  [CompoundLayouts.RELEVANT_EXPERIENCE]: {
    layout: CompoundLayouts.RELEVANT_EXPERIENCE,
    previewImage: RelevantExperience,
    title: 'Text + Rel. Exp (Logos)',
    schema: schemas.relevantExperience,
    name: 'Text + Relevant Experience'
  },
  [BasicLayouts.RELEVANT_EXPERIENCE_NEW]: {
    layout: BasicLayouts.RELEVANT_EXPERIENCE_NEW,
    previewImage: newRelevantExperience,
    title: 'Text + Relevant Experience',
    schema: schemas.relevantExperienceNew,
    name: 'Text + Relevant Experience'
  },
  [BasicLayouts.SOURCE_ENVIRONMENT]: {
    layout: BasicLayouts.SOURCE_ENVIRONMENT,
    previewImage: SourceEnvironment,
    title: 'Text + logos',
    schema: schemas.sourceEnvironment,
    name: 'Text + Companies'
  },
  [BasicLayouts.LETTER_ENGAGEMENT]: {
    layout: BasicLayouts.LETTER_ENGAGEMENT,
    previewImage: LetterEngagement,
    title: 'Text + Letter of Engagement',
    schema: schemas.letterEngagement,
    name: 'Text + Letter of Engagement'
  },

  [BasicLayouts.SF_PROCESS]: {
    layout: BasicLayouts.SF_PROCESS,
    previewImage: SfProcess,
    title: 'SF Process',
    schema: schemas.sfProcess,
    name: 'SF Process'
  },
  [CompoundLayouts.TEXT_IMAGE_SIGNATURE]: {
    layout: CompoundLayouts.TEXT_IMAGE_SIGNATURE,
    previewImage: TextImage,
    title: 'Welcome Client Image',
    extra: {
      orientation: 'start',
      media: 'video'
    },
    schema: schemas.signatureImage,
    name: 'Welcome Client Image'
  },
  [CompoundLayouts.DIVERSITY_AND_INCLUSION]: {
    layout: CompoundLayouts.DIVERSITY_AND_INCLUSION,
    previewImage: DiversityInclusion,
    title: 'Text + Diversity Graph',
    schema: schemas.diversityAndInclusion,
    name: 'Text + Diversity Graph'
  },
  [CompoundLayouts.TEXT_IMAGE]: {
    layout: CompoundLayouts.TEXT_IMAGE,
    previewImage: TextImage,
    title: 'Text + Image',
    extra: {
      orientation: 'start',
      media: 'image'
    },
    schema: schemas.textImage,
    name: 'Text + Image'
  },
  [CompoundLayouts.IMAGE_TEXT]: {
    layout: CompoundLayouts.IMAGE_TEXT,
    previewImage: ImageText,
    title: 'Image + Text',
    extra: {
      orientation: 'end',
      media: 'image'
    },
    schema: schemas.imageText,
    name: 'Image + Text'
  },
  [CompoundLayouts.TEXT_VIDEO]: {
    layout: CompoundLayouts.TEXT_VIDEO,
    previewImage: TextVideo,
    title: 'Text + Video',
    extra: {
      orientation: 'start',
      media: 'video'
    },
    schema: schemas.textVideo,
    name: 'Text + Video'
  },
  [CompoundLayouts.VIDEO_TEXT]: {
    layout: CompoundLayouts.VIDEO_TEXT,
    previewImage: VideoText,
    title: 'Video + Text',
    extra: {
      orientation: 'end',
      media: 'video'
    },
    schema: schemas.videoText,
    name: 'Video + Text'
  },
  [CompoundLayouts.CLIENT_SUITE]: {
    layout: CompoundLayouts.CLIENT_SUITE,
    previewImage: clientSuite,
    title: 'ClientSuite',
    extra: {
      orientation: 'end',
      media: 'image'
    },
    schema: schemas.clientSuiteImageText,
    name: 'ClientSuite'
  },
  [CompoundLayouts.CANDIDATE_SUITE]: {
    layout: CompoundLayouts.CANDIDATE_SUITE,
    previewImage: candidateSuite,
    title: 'Candidate Suite',
    extra: {
      orientation: 'start',
      media: 'image'
    },
    schema: schemas.clientSuiteImageText,
    name: 'Candidate Suite'
  }
};

export const unrepeatableLayouts = [
  BasicLayouts.CANDIDATES,
  BasicLayouts.GLOBAL,
  BasicLayouts.SF_PROCESS,
  BasicLayouts.WELCOME_LETTER,
  BasicLayouts.LETTER_ENGAGEMENT,
  CompoundLayouts.TEXT_IMAGE_SIGNATURE
];
