/* eslint-disable react/react-in-jsx-scope */
import { RolesListProps } from '../types';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { DropWrapper, DragWrapper, InputNumber, AutoComplete } from 'components';
import { reorderList } from 'helpers/data';
import DragIcon from 'assets/icons/DragIcon';
import { Star, Trash } from 'react-feather';
import classNames from 'classnames';
import { InputCounterChangeProps, InputCounterOperation, SelectOption } from 'components/dynamicInputs/types';

const RolesList = ({ onChange, value }: RolesListProps) => {
  const handleChangeNewRole = (selected: SelectOption) => {
    // Validates if value was previously added
    const itExists = value.some((role: any) => role.id === selected.name);

    if (itExists) {
      onChange(value);
      return;
    }

    onChange([
      ...value,
      {
        id: selected.name,
        name: selected.name,
        pinned: false,
        count: 1
      }
    ]);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const orderedList = reorderList(value, result.source.index, result.destination.index);
    onChange(orderedList);
  };

  const handleChangeRoleCount = (direction: InputCounterChangeProps) => {
    onChange(
      value.map((role: any) => {
        if (role.id === direction.id) {
          return {
            ...role,
            count: direction.operation === InputCounterOperation.ADD ? role.count + 1 : role.count - 1
          };
        }

        return role;
      })
    );
  };

  const handlePin = (id: string) => {
    onChange(
      value.map((role: any) => {
        if (role.pinned) {
          return {
            ...role,
            pinned: false
          };
        }
        if (role.id === id) {
          return {
            ...role,
            pinned: !role.pinned
          };
        }

        return role;
      })
    );
  };

  const handleRemoveRole = (id: string) => {
    onChange(value.filter((role: any) => role.id !== id));
  };

  const handleChangeRoleName = (index: number, newName: string) => {
    const updatedValue = [...value];
    updatedValue[index].name = newName;
    onChange(updatedValue);
  };

  return (
    <div>
      <AutoComplete
        name="newrole"
        label="New Role"
        value="" // value always empty so I can select elements (Like a default select/option)
        onChange={handleChangeNewRole}
        endpoint="/companies/search/jobtitle"
        placeholder=""
        isMulti={false}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <DropWrapper droppableId="role-list-droppable" type="ROLES_LIST" direction="vertical">
          {value?.map((role, index) => (
            <DragWrapper key={role.id} draggableId={role.id} index={index}>
              <div className="role-item" id={role.id}>
                <div className="drag">
                  <DragIcon />
                </div>
                <input
                  className="name body-2"
                  type="text"
                  value={role.name}
                  onChange={e => handleChangeRoleName(index, e.target.value)}
                />
                <div className="actions">
                  <button
                    type="button"
                    onClick={() => handlePin(role.id)}
                    className={classNames('action', { selected: role.pinned })}
                  >
                    <Star />
                  </button>
                  <button type="button" onClick={() => handleRemoveRole(role.id)} className="action">
                    <Trash />
                  </button>
                  <InputNumber
                    value={role.count}
                    onChange={handleChangeRoleCount}
                    id={role.id}
                    name={`input-name-${role.id}`}
                    max={100}
                    min={1}
                  />
                </div>
              </div>
            </DragWrapper>
          ))}
        </DropWrapper>
      </DragDropContext>
    </div>
  );
};

export default RolesList;
