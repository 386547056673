import joi from 'joi';
import { InputsEnum, TextInputTypes } from 'components/dynamicInputs/types';

export default {
  title: {
    value: 'Global Reach',
    label: 'Title',
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    limit: 20,
    joi: joi.string().required()
  },
  content: {
    value:
      '<p>Our Partners—your team—share a long-term vested interest in the success of your search. With search consultants across the globe, we collaborate to form relevant teams that fit your industry, functional and geographical needs.</p><p>Kingsley Gate Partners is the new leader of Global Executive Search.</p>',
    label: 'Description',
    input: InputsEnum.REACH_TEXT,
    limit: 400,
    joi: joi.string().required()
  },
  highlight: {
    value: 'Presence in over 230 countries all over the world.',
    label: 'Highlight',
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    limit: 50,
    joi: joi.string().required()
  },
  image: {
    value: '',
    label: 'Image',
    input: InputsEnum.GLOBAL_REACH,
    joi: joi.object().required()
  }
};
