import React from 'react';
import { Button, Input } from 'components';
import { TextInputTypes } from '../types';
import { Values } from 'hooks/useModal';
import { ModalsList } from 'constants/modals';

type Props = {
  item: any;
  handleChange: (value: string, name: string, id: string) => void;
  modal: Values;
};

const Dropdown = ({ item, handleChange, modal }: Props) => {
  const handleEditBio = () => {
    modal.open(
      ModalsList.PARTNERS_BIO,
      (data: any) => {
        if (data) {
          handleChange(data.bio, 'bio', item.id);
        }
      },
      { bio: item.bio }
    );
  };

  return (
    <div className="dropdown">
      <Input
        value={item.position}
        name="position"
        type={TextInputTypes.TEXT}
        label="Job Title"
        onChange={(value, name) => handleChange(value, name!, item.id)}
        autoComplete="off"
      />
      <Input
        value={item.linkedin}
        name="linkedin"
        type={TextInputTypes.TEXT}
        label="Linkedin"
        onChange={(value, name) => handleChange(value, name!, item.id)}
        autoComplete="off"
      />
      <Input
        value={item.phone}
        name="phone"
        type={TextInputTypes.TEXT}
        label="Phone"
        onChange={(value, name) => handleChange(value, name!, item.id)}
        autoComplete="off"
      />
      <Input
        value={item.email}
        name="email"
        type={TextInputTypes.TEXT}
        label="Mail"
        onChange={(value, name) => handleChange(value, name!, item.id)}
        autoComplete="off"
      />
      <Input
        value={item.bio}
        name="bio"
        type={TextInputTypes.TEXT}
        label="Bio"
        onChange={(value, name) => handleChange(value, name!, item.id)}
        autoComplete="off"
      />
      <Button text="EDIT BIO" variant="secondary" onClick={handleEditBio} />
    </div>
  );
};

export default Dropdown;
