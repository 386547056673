import React from 'react';
import { TextInputProps } from '../types';

const Text = ({
  type,
  placeholder,
  label,
  name,
  value,
  onChange,
  onBlur,
  autoComplete,
  limit,
  disabled = false
}: TextInputProps) => {
  return (
    <div className="input-contain input-wrapper" data-attr={name}>
      <input
        className="body"
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={({ target }) => onChange(target.value, target.name)}
        onBlur={onBlur}
        disabled={disabled}
        autoComplete={autoComplete}
        maxLength={limit}
      />

      {!!label && (
        <label className="label" id="placeholder-frame">
          <div className="text">{label}</div>
        </label>
      )}
    </div>
  );
};

export default Text;
