import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import withNetworkStatus from 'hoc/withNetworkStatus';
import { Deck, Decks } from 'pages';

const Anim = ({ component }: { component: React.ReactNode }) => (
  <motion.div
    className="transition-page"
    initial="initial"
    animate="animate"
    exit="exit"
    variants={{
      initial: { opacity: 0 },
      animate: { opacity: 1, transition: { duration: 0.35 } },
      exit: { opacity: 0, transition: { duration: 0.15 } }
    }}
  >
    {component}
  </motion.div>
);

const Router = () => {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes key={location.pathname} location={location}>
        <Route path="/" element={<Anim component={<Decks />} />} />
        <Route path="/deck/:id" element={<Anim component={<Deck />} />} />
      </Routes>
    </AnimatePresence>
  );
};

export default withNetworkStatus(Router);
