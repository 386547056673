import React, { useEffect, useState } from 'react';
import { User, Phone, X } from 'react-feather';
// import { Table } from 'components;
// import { TableOptions, ComponentList } from 'components/common/table/types';
import AddPatner from '../deckInformation/addPartners';
import AddClient from '../deckInformation/addClients';

type ClientContactData = {
  name: string;
  email: string;
  phone: string | number;
  client_id: string;
  delete?: string | number;
};

type PartnerContactData = {
  name: string;
  email: string;
  phone: string | number;
  partner_id: string;
  delete?: string | number;
};

type Props = {
  clients: ClientContactData[];
  partners: PartnerContactData[];
  onChangePartner: any;
  onChangeClients: any;
  companyId: string;
  galaxy_id: string;
};

const Contact = ({ clients, partners, onChangePartner, onChangeClients, companyId, galaxy_id }: Props) => {
  const [partner, setPartner] = useState<any>();
  const [client, setClient] = useState<any>();
  useEffect(() => {
    if (partners.length) {
      // if (typeof partner === 'undefined') {
      setPartner(partners);
      // }
      console.log(partners);
      console.log(partner);
    }

    if (clients.length) {
      // if (typeof client === 'undefined') {
      setClient(clients);
      // }
      console.log('Clients: ', clients);
      console.log('client: ', client);
    }
  });

  const onChangevalue = (data: any) => {
    console.log('onchange:', data);
    setPartner(data);
    console.log(partners);
  };

  const onChangeClient = (data: any) => {
    console.log('onchange client:', data);
    setClient(data);
    console.log(clients);
  };

  const handleOnDelete = (id?: string | number) => {
    console.log(id);
    setPartner(partner.filter((item: any) => item.id !== id));
    console.log(partner);
  };

  const handleOnDeleteClient = (id?: string | number) => {
    console.log(id);
    setClient(client.filter((item: any) => item.id !== id));
    console.log(client);
  };

  useEffect(() => {
    onChangePartner(partner);
  }, [partner]);

  useEffect(() => {
    onChangeClients(client);
  }, [client]);

  return (
    <div className="contact-information">
      <div className="add-partner">
        <div className="row">
          <div className="label">
            <User />
            <span className="body strong">Partners</span>
          </div>
          <div className="value" style={{ width: 'auto' }}>
            {/* <Table options={{ data: partner, ...tableOptions }} /> */}
            <table style={{ width: '100%' }}>
              <thead style={{ color: 'var(--gray-darkest)', fontSize: '14px' }}>
                <tr className="row">
                  <th
                    className="col-3"
                    style={{ width: '30%', textAlign: 'left', marginLeft: '2%', fontWeight: '600' }}
                  >
                    Name
                  </th>
                  <th className="col-3" style={{ width: '33%', textAlign: 'left', fontWeight: '600' }}>
                    Email
                  </th>
                  <th className="col-3" style={{ width: '30%', textAlign: 'left', fontWeight: '600' }}>
                    Phone
                  </th>
                  <th className="col-3" style={{ width: '12%', textAlign: 'left', fontWeight: '600' }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="tbody_contact" style={{ color: 'var(--gray-darker)', fontSize: '14px' }}>
                {partner !== undefined &&
                  partner.map((d: any) => (
                    <tr className="row" style={{ marginTop: '1%' }} key={d.name}>
                      <td
                        className="col-3 td-contact"
                        style={{ width: '30%', textAlign: 'left', marginLeft: '2%', overflow: 'hidden' }}
                      >
                        {d.name}
                      </td>
                      <td
                        className="col-3 td-contact"
                        style={{
                          width: '33%',
                          textAlign: 'left',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {d.email}
                      </td>
                      <td className="col-3 td-contact" style={{ width: '30%', textAlign: 'left', overflow: 'hidden' }}>
                        {d.phone}
                      </td>
                      <td className="col-3 td-contact" style={{ width: '12%', textAlign: 'left', overflow: 'hidden' }}>
                        <button
                          onClick={() => {
                            console.log('hello', d.id);
                            handleOnDelete(d.id);
                          }}
                        >
                          <X />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row add-slide">
          <AddPatner values={partners} onChange={onChangevalue} title="Partner" />
        </div>
      </div>
      <div className="row">
        <div className="label">
          <Phone />
          <span className="body strong">Clients</span>
        </div>
        <div className="value">
          {/* <Table alternative options={{ data: clients, ...tableOptions }} /> */}
          <table style={{ width: '100%' }}>
            <thead style={{ color: 'var(--gray-darkest)', fontSize: '14px' }}>
              <tr className="row">
                <th className="col-3" style={{ width: '30%', textAlign: 'left', marginLeft: '2%', fontWeight: '600' }}>
                  Name
                </th>
                <th className="col-3" style={{ width: '33%', textAlign: 'left', fontWeight: '600' }}>
                  Email
                </th>
                <th className="col-3" style={{ width: '30%', textAlign: 'left', fontWeight: '600' }}>
                  Phone
                </th>
                <th className="col-3" style={{ width: '12%', textAlign: 'left', fontWeight: '600' }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="tbody_contact" style={{ color: 'var(--gray-darker)', fontSize: '14px' }}>
              {client !== undefined &&
                client.map((d: any) => (
                  <tr className="row" style={{ marginTop: '1%' }} key={d.name}>
                    <td
                      className="col-3 td-contact"
                      style={{ width: '30%', textAlign: 'left', marginLeft: '2%', overflow: 'hidden' }}
                    >
                      {d.name}
                    </td>
                    <td
                      className="col-3 td-contact"
                      style={{
                        width: '33%',
                        textAlign: 'left',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {d.email}
                    </td>
                    <td className="col-3 td-contact" style={{ width: '30%', textAlign: 'left', overflow: 'hidden' }}>
                      {d.phone}
                    </td>
                    <td className="col-3 td-contact" style={{ width: '12%', textAlign: 'left', overflow: 'hidden' }}>
                      <button
                        onClick={() => {
                          console.log('hello', d.id);
                          handleOnDeleteClient(d.id);
                        }}
                      >
                        <X />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row add-slide">
        <AddClient
          values={clients}
          onChange={onChangeClient}
          companyId={companyId}
          title="Client"
          galaxy_id={galaxy_id}
        />
      </div>
    </div>
  );
};
export default Contact;
