import React, { useState } from 'react';
import classnames from 'classnames';
import { ChevronDown, ChevronUp, Image, Trash2, ThumbsUp, ThumbsDown, Info } from 'react-feather';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import DragIcon from 'assets/icons/DragIcon';
import { ModalsList } from 'constants/modals';
import { useModal } from 'hooks/useModal';
import { reorderList } from 'helpers/data';
import { DropWrapper, DragWrapper } from 'components';
import { InputCandidateProps } from '../types';
import Dropdown from './dropdown';

const aspectRatio = '158/83';
const shape = 'rect';

enum Feedback {
  'LIKE' = 'like',
  'DISLIKE' = 'dislike'
}

const InputCandidate = ({ candidatesFeedback, type, value: candidates, onChange, onBlur }: InputCandidateProps) => {
  const [selectedId, setSelectedId] = useState<string>('');
  const modal = useModal();
  const params = useParams();

  const handleChange = (value: string, name: string, id: string) => {
    const aux = candidates.map(item => {
      if (item.id === id) {
        if (name) {
          item[name] = value;
        }
      }

      return item;
    });

    onChange(aux);
  };

  const galleryModal = (id: string) => {
    modal.open(
      ModalsList.GALLERY,
      (data: any) => {
        if (data) {
          const aux = candidates.map(item => {
            if (item.id === id) {
              item.image = data.url;
            }

            return item;
          });

          onChange(aux);
        }
      },
      { aspectRatio, shape, deckid: params?.id }
    );
  };

  const showDropdown = (id: string) => selectedId === id;

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const orderedList = reorderList(candidates, result.source.index, result.destination.index);
    onChange(orderedList);
  };

  const deleteCandidate = (id: string) => {
    onChange(candidates.filter(item => item.id !== id));
  };

  const confirmModal = (item: any) => {
    modal.open(
      ModalsList.DIALOG_CONFIRM,
      (confirmed: any) => {
        if (confirmed) {
          deleteCandidate(item.id);
        }
      },
      { title: `Delete candidate ${item.name}` }
    );
  };

  const handleSeePositiveFeedback = (candidateName: string, feedback: any) => () => {
    modal.open(ModalsList.CANDIDATE_POSITIVE_FEEDBACK, undefined, { ...feedback, candidateName });
  };

  const handleSeeBadFeedback = (candidateName: string, feedback: any) => () => {
    modal.open(ModalsList.CANDIDATE_NEGATIVE_FEEDBACK, undefined, { ...feedback, candidateName });
  };

  return (
    <>
      {/* <Button text="Import from Galaxy" variant="secondary" small onClick={handleImportFromGalaxy} /> */}
      <br />

      <DragDropContext onDragEnd={onDragEnd}>
        <DropWrapper droppableId="candidates-list-droppable" type="INPUT_CANDIDATES" direction="vertical">
          {candidates.map((item, index) => {
            const feedbackIndex = candidatesFeedback?.findIndex(feedback => feedback.candidate_id === item.id);
            const feedback = candidatesFeedback?.[feedbackIndex];
            const feedbackType = feedbackIndex > -1 ? feedback.type : undefined;

            return (
              <DragWrapper key={item.id} draggableId={item.id} index={index}>
                <div key={index} className={classnames('input-candidate-dropdown', 'input-wrapper', feedbackType)}>
                  <div className="input">
                    <div className="actions">
                      <DragIcon />
                      <Image onClick={() => galleryModal(item.id)} />
                    </div>
                    <input
                      name="name"
                      type={type}
                      value={item.name}
                      placeholder="Candidate"
                      onChange={e => handleChange(e.target.value, e.target.name, item.id)}
                      onBlur={onBlur}
                    />
                    <div className="actions">
                      <Trash2 onClick={() => confirmModal(item)} />
                      {showDropdown(item.id) ? (
                        <ChevronUp onClick={() => setSelectedId('')} />
                      ) : (
                        <ChevronDown onClick={() => setSelectedId(item.id)} />
                      )}
                    </div>
                  </div>
                  {showDropdown(item.id) && (
                    <>
                      {feedbackType === Feedback.LIKE ? (
                        <div className="alert">
                          <ThumbsUp />
                          <div className="body-2">Candidate liked by client</div>
                          <Info onClick={handleSeePositiveFeedback(item.name, feedback)} />
                        </div>
                      ) : feedbackType === Feedback.DISLIKE ? (
                        <div className="alert">
                          <ThumbsDown />
                          <div className="body-2">Candidate disliked by client</div>
                          <Info onClick={handleSeeBadFeedback(item.name, feedback)} />
                        </div>
                      ) : null}
                      <Dropdown item={item} handleChange={handleChange} />
                    </>
                  )}
                </div>
              </DragWrapper>
            );
          })}
        </DropWrapper>
      </DragDropContext>
    </>
  );
};

export default InputCandidate;
