import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Trash, Image } from 'react-feather';
import { useParams } from 'react-router-dom';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { ModalsList } from 'constants/modals';
import { reorderList } from 'helpers/data';
import { useModal } from 'hooks/useModal';
import { Button, DragWrapper, DropWrapper } from 'components';
import DragIcon from 'assets/icons/DragIcon';
import { InputPartnerProps } from '../types';
import Dropdown from './dropdown';

const aspectRatio = '1/1';
const shape = 'round';

const InputPartner = ({ type, placeholder, value: members, onChange, onBlur }: InputPartnerProps) => {
  const [selectedId, setSelectedId] = useState<string>('');
  const modal = useModal();
  const params = useParams();

  const showDropdown = (id: string) => selectedId === id;

  const handleChange = (value: string, name?: string, id?: string) => {
    const aux = members.map((item: any) => {
      if (item.id === id) {
        item[name!] = value;
      }

      return item;
    });

    onChange(aux);
  };

  const handleGalleryModal = (id: string) => {
    modal.open(
      ModalsList.GALLERY,
      (data: any) => {
        if (data) {
          const aux = members.map((item: any) => {
            if (item.id === id) {
              item.image = data.url;
            }

            return item;
          });

          onChange(aux);
        }
      },
      { deckid: params.id, shape, aspectRatio }
    );
  };

  const deleteModal = (item: any) => {
    modal.open(
      ModalsList.DIALOG_CONFIRM,
      (confirmed: any) => {
        if (confirmed) {
          deletePartner(item.id);
        }
      },
      { title: `Delete partner ${item.name}` }
    );
  };

  const deletePartner = (id: string) => {
    onChange(members.filter(item => item.id !== id));
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const orderedList = reorderList(members, result.source.index, result.destination.index);
    onChange(orderedList);
  };

  const handleImport = () => {
    modal.open(ModalsList.IMPORT_TEAM_MEMBERS, (data: any) => {
      if (data) {
        onChange([
          ...members,
          ...data
            .filter((e: any) => {
              const membersId = members.reduce((acc, cur) => [...acc, cur.id], []);
              return !membersId.includes(e.id);
            })
            .map((e: any) => ({
              id: e.id,
              image: e.profile_image_id == null ? null : '',
              name: e.name,
              position: e.title,
              phone: e.work_phone,
              email: e.email,
              bio: e.bio,
              linkedin: e.linkedin_url
            }))
        ]);
      }
    });
  };

  return (
    <div className="input-partner input-wrapper">
      <Button text="Import from Galaxy" variant="secondary" small onClick={handleImport} />

      <DragDropContext onDragEnd={onDragEnd}>
        <DropWrapper droppableId="members-list-droppable" type="INPUT_MEMBERS" direction="vertical">
          {members.map((item, index) => (
            <DragWrapper key={item.id} draggableId={item.id} index={index}>
              <div key={index}>
                <div className="input">
                  <div className="actions">
                    <DragIcon />
                    <Image onClick={() => handleGalleryModal(item.id)} />
                  </div>
                  <input
                    name="name"
                    value={item.name}
                    type={type}
                    placeholder={placeholder}
                    onChange={e => handleChange(e.target.value, e.target.name, item.id)}
                    onBlur={onBlur}
                  />
                  <div className="actions">
                    <Trash onClick={() => deleteModal(item)} />
                    {showDropdown(item.id) ? (
                      <ChevronUp onClick={() => setSelectedId('')} />
                    ) : (
                      <ChevronDown onClick={() => setSelectedId(item.id)} />
                    )}
                  </div>
                </div>
                {showDropdown(item.id) && <Dropdown item={item} handleChange={handleChange} modal={modal} />}
              </div>
            </DragWrapper>
          ))}
        </DropWrapper>
      </DragDropContext>
    </div>
  );
};

export default InputPartner;
