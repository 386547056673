import React from 'react';
import Base from '../base';
import GalleryCrop from 'galleryCrop';
import { Props } from '../types';

const Gallery = ({ closing, onClose, params }: Props) => {
  const handleSelect = (imageSelected: any) => {
    onClose(imageSelected);
  };

  return (
    <Base closing={closing} onClose={onClose} size="lg" header="Add an image">
      <div className="modal-gallery">
        <GalleryCrop
          aspectRatio={params.aspectRatio}
          deckid={params.deckid}
          onSelect={(image: any) => handleSelect(image)}
          cropShape={params.shape}
        />
      </div>
    </Base>
  );
};

export default Gallery;
