import React, { useEffect } from 'react';
import { Button } from 'components';
import useApi, { Methods } from 'hooks/useApi';
import Base from '../base';
import { Props } from '../types';

const DeckDuplicate = ({ closing, onClose, params }: Props) => {
  const saveApi = useApi();
  const origin = window.location.origin;

  const handleSave = () => {
    saveApi.fetchData(
      {
        endpoint: `/decks/duplicate`,
        method: Methods.POST
      },
      {
        deckid: Number(params)
      }
    );
  };

  useEffect(() => {
    if (saveApi.response) {
      onClose();
      window.open(`${origin}/deck/${saveApi.response.id}`, '_blank');
    }
  }, [saveApi.response]);

  return (
    <Base
      onClose={onClose}
      closing={closing}
      size="sm"
      header="Duplicate deck"
      footer={
        <>
          <Button text="Cancel" variant="secondary" onClick={() => onClose()} />
          <Button text="Duplicate" onClick={() => handleSave()} />
        </>
      }
    >
      <div className="modal-dialog-confirm">
        <p>Duplicate Deck #{params} ?</p>
      </div>
    </Base>
  );
};

export default DeckDuplicate;
