import React, { useRef } from 'react';
import { ControlledMenu, useMenuState } from '@szhsin/react-menu';
import { MoreHorizontal } from 'react-feather';
import { Tag } from 'components';

type Props = {
  value: { id: string; label: string }[];
};

const LIMIT = 2;

const TableTags = ({ value }: Props) => {
  const ref = useRef(null);
  const [menuProps, toggleMenu] = useMenuState({ transition: true });

  return (
    <div className="table-tags">
      {value.slice(0, LIMIT).map(tag => (
        <Tag key={tag.id} value={tag} />
      ))}
      {/* @ts-ignore */}
      {value.length > LIMIT && <MoreHorizontal ref={ref} className="view-more" onMouseEnter={() => toggleMenu(true)} />}

      <ControlledMenu
        {...menuProps}
        anchorRef={ref}
        onMouseLeave={() => {
          /* toggleMenu(false) */
        }}
        onClose={() => toggleMenu(false)}
        align="end"
        viewScroll="close"
        portal={{
          target: document.getElementById('root')
        }}
      >
        <div className="all-tags">
          {value.map(tag => (
            <Tag key={tag.id} value={tag} />
          ))}
        </div>
      </ControlledMenu>
    </div>
  );
};

export default TableTags;
