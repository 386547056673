import joi from 'joi';
import { InputsEnum, TextInputTypes } from 'components/dynamicInputs/types';

export default {
  title: {
    value: 'Sample Candidate Profile',
    label: 'Title',
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    limit: 40,
    joi: joi.string().required()
  },
  content: {
    value:
      'The next step is to Find the candidates based on your target criteria. We look forward to your feedback on the sample candidate profiles provided here.',
    label: 'Description',
    input: InputsEnum.REACH_TEXT,
    limit: 550,
    joi: joi.string().required()
  },
  members: {
    value: [],
    label: 'Candidate',
    input: InputsEnum.INPUT_CANDIDATE,
    joi: joi.object().required()
  }
};
