import joi from 'joi';
import { InputsEnum, TextInputTypes } from 'components/dynamicInputs/types';

export default {
  title: {
    value: 'The Process',
    label: 'Title',
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    limit: 40,
    joi: joi.string().required()
  },
  content: {
    value:
      '<p>Imagine the next leader you bring on being your best hire ever!</p><p><br></p><p>Our unique structure, approach, and methodologies are designed to deliver the most exceptional talent at the very top of the organization—and to keep it coming. We employ a rigorous and systematic approach for sourcing top-caliber talent who will strengthen the capabilities, operations, and culture of the organization. Our Synchronous Fit process ensures that they are not just skilled in the functions of interest but that they will also be a good fit with the rest of the team.</p>',
    label: 'Description',
    input: InputsEnum.REACH_TEXT,
    limit: 800,
    joi: joi.string().required()
  },
  video: {
    value: {
      url: 'https://d2a3bjlz87klfj.cloudfront.net/video/synclink_process.mp4',
      thumbnail: 'https://d2a3bjlz87klfj.cloudfront.net/video/synclink_process_thumnail.png'
    },
    label: 'Video',
    input: InputsEnum.VIDEO_PICKER,
    joi: joi.object().required()
  },
  videoText: {
    value: 'Hello',
    label: 'Video title',
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    limit: 32,
    joi: joi.string().required()
  }
};
