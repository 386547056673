import React from 'react';

type Props = {
  value: number | string;
};

const Badge = ({ value }: Props) => {
  return <span className="badge body-2">{value}</span>;
};

export default Badge;
