/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-unused-vars */
import TabsSwitcher from 'components/common/tabsSwitcher';
import useFormHandler from 'hooks/useFormHandler';
import Base from '../base';
import { Props } from '../types';
import BrandingTheme from './brandingTheme';
import EmailTemplate from './emailTemplate';
import { LogoContext } from 'app';
import { useContext } from 'react';

const SystemSetting = ({ closing, onClose, params }: Props) => {
  const { setLogo } = useContext(LogoContext);

  const handleOnChange = (value: any) => {
    console.log('closing-----', value);
    setLogo('1');
    //onClose(value);
  };

  return (
    <div className="top-system-setting">
      <Base header="System Settings" closing={closing} onClose={onClose} size="lg">
        <div className="modal-system-setting">
          <TabsSwitcher
            forceRenderTabPanel
            defaultIndex={params.defaultIndex}
            data={[
              {
                title: 'Branding Themes',
                component: <BrandingTheme onChange={(data: any) => handleOnChange(data)} />
              },
              {
                title: 'Email Template',
                component: <EmailTemplate onChange={(data: any) => handleOnChange(data)} />
              }
            ]}
          />
        </div>
      </Base>
    </div>
  );
};

export default SystemSetting;
