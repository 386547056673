import React, { useEffect, useState } from 'react';
import { Frown } from 'react-feather';
import { deleteFromStorage, useLocalStorage, writeStorage } from '@rehooks/local-storage';
import { useSearchParams } from 'react-router-dom';
import { useToast } from 'hooks/useToast';

export enum Methods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

type Config = {
  endpoint: string;
  method?: Methods;
};

const API_URL = process.env.REACT_APP_KGP_URL;

function objToQueryString(obj: any) {
  const keyValuePairs = [];

  for (const key in obj) {
    keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
  }

  return keyValuePairs.join('&');
}

export default function useApi() {
  const notify = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const [token] = useLocalStorage('token');
  const [refreshToken] = useLocalStorage('refreshToken');
  const [response, setResponse] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  async function fetchData(config: Config, data?: any) {
    const headers: Headers = new Headers();
    const method = config.method || Methods.GET;
    let url = `${API_URL}${config.endpoint}`;

    setIsLoading(true);

    headers.append('Content-Type', 'application/json');
    !!token && headers.append('Authorization', `Bearer ${token}`);
    !!refreshToken && headers.append('refresh-token', refreshToken);

    if (data && method === Methods.GET) {
      url += `?${objToQueryString(data)}`;
    }

    const r = await fetch(url, {
      method,
      headers,
      body: data && method !== Methods.GET ? JSON.stringify(data) : undefined
    });
    // eslint-disable-next-line no-debugger
    // debugger;
    if (!r.ok) {
      notify(r.statusText, <Frown />);
      let response = (await r.json()).message;
      console.log('Fetch error:', response);
      if (response === 'Refresh Token has been revoked') {
        console.log('Going to clear cache');
        deleteFromStorage('token');
        deleteFromStorage('refreshToken');
        deleteFromStorage('user');
      }
    } else {
      setResponse(await r.json());
    }

    setIsLoading(false);
    setLoaded(true);
  }

  useEffect(() => {
    if (response && response.token) {
      const param = searchParams.get('code');
      const { access_token: token, refresh_token: refreshToken } = response.token;

      !!token && writeStorage('token', token);
      !!refreshToken && writeStorage('refreshToken', refreshToken);
      !!response.user && writeStorage('user', response.user);

      if (param) {
        searchParams.delete('code');
        setSearchParams(searchParams);
      }
    }
  }, [response]);

  return { response, isLoading, fetchData, loaded };
}
