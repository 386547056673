import React, { useContext, useState, createContext } from 'react';
import { Portal } from 'react-portal';
import Modals, { ModalsList } from 'constants/modals';

type Callback = (data?: any) => void;
type CompoundModal = { modal: ModalsList | any; closing: boolean; cb?: Callback; params?: any };

const CLOSING_TIME = 250;

export type Values = {
  open: (modal: ModalsList | any, cb?: Callback, params?: any) => void;
};

const ModalContext = createContext<Values>({} as Values);

export const useModal = () => {
  const context = useContext(ModalContext);
  return context;
};

export default ({ children }: { children: React.ReactNode }) => {
  const [opens, setOpens] = useState<CompoundModal[]>([]);

  const handleOpen = (modal: ModalsList | any, cb?: Callback, params?: any) => {
    setOpens([...opens, { modal, cb, closing: false, params }]);
  };

  const handleClose = (index: number) => (data: any) => {
    const position = opens.findIndex((_, i) => index === i);

    setOpens([
      ...opens.map((modal, i) => {
        if (i === position) {
          modal.closing = true;
        }

        return modal;
      })
    ]);

    setTimeout(() => {
      setOpens([...opens.filter((_, i) => i !== position)]);
    }, CLOSING_TIME);
    // @ts-ignore
    opens[position]?.cb && opens[position]?.cb(data);
  };

  return (
    <ModalContext.Provider value={{ open: handleOpen }}>
      {opens.map(({ modal, closing, params }, index) => {
        // @ts-ignore
        const Modal = Modals[modal];

        if (!Modal) {
          return null;
        }

        return (
          <Portal key={modal} node={document.getElementById('root')}>
            <Modal closing={closing} onClose={handleClose(index)} params={params} />
          </Portal>
        );
      })}
      {children}
    </ModalContext.Provider>
  );
};
