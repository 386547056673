import React from 'react';
import { NotificationEnum } from 'hooks/useNotifications';

type Props = {
  title: number | string;
  body: string;
  username: string;
  deckName: string;
  candidateName: string | null;
  type: NotificationEnum;
  onClick: (payload: any) => void;
};

const NotificationItem = ({ title, body }: any) => {
  return (
    <>
      <p className="body-2 strong">{title}</p>
      <p className="body-2 notification-body">{body}</p>
    </>
  );
};

const Notification = ({ body, onClick, username, deckName, candidateName, type }: Props) => {
  return (
    <div className="notification" onClick={onClick}>
      <>
        {
          {
            [NotificationEnum.COMMENT]: (
              <NotificationItem title={`${username} left a comment in synclink ${deckName}`} body={body} />
            ),
            [NotificationEnum.LIKE]: (
              <NotificationItem
                title={`${username} liked a candidate`}
                body={`${username} liked the candidate ${candidateName}`}
              />
            ),
            [NotificationEnum.DISLIKE]: (
              <NotificationItem
                title={`${username} dislike a candidate`}
                body={`${username} dislike the candidate ${candidateName}`}
              />
            ),
            [NotificationEnum.SIGN]: (
              <NotificationItem
                title={`${username} signed a document`}
                body={`${username} signed a document in ${deckName}`}
              />
            )
          }[type]
        }
      </>
    </div>
  );
};

export default Notification;
