import React from 'react';
import { Theme, Themes } from 'constants/themes';
import { ThemeCard } from 'components';
import { ThemeProps } from '../types';

const ThemePicker = ({ value, onChange }: ThemeProps) => {
  return (
    <div className="input-deck-theme">
      <div className="grid">
        {Object.keys(Themes)?.map((key, index) => {
          const themes = Themes[key as Theme] as any;

          return <ThemeCard key={index} themes={themes} onClick={() => onChange(key)} selected={value === key} />;
        })}
      </div>
    </div>
  );
};

export default ThemePicker;
