import React, { useEffect } from 'react';
import useFormHandler from 'hooks/useFormHandler';
import { Button, DynamicForm } from 'components';
import schema from 'schemas/modals/newRole';
import Base from '../base';
import { Props } from '../types';

const AddRolesModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);

  useEffect(() => {
    formHandler.setFullForm({
      rolesList: params.data[0].roles
    });
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.disabled = true;
    onClose(formHandler.data);
  };

  return (
    <Base
      header="Add Roles"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Done" disabled={!formHandler.valid} onClick={handleClick} />}
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default AddRolesModal;
