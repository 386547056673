import React from 'react';
import {
  BREAKPOINT_DESKTOP_LG,
  BREAKPOINT_DESKTOP_MD,
  BREAKPOINT_MOBILE,
  BREAKPOINT_TABLET_LG,
  BREAKPOINT_TABLET_MD
} from '../../../constants/breakpoints';
import CandidateCard from '../../shared/candidateCard';
import Carousel from '../../shared/carousel';
import Expansible from '../../../components/shared/expansible';
import Sanitized from '../../shared/sanitized';
import Shapes from '../../shared/shapes';

type Members = {
  id: string;
  name: string;
  title: string;
  company: string;
  image: string;
  position: string;
  address: string;
  content: string;
  linkedin: string;
};

type Props = {
  title: string;
  content: string;
  subContent: string;
  members: Members[];
  selected: boolean;
  feedback: { candidate_id: string; type: 'like' | 'dislike' }[];
};

const clientBreakpoints = {
  width: 600,
  slidesPerView: 1.2,
  spaceBetween: 25,
  [BREAKPOINT_MOBILE]: {
    slidesPerView: 2,
    spaceBetween: 25
  },
  [BREAKPOINT_TABLET_MD]: {
    slidesPerView: 2.5,
    spaceBetween: 25
  },
  [BREAKPOINT_TABLET_LG]: {
    slidesPerView: 1.8,
    spaceBetween: 25
  },
  [BREAKPOINT_DESKTOP_MD]: {
    slidesPerView: 2,
    spaceBetween: 25
  },
  [BREAKPOINT_DESKTOP_LG]: {
    slidesPerView: 2.2,
    spaceBetween: 25
  }
};

const partnerBreakpoints = {
  [BREAKPOINT_MOBILE]: {
    slidesPerView: 1.8,
    spaceBetween: 25
  }
};

const sampleCandidate = {
  id: '1',
  name: 'Sample candidate',
  title: 'Title',
  company: 'Sample Company',
  image: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/decks/1/candidateimage_298x156_149x78_0_1669236137152.jpg`,
  position: "Candidate's title",
  address: "Candidate's location",
  content: 'Short description of the candidate',
  linkedin: 'https://www.linkedin.com/',
  sample: true
};

const LayoutCandidates = ({ title, content, subContent, members, feedback }: Props) => (
  <div className="layout-candidates">
    <Shapes />

    <div className="flex-wrapper">
      <div className="left">
        <h3>{title}</h3>

        <Expansible classes="content body light">
          <Sanitized html={content} />
        </Expansible>

        <hr />
        <p className="body light">{subContent}</p>
      </div>

      <div className="right">
        {window.partner && members?.length === 0 ? (
          <Carousel
            paginationType="bullets"
            dynamicBullets
            breakpoints={window.partner ? partnerBreakpoints : clientBreakpoints}
          >
            <CandidateCard {...sampleCandidate} />
            <CandidateCard {...sampleCandidate} />
          </Carousel>
        ) : (
          <Carousel
            paginationType="bullets"
            dynamicBullets
            breakpoints={window.partner ? partnerBreakpoints : clientBreakpoints}
          >
            {members?.map((member, index) => {
              console.log('Memeber :', member);
              const feedbackIndex = feedback?.findIndex(item => item.candidate_id === member.id);
              const state = feedback?.[feedbackIndex]?.type;

              return <CandidateCard key={index} {...member} feedback={state} />;
            })}
          </Carousel>
        )}
      </div>
    </div>
  </div>
);

export default LayoutCandidates;
