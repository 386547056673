import React from 'react';
import ImageFadeIn from 'react-image-fade-in';

type Props = {
  src: string;
  alt?: string;
};

const Img = ({ src, alt }: Props) => <ImageFadeIn src={src} alt={alt} opacityTransition={0.25} />;

export default Img;
