import React, { useState, useEffect } from 'react';
import Base from '../base';
import { Button, Table, Input, DropdownButton } from 'components';
import { TextInputTypes } from 'components/dynamicInputs/types';
import { Props } from '../types';
import { ComponentList, TableOptions } from 'components/common/table/types';
import useDebounce from 'hooks/useDebounce';
import { ChevronDown, Frown } from 'react-feather';
import { useLocalStorage } from '@rehooks/local-storage';
import { useToast } from 'hooks/useToast';
import axios from 'axios';
import * as importWorkbenchLabels from 'constants/importWorkbench';
const tableOptions: TableOptions = {
  endpoint: importWorkbenchLabels.MY_WORKBENCH_ENDPOINT,
  searchable: false,
  columns: [
    {
      id: 'workbench_name',
      header: importWorkbenchLabels.TABLE_NAME,
      type: ComponentList.TEXT,
      width: '135px',
      sortable: false
    },
    {
      id: 'archive_date',
      header: importWorkbenchLabels.TABLE_ACTIVE_DATE,
      type: ComponentList.TEXT,
      width: '135px',
      sortable: false
    },
    {
      id: 'description',
      header: importWorkbenchLabels.TABLE_OPTION_DESCRIPTION,
      type: ComponentList.TEXT,
      width: '180px',
      sortable: false
    },
    {
      id: 'created_at',
      header: importWorkbenchLabels.TABLE_OPTION_CREATED_DATE,
      type: ComponentList.DATE,
      width: '150px',
      sortable: false
    }
  ],

  sort: {
    column: 'id',
    order: 'asc'
  },
  itemsPerPage: 100,
  hideFooter: true
};

export enum Methods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}
const API_URL = process.env.REACT_APP_KGP_URL;
const ImportWorkbench = ({ closing, onClose }: Props) => {
  const notify = useToast();
  const [selected, setSelected] = useState<any>([]);
  const [search, setSearch] = useState('');
  const [extraFilters, setExtraFilters] = useState([{ column: 'q', value: '' }]);
  const searchDebounced = useDebounce(search);
  const [loading, setLoading] = useState(false);
  const [token] = useLocalStorage('token');
  const [refreshToken] = useLocalStorage('refreshToken');
  const headers: Headers = new Headers();
  headers.append('Content-Type', 'application/json');
  !!token && headers.append('Authorization', `Bearer ${token}`);
  !!refreshToken && headers.append('refresh-token', refreshToken);
  const [isDefaultSelected, setIsDefaultSelected] = useState<boolean>(true);
  const [workbenchSelected, setWorkbenchSelected] = useState(0);

  const lists = [
    {
      text: importWorkbenchLabels.MY_WORKBENCH,
      onClick: () => {
        setSelected('');
        setIsDefaultSelected(true);
        tableOptions.endpoint = importWorkbenchLabels.MY_WORKBENCH_ENDPOINT;
        setWorkbenchSelected(0);
      }
    },
    {
      text: importWorkbenchLabels.KGP_WORKBENCH,
      onClick: () => {
        setSelected('');
        tableOptions.endpoint = importWorkbenchLabels.KGP_WORKBENCH_ENDPOINT;
        setIsDefaultSelected(false);
        setWorkbenchSelected(1);
      }
    }
  ];

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    console.log('disable', e.currentTarget.disabled);
    if (selected.length > 0) {
      let url = `${API_URL}/workbenches/searches/${selected[0]?.id}`;
      //e.currentTarget.disabled = true;
      setLoading(true);
      const result = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'refresh-token': refreshToken
        }
      });
      if (result.status === 200) {
        if (result.data.items.data.length > 0) {
          setLoading(true);
          const data = await setTables(result.data.items.data);
          tableOptions.endpoint = importWorkbenchLabels.MY_WORKBENCH_ENDPOINT;
          onClose(data);
          setLoading(false);
        } else {
          notify(importWorkbenchLabels.NO_DATA_FOUND, <Frown />);
          setLoading(false);
        }
      } else {
        notify(importWorkbenchLabels.SERVER_ERROR, <Frown />);
        setLoading(false);
      }
    } else {
      notify(importWorkbenchLabels.SELECT_WORKBENCH, <Frown />);
    }
  };
  const setTables = (tables: any) => {
    const setDataSearch = tables.map((item: any) => {
      return {
        company_id: item.company_id,
        company: item.company,
        job_title: item.job_title
      };
    });
    const result = setDataTabels(setDataSearch);
    return result;
  };
  const setDataTabels = (dataTabels: any) => {
    let result = dataTabels.reduce(
      (r: { [x: string]: { job_title: any[] } }, c: { company_id: string | number; company: any; job_title: any }) => {
        r[c.company_id] = r[c.company_id] || { company_id: c.company_id, company: c.company, job_title: [] };
        r[c.company_id].job_title.push(c.job_title);
        return r;
      },
      {}
    );
    const companyData = Object.values(result);
    return companyData;
  };

  useEffect(() => {
    setExtraFilters([{ column: 'q', value: searchDebounced }]);
  }, [searchDebounced]);

  const handleOnClose = () => {
    tableOptions.endpoint = importWorkbenchLabels.MY_WORKBENCH_ENDPOINT;
    setIsDefaultSelected(true);
    onClose();
  };

  return (
    <Base
      className="modal-table"
      closing={closing}
      header={
        <div className="search-header">
          <h6>{importWorkbenchLabels.IMPORT_FROM_WORKBENCH}</h6>
          <Input
            type={TextInputTypes.TEXT}
            placeholder={importWorkbenchLabels.SELECT_WORKBENCH_TEXT}
            value={search}
            onChange={setSearch}
          />
        </div>
      }
      onClose={handleOnClose}
      size="lg"
      footer={
        <Button
          text={
            !selected.length
              ? importWorkbenchLabels.IMPORT_TEXT
              : loading === true
              ? importWorkbenchLabels.LOADING
              : importWorkbenchLabels.IMPORT_SELECTED
          }
          //disabled={selected.length > 0 ? false : true}
          onClick={handleClick}
        />
      }
    >
      <div className="import-workbench-main">
        <div className="workbench-div">
          <DropdownButton
            items={lists}
            button={
              <div className="workbenchSelect">
                <>
                  <label className="workbenchLabel">{lists[workbenchSelected].text}</label>
                  <ChevronDown className="workbenchLabel svg"></ChevronDown>
                </>
              </div>
            }
          />
        </div>
        {isDefaultSelected && (
          <Table
            alternative
            options={tableOptions}
            selected={{ value: selected, change: setSelected }}
            extraFilters={extraFilters}
            workbench={importWorkbenchLabels.WORKBENCH}
            searchs={search}
            setSearchs={setSearch}
          />
        )}
        {!isDefaultSelected && (
          <Table
            alternative
            options={tableOptions}
            selected={{ value: selected, change: setSelected }}
            extraFilters={extraFilters}
            workbench={importWorkbenchLabels.WORKBENCH}
            searchs={search}
            setSearchs={setSearch}
          />
        )}
      </div>
    </Base>
  );
};

export default ImportWorkbench;
