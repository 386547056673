import { openDB } from 'idb';

type Props = {
  dbName: string;
  storeName: string;
  limit: number;
};

const useIndexedDB = ({ dbName, storeName }: Props) => {
  const createIndexedDB = async () => {
    const adminDB = await openDB(dbName, 2, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: 'id', autoIncrement: true });
        }
      }
    });

    return adminDB;
  };

  const updateById = async (id: string, payload: any) => {
    const adminDB = await createIndexedDB();

    adminDB.getKey(storeName, id).then(key => {
      adminDB.put(storeName, payload, key);
    });
  };

  const getIndexedDB = async () => {
    const adminDB = await createIndexedDB();
    const list = await adminDB.getAll(storeName);
    return list.reverse();
  };

  return {
    getIndexedDB,
    updateById
  };
};

export default useIndexedDB;
