import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Archive, BarChart2, ChevronDown, CornerDownLeft, User, Users, Share2, Trash2 } from 'react-feather';
import useApi, { Methods } from 'hooks/useApi';
import { useModal } from 'hooks/useModal';
import { ModalsList } from 'constants/modals';
import { Button, DropdownButton, Table } from 'components';
import { FilterTypes, TableOptions, ComponentList } from 'components/common/table/types';
import { useToast } from 'hooks/useToast';

const decksTableOptions: TableOptions = {
  endpoint: '/decks',
  searchable: true,
  columns: [
    { id: 'search_id', header: 'ID #', type: ComponentList.SEARCH_ID, width: '140px', sortable: true },
    { id: 'job_title', header: 'Project title', type: ComponentList.TEXT, width: '150px', sortable: true },
    { id: 'company_name', header: 'Company', type: ComponentList.TEXT, width: '225px', sortable: true },
    {
      id: 'status',
      header: 'Status',
      type: ComponentList.DECK_STATUS,
      width: '200px',
      filter: {
        type: FilterTypes.SELECT
      }
    },
    { id: 'owner.name', header: 'Owner', type: ComponentList.TEXT, width: '210px', sortable: false },
    { id: 'created_at', header: 'Creation date', type: ComponentList.DATE, width: '165px', sortable: true },
    { id: 'sent_date', header: 'Sent date', type: ComponentList.DATE, width: '136px', sortable: true },
    { id: 'last_update', header: 'Last update', type: ComponentList.DATE, width: '148px', sortable: true },
    {
      id: 'tags',
      header: 'Tags',
      type: ComponentList.TAGS,
      filter: {
        key: 'tags.id',
        type: FilterTypes.MULTI
      }
    }
  ],
  sort: {
    column: 'search_id',
    order: 'asc'
  },
  itemsPerPage: 20
};

const tabs = ['Active', 'Archives'];

const DecksPage = () => {
  const modal = useModal();
  const fetchArchive = useApi();
  const fetchDelete = useApi();
  const [listSelected, setListSelected] = useState(0);
  const [tabSelected, setTabSelected] = useState(0);
  const notify = useToast();

  const lists = [
    {
      text: 'My SyncLinks',
      icon: <User />,
      onClick: () => setListSelected(0)
    },
    {
      text: 'All SyncLinks',
      icon: <Users />,
      onClick: () => setListSelected(1)
    },
    {
      text: 'Shared With Me',
      icon: <Users />,
      onClick: () => setListSelected(2)
    }
  ];

  const handleToggleArchive = (id?: string | number) => {
    fetchArchive.fetchData({ endpoint: '/decks/archive', method: Methods.POST }, { deckid: `${id}` });
  };

  const handleCreateDeck = () => {
    modal.open(ModalsList.CREATE_DECK_TEMPLATE, () => {}, { popupFrom: 'create', deckId: '' });
  };

  const handleDeleteDeck = (id?: string | number, deck?: any) => {
    modal.open(
      ModalsList.DIALOG_CONFIRM,
      (confirmed: any) => {
        if (confirmed) {
          fetchDelete.fetchData(
            { endpoint: `/decks/${id}`, method: Methods.DELETE },
            {
              search_galaxy_id: `${deck.search_galaxy_id}`
            }
          );
        }
      },
      { title: `Delete deck ${id}` }
    );
  };

  useEffect(() => {
    if (fetchDelete?.response) {
      notify('Deck deleted', <Trash2 />);
    }
  }, [fetchDelete?.response]);

  useEffect(() => {
    if (fetchArchive.loaded && !fetchArchive.isLoading) {
      setTabSelected(tabSelected ? 0 : 1);
    }
  }, [fetchArchive.loaded, fetchArchive.isLoading]);

  decksTableOptions.menuButtons = [
    {
      onClick: (id?: string | number) => modal.open(ModalsList.SHARE_DECK, () => {}, id?.toString()),
      text: 'Share',
      icon: <Share2 />
    },
    {
      onClick: (id?: string | number, params?: any) =>
        modal.open(ModalsList.DECK_ANALYTICS, () => {}, { deckId: id, title: params.job_title }),
      text: 'SyncLink analytics',
      icon: <BarChart2 />
    },
    {
      onClick: handleDeleteDeck,
      text: 'Delete',
      icon: <Trash2 />
    }
  ];

  if (tabSelected === 0) {
    decksTableOptions.menuButtons.push({ onClick: handleToggleArchive, text: 'Archive', icon: <Archive /> });
  } else {
    decksTableOptions.menuButtons.push({ onClick: handleToggleArchive, text: 'Unarchive', icon: <CornerDownLeft /> });
  }

  return (
    <div className="page-decks">
      <header>
        <DropdownButton
          items={lists}
          button={
            <h6 className="strong">
              <>
                {lists[listSelected].text}
                <ChevronDown />
              </>
            </h6>
          }
        />
        <Button text="Create SyncLink" onClick={handleCreateDeck} />
      </header>
      <section>
        <div className="controls">
          <div className="tabs">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={classnames('body', 'strong', { selected: index === tabSelected })}
                onClick={() => setTabSelected(index)}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>
        <div className="table-wrapper">
          <Table
            options={decksTableOptions}
            extraFilters={[{ column: 'archived', value: tabSelected === 1 }]}
            queryParams={{ myDecks: listSelected === 0, sharedWithMe: listSelected === 2 }}
          />
        </div>
      </section>
    </div>
  );
};

export default DecksPage;
