import React, { useState } from 'react';
import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd';
import { Plus } from 'react-feather';
import { ModalsList } from 'constants/modals';
import { useModal } from 'hooks/useModal';
import { DragWrapper, DropWrapper } from 'components';
import SlideSection from './slideSection';

type Props = {
  deck: any;
};

const SlidesNav = ({ deck }: Props) => {
  const modal = useModal();
  const [dragStartId, setDragStartId] = useState<string>('');

  const handleAddSection = () => {
    modal.open(ModalsList.SECTION, (data: any) => {
      if (data) {
        deck.addSection(data.name);
      }
    });
  };

  const onDragStart = (result: DragStart) => {
    setDragStartId(result.draggableId);
  };

  const onDragEnd = (result: DropResult) => {
    deck.changeOrder(result);
    setDragStartId('');
  };

  return (
    <div className="slides-nav">
      <div className="add-slide">
        <span className="body strong">Sections</span>
        <Plus onClick={handleAddSection} />
      </div>

      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <DropWrapper droppableId="slideGroups" type="SECTION" direction="vertical">
          {deck.sectionOrder.map((sectionId: string, index: number) => (
            <DragWrapper key={sectionId} draggableId={sectionId} index={index}>
              <SlideSection
                id={sectionId}
                deck={deck}
                name={deck?.sections[sectionId]?.name}
                slides={deck?.sections[sectionId]?.slides}
                dragStartId={dragStartId}
                modal={modal}
              />
            </DragWrapper>
          ))}
        </DropWrapper>
      </DragDropContext>

      <div className="hidden-space" />
    </div>
  );
};

export default SlidesNav;
