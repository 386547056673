/* eslint-disable react/react-in-jsx-scope */
import Button from 'components/common/button';
import { useEffect, useState } from 'react';
import { brandingThemeFormProps } from '../types';
import { DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import bodyTextSchema from 'schemas/modals/bodyText';
import useApi, { Methods } from 'hooks/useApi';
import useLocalStorage from '@rehooks/local-storage';
import { useToast } from 'hooks/useToast';

const EmailTemplateForm = ({ value, onChange }: brandingThemeFormProps) => {
  const [subjectText, setSubjectText] = useState();
  const [titleText, setTitleText] = useState();
  const [bodyText, setBodyText] = useState();
  const [themeName, setThemeName] = useState();
  const [isDefault, setIsDefault] = useState<boolean>();
  const [count, setCount] = useState(0);
  const bodyTextFormHandler = useFormHandler(bodyTextSchema);
  const saveTemplate = useApi();
  const [temp, setTemp] = useState<any>();
  const [createdBy, setCreatedBy] = useState();
  const [user] = useLocalStorage('user', { id: 0 });
  const notify = useToast();
  const [isEnableSave, setIsEnableSave] = useState(false);

  useEffect(() => {
    if (count == 0 && value?.titleText !== undefined) {
      setThemeName(value.name);
      setSubjectText(value.subjectText);
      setBodyText(value.bodyText);
      setTitleText(value.titleText);
      setIsDefault(value.isDefault);
      bodyTextFormHandler.setFullForm({ bodyText: value.bodyText });
      setCreatedBy(value.createdBy);
      setCount(1);
    }
  });

  const handleOnChange = (obj: any, type: string) => {
    console.log(obj);
    switch (type) {
      case 'subjectText': {
        setIsEnableSave(true);
        setSubjectText(obj.target.value);
        break;
      }
      case 'bodyText': {
        setIsEnableSave(true);
        setBodyText(bodyText + obj.target.value);
        break;
      }
      case 'titleText': {
        setIsEnableSave(true);
        setTitleText(obj.target.value);
        break;
      }
      case 'themeName': {
        setIsEnableSave(true);
        setThemeName(obj.target.value);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleSave = () => {
    setIsEnableSave(false);
    let temp = {
      id: value.id,
      name: themeName,
      subjectText: subjectText,
      bodyText: bodyTextFormHandler.data?.bodyText,
      titleText: titleText,
      isDefault: isDefault
    };

    setTemp(temp);
    console.log(user);
    saveTemplate.fetchData(
      {
        endpoint: '/decks/createEmailTemplates',
        method: Methods.POST
      },
      {
        id: value.id,
        email_template: themeName,
        subject: subjectText,
        title: titleText,
        body: bodyTextFormHandler.data?.bodyText,
        default: isDefault,
        deck_id: 1,
        from: 2,
        created_by: createdBy,
        updated_by: user.id
      }
    );
    // onChange(temp);
  };

  useEffect(() => {
    if (bodyTextFormHandler.isChange) {
      setIsEnableSave(true);
    }
  }, [bodyTextFormHandler.isChange]);

  useEffect(() => {
    if (saveTemplate.response !== undefined) {
      notify('Changes saved successfully');
      onChange(temp);
    }
  }, [saveTemplate.loaded]);

  return (
    <div className="branding-theme-container">
      <div className="branding-theme-form">
        <div className="input-container">
          <div className="text1">Template Name</div>
          <div className="color-picker">
            <input name="themeName" type="text" value={themeName} onChange={obj => handleOnChange(obj, 'themeName')} />
          </div>
        </div>
        <div className="input-container">
          <span className="checkbox-label">Default Template</span>
          <input
            type="checkbox"
            checked={isDefault}
            onChange={() => {
              setIsDefault(!isDefault), setIsEnableSave(true);
            }}
          />
        </div>
        <span className="m-left">{}</span>
      </div>
      <div className="branding-theme-form2">
        <div className="input-container">
          <div className="text1">Subject Text</div>
          <div className="color-picker">
            <input
              name="subjectText"
              type="text"
              value={subjectText}
              onChange={obj => handleOnChange(obj, 'subjectText')}
            />
          </div>
        </div>
        <div className="input-container">
          <div className="text1">Title Text</div>
          <div className="color-picker">
            <input name="titleText" type="text" value={titleText} onChange={obj => handleOnChange(obj, 'titleText')} />
          </div>
        </div>

        <span className="reach-text">
          <DynamicForm handler={bodyTextFormHandler} />
        </span>
      </div>

      <div className="save-button">
        <Button text={'Save'} onClick={handleSave} small={true} disabled={!isEnableSave} />
      </div>
    </div>
  );
};

export default EmailTemplateForm;
