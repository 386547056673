import React from 'react';

const Loading = () => (
  <svg className="loading-logo" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.5 97.9">
    <defs></defs>
    <path className="cls-1" d="m79.5,97.9h-22.91c0-31.2-25.38-56.59-56.59-56.59v-22.91c43.84,0,79.5,35.66,79.5,79.5Z" />
    <path className="cls-2" d="m0,79.5v-22.91c31.2,0,56.59-25.39,56.59-56.59h22.91c0,43.84-35.66,79.5-79.5,79.5Z" />
  </svg>
);

export default Loading;
