import React, { ChangeEvent, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useModal } from 'hooks/useModal';
import { DragWrapper, DropWrapper } from 'components';
import { ModalsList } from 'constants/modals';
import Button from 'components/common/button';
import Roles from 'components/common/roles';
import { CompanyType, TextInputTypes } from '../types';
import InputDropdown from 'components/common/inputDropdown';

type CompanyProps = {
  companyList: CompanyType[];
  changeCompanyName: (e: ChangeEvent<HTMLInputElement>, id: string) => void;
  type: TextInputTypes;
  deleteCompany: (id: string) => void;
  addRoles: (id: string) => void;
  selectCompanyImage?: (id: string) => void;
  onDragEnd?: any;
  isSubCategories?: boolean;
  slideType?: string;
};

const Companies = ({
  companyList,
  changeCompanyName,
  deleteCompany,
  addRoles,
  selectCompanyImage,
  onDragEnd,
  isSubCategories,
  slideType
}: CompanyProps) => {
  const modal = useModal();
  const [selectedId, setSelectedId] = useState<string>('');

  const showDropdown = (id: string) => selectedId === id;

  const confirmDelete = (id: string, companyName: string) => {
    modal.open(
      ModalsList.DIALOG_CONFIRM,
      (confirmed: boolean) => {
        if (confirmed) {
          deleteCompany(id);
        }
      },
      { title: `Delete company ${companyName}` }
    );
  };

  useEffect(() => {
    console.log('compay slide type: ', slideType);
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <DropWrapper droppableId="company-list-droppable" type="INPUT_COMPANY" direction="vertical">
        {companyList.map((company, index) => (
          <DragWrapper key={company.id} draggableId={company.id} index={index}>
            <React.Fragment key={index}>
              <InputDropdown
                key={index}
                id={company.id}
                name="name"
                hasDragIcon
                placeholder="Company"
                value={company.name}
                onChange={changeCompanyName}
                galleryModal={selectCompanyImage}
                confirmName={company.name}
                confirmModal={confirmDelete}
                setDropdownId={setSelectedId}
                showDropdown={showDropdown}
                isSubCategoriesCompany={isSubCategories != undefined && isSubCategories ? true : false}
                slideType={slideType}
              >
                <div className="dropdown">
                  <label>Roles Field</label>
                  <Roles items={company.roles} />
                  <Button text="Add roles" tiny small variant="secondary" onClick={() => addRoles(company.id)} />
                </div>
              </InputDropdown>
            </React.Fragment>
          </DragWrapper>
        ))}
      </DropWrapper>
    </DragDropContext>
  );
};
export default Companies;
