import lazies from './lazies';

export enum ModalsList {
  CANDIDATE_BAD_FEEDBACK = 'CANDIDATE_BAD_FEEDBACK',
  CANDIDATE_POSITIVE_FEEDBACK = 'CANDIDATE_POSITIVE_FEEDBACK',
  CANDIDATE_PROFILE = 'CANDIDATE_PROFILE',
  ROLES = 'ROLES',
  FIT_PROCESS = 'FIT_PROCESS',
  PARTNER_PROFILE = 'PARTNER_PROFILE',
  TEAM_MEMBERS = 'TEAM_MEMBERS',
  SHOW_MORE = 'SHOW_MORE',
  SUB_CATEGORY = 'SUB_CATEGORY',
  VIDEO_MODAL = 'VIDEO_MODAL'
}

export default {
  [ModalsList.CANDIDATE_BAD_FEEDBACK]: lazies.CandidateBadFeedback,
  [ModalsList.CANDIDATE_POSITIVE_FEEDBACK]: lazies.CandidatePositiveFeedback,
  [ModalsList.CANDIDATE_PROFILE]: lazies.CandidateProfile,
  [ModalsList.ROLES]: lazies.Roles,
  [ModalsList.FIT_PROCESS]: lazies.FitProcess,
  [ModalsList.PARTNER_PROFILE]: lazies.PartnerProfile,
  [ModalsList.TEAM_MEMBERS]: lazies.TeamMembers,
  [ModalsList.SHOW_MORE]: lazies.ShowMore,
  [ModalsList.SUB_CATEGORY]: lazies.SubCategory,
  [ModalsList.VIDEO_MODAL]: lazies.VideoModal
};
