/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import Button from 'components/common/button';
//import { AutoComplete, TabsSwitcher } from 'components';
import { extendedDateTimeFormat } from 'constants/dates';
import useApi, { Methods } from 'hooks/useApi';
import useFormHandler from 'hooks/useFormHandler';
import deckSchema from 'schemas/modals/deckInformation';
import searchSchema from 'schemas/modals/searchInformation';
import Base from '../base';
import { Props } from '../types';
import Deck from './deck';
import Contact from './contact';
import Search from './search';
import TabsSwitcher from 'components/common/tabsSwitcher';
import { LogoContext } from 'app';

const DeckInformation = ({ closing, onClose, params }: Props) => {
  const { fetchData, response, loaded, isLoading } = useApi();
  const fetchBrandingThemeData = useApi();
  const updateInfo = useApi();
  const fetchPartnerClients = useApi();
  const [deckLabels, setDeckLabels] = useState<any>();
  const deckFormHandler = useFormHandler(deckSchema);
  const searchFormHandler = useFormHandler(searchSchema);
  const [partners, setPartners] = useState<any>();
  const [companyId, setCompanyId] = useState<string>('');
  const [clients, setClients] = useState<any>();
  const [galaxyId, setGalaxyId] = useState<any>();
  const [brandingThemeId, setBrandingThemeId] = useState();
  const [emailTemplateId, setEmailTemplateId] = useState();
  const [brandingThemeName, setBrandingThemeName] = useState();
  const [emailTemplateName, setEmailTemplateName] = useState();
  const [isReady, setIsReady] = useState(false);
  const { logo, setLogo } = useContext(LogoContext);
  const [isPartnersClientsLoaded, setIsPartnersClientsLoaded] = useState(false);
  const [masterTemplateName, setMasterTemplateName] = useState('');
  const [isMasterTemplatedSelected, setIsMasterTemplatedSelected] = useState(false);
  const [infoChangedCount, setInfoChangedCount] = useState(0);

  const hasPartners = searchFormHandler?.data?.searchInformation?.leadPartner?.length > 0;
  const hasProjectTitle = searchFormHandler?.data?.searchInformation?.jobTitle;

  const saveDeckInfo = () => {
    const { companyName, jobTitle, industry, region, country, jobDescription } =
      searchFormHandler.data.searchInformation;
    const tags = deckFormHandler.data.tags;

    fetchBrandingThemeData.fetchData({
      endpoint: `/decks/getBrandingThemeById/${brandingThemeId}`
    });

    updateInfo.fetchData(
      {
        endpoint: `/decks/updateInformation`,
        method: Methods.POST
      },
      {
        deckid: params.deckId.toString(),
        search_galaxy_id: searchFormHandler.data.searchInformation.searchGalaxyId,
        job_title: jobTitle,
        company_name: companyName,
        company_galaxy_id: companyId,
        tags,
        function: searchFormHandler.data.searchInformation.function,
        industry: industry,
        region: region !== undefined ? region : '',
        country: country,
        job_description: jobDescription,
        partners: partners,
        clients: clients,
        reqType: 'update',
        branding_theme_id: brandingThemeId,
        email_template_id: emailTemplateId,
        is_master_template: isMasterTemplatedSelected,
        template_name: masterTemplateName
      }
    );
  };

  useEffect(() => {
    fetchData({
      endpoint: `/decks/${params.deckId}`,
      method: Methods.GET
    });
  }, []);

  const handleTheme = async () => {
    await handleBrandingTheme();
    handleOnClose();
  };

  const handleBrandingTheme = async () => {
    let theme = fetchBrandingThemeData?.response?.brandingThemes[0];

    document.documentElement.style.setProperty('--headeer-bar-color', theme.header_bar_color);
    localStorage.setItem('headerbarColor', theme.header_bar_color);
    document.documentElement.style.setProperty('--primary-button-color', theme.primary_button_color);
    localStorage.setItem('primaryButtonColor', theme.primary_button_color);
    document.documentElement.style.setProperty('--secondary-button-color', theme.secondary_button_color);
    localStorage.setItem('secondaryButtoncolor', theme.secondary_button_color);
    document.documentElement.style.setProperty('--title-text-color', theme.title_text_color);
    localStorage.setItem('titleTextColor', theme.title_text_color);
    document.documentElement.style.setProperty('--body-text-color', theme.body_text_color);
    localStorage.setItem('bodyTextColor', theme.body_text_color);
    localStorage.setItem('logoUrl', theme.logo);
    if (theme.font === 'Satoshi') {
      document.documentElement.style.setProperty('--font', theme.font);
    } else if (theme.font === 'Bitter') {
      document.documentElement.style.setProperty('--font', 'Bitter');
    } else if (theme.font === 'OpenSans') {
      document.documentElement.style.setProperty('--font', 'OpenSans');
    } else if (theme.font === 'Roboto') {
      document.documentElement.style.setProperty('--font', 'Roboto');
    } else {
      document.documentElement.style.setProperty('--font', 'Satoshi');
    }
    setLogo('1');
    console.log(logo);
  };

  const handleOnClose = () => {
    onClose({
      deck: deckFormHandler.data,
      leadPartner: searchFormHandler.data.searchInformation.leadPartner,
      search: searchFormHandler.data
    });
  };

  useEffect(() => {
    if (fetchBrandingThemeData?.response) {
      handleTheme();
    }
  }, [fetchBrandingThemeData?.response]);

  useEffect(() => {
    if (loaded && response) {
      setGalaxyId(response.search_galaxy_id);
      setDeckLabels({
        deckInformation: {
          search: `#${response?.id}`,
          status: response?.status?.label,
          creationDate: format(parseISO(response?.created_at), extendedDateTimeFormat),
          owner: response?.created_by,
          updates: response?.last_update
            ? `${format(parseISO(response?.last_update), extendedDateTimeFormat)} by ${response?.updated_by}`
            : '-'
        }
      });
      deckFormHandler.setFullForm({
        tags: response?.tags.map((e: any) => e.id)
      });
      setBrandingThemeId(response?.branding_theme_id);
      setEmailTemplateId(response?.email_template_id);
      setBrandingThemeName(response?.branding_theme_name);
      setEmailTemplateName(response?.email_template_name);
      setMasterTemplateName(response?.template_name);
      setIsMasterTemplatedSelected(response?.is_master_template);
      setIsReady(true);
      console.log('response?.branding_theme_id: ', response?.branding_theme_id);
      searchFormHandler.setFullForm({
        searchInformation: {
          jobTitle: response?.job_title,
          searchGalaxyId: response?.search_galaxy_id,
          companyName: response?.company_name,
          companyId: response?.company_galaxy_id,
          function: response?.function,
          industry: response?.industry,
          region: response?.region,
          country: response?.country,
          jobDescription: response?.job_description,
          leadPartner: response?.leadPartners || []
        }
      });

      console.log('company_galaxy_id :', response?.company_galaxy_id);
      setCompanyId(response?.company_galaxy_id);
      fetchPartnerClients.fetchData({
        endpoint: `/decks/partnerandclient/${params.deckId}`,
        method: Methods.GET
      });
    }
  }, [response]);

  useEffect(() => {
    if (fetchPartnerClients?.response) {
      setPartners(fetchPartnerClients.response?.partners);
      setClients(fetchPartnerClients.response?.clients);
      setIsPartnersClientsLoaded(true);
    }
  }, [fetchPartnerClients.response]);

  useEffect(() => {
    if (searchFormHandler.data.searchInformation.searchGalaxyId !== undefined) {
      // console.log("++++++",searchFormHandler.data.searchInformation.searchGalaxyId);
      setGalaxyId(searchFormHandler.data.searchInformation.searchGalaxyId);
      setCompanyId(searchFormHandler.data.searchInformation.companyId);
      if (infoChangedCount > 0) {
        fetchPartnerClients.fetchData({
          endpoint: `/decks/partnerbyid/${searchFormHandler.data.searchInformation.searchGalaxyId}`,
          method: Methods.GET
        });
      }
      setInfoChangedCount(infoChangedCount + 1);
    }
  }, [searchFormHandler.data.searchInformation.searchGalaxyId]);

  let onChangePartner = (data: any) => {
    if (data !== undefined) {
      setPartners(data);
    }
  };

  let onChangeClient = (data: any) => {
    if (data !== undefined) {
      setClients(data);
    }
  };

  const onChangeBrandingTheme = (data: any) => {
    console.log('onChangeBrandingTheme', data);
    setBrandingThemeId(data);
  };

  const onChangeEmailTemplate = (data: any) => {
    console.log('onChangeEmailTemplate', data);
    setEmailTemplateId(data);
  };

  const onChangePartnerClientLoaded = (data: any) => {
    console.log('onChangePartnerClientLoaded', data);
    setIsPartnersClientsLoaded(data);
  };

  const onSetMasterTemplateName = (data: any) => {
    setMasterTemplateName(data);
  };

  const onSetIsMasterTemplatedSelected = (data: any) => {
    setIsMasterTemplatedSelected(data);
  };

  return (
    <>
      <Base
        header="SyncLink Information"
        footer={
          <Button
            text="Save information"
            onClick={saveDeckInfo}
            disabled={isLoading || !hasPartners || !hasProjectTitle}
          />
        }
        closing={closing}
        onClose={onClose}
        size="lg"
      >
        <div className="modal-deck-information">
          <TabsSwitcher
            forceRenderTabPanel
            defaultIndex={params.defaultIndex}
            data={[
              {
                title: 'SyncLink Information',
                component: (
                  <Deck
                    formHandler={deckFormHandler}
                    deckLabels={deckLabels}
                    deckId={params.deckId}
                    brandingThemeName={brandingThemeName}
                    emailTemplateName={emailTemplateName}
                    onChangeBrandingTheme={onChangeBrandingTheme}
                    onChangeEmailTemplate={onChangeEmailTemplate}
                    isReady={isReady}
                    masterTemplateName={masterTemplateName}
                    onSetMasterTemplateName={onSetMasterTemplateName}
                    onSetIsMasterTemplatedSelected={onSetIsMasterTemplatedSelected}
                  />
                )
              },
              {
                title: 'Project Information',
                component: <Search formHandler={searchFormHandler} />
              },
              {
                title: 'Contact Information',
                component: (
                  <Contact
                    clients={clients || []}
                    partners={partners || []}
                    onChangePartner={onChangePartner}
                    onChangeClients={onChangeClient}
                    companyId={companyId || ''}
                    galaxy_id={galaxyId || ''}
                    isPartnerClientLoaded={isPartnersClientsLoaded}
                    onChangePartnerClientLoaded={onChangePartnerClientLoaded}
                  />
                )
              }
            ]}
          />
        </div>
      </Base>
    </>
  );
};

export default DeckInformation;
