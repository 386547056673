import React from 'react';
import { format, parseISO } from 'date-fns';
import { extendedDateFormat } from 'constants/dates';

type Props = {
  value: string;
};

const Date = ({ value }: Props) => {
  return !value ? <span>no date</span> : <span>{format(parseISO(value), extendedDateFormat)}</span>;
};

export default Date;
