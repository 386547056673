import React, { useState } from 'react';
import classnames from 'classnames';
import { Trans } from 'components/common/trans';
import { ThumbsUp, ThumbsDown, Linkedin } from 'react-feather';
import { useModal } from 'hooks/useModal';
import { ModalsList } from '../../../constants/modals';
import Image from '../../shared/image';
import Button from '../button';

type Props = {
  id: string;
  name: string;
  title: string;
  company: string;
  image: string;
  position: string;
  address: string;
  content: string;
  linkedin: string;
  feedback?: string;
  sample?: boolean;
};

const CandidateCard = ({
  id,
  name,
  title,
  company,
  image,
  position,
  content,
  linkedin,
  feedback: _feedback,
  sample = false
}: Props) => {
  const modal = useModal();
  const [feedback, setFeedback] = useState<string | undefined>(_feedback);

  const handleDislikeCandidate = () => {
    if (sample) {
      return;
    }

    modal.open(
      ModalsList.CANDIDATE_BAD_FEEDBACK,
      (changed?: boolean) => {
        changed === true && setFeedback('dislike');
      },
      { candidateId: id }
    );
  };

  const handleLikeCandidate = () => {
    if (sample) {
      return;
    }

    modal.open(
      ModalsList.CANDIDATE_POSITIVE_FEEDBACK,
      (changed?: boolean) => {
        changed === true && setFeedback('like');
      },
      { candidateId: id }
    );
  };

  const handleOpenProfile = () => {
    if (sample) {
      return;
    }

    modal.open(ModalsList.CANDIDATE_PROFILE, () => {}, { id, avatar: image });
  };

  return (
    <div className="container">
      <div className="card">
        <div className="header">
          <Image
            url={image}
            fallback={`${process.env.REACT_APP_KGP_URL}/images?type=candidate&id=${id}`}
            fallback2={`${process.env.REACT_APP_PUBLIC_ASSETS_URL}/decks/379/profile_298x156_149x78_0_1671137188658.jpg`}
          />
        </div>
        <div className="body">
          <h6>
            {name}{' '}
            {!!linkedin && (
              <a className="linkedin-logo" href={linkedin} target="_blank" rel="noreferrer">
                <Linkedin />
              </a>
            )}
          </h6>

          <span>{position}</span>
          <span className="body-2 company-and-title">
            {company} <br /> {title}
          </span>
          <hr className="divider" />
          <span className="content">{content}</span>
        </div>
        <div className="footer">
          <div className="learnmore">
            <Button
              variant="link"
              text={<Trans>Learn more</Trans>}
              onClick={handleOpenProfile}
              data-event="click"
              data-action="candidate-detail"
              data-name={name}
            />
          </div>
          <div className="thumbs">
            <button
              onClick={handleDislikeCandidate}
              disabled={feedback === 'dislike'}
              className={classnames('dislike', { marked: feedback === 'dislike' })}
            >
              <ThumbsDown />
            </button>
            <button
              disabled={feedback === 'like'}
              onClick={handleLikeCandidate}
              className={classnames('like', { marked: feedback === 'like' })}
            >
              <ThumbsUp />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateCard;
