/* eslint-disable react/jsx-key */
/* eslint-disable react/react-in-jsx-scope */
import Button from 'components/common/button';
import Base from '../base';
import { Props } from '../types';
import { useEffect, useState } from 'react';
import { useModal } from 'hooks/useModal';
import { ModalsList } from 'constants/modals';
import useApi, { Methods } from 'hooks/useApi';

const CreateDeckTemplate = ({ closing, onClose, params }: Props) => {
  const modal = useModal();
  const [isFromScratch, setIsFromScratch] = useState(true);
  const [initialDeckId, setInitialDeckId] = useState(1);
  const getMasterDecks = useApi();
  const updateDeckWithTemplate = useApi();
  const [masterTemplates, setMasterTemplates] = useState<any>();

  useEffect(() => {
    // get all the master templates.
    getMasterDecks.fetchData({ endpoint: '/decks/getdecks/masterdecks' });
  }, []);

  useEffect(() => {
    if (getMasterDecks.response) {
      setMasterTemplates(getMasterDecks.response.decks);
    }
  }, [getMasterDecks?.response]);

  // Update deck information.
  const nextInfo = () => {
    if (params.deckId) {
      updateDeckWithTemplate.fetchData(
        {
          endpoint: '/decks/updateDeckWithSelectTemplate',
          method: Methods.POST
        },
        {
          updateIntoDeckId: params.deckId,
          templateId: initialDeckId
        }
      );
    } else {
      modal.open(
        ModalsList.CREATE_DECK,
        (data: any) => {
          if (data) {
            onClose(data);
          } else {
            onClose();
          }
        },
        { defaultIndex: 1, defaultDeckId: initialDeckId, popupFrom: params.popupFrom, deckId: params.deckId }
      );
    }
  };

  useEffect(() => {
    if (updateDeckWithTemplate.response) {
      window.location.reload();
    }
  }, [updateDeckWithTemplate?.response]);

  const onChangeRadio = (isFromScratch: boolean, initialDeckId: any) => {
    setIsFromScratch(isFromScratch);
    setInitialDeckId(initialDeckId);
  };

  useEffect(() => {
    console.log(initialDeckId);
    console.log(getMasterDecks?.response);
  });

  return (
    <Base
      header="Create New"
      footer={<Button text="Next" onClick={nextInfo} disabled={!isFromScratch && initialDeckId === 1} />}
      closing={closing}
      onClose={onClose}
      size="lg"
    >
      <>
        <p>Pick one of the options below to get started,</p>

        <div className="input-container">
          <div className="radio-input">
            <input type="radio" value={'1'} name="scratch" onChange={() => onChangeRadio(true, 1)} defaultChecked />
            <label className="label-class">Use the standard slide set</label>
            <br></br>
          </div>
          <div className="radio-input">
            <input type="radio" value={'2'} name="scratch" onChange={() => setIsFromScratch(false)} />
            <label className="label-class">Clone an existing Synclink</label>
            <br></br>
          </div>

          {!isFromScratch && (
            <div className="template-input-container">
              <table style={{ width: '100%', marginTop: '10px' }}>
                <thead>
                  <tr>
                    <th className="input-th"> </th>
                    <th className="template-name font-weight-th">Template Name</th>
                    <th className="country font-weight-th">Country</th>
                    <th className="region font-weight-th">Region</th>
                  </tr>
                </thead>
                {masterTemplates === undefined && getMasterDecks.isLoading && (
                  <div className="template-radio-input">
                    <p>Loading...</p>
                  </div>
                )}
                <tbody>
                  {masterTemplates !== undefined &&
                    masterTemplates.map((template: any, index: any) => (
                      <tr key={index}>
                        <td className="input-th">
                          <input
                            type="radio"
                            value={index}
                            name="template"
                            onChange={() => setInitialDeckId(template.id)}
                          />
                        </td>
                        <td className="label-class template-name">{template.template_name}</td>
                        <td className="label-class country">{template.country}</td>
                        <td className="label-class region">{template.region}</td>
                        {/* </div> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </>
    </Base>
  );
};

export default CreateDeckTemplate;
