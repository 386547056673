import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import HelloSign from 'hellosign-embedded';
import useWindowSize from '@revolt-digital/use-window-size';
import { useParams } from 'react-router-dom';
import { ArrowLeft, ArrowRight, Download, Edit3 } from 'react-feather';
import { Trans } from 'components/common/trans';
import useApi from 'hooks/useApi';
import useHellosign, { Methods } from '../../../hooks/useHellosign';
import { BREAKPOINT_TABLET_LG } from '../../../constants/breakpoints';
import Button from '../../shared/button';
import Expansible from '../../shared/expansible';
import Sanitized from '../../shared/sanitized';
import BGMosaic from '../../shared/bgMosaic';

type Props = {
  title: string;
  content: string;
  image: string;
  loe: any;
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Loe = ({ title, content, loe, image }: Props) => {
  const isMobile = useWindowSize().width <= BREAKPOINT_TABLET_LG;
  const { deckId } = useParams();
  const [dataPdf, setDataPdf] = useState<any>();
  const [urlPdf, setUrlPdf] = useState<any>();
  const [uriPdf, setUriPdf] = useState<any>();
  const [onRetry, setOnRetry] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [embeddedPdf, setEmbeddedPdf] = useState<any>();
  const getPdfData = useHellosign();
  const getFileUrl = useHellosign();
  const getUriPdf = useHellosign();
  const setSigned = useApi();
  const getEmbedded = useHellosign();
  const getSignaturePdf = useHellosign();
  const client = new HelloSign();

  const callSign = () => {
    client.open(embeddedPdf, {
      clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID
    });
    client.on('close', () => getEmbeddedUrl());
    client.on('sign', () => {
      fetchPdf();
      setSignedNotification();
    });
  };
  const setSignedNotification = () => {
    const data = {
      deckid: deckId
    };
    setSigned.fetchData({ endpoint: '/decks/sign', method: Methods.POST }, data);
  };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset: number) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  const fetchPdf = async () => {
    if (deckId) {
      getPdfData.fetchData({
        endpoint: `signature_request/list?query=metadata:${deckId}`,
        method: Methods.GET
      });
    }
  };

  useEffect(() => {
    if (!window.partner) {
      fetchPdf();
    }
  }, []);

  useEffect(() => {
    if (getPdfData?.response) {
      setDataPdf(getPdfData?.response?.signature_requests?.[0]);
    }
  }, [getPdfData?.response]);

  const requestUri = () => {
    if (dataPdf) {
      getUriPdf.fetchData({
        endpoint: `signature_request/files_as_data_uri/${dataPdf?.signature_request_id}`,
        method: Methods.GET
      });
    }
  };

  useEffect(() => {
    if (dataPdf) {
      getFileUrl.fetchData({
        endpoint: `signature_request/files/${dataPdf?.signature_request_id}?file_type=pdf&get_url=true`,
        method: Methods.GET
      });
      requestUri();
      if (!dataPdf?.is_complete) {
        getSignaturePdf.fetchData({
          endpoint: `signature_request/${dataPdf?.signature_request_id}`,
          method: Methods.GET
        });
      }
    }
  }, [dataPdf]);

  const getEmbeddedUrl = () => {
    if (getSignaturePdf?.response) {
      try {
        getEmbedded.fetchData({
          endpoint: `embedded/sign_url/${getSignaturePdf?.response?.signature_request?.signatures[0]?.signature_id}`,
          method: Methods.GET
        });
      } catch (error) {
        console.log(error);
        // fetchPdf();
      }
    }
  };

  useEffect(() => {
    getEmbeddedUrl();
  }, [getSignaturePdf?.response]);

  useEffect(() => {
    if (getEmbedded?.response?.error) {
      fetchPdf();
      return;
    }
    if (getEmbedded?.response?.embedded?.sign_url) {
      setEmbeddedPdf(getEmbedded?.response?.embedded?.sign_url);
    }
  }, [getEmbedded?.response]);

  useEffect(() => {
    if (getFileUrl?.response) {
      setUrlPdf(getFileUrl?.response);
    }
  }, [getPdfData]);

  useEffect(() => {
    if (getUriPdf?.response?.error?.error_name === 'conflict') {
      setTimeout(() => {
        requestUri();
        return;
      }, 3000);
    }
    setUriPdf(getUriPdf?.response?.data_uri);
  }, [getUriPdf]);

  return (
    <div className="layout-signature-document">
      <BGMosaic />

      <div className="flex-wrapper">
        <div className="info">
          <div className="text-container">
            <h3>{title}</h3>
            <Expansible classes="body">
              <Sanitized html={content} />
            </Expansible>
            <div className="footer">
              {dataPdf?.is_complete && (
                <Button
                  text={<Trans>Download</Trans>}
                  icon={<Download />}
                  onClick={() => window.open(urlPdf?.file_url, '_self')}
                  disabled={!uriPdf}
                />
              )}
            </div>
          </div>
        </div>
        <div className="signature-pdf">
          <div className="pdf-container">
            {(window.partner && !!loe?.url) || !!uriPdf ? (
              <>
                <Document
                  file={window.partner ? loe?.url : uriPdf}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={() => setOnRetry(s => ++s)}
                  key={onRetry}
                >
                  <Page
                    pageNumber={pageNumber}
                    height={isMobile || window.partner ? 450 : 550}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                </Document>
                {window.partner ||
                  (numPages >= 1 && (
                    <div className="pdf-buttons">
                      <Button
                        icon={<ArrowLeft />}
                        variant="default"
                        small
                        onClick={pageNumber <= 1 ? () => null : () => changePage(-1)}
                      />
                      {!dataPdf?.is_complete && (
                        <Button icon={<Edit3 />} variant="default" small onClick={callSign} text="preview AND SIGN" />
                      )}
                      <Button
                        icon={<ArrowRight />}
                        variant="default"
                        small
                        onClick={pageNumber >= numPages ? () => null : () => changePage(1)}
                      />
                    </div>
                  ))}
              </>
            ) : (
              <img src={image} alt="empty state" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Loe;
