import React, { useEffect, useState } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';
import schema from 'schemas/modals/sendToClient';
import useFormHandler from 'hooks/useFormHandler';
import { useToast } from 'hooks/useToast';
import useApi, { Methods } from 'hooks/useApi';
import useHellosign from 'hooks/useHellosign';
import { Button, DynamicForm, Input } from 'components';
import Base from '../base';
import { Props } from '../types';
import { TextInputTypes } from 'components/dynamicInputs/types';
import bodyTextSchema from 'schemas/modals/bodyText';
import AutoComplete from 'components/dynamicInputs/autoComplete';
import { PartnerItem } from 'components/dynamicInputs/types';

const SendToClient = ({ closing, onClose, params }: Props) => {
  const notify = useToast();
  const formHandler = useFormHandler(schema);
  const getFile = useHellosign();
  const uploadLOE = useHellosign();
  const saveApi = useApi();
  const sendApi = useApi();
  const getApi = useApi();
  const [name, setName] = useState<string>('');
  const [ccName, setccName] = useState<PartnerItem>();
  const getDeckPartnersApi = useApi();
  const getClientCompanyNameApi = useApi();
  const getDeckEmailTemplateApi = useApi();
  const getPdfData = useHellosign();
  const getSingerList = useHellosign();
  const cancelRequest = useHellosign();
  const bodyTextFormHandler = useFormHandler(bodyTextSchema);
  const [subject, setSubject] = useState('');
  const [title, setTitle] = useState('');
  const [parterOptions, setParterOptions] = useState([]);
  const [signer, setSigner] = useState<any>();
  const [emailToSign, setEmailToSign] = useState<any>();
  const isLoading = saveApi.isLoading || sendApi.isLoading || getApi.isLoading;
  const { deck_id: deckId, isEdit } = params;
  const [urlPdf] = useLocalStorage(`urlPdf-${deckId}`);
  const [headerbarColor] = useLocalStorage(`headerbarColor`);

  const handleSave = () => {
    saveApi.fetchData(
      {
        endpoint: `/decks/clients/save`,
        method: Methods.POST
      },
      {
        deckid: Number(deckId),
        clients: formHandler.data.sendToClient.map((client: any) => ({
          id: client.id.toString(),
          name: client.name,
          email: client.email
        }))
      }
    );

    if (urlPdf) {
      console.log('Id : ', formHandler.data.sendToClient[0].id.toString());
      console.log('Going to upload LOE to HelloSign');
      uploadLOE.fetchData(
        {
          endpoint: 'signature_request/create_embedded',
          method: Methods.POST
        },
        {
          client_id: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
          metadata: { ...params, client_id: formHandler.data.sendToClient[0].id.toString() },
          file_url: [urlPdf],
          signers: [
            { email_address: formHandler.data.sendToClient[0].email, name: formHandler.data.sendToClient[0].name }
          ],
          signing_options: {
            draw: true,
            type: true,
            upload: true,
            phone: false,
            default_type: 'draw'
          },
          test_mode: process.env.REACT_APP_HELLOSIGN_TEST_MODE
        }
      );
    }
  };
  useEffect(() => {
    if (getPdfData.response && getFile.response) {
      console.log('getFile.response :', getFile.response);
      if (getPdfData.response.signature_requests.length > 0) {
        for (const signature_request of getPdfData.response.signature_requests) {
          console.log('signature_request.signature_request_id: ', signature_request.signature_request_id);
          if (getFile.response.signature_request.signature_request_id !== signature_request.signature_request_id) {
            cancelRequest.fetchData({
              endpoint: `signature_request/cancel/${signature_request.signature_request_id}`,
              method: Methods.POST
            });
          }
        }
      }
    }
  }, [getPdfData.response, getFile.response]);

  useEffect(() => {
    if (getPdfData.response && getApi.loaded && signer) {
      console.log('Signature Req Id ', getPdfData?.response);
      //  Create new signature in hello sign.
      getFile.fetchData(
        {
          endpoint: 'signature_request/create_embedded',
          method: Methods.POST
        },
        {
          client_id: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
          metadata: { ...params, client_id: emailToSign.id },
          file_url: [urlPdf],
          signers: [{ email_address: signer[0].email, name: signer[0].name }],
          signing_options: {
            draw: true,
            type: true,
            upload: true,
            phone: false,
            default_type: 'draw'
          },
          test_mode: process.env.REACT_APP_HELLOSIGN_TEST_MODE
        }
      );
    }
  }, [getPdfData.response, getApi.loaded]);

  const handleSend = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.disabled = true;
    console.log('formHandler.data : ', formHandler.data);
    console.log('formHandler.subject : ', formHandler.data.sendToClient[0].subject);
    console.log('formHandler.data title : ', formHandler.data.sendToClient[0].title);
    console.log('[headerbarColor] : ', [headerbarColor]);

    getPdfData.fetchData({
      endpoint: `signature_request/list?query=metadata:${deckId}`,
      method: Methods.GET
    });

    sendApi.fetchData(
      { endpoint: `/decks/send`, method: Methods.POST },
      {
        deckid: Number(deckId),
        clients: formHandler.data.sendToClient.map((client: any) => ({
          id: client.id.toString(),
          subject: formHandler.data.sendToClient[0].subject,
          title: formHandler.data.sendToClient[0].title,
          body: formHandler.data.sendToClient[0].body,
          headerbarColor: formHandler.data.sendToClient[0].headerbarColor,
          primaryButtonColor: formHandler.data.sendToClient[0].primaryButtonColor,
          titleTextColor: formHandler.data.sendToClient[0].titleTextColor,
          bodyTextColor: formHandler.data.sendToClient[0].bodyTextColor,
          logoUrl: formHandler.data.sendToClient[0].logoUrl,
          fromEmail:
            formHandler.data.sendToClient[0].fromEmail !== undefined
              ? formHandler.data.sendToClient[0].fromEmail
              : getDeckPartnersApi.response?.partners[0].email,
          fromUserName:
            formHandler.data.sendToClient[0].fromUserName !== undefined
              ? formHandler.data.sendToClient[0].fromUserName
              : getDeckPartnersApi.response?.partners[0].name,
          name: client.name.toString(),
          email: client.email.toString(),
          ccPartnerList: formHandler.data.sendToClient[0].ccPartnerList
        }))
      }
    );
  };

  useEffect(() => {
    getApi.fetchData({ endpoint: `/decks/getLOESlide/${deckId}` });
    getDeckEmailTemplateApi.fetchData({ endpoint: `/decks/getEmailTemplate/${deckId} ` });
    getDeckPartnersApi.fetchData({ endpoint: `/decks/getDeckPartners/${deckId}` });
    getClientCompanyNameApi.fetchData({ endpoint: `/decks/getClientCompanyName/${deckId}` });
    getSingerList.fetchData({
      endpoint: `/signature_request/list?page=1&page_size=20`,
      method: Methods.GET
    });
  }, []);

  useEffect(() => {
    if (getDeckEmailTemplateApi.response) {
      console.log('Deck Email Template: ', getDeckEmailTemplateApi.response);
      for (const template of getDeckEmailTemplateApi.response.emailTemplates) {
        bodyTextFormHandler.setFullForm({
          bodyText: template.body
        });
        setTitle(template.title);
      }
    }

    if (getDeckEmailTemplateApi.response && getClientCompanyNameApi.response) {
      console.log('Deck Client Company Name: ', getClientCompanyNameApi.response.name);
      for (const template of getDeckEmailTemplateApi.response.emailTemplates) {
        setSubject(`${template.subject.replace('(Client Company Name)', '')} ${getClientCompanyNameApi.response.name}`);
      }
    }
  }, [getClientCompanyNameApi.response, getDeckEmailTemplateApi.response]);

  useEffect(() => {
    if (formHandler.data.sendToClient.length > 0) {
      formHandler.data.sendToClient[0].subject = subject;
      formHandler.data.sendToClient[0].title = title;
      formHandler.data.sendToClient[0].body = bodyTextFormHandler.data?.bodyText;
    }
  }, [formHandler.data.sendToClient.length > 0]);

  useEffect(() => {
    if (getDeckPartnersApi.response) {
      console.log('Deck Partner Res: ', getDeckPartnersApi.response);
      let tempOption = getDeckPartnersApi.response?.partners.map((e: any) => ({
        value: { id: e.id, name: e.name, email: e.email },
        label: e.name
      }));
      setParterOptions(tempOption);
      setName(getDeckPartnersApi.response?.partners[0].name);
    }
  }, [getDeckPartnersApi.response]);

  useEffect(() => {
    if (getApi.loaded) {
      if (
        getApi.response.loe_slide !== undefined &&
        getApi.response?.loe_slide?.raw?.loe?.signer &&
        !getApi.response?.loe_slide?.hide
      ) {
        console.log('Signer :', getApi.response?.loe_slide?.raw?.loe?.signer);
        setSigner(getApi.response?.loe_slide?.raw?.loe?.signer);
        if (isEdit) {
          formHandler.setFullForm({
            sendToClient: [getApi.response?.loe_slide?.raw?.loe?.signer[0], { isEdit: true }]
          });
        } else {
          formHandler.setFullForm({
            sendToClient: [getApi.response?.loe_slide?.raw?.loe?.signer[0], { isEdit: false }]
          });
        }
      } else {
        if (isEdit) {
          formHandler.setFullForm({ sendToClient: [{ isEdit: true }] });
        } else {
          formHandler.setFullForm({ sendToClient: [{ isEdit: false }] });
        }
      }
    }
  }, [getApi.loaded]);

  useEffect(() => {
    setEmailToSign(formHandler.data.sendToClient[0]); //Only the first client will be able to sign
  }, [formHandler.data]);

  useEffect(() => {
    if (saveApi.response) {
      notify('Information saved');
      isEdit && onClose(formHandler.data);
    }
  }, [saveApi.response]);

  useEffect(() => {
    if (sendApi.response) {
      notify('Links sent to the clients');
      onClose(formHandler.data);
    }
  }, [sendApi.response]);

  useEffect(() => {
    console.log('bodyTextFormHandler.data?.bodyText', bodyTextFormHandler.data?.bodyText);
    if (formHandler.data.sendToClient.length > 0) {
      formHandler.data.sendToClient[0].body = bodyTextFormHandler.data?.bodyText;
    }
  }, [bodyTextFormHandler.data?.bodyText]);

  const handleChangeSubject = (subjectValue: any) => {
    if (formHandler.data.sendToClient.length > 0) {
      formHandler.data.sendToClient[0].subject = subjectValue;
    }
    setSubject(subjectValue);
  };

  const handlePartner = (data: any) => {
    if (formHandler.data.sendToClient.length > 0) {
      formHandler.data.sendToClient[0].fromEmail = data.id.email;
      formHandler.data.sendToClient[0].fromUserName = data.id.name;
    }
    setName(data.name);
  };

  const handleCCPartner = (data: any) => {
    if (formHandler.data.sendToClient.length > 0) {
      formHandler.data.sendToClient[0].ccPartnerList = data;
    }

    console.log('data.name', data);
    setccName(data);
  };

  const handleChangeTitle = (titleValue: any) => {
    if (formHandler.data.sendToClient.length > 0) {
      formHandler.data.sendToClient[0].title = titleValue;
    }
    setTitle(titleValue);
    console.log('titleValue :', titleValue);
  };

  return (
    <Base
      header={!isEdit ? 'Send to client' : 'Add client'}
      closing={closing}
      onClose={onClose}
      size={!isEdit ? 'lg' : 'md'}
      footer={
        <>
          {isEdit && (
            <Button
              text="Save info"
              disabled={!formHandler.valid || isLoading}
              onClick={handleSave}
              variant="secondary"
            />
          )}
          {!isEdit && <Button text="Send links" disabled={!formHandler.valid || isLoading} onClick={handleSend} />}
        </>
      }
    >
      <div className="sentClient">
        <div className="titles">
          <h1 className="body strong">{!isEdit ? 'Send this SyncLink to your client' : 'Add your Client email'}</h1>
          <p className="body">
            {!isEdit
              ? 'Search for your Client to share your SyncLink.'
              : 'This client will be responsible to sign the LOE'}
          </p>
        </div>
        <div className={isEdit ? 'signer' : ''}>
          <DynamicForm handler={formHandler} />
        </div>

        {isEdit ? (
          <></>
        ) : (
          <div className="value">
            <AutoComplete
              name="partnerName"
              label="From"
              value={{ name: name } || {}}
              onChange={obj => handlePartner(obj)}
              customOptions={parterOptions?.map((item: any) => ({
                id: item.value,
                name: item.label
              }))}
              isMulti={false}
            />
            <AutoComplete
              name="ccPartnerName"
              label="CC"
              value={ccName}
              onChange={obj => handleCCPartner(obj)}
              endpoint="/partners/search"
              placeholder=""
              isMulti={true}
            />
            <Input
              label="Subject"
              name="subject"
              type={TextInputTypes.TEXT}
              value={subject}
              limit={100}
              onChange={handleChangeSubject}
            />
            <Input
              label="Title"
              name="title"
              type={TextInputTypes.TEXT}
              value={title}
              limit={100}
              onChange={handleChangeTitle}
            />
            <small className="body signer title-field-information">
              *Note: (Client FirstName), (Client LastName) will resolve to the respective client name in the email.
            </small>
            <span className="reach-text">
              <DynamicForm handler={bodyTextFormHandler} />
            </span>
          </div>
        )}
      </div>
    </Base>
  );
};

export default SendToClient;
