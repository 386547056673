import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

type Props = {
  className?: string;
  url?: string;
  alt?: string;
  fallback?: string;
  fallback2?: string;
};

const Image = ({ className = '', alt, url, fallback, fallback2 }: Props) => {
  const [fallbackAttemptPassed, setFallbackAttemptPassed] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const onError = (event: any) => {
    event.currentTarget.src = !fallbackAttemptPassed ? fallback : fallback2;

    if ((!fallbackAttemptPassed && !fallback2) || (fallbackAttemptPassed && fallback2)) {
      event.currentTarget.onerror = null;
    }

    setFallbackAttemptPassed(true);
  };

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    !url && setFallbackAttemptPassed(true);
  }, []);

  if (!fallback) {
    return <img className={className} src={url} alt={alt} />;
  }

  return (
    <img
      className={classnames(className, 'lazy-img', { loaded })}
      alt={alt}
      onError={onError}
      onLoad={onLoad}
      src={url || fallback}
    />
  );
};

export default Image;
