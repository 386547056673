import React from 'react';

type Props = {
  date: string;
  deckName: string;
  text: string;
  client: string;
};

const Comment = ({ deckName, text, client, date }: Props) => {
  return (
    <div className="comment-item">
      <div className="comment-item-header">
        <h5 className="body-2 bold">{client} </h5>
        <small>on {deckName}</small>
        <small>{date}</small>
      </div>
      <div className="comment-item-body">
        <p className="body-2">{text}</p>
      </div>
    </div>
  );
};

export default Comment;
