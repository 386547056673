import React, { useEffect } from 'react';
import { Plus, X } from 'react-feather';
import useApi from 'hooks/useApi';
import { TagInputProps } from '../types';

const TagInput = ({ value = [], onChange }: TagInputProps) => {
  const getTags = useApi();
  const getUserRole = useApi();

  const handleTagSelect = (id: string) => {
    if (value.includes(id)) {
      onChange(value.filter(item => item !== id));
    } else {
      onChange([...value, id]);
    }
  };

  useEffect(() => {
    getUserRole.fetchData({ endpoint: `/decks/getCognitoUserRole` });
    getTags.fetchData({
      endpoint: '/tags'
    });
  }, []);

  return (
    <div className="tag-input">
      {getTags.loaded &&
        getTags.response.items.map((item: any) => (
          <div
            key={item.id}
            className="tag-element"
            style={{ backgroundColor: value.includes(item.id) ? item.color : undefined }}
            onClick={() => handleTagSelect(item.id)}
          >
            {value.includes(item.id) ? <X /> : <Plus />}
            <span className="body-2">{item.label}</span>
          </div>
        ))}
    </div>
  );
};

export default TagInput;
