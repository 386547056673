import React from 'react';
import useFormHandler from 'hooks/useFormHandler';
import { Button, DynamicForm } from 'components';
import schema from 'schemas/modals/editSlideName';
import { Props } from '../types';
import Base from '../base';

const Slide = ({ closing, onClose }: Props) => {
  const formHandler = useFormHandler(schema);

  return (
    <Base
      closing={closing}
      header="Edit slide's name"
      onClose={onClose}
      size="md"
      footer={<Button text="Save" disabled={!formHandler.valid} onClick={() => onClose(formHandler.data)} />}
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default Slide;
