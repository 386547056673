import React from 'react';

const Shapes = () => (
  <svg className="shapes" viewBox="0 0 649 151" fill="none">
    <g opacity="0.6">
      <path
        opacity="0.4"
        d="M58.2393 200.24L163.342 200.24C213.383 200.24 253.948 159.675 253.948 109.634L253.948 0.000822124L156.094 0.000830679C102.05 0.000835403 58.2392 43.8118 58.2392 97.8554L58.2393 200.24Z"
        fill="#DFDBF5"
      />
      <path
        d="M58.2393 200.24L-47.7699 200.24C-97.8102 200.24 -138.376 159.675 -138.376 109.634L-138.376 0.000830224L58.2393 0.000823975L58.2393 200.24Z"
        fill="#EFEAF6"
      />
      <path
        opacity="0.5"
        d="M449.658 200.24L344.555 200.24C294.515 200.24 253.949 159.675 253.949 109.634L253.949 0.000830196L449.658 0.000823975L449.658 200.24Z"
        fill="#F4F2FA"
      />
      <path
        d="M447.846 198.428L447.846 92.4186C447.846 42.3783 488.411 1.81257 538.452 1.81257L648.085 1.81257L648.085 107.822C648.085 157.862 607.519 198.428 557.479 198.428L447.846 198.428Z"
        fill="#F9EFF6"
      />
    </g>
  </svg>
);

export default Shapes;
