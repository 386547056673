import React, { useMemo } from 'react';
import useWindowSize from '@revolt-digital/use-window-size';
import { useModal } from 'hooks/useModal';
import { ModalsList } from '../../../constants/modals';
import { BREAKPOINT_TABLET_LG } from '../../../constants/breakpoints';
import Carousel from '../../shared/carousel';
import CompanyCard from '../../shared/companyCard';
import SubCategoryCard from '../../shared/subCategoryCard';
import Expansible from '../../../components/shared/expansible';
import Sanitized from '../../shared/sanitized';
import TabBar from '../../shared/tabs';
import Shape1 from '../../shared/shape1';
import Shape2 from '../../shared/shape2';
import Shape3 from '../../shared/shape3';
// import useLocalStorage from '@rehooks/local-storage';

export type RoleListData = {
  id: string;
  name: string;
  pinned: boolean;
  count: number;
};

type ItemType = {
  id: string;
  label: string;
  companies: Company[];
  subCategories: SubCategory[];
};

type Company = {
  id: string;
  name: string;
  image: string;
  roles: RoleListData[];
};

export type SubCategory = {
  id: string;
  name: string;
  companies: Company[];
};

type Item = Company & SubCategory;

type Companies = {
  hasSubcategories: boolean;
  items: Item[];
};

type Props = {
  title: string;
  content: string;
  highlight: string;
  items: ItemType[];
  selected: boolean;
};

const CompaniesCarousel = ({ items, hasSubcategories }: Companies) => {
  const windowSize = useWindowSize();
  const modal = useModal();
  // const [token] = useLocalStorage('token');
  const isMobile = windowSize.width <= BREAKPOINT_TABLET_LG;
  const handleRolesModal = (companyName: string, roles: RoleListData[]) => {
    modal.open(ModalsList.ROLES, () => {}, {
      companyName,
      roles
    });
  };

  const getRolePinned = (roles: RoleListData[]) => {
    const pinnedRole = roles.find(role => role.pinned);
    return pinnedRole ? pinnedRole.name : '';
  };
  return (
    <>
      {items?.length > 0 && (
        <Carousel
          paginationType="bullets"
          dynamicBullets
          slidesPerView={isMobile ? 2 : hasSubcategories ? 2 : 3}
          grid={{ rows: isMobile ? 2 : hasSubcategories ? 2 : 3, fill: 'row' }}
          spaceBetween={hasSubcategories && !isMobile ? 20 : 10}
          slidesPerGroup={3}
        >
          {items.map((item, key) =>
            !hasSubcategories ? (
              <CompanyCard
                key={key}
                handleRolesModal={handleRolesModal}
                image={`${process.env.REACT_APP_KGP_URL}/images?type=company&id=${item.id}`}
                name={item.name}
                rolePinned={getRolePinned(item.roles)}
                roles={item.roles}
                id={item.image}
              />
            ) : (
              <SubCategoryCard key={key} id={item.id} name={item.name} companies={item.companies} />
            )
          )}
        </Carousel>
      )}
    </>
  );
};

const LayoutExperience = ({ title, content, highlight, items }: Props) => {
  const companies = useMemo(
    () =>
      items?.map(item => {
        if (item?.companies?.length) {
          return { hasSubcategories: false, items: item.companies };
        }
        return { hasSubcategories: true, items: item.subCategories };
      }),
    [items]
  );

  return (
    <div className="layout-experience">
      <Shape1 />
      <Shape2 />
      <Shape3 />

      <div className="flex-wrapper">
        <div className="left">
          <div>
            <h3>{title}</h3>
            <Expansible classes="body light">
              <Sanitized html={content} />
            </Expansible>
          </div>
          <h5 className="light">{highlight}</h5>
        </div>

        <div className="right">
          <TabBar tabs={items} panels={companies} component={CompaniesCarousel} />
        </div>
      </div>
    </div>
  );
};

export default LayoutExperience;
