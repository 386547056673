import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { AutoComplete, SelectDropdown, Textarea } from 'components';
import { Trash } from 'react-feather';
import { SelectOption, PartnerItem, PartnersListProps } from 'components/dynamicInputs/types';
import { SHARE_PERMISSIONS } from 'constants/permissions';

const ShareDeck = ({ onChange, value }: PartnersListProps) => {
  const [newUsersPermissions, setNewUsersPermissions] = useState(SHARE_PERMISSIONS[1].id);
  const [messageValue, setMessageValue] = useState('');

  // Remove owner from permissions options select
  const parsedOptions = useMemo(() => {
    return SHARE_PERMISSIONS.filter(permission => permission.id !== 'owner');
  }, [SHARE_PERMISSIONS]);

  useEffect(() => {
    const aux = value.newSharedUsers.map((user: PartnerItem) => {
      return { ...user, message: messageValue };
    });

    onChange({ ...value, newSharedUsers: aux });
  }, [messageValue]);

  const handleAddNewUsers = useCallback(
    (users: any[]) => {
      const aux = users.map(mail => {
        return { ...mail, permission: newUsersPermissions, message: messageValue };
      });

      onChange({ ...value, newSharedUsers: aux });
    },
    [value, messageValue]
  );

  const handleSetOnChange = useCallback(
    (val: SelectOption) => {
      onChange({
        ...value,
        newSharedUsers: value.newSharedUsers.map((user: PartnerItem) => {
          return {
            ...user,
            permission: val.id
          };
        })
      });

      setNewUsersPermissions(val.id);
    },
    [value]
  );

  const handleMessage = useCallback((val: string) => {
    setMessageValue(val);
  }, []);

  const handleChangeUserPermission = (newPermission: SelectOption, userId: string | number) => {
    onChange({
      sharedUsers: value.sharedUsers.map((user: PartnerItem) => {
        if (user.id === userId) {
          return {
            ...user,
            permission: newPermission.id
          };
        }

        return user;
      }),
      newSharedUsers: [],
      saveActive: true
    });
  };

  const handleDeleteUserAccess = (actionType: string, userId: string) => {
    if (actionType === 'delete') {
      onChange({
        ...value,
        sharedUsers: value.sharedUsers.filter((user: PartnerItem) => user.id !== userId),
        saveActive: true
      });
    }
  };

  return (
    <section className="share-deck-container">
      <header className="share-deck-header">
        <div className="share-deck-left">
          <AutoComplete
            name="newemail"
            label="KGP Firm Member Name"
            value={value.newSharedUsers}
            onChange={handleAddNewUsers}
            endpoint="/partners/search"
            placeholder=""
            isMulti={true}
          />
        </div>
        <div className="share-deck-right">
          <SelectDropdown
            value={newUsersPermissions}
            onChange={handleSetOnChange}
            options={parsedOptions}
            portalTarget=".modal-base.init"
          />
        </div>
      </header>
      <section className="share-deck-body">
        {value.newSharedUsers.length > 0 ? (
          <Textarea
            value={messageValue}
            name="message"
            label="Message for partner (optional)"
            placeholder="Message"
            onChange={handleMessage}
          />
        ) : (
          <div className="share-deck-user">
            {value?.sharedUsers?.length > 0 &&
              value?.sharedUsers?.map((user: PartnerItem, index: number) => (
                <div key={`shared-user-${index}`} className="share-deck-user-wrapper">
                  <div className="details">
                    <p className="body strong">{user.name}</p>
                    <p className="body-2">{user.email}</p>
                  </div>
                  <div className="actions">
                    {user.permission === 'owner' || user.permission === 'Lead Partner' ? (
                      <p className="body-2 shared-user-owner">
                        {user.permission === 'owner' ? 'Owner' : 'Lead Partner'}
                      </p>
                    ) : (
                      <SelectDropdown
                        value={user.permission}
                        onChange={permission => handleChangeUserPermission(permission, user.id)}
                        options={parsedOptions}
                        actionOptions={[{ icon: <Trash />, name: 'Delete access', id: 'delete' }]}
                        onClickActionOption={action => handleDeleteUserAccess(action, user.id)}
                        portalTarget=".modal-base.init"
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>
        )}
      </section>
    </section>
  );
};

export default ShareDeck;
