import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import Image from '../../shared/image';

type Props = {
  url: string;
  active?: boolean;
  rest?: HTMLAttributes<HTMLDivElement>;
};

const fallbackUrl = `${process.env.REACT_APP_FALLBACK_AVATAR}`;

const Avatar = ({ url, active = false, ...rest }: Props) => {
  return (
    <div className={classNames('avatar', { active })} {...rest}>
      <div>
        <Image url={url} fallback={fallbackUrl} />
        <div className="lines">
          <div />
          <div />
        </div>
      </div>
    </div>
  );
};

export default Avatar;
