import React from 'react';
import { Button, Input } from 'components';
import { TextInputTypes } from '../types';
import { ModalsList } from 'constants/modals';
import { useModal } from 'hooks/useModal';

type Props = {
  item: any;
  handleChange: (value: string, name: string, id: string) => void;
};

const Dropdown = ({ item, handleChange }: Props) => {
  const modal = useModal();

  const previewCandidateModal = () => {
    modal.open(ModalsList.PREVIEW_CANDIDATE, () => {}, { item: item });
  };
  return (
    <div className="dropdown">
      <Input
        label="Title"
        name="position"
        type={TextInputTypes.TEXT}
        value={item.title}
        limit={34}
        onChange={(value, name) => handleChange(value, name!, item.id)}
      />
      <Input
        label="Company"
        name="company"
        type={TextInputTypes.TEXT}
        value={item.company}
        limit={105}
        onChange={(value, name) => handleChange(value, name!, item.id)}
      />
      <Input
        label="Location"
        name="address"
        type={TextInputTypes.TEXT}
        value={item.address}
        limit={34}
        onChange={(value, name) => handleChange(value, name!, item.id)}
      />
      <Input
        label="Linkedin"
        name="linkedin"
        type={TextInputTypes.TEXT}
        value={item.linkedin}
        onChange={(value, name) => handleChange(value, name!, item.id)}
      />
      <Button text="preview candidate's info" small variant="secondary" item="Hello" onClick={previewCandidateModal} />
    </div>
  );
};

export default Dropdown;
