import { lazy } from 'react';

// replace with '../../components/common/empty' in the partner side

export default {
  // Tooltip
  Tooltip: lazy(() => import('../../components/common/empty')),
  // Modals
  CandidateBadFeedback: lazy(() => import('../../components/common/empty')),
  CandidatePositiveFeedback: lazy(() => import('../../components/common/empty')),
  CandidateProfile: lazy(() => import('../../components/common/empty')),
  Roles: lazy(() => import('../../components/common/empty')),
  FitProcess: lazy(() => import('../../components/common/empty')),
  PartnerProfile: lazy(() => import('../../components/common/empty')),
  TeamMembers: lazy(() => import('../../components/common/empty')),
  ShowMore: lazy(() => import('../../components/common/empty')),
  SubCategory: lazy(() => import('../../components/common/empty')),
  VideoModal: lazy(() => import('../../components/common/empty')),
  // Others
  AvatarPagination: lazy(() => import('../../components/common/empty'))
};
