import Brand1 from 'assets/images/themes/brand-1.svg';
import Brand2 from 'assets/images/themes/brand-2.svg';
import Brand3 from 'assets/images/themes/brand-3.svg';

export enum Theme {
  BRAND_1 = 'BRAND_1',
  BRAND_2 = 'BRAND_2',
  BRAND_3 = 'BRAND_3'
}

export const Themes = {
  [Theme.BRAND_1]: {
    theme: Theme.BRAND_1,
    previewImage: Brand1,
    title: 'Theme 1'
  },
  [Theme.BRAND_2]: {
    theme: Theme.BRAND_2,
    previewImage: Brand2,
    title: 'Theme 2'
  },
  [Theme.BRAND_3]: {
    theme: Theme.BRAND_3,
    previewImage: Brand3,
    title: 'Theme 3'
  }
};
