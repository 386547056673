import React from 'react';
import { Edit2 } from 'react-feather';
import { ModalsList } from 'constants/modals';
import { useModal } from 'hooks/useModal';
import { Button } from 'components';
import partnerButton from 'assets/images/layouts/inputs/image-partner.svg';
import { useParams } from 'react-router-dom';
import { ImagePickerProps } from '../types';

const aspectRatio = '123/149';
const shape = 'rect';

const ImagePicker = ({ onChange }: ImagePickerProps) => {
  const modal = useModal();
  const params = useParams();

  const handleGalleryModal = () => {
    modal.open(
      ModalsList.GALLERY,
      (data: any) => {
        if (data) {
          onChange(data.url);
        }
      },
      { deckid: params.id, shape, aspectRatio }
    );
  };

  return (
    <div className="input-wrapper">
      <label>Edit image</label>
      <button onClick={handleGalleryModal}>
        <img src={partnerButton} />
      </button>

      <Button text="Change image" variant="alternative" transparent icon={<Edit2 />} onClick={handleGalleryModal} />
    </div>
  );
};

export default ImagePicker;
