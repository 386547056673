import React from 'react';
import DynamicForm from 'components/common/dynamicForm';

const Search = ({ formHandler }: any) => (
  <div className="search-information">
    <div className="row">
      <div className="label"></div>
      <div className="value">
        <DynamicForm handler={formHandler} />
      </div>
    </div>
  </div>
);

export default Search;
