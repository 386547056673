/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-key */
import { SubCategory } from '../../layouts/experience';

const SubCategoryCard = ({ name, companies }: SubCategory) => {
  return (
    <div className="sub-category-card">
      <div className="company-name-new">{name}</div>
      {companies.map(company => (
        <div className="row">
          <div className="company-left">{company.name}</div>
          <div className="company-right">
            {company.roles.map((role, index) => (
              <>
                {role.name} {role.count > 1 ? `(${role.count})` : ''}
                {index != company.roles.length - 1 ? ', ' : ''}
              </>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SubCategoryCard;
