import { useState } from 'react';
import { encode } from 'base-64';

export enum Methods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

type Config = {
  endpoint: string;
  method?: Methods;
};

function objToQueryString(obj: any) {
  const keyValuePairs = [];

  for (const key in obj) {
    keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
  }

  return keyValuePairs.join('&');
}

export default function useHellosign() {
  const [response, setResponse] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  async function fetchData(config: Config, data?: any) {
    const headers: Headers = new Headers({
      Authorization: `Basic ${encode(`${process.env.REACT_APP_HELLOSIGN_KEY}:`)}`,
      'Content-Type': 'application/json'
    });
    const method = config.method || Methods.GET;
    let url = `${process.env.REACT_APP_HELLOSIGN_URL}${config.endpoint}`;

    setIsLoading(true);

    if (data && method === Methods.GET) {
      url += `?${objToQueryString(data)}`;
    }

    const r = await fetch(url, {
      method,
      headers,
      body: data && method !== Methods.GET ? JSON.stringify(data) : undefined
    });

    setResponse(await r.json());
    setIsLoading(false);
  }

  return { response, isLoading, fetchData };
}
