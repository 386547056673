import React, { Dispatch, SetStateAction } from 'react';

export enum ComponentList {
  TEXT = 'TEXT',
  LIST = 'LIST',
  SEARCH_ID = 'SEARCH_ID',
  DECK_STATUS = 'DECK_STATUS',
  TAGS = 'TAGS',
  DATE = 'DATE',
  HTMLElement = 'HTMLElement'
}

export enum FilterTypes {
  DATE = 'DATE',
  SELECT = 'SELECT',
  MULTI = 'MULTI'
}

export type SelectOptionColumn = {
  label: string;
  key: string;
};

export type TableColumn = {
  id: string;
  header?: string;
  width?: string;
  type: ComponentList;
  mask?: any;
  sortable?: boolean;
  filter?: {
    type: FilterTypes;
    key?: string;
    endpoint?: string;
    options?: string;
  };
  componentProps?: any;
};

export type MenuButton = {
  text: string;
  icon?: React.ReactElement;
  onClick: (id?: string | number, params?: any) => void;
};

export type Sort = {
  column: string;
  order: 'asc' | 'desc';
};

export type Config = {
  sort: Sort;
  limit: number;
  page: number;
  filtersSelected: any;
};

export type TableOptions = {
  columns: TableColumn[];
  data?: any[];
  endpoint?: string;
  itemsPerPage?: number;
  menuButtons?: MenuButton[];
  searchable?: boolean;
  canReload?: boolean;
  sort?: Sort;
  hideFooter?: boolean;
  selectAll?: boolean;
  selectOptionColumn?: SelectOptionColumn[];
};

export type Props = {
  options: TableOptions;
  alternative?: boolean;
  selected?: {
    value: any[];
    change: Dispatch<SetStateAction<any>>;
  };
  extraFilters?: { column: string; value: any }[];
  queryParams?: any;
  onColumnChanged?: (columns: any) => void;
  workbench?: any;
  searchs?: string;
  setSearchs?: (columns: any) => void;
};
