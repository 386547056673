import { remark } from 'remark';
import rehypeParse from 'rehype-parse';
import rehypeRemark from 'rehype-remark';
import remarkStringify from 'remark-stringify';
// import remarkHtml from 'remark-html';

export const objectMap = (obj: Object, fn: Function) => {
  const arr = Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]);

  return arr.reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1] }), {}); // Object.fromEntries
};

export const reorderList = <T>(list: T[], startIndex: number, endIndex: number) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

export const addToList = <T>(list: T[], index: number, element: T) => {
  const result = [...list];

  result.splice(index, 0, element);

  return result;
};

export const removeFromList = <T>(list: T[], index: number): [T, T[]] => {
  const result = [...list];
  const [removed] = result.splice(index, 1);

  return [removed, result];
};

export const arrayToObject = (array: any[]) => {
  const object = array.reduce((acc, curr, i) => {
    return { ...acc, [i]: { ...curr } };
  }, {});

  return object;
};

export const insert = <T>(array: T[], index: number, element: T) => [
  ...array.slice(0, index),
  element,
  ...array.slice(index)
];

export const objectToArray = (object: Object) => {
  const array = Object.entries(object).map(([k, v]) => ({ ...v, id: k }));

  return array;
};

// export const markdownToHtml = (markdownText: string) => {
//   const file = remark().use(remarkHtml).processSync(markdownText);
//   return String(file);
// };

export const htmlToMarkdown = (htmlText: string) => {
  const file = remark()
    .use(rehypeParse, { emitParseErrors: true, duplicateAttribute: false })
    .use(rehypeRemark)
    .use(remarkStringify)
    .processSync(htmlText);

  return String(file);
};

export const getDurationInMinutes = (time: number = 0) => {
  let durationMinutes = Math.floor(time / 60);
  let rawDurationSeconds = Math.floor(time % 60);

  if (isNaN(rawDurationSeconds)) {
    rawDurationSeconds = 0;
  }

  if (!isFinite(durationMinutes)) {
    durationMinutes = 0;
  }

  const durationSeconds = `0${rawDurationSeconds}`.slice(-2);
  return `${durationMinutes}:${durationSeconds}`;
};

export const isArray = (value: any): boolean => {
  return Array.isArray(value);
};

export const removeDuplicates = (arr: any[]) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};
