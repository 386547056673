// import { boolean } from 'joi';
import React from 'react';
import Base from '../base';
import { Props } from '../types';

type CompanyType = {
  id: string;
  name: string;
};
type BoardHistory = {
  id: string;
  contact_id: string;
  company_id: string;
  title: string;
  start_date: string;
  end_date: string;
  committee: string;
  position: string;
  company: CompanyType;
};

type JobHistory = {
  id: string;
  contact_id: string;
  company_id: string;
  title: string;
  start_year: string;
  end_year: string;
  position: string;
  summary: string;
  company: CompanyType;
};
type EducationDetails = {
  id: string;
  contact_id: string;
  school_name: string;
  degree_name: string;
  major: string;
  degree_year: string;
  position: string;
};

type EducationContentProps = {
  education: EducationDetails[];
  rationale: string;
};

type HistoryContenProps = {
  workHistory: JobHistory[];
  boardHistory: BoardHistory[];
};

// msg: boolean;
const htmlParse = require('html-react-parser');
// eslint-disable-next-line no-debugger
const HistoryContent = ({ workHistory, boardHistory }: HistoryContenProps) => (
  <div className="history-content">
    {
      <div>
        {workHistory.find(item => item?.title !== null && item?.title !== '') && (
          <>
            <h6>Work history</h6>
            <div className="divider" />

            <div className="options">
              {workHistory &&
                workHistory.map((data, index) =>
                  data !== null ? (
                    <div key={index}>
                      <div className="work">
                        <span>{data?.title}</span>
                        <small>
                          {data.start_year && data.end_year
                            ? `${data.start_year} to ${data.end_year}`
                            : data.start_year
                            ? data.start_year
                            : data.end_year}
                        </small>
                      </div>
                      <div>
                        <small>{data?.company?.name}</small>
                      </div>
                    </div>
                  ) : null
                )}
            </div>
          </>
        )}
      </div>
    }
    {
      <div>
        {boardHistory.find(item => item?.title !== null && item?.title !== '') && (
          <>
            <h6>Board history</h6>
            <div className="divider" />
            <div className="options">
              {boardHistory &&
                boardHistory.map((data, index) =>
                  data !== null ? (
                    <div key={index}>
                      <div className="work">
                        <span>{data?.title}</span>

                        <small>
                          {data.start_date && data.end_date
                            ? `${data.start_date} to ${data.end_date}`
                            : data.start_date
                            ? data.start_date
                            : data.end_date}
                        </small>
                      </div>
                      <div>
                        <small>{data?.company?.name}</small>
                      </div>
                    </div>
                  ) : null
                )}
            </div>
          </>
        )}
      </div>
    }
  </div>
);

const EducationContent = ({ education, rationale }: EducationContentProps) => (
  <div className="education-content">
    {
      <div>
        {education.find(item => item?.school_name !== null && item?.school_name !== '') && (
          <>
            <h6>Education</h6>
            <div className="divider" />
            <div className="options">
              {education &&
                education.map((data, index) => (
                  <div key={index}>
                    <div className="work">
                      <span>{data?.school_name}</span>
                      <small>{data?.degree_name}</small>
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    }
    {
      <div>
        {rationale !== '<p><br /></p>' && rationale !== null && rationale !== '' && (
          <>
            <h6>Rationale</h6>
            <div className="divider" />
            <div className="options">
              <span>{rationale ? htmlParse(rationale) : ''}</span>
            </div>
          </>
        )}
      </div>
    }
  </div>
);

const PreviewCandidate = ({ closing, onClose, params }: Props) => {
  console.log(params.item);

  const htmlParse = require('html-react-parser');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let bio: any = params?.item.published_bio !== null ? htmlParse(params?.item.published_bio) : '';
  const getValiationMessage = () => {
    const IsmptyBoardHistory = params.item.board_details.find((ele: any) => ele?.title);
    const IsmptyEmpHistory = params?.item?.job_history.find((ele: any) => ele?.title);
    const IsmptyEducation = params.item.education_details.find((ele: any) => ele?.school_name);
    const IsmptyRationale = params?.item?.rationale;
    let bio_valid: boolean = false;
    if (
      params.item.published_bio == '<p><br /></p>' ||
      params.item.published_bio == null ||
      params.item.published_bio == ''
    ) {
      bio_valid = true;
    }
    if (!IsmptyBoardHistory && !IsmptyEmpHistory && !IsmptyEducation && !IsmptyRationale && bio_valid) {
      return 'No details available';
    } else return null;
  };
  return (
    <Base header={`${params.item.name}`} closing={closing} onClose={onClose} size="lg">
      <div className="modal-preview-candidate">
        <div style={{ textAlign: 'center' }}>{getValiationMessage()}</div>
        <div className="content">
          {params.item.published_bio !== '<p><br /></p>' &&
            params.item.published_bio !== null &&
            params.item.published_bio !== '' && (
              <div className="hero">
                <h6>Bio</h6>
                <div className="divider" />
                <div className="options">{bio}</div>
              </div>
            )}

          <div className="body">
            <HistoryContent workHistory={params.item.job_history} boardHistory={params.item.board_details} />
            <EducationContent education={params.item.education_details} rationale={params.item.rationale} />
          </div>
        </div>
      </div>
    </Base>
  );
};

export default PreviewCandidate;
