import React, { useEffect, useState, useMemo } from 'react';
import { getDurationInMinutes } from 'helpers/data';

let interval: ReturnType<typeof setInterval>;

type Props = {
  currentTime?: number;
  durationTime?: number;
  maxDurationTime?: number;
};

const RecordingTimer = ({ currentTime, durationTime, maxDurationTime }: Props) => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (!currentTime && !durationTime) {
      interval = setInterval(() => {
        setTimer(prev => prev + 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [currentTime, durationTime]);

  useEffect(() => {
    if (maxDurationTime && timer === maxDurationTime) {
      clearInterval(interval);
    }
  }, [timer]);

  const getCurrentTime = useMemo(() => getDurationInMinutes(currentTime ?? timer), [currentTime ?? timer]);

  const getDurationTime = useMemo(
    () => getDurationInMinutes(durationTime ?? maxDurationTime),
    [durationTime ?? maxDurationTime]
  );

  const getProgressValue = () => {
    if (currentTime && durationTime) {
      return (currentTime / durationTime) * 100;
    } else if (maxDurationTime) {
      return (timer / maxDurationTime) * 100;
    }

    return 0;
  };

  return (
    <div>
      <p className="video-time strong">
        {getCurrentTime} / {getDurationTime}
      </p>
      <div className="video-progressbar-container">
        <div style={{ width: `${getProgressValue()}%` }} className="video-progressbar"></div>
      </div>
    </div>
  );
};

export default RecordingTimer;
