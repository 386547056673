import joi from 'joi';
import { InputsEnum, TextInputTypes } from 'components/dynamicInputs/types';

export default {
  name: {
    value: '',
    label: "Edit slide's name",
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required()
  }
};
