import React, { ChangeEvent } from 'react';

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  label?: string;
  checked?: boolean;
};

const Checkbox = ({ onChange, label, value, checked = false }: Props) => {
  return (
    <div className="checkbox-wrapper">
      <input className="checkbox" onChange={onChange} type="checkbox" value={value} checked={checked} />
      {!!label && <label className="body-2 strong">{label}</label>}
    </div>
  );
};

export default Checkbox;
