import React from 'react';
import { TextareaProps } from '../types';

const Textarea = ({ label, value, name, onChange, onBlur }: TextareaProps) => (
  <div className="text-area-contain input-wrapper">
    <textarea
      className="body"
      name={name}
      value={value}
      onChange={({ target }) => onChange(target.value, target.name)}
      onBlur={onBlur}
    />
    <label className="label">
      <div className="text">{label}</div>
    </label>
  </div>
);

export default React.memo(Textarea);
