import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

type TabsType = {
  id: string;
  label: string;
};

type Props<T> = {
  tabs: TabsType[];
  panels: T[];
  component: React.ComponentType<any>;
  forceRenderTabPanel?: boolean;
  panelHeight?: number;
};

const TabBar = <T extends object>({
  tabs,
  panels,
  forceRenderTabPanel,
  component: Component,
  panelHeight
}: Props<T>) => (
  <Tabs forceRenderTabPanel={forceRenderTabPanel}>
    <TabList>
      {tabs?.length > 0
        ? tabs?.map(({ id, label }, index) => (
            <Tab key={index} data-event="click" data-action="change-tab" data-tab-selected={id}>
              {label}
            </Tab>
          ))
        : null}
    </TabList>
    {panels?.map((item, index) => (
      <TabPanel
        key={index}
        style={{
          height: panelHeight
        }}
      >
        <Component {...item} />
      </TabPanel>
    ))}
  </Tabs>
);

export default TabBar;
