import React from 'react';
import useFormHandler from 'hooks/useFormHandler';
import { Button, DynamicForm } from 'components';
import schema from 'schemas/modals/deckTheme';
import Base from '../base';
import { Props } from '../types';

const DeckTheme = ({ closing, onClose }: Props) => {
  const formHandler = useFormHandler(schema);

  return (
    <Base
      onClose={onClose}
      closing={closing}
      size="lg"
      header="Change Deck's theme"
      footer={<Button text="Change" disabled={!formHandler.valid} onClick={() => onClose(formHandler.data)} />}
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default DeckTheme;
