import {
  AddRoles,
  CandidatePositiveFeedback,
  CandidateNegativeFeedback,
  ChooseLayout,
  ConfirmLeaveDeck,
  DeckAnalytics,
  DeckDuplicate,
  DeckInformation,
  DialogConfirm,
  Experience,
  ImportFromDocument,
  Form,
  Gallery,
  ImportCandidates,
  ImportCompanies,
  ImportTeamMembers,
  SelectTheme,
  SendToClient,
  ShareDeck,
  DeckTheme,
  Section,
  Slide,
  PreviewCandidate,
  VideoRecorder,
  PartnersBio,
  CreateDeck,
  ImportWorkbench,
  CreateDeckTemplate
} from 'components';
import SystemSetting from 'components/modals/systemSetting';

export enum ModalsList {
  ADD_ROLES = 'ADD_ROLES',
  CANDIDATE_POSITIVE_FEEDBACK = 'CANDIDATE_POSITIVE_FEEDBACK',
  CANDIDATE_NEGATIVE_FEEDBACK = 'CANDIDATE_NEGATIVE_FEEDBACK',
  CHOOSE_LAYOUT = 'CHOOSE_LAYOUT',
  CONFIRM_LEAVE_DECK = 'CONFIRM_LEAVE_DECK',
  SECTION = 'SECTION',
  SLIDE = 'SLIDE',
  DECK_ANALYTICS = 'DECK_ANALYTICS',
  DECK_DUPLICATE = 'DECK_DUPLICATE',
  DECK_INFORMATION = 'DECK_INFORMATION',
  DIALOG_CONFIRM = 'DIALOG_CONFIRM',
  EXPERIENCE = 'EXPERIENCE',
  IMPORT_FROM_DOCUMENT = 'IMPORT_FROM_DOCUMENT',
  FORM = 'FORM',
  GALLERY = 'GALLERY',
  IMPORT_CANDIDATES = 'IMPORT_CANDIDATES',
  IMPORT_COMPANIES = 'IMPORT_COMPANIES',
  IMPORT_TEAM_MEMBERS = 'IMPORT_TEAM_MEMBERS',
  SELECT_THEME = 'SELECT_THEME',
  SHARE_DECK = 'SHARE_DECK',
  SEND_CLIENT = 'SEND_CLIENT',
  DECK_THEME = 'DECK_THEME',
  PREVIEW_CANDIDATE = 'PREVIEW_CANDIDATE',
  VIDEO_RECORDER = 'VIDEO_RECORDER',
  PARTNERS_BIO = 'PARTNERS_BIO',
  CREATE_DECK = 'CREATE_DECK',
  IMPORT_WORKBENCH = 'IMPORT_WORKBENCH',
  SYSTEM_SETTING = 'SYSTEM_SETTING',
  CREATE_DECK_TEMPLATE = 'CREATE_DECK_TEMPLATE'
}

export default {
  [ModalsList.ADD_ROLES]: AddRoles,
  [ModalsList.CANDIDATE_POSITIVE_FEEDBACK]: CandidatePositiveFeedback,
  [ModalsList.CANDIDATE_NEGATIVE_FEEDBACK]: CandidateNegativeFeedback,
  [ModalsList.CHOOSE_LAYOUT]: ChooseLayout,
  [ModalsList.CONFIRM_LEAVE_DECK]: ConfirmLeaveDeck,
  [ModalsList.SECTION]: Section,
  [ModalsList.SLIDE]: Slide,
  [ModalsList.DECK_ANALYTICS]: DeckAnalytics,
  [ModalsList.DECK_DUPLICATE]: DeckDuplicate,
  [ModalsList.DECK_INFORMATION]: DeckInformation,
  [ModalsList.DIALOG_CONFIRM]: DialogConfirm,
  [ModalsList.EXPERIENCE]: Experience,
  [ModalsList.IMPORT_FROM_DOCUMENT]: ImportFromDocument,
  [ModalsList.FORM]: Form,
  [ModalsList.GALLERY]: Gallery,
  [ModalsList.IMPORT_CANDIDATES]: ImportCandidates,
  [ModalsList.IMPORT_COMPANIES]: ImportCompanies,
  [ModalsList.IMPORT_TEAM_MEMBERS]: ImportTeamMembers,
  [ModalsList.SELECT_THEME]: SelectTheme,
  [ModalsList.SEND_CLIENT]: SendToClient,
  [ModalsList.SHARE_DECK]: ShareDeck,
  [ModalsList.DECK_THEME]: DeckTheme,
  [ModalsList.PREVIEW_CANDIDATE]: PreviewCandidate,
  [ModalsList.VIDEO_RECORDER]: VideoRecorder,
  [ModalsList.PARTNERS_BIO]: PartnersBio,
  [ModalsList.CREATE_DECK]: CreateDeck,
  [ModalsList.IMPORT_WORKBENCH]: ImportWorkbench,
  [ModalsList.SYSTEM_SETTING]: SystemSetting,
  [ModalsList.CREATE_DECK_TEMPLATE]: CreateDeckTemplate
};
