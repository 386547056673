/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import { Trans } from 'components/common/trans';
import { RoleListData } from '../../layouts/experience';
import axios from 'axios';

type Props = {
  image: string;
  name: string;
  rolePinned: string;
  roles: RoleListData[];
  id: string;
  handleRolesModal: (companyName: string, roles: RoleListData[]) => void;
};

const CompanyCard = ({ id, name, image, rolePinned, roles, handleRolesModal }: Props) => {
  const [img, setImg] = useState(false);
  const [count, setCount] = useState(0);
  const handleClick = () => {
    roles.length > 0 && handleRolesModal(name, roles);
  };
  useEffect(() => {
    profilePicData();
  }, []);
  useEffect(() => {
    let temp = 0;
    for (const role of roles) {
      temp = temp + role.count;
      setCount(temp);
    }
  });
  const profilePicData = async () => {
    const result = await axios.get(image);
    try {
      if (result.status === 200) {
        return setImg(true);
      }
    } catch (error) {
      console.log('error', id);
    }
  };
  return (
    <div className="company-card">
      <div
        className="card"
        onClick={handleClick}
        title={name}
        data-event="click"
        data-disabled={roles.length === 0}
        data-action="partner-card-detail"
      >
        <div className="header">
          {img ? <img src={image} /> : id ? <img src={id} /> : <div className="name">{name}</div>}
        </div>
        <div className="footer">
          <div className="ellipsis">
            <small>{rolePinned}</small>
          </div>
          <div className="learn-more">
            <small className="strong">
              {roles.length > 0 ? (
                <>
                  {`+${count} `}
                  <Trans>roles filled</Trans>
                </>
              ) : (
                ' '
              )}
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyCard;
