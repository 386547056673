import React, { FC, useCallback, useRef, useState } from 'react';
import { Card, Button, Spinner } from '../common';
import { normalizeFilename } from '../../utils';
import { ChevronLeft } from 'react-feather';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

type Props = {
  deckid: string;
  uploadImage: (file: any, type: number) => Promise<void>;
  uploadImageLoading: boolean;
  url: string;
  name: string;
  setIsEdition: React.Dispatch<boolean>;
};

const CropImage: FC<Props> = ({ deckid, url, name, setIsEdition, uploadImage, uploadImageLoading }) => {
  const imgRef = useRef(null);
  const [crop, setCrop] = useState<Crop>({ unit: '%', x: 0, y: 0, width: 50, height: 50 });

  const onLoad = useCallback((img: any) => {
    console.log('onLoad', img.naturalWidth, img.width, img.naturalHeight, img.height);
    imgRef.current = img;
  }, []);

  const onCrop = async () => {
    if (imgRef.current && crop.width && crop.height) {
      const filename = normalizeFilename(deckid, name);
      const croppedImage = await createCropPreview(imgRef.current, crop, filename);

      await uploadImage(croppedImage, 1);
      setIsEdition(false);
    }
  };

  const createCropPreview = async (image: any, crop: any, fileName: any) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise(resolve => {
      canvas.toBlob(file => {
        const myFile = new File([file!], `${fileName}`);

        resolve(myFile);
      });
    });
  };

  return (
    <Card
      title="Edit the image"
      buttons={
        <div className="crop-image-footer">
          <Button text="back" secondary icon={<ChevronLeft />} onClick={() => setIsEdition(false)} />
          <Button
            text="save"
            icon={uploadImageLoading && <Spinner />}
            disabled={uploadImageLoading}
            primary
            onClick={onCrop}
          />
        </div>
      }
    >
      <>
        <div className="crop-image">
          <div className="cropper-container">
            {url && (
              <ReactCrop
                src={url}
                onImageLoaded={onLoad}
                crop={crop}
                crossorigin={'anonymous'}
                onChange={c => setCrop(c)}
              />
            )}
          </div>
        </div>
      </>
    </Card>
  );
};

export default CropImage;
