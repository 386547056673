import React from 'react';

const DragIcon = () => {
  return (
    <svg width="15" height="10" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 5.36947C0.63181 5.36947 0.333333 5.66794 0.333333 6.03613C0.333333 6.40432 0.63181 6.7028 1 6.7028V5.36947ZM9 6.7028C9.36819 6.7028 9.66667 6.40432 9.66667 6.03613C9.66667 5.66794 9.36819 5.36947 9 5.36947V6.7028ZM1 6.7028H9V5.36947H1V6.7028Z"
        fill="#4F4CC6"
      />
      <path
        d="M1 0.544271C0.63181 0.544271 0.333333 0.842748 0.333333 1.21094C0.333333 1.57913 0.63181 1.8776 1 1.8776V0.544271ZM9 1.8776C9.36819 1.8776 9.66667 1.57913 9.66667 1.21094C9.66667 0.842748 9.36819 0.544271 9 0.544271V1.8776ZM1 1.8776H9V0.544271H1V1.8776Z"
        fill="#4F4CC6"
      />
    </svg>
  );
};

export default DragIcon;
