export const IMPORT_FROM_WORKBENCH = 'Import from WorkBench';
export const MY_WORKBENCH = 'My Workbench';
export const KGP_WORKBENCH = 'KGP Workbench';
export const MY_WORKBENCH_ENDPOINT = '/workbenches/types?q=Searches';
export const KGP_WORKBENCH_ENDPOINT = '/workbenches/kgpworkbenches';
export const NO_DATA_FOUND = 'No data found';
export const SERVER_ERROR = 'server error';
export const SELECT_WORKBENCH = 'Select workbench';
export const SELECT_WORKBENCH_TEXT = 'Search workbench..';
export const IMPORT_TEXT = 'Import';
export const LOADING = '...loading';
export const IMPORT_SELECTED = 'Import selected';
export const WORKBENCH = 'workbench';

//Table columns
export const TABLE_NAME = 'Name';
export const TABLE_ACTIVE_DATE = 'Active Date';
export const TABLE_OPTION_DESCRIPTION = 'Description';
export const TABLE_OPTION_CREATED_DATE = 'Created Date';
