import joi from 'joi';
import { InputsEnum } from 'components/dynamicInputs/types';

export default {
  bio: {
    value: '',
    label: 'Bio',
    input: InputsEnum.TEXTAREA,
    joi: joi.string().required()
  }
};
