import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { File, Check, Download, Edit2, Plus, Mail } from 'react-feather';
import useLocalStorage, { writeStorage } from '@rehooks/local-storage';
import { FilePickerProps } from '../types';
import { ModalsList } from 'constants/modals';
import { useModal } from 'hooks/useModal';
import useHellosign, { Methods } from 'hooks/useHellosign';
import useApi from 'hooks/useApi';
import { Button } from 'components';

const FilePicker = ({ onChange, value }: FilePickerProps) => {
  const inputRef = useRef<HTMLInputElement>({} as HTMLInputElement);
  const [user] = useLocalStorage('user', { id: 0 });
  const [signers, setSigners] = useState<any>();
  const { id: deckId } = useParams();
  const createUrl = useApi();
  const getFile = useHellosign();
  const getSignedFile = useHellosign();
  const getApi = useApi();
  const modal = useModal();

  const fileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    event.target.value = '';
    onChange({ name: fileObj.name, file: fileObj, isSigned: false });
    createUrl.fetchData(
      { endpoint: '/decks/docs/upload_url', method: Methods.POST },
      { deckid: deckId, fileName: fileObj.name.replace('.pdf', ''), extension: 'pdf' }
    );
  };

  const downloadFile = () => {
    window.open(getSignedFile.response.file_url, '_blank');
  };

  useEffect(() => {
    getFile.fetchData({
      endpoint: `signature_request/list?query=metadata:${deckId}`,
      method: Methods.GET
    });
  }, []);

  useEffect(() => {
    if (window.partner) {
      if (!value?.file?.lastModified && getFile.response && getFile.response.signature_requests.length > 0) {
        onChange({
          ...value,
          isSigned: getFile.response.signature_requests[0].is_complete
        });
      }
    }
  }, [getFile.response]);

  useEffect(() => {
    if (createUrl.response) {
      // saveFile.fetchData({ endpoint: createUrl.response?.urlUpload, method: Methods.PUT }, file, true);
      //TODO - saveFile.fetchData is not working with the endpoint and method above - need to fix
      fetch(createUrl.response?.urlUpload, {
        method: Methods.PUT,
        body: value?.file
      });
      writeStorage(`urlPdf-${deckId}`, createUrl.response?.urlDocument);
      onChange({ ...value, url: createUrl.response?.urlDocument });
    }
  }, [createUrl.response]);

  useEffect(() => {
    if (getFile.response?.signature_requests?.length > 0) {
      getSignedFile.fetchData({
        endpoint: `signature_request/files/${getFile?.response?.signature_requests[0]?.signature_request_id}?file_type=pdf&get_url=true`,
        method: Methods.GET
      });
    }
  }, [getFile.response]);

  useEffect(() => {
    getApi.fetchData({
      endpoint: `/decks/getLOESlide/${deckId}`
    });
  }, []);

  useEffect(() => {
    if (getApi.response) {
      if (getApi.response?.loe_slide?.raw?.loe?.signer) {
        console.log('Singer :', getApi.response?.loe_slide?.raw?.loe?.signer);
        setSigners(getApi.response?.loe_slide?.raw?.loe?.signer);
      }
    }
  }, [getApi.response]);

  const UploadFile = ({ isEdit = false }) => {
    return (
      <div>
        <input style={{ display: 'none' }} ref={inputRef} type="file" onChange={fileChange} accept="application/pdf" />
        <Button
          text={isEdit ? 'Upload Document' : 'Import letter of engagement'}
          variant={isEdit ? 'alternative' : 'primary'}
          small
          icon={isEdit && <Edit2 />}
          onClick={() => {
            inputRef.current.click();
          }}
        />
      </div>
    );
  };

  const confirmModal = () => {
    modal.open(
      ModalsList.SEND_CLIENT,
      (data: any) => {
        if (data) {
          setSigners(data.sendToClient);
          console.log('data from signer', data);
          onChange({
            ...value,
            signer: [
              { id: data.sendToClient[0].id, name: data.sendToClient[0].name, email: data.sendToClient[0].email }
            ]
          });
        }
      },
      {
        deck_id: deckId,
        user_id: user.id,
        isEdit: true
      }
    );
  };

  return (
    <div className="file-picker-wrapper input-wrapper">
      <label>LOE Properties</label>
      {!value?.file ? (
        <UploadFile />
      ) : (
        <>
          <div className="file-selected">
            <div className="file-wrapper">
              <div className="file-icon">
                <File />
              </div>
              <small>{value?.name}</small>
            </div>
            {value?.isSigned && !value?.lastModified && (
              <div className="signed">
                <Check />
                <small>Signed</small>
              </div>
            )}
          </div>
          <div>
            {value?.isSigned && !value?.lastModified ? (
              <div className="file-button">
                <UploadFile isEdit />
                <Button text="Download" variant="primary" small icon={<Download />} onClick={downloadFile} />
              </div>
            ) : (
              <>
                <div className="file-button">
                  <UploadFile isEdit />
                </div>
                <div className="addSigners">
                  {signers ? (
                    <div>
                      <div className="input-signer">
                        <div className="actions">
                          <Mail />
                        </div>
                        {signers[0]?.name === undefined ? (
                          <input name="name" value={signers?.name} disabled />
                        ) : (
                          <input name="name" value={signers[0]?.name} disabled />
                        )}
                      </div>
                      <Button
                        text="Edit signer"
                        small
                        variant="alternative"
                        icon={<Edit2 />}
                        onClick={() => confirmModal()}
                      />
                    </div>
                  ) : (
                    <>
                      <label>LOE Signer Email</label>
                      <Button
                        text="add signer"
                        small
                        variant="secondary"
                        icon={<Plus />}
                        onClick={() => confirmModal()}
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default FilePicker;
