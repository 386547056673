import React, { useEffect, useState } from 'react';
import { Button } from 'components';
import { RolesListProps } from '../types';
import useApi from 'hooks/useApi';
import Select from 'react-select';
import { useToast } from 'hooks/useToast';
import bodyTextSchema from 'schemas/modals/bodyText';
import useFormHandler from 'hooks/useFormHandler';
import { Spinner } from 'galleryCrop/components/common';

const SendToClientForm = ({ value, onChange }: RolesListProps) => {
  const clientContactFetchApi = useApi();
  const getBrandingThemeApi = useApi();
  const getLOEStausApi = useApi();
  const [options, setOptions] = useState<any>();
  const [headerbarColor, setHeaderbarColor] = useState<string>('');
  const [primaryButtonColor, setPrimaryButtonColor] = useState<string>('');
  const [titleTextColor, setTitleTextColor] = useState<string>('');
  const [bodyTextColor, setBodyTextColor] = useState<string>('');
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [signer, setSigner] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const bodyTextFormHandler = useFormHandler(bodyTextSchema);
  let href = window.location.href;
  let deckId = href.split('/').slice(-1);
  const notify = useToast();
  console.log('Sent to Client Value : ', value);
  const [isloaded, setIsLoaded] = useState<boolean>(false);
  const [isLOEHide, setIsLOEHide] = useState<boolean>(true);
  // const [isLOESigned, setIsLOESigned] = useState<boolean>(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    clientContactFetchApi.fetchData({ endpoint: `/decks/clientsFromDB/${deckId}` });
    getBrandingThemeApi.fetchData({ endpoint: `/decks/getBrandingTheme/${deckId}` });
    getLOEStausApi.fetchData({ endpoint: `/decks/getLOESlide/${deckId}` });
  }, []);

  useEffect(() => {
    if (getLOEStausApi.response) {
      console.log('getLOEStausApi THeme :', getLOEStausApi.response);

      if (getLOEStausApi.response.isSlideDeleted) {
        setIsLOEHide(true);
      } else {
        setIsLOEHide(getLOEStausApi.response.loe_slide.hide);
        // setIsLOESigned(getLOEStausApi.response.loe_slide.raw.loe.isSigned);
        if (getLOEStausApi.response?.loe_slide?.raw?.loe?.signer) {
          setSigner(getLOEStausApi.response.loe_slide?.raw?.loe?.signer[0].email);
        }
      }
    }
  }, [getLOEStausApi.response]);

  useEffect(() => {
    if (getBrandingThemeApi.response) {
      console.log('Branding THeme :', getBrandingThemeApi.response);
      const brandingTheme = getBrandingThemeApi.response.brandingThemes[0];
      setHeaderbarColor(brandingTheme.header_bar_color);
      setPrimaryButtonColor(brandingTheme.primary_button_color);
      setTitleTextColor(brandingTheme.title_text_color);
      setBodyTextColor(brandingTheme.body_text_color);
      setLogoUrl(brandingTheme.logo);
    }
  }, [getBrandingThemeApi.response]);

  useEffect(() => {
    console.log('Value :', value);
    if (value.length > 0) {
      value[0].headerbarColor = headerbarColor;
      value[0].primaryButtonColor = primaryButtonColor;
      value[0].titleTextColor = titleTextColor;
      value[0].bodyTextColor = bodyTextColor;
      value[0].logoUrl = logoUrl;
      if (count === 0) {
        if (value.length > 1) {
          setIsEdit(value[1].isEdit || false);
        } else {
          setIsEdit(value[0].isEdit || false);
        }
        value.splice(-1);
        setCount(1);
      }
      setIsLoaded(true);
    }
  }, [value.length > 0]);

  useEffect(() => {
    console.log('bodyTextFormHandler.data?.bodyText', bodyTextFormHandler.data?.bodyText);
    if (value.length > 0) {
      value[0].body = bodyTextFormHandler.data?.bodyText;
    }
  }, [bodyTextFormHandler.data?.bodyText]);

  useEffect(() => {
    console.log('bodyTextFormHandler.data?.bodyText', bodyTextFormHandler.data?.bodyText);
    if (value.length > 0) {
      value[0].body = bodyTextFormHandler.data?.bodyText;
    }
  }, [bodyTextFormHandler.data?.bodyText]);

  useEffect(() => {
    console.log(clientContactFetchApi.response);
    let temp = clientContactFetchApi.response?.clients.map((d: any) => ({
      value: { id: d.id, name: d.name, email: d.email },
      label: d.name
    }));
    setOptions(temp);
  }, [clientContactFetchApi.response]);

  const handleChangeNewEmailForTo = (v: any) => {
    console.log('for to');
    if (v.value.email === undefined || v.value.email === null || v.value.email === '') {
      notify('Email is not available for this client.');
    } else {
      if (value.length === 0) {
        onChange([v.value]);
      } else {
        onChange([v.value]);
      }
    }
  };

  const handleChangeNewEmailForCC = (v: any) => {
    console.log('for cc');
    if (v.value.email === undefined || v.value.email === null || v.value.email === '') {
      notify('Email is not available for this client.');
    } else {
      if (value.length === 0) {
        onChange([...value, v.value]);
      } else {
        onChange([...value, v.value]);
      }
    }
  };

  const handleRemoveEmail = (id: string) => {
    onChange(value.filter(item => item.id !== id));
  };

  const renderForm = () => {
    console.log('loaded: ', isloaded);
    if (!isloaded) {
      return (
        <div className="spinner">
          <Spinner solid={true} />
        </div>
      );
    }
    return (
      <div>
        {value.map((item, index) => (
          <div key={`email-${item?.id}`}>
            <div className="send-client-email">
              <p className="body">
                {item?.email} {index === 0 && item?.email === signer && <small>*signer</small>}
              </p>
              {isEdit && <Button text="Remove" variant="alternative" onClick={() => handleRemoveEmail(item.id)} />}
              {!isEdit && item?.email !== signer && (
                <Button text="Remove" variant="alternative" onClick={() => handleRemoveEmail(item.id)} />
              )}
            </div>
          </div>
        ))}
        <small className="body signer">*Only the first client selected will be able to sign</small>
        {value.length === 0 && !isEdit && !isLOEHide && (
          <small className="body signer">*Please add signer from Letter of Engagement slide </small>
        )}
      </div>
    );
  };

  return (
    <div className="send-client-container">
      <div className="send-client-form search-info-form">
        <div className="row">
          <div className="label">
            <div className="send-client-body">{renderForm()}</div>
            <label className="text">Client email</label>
            {isEdit && (
              <Select
                placeholder=""
                onChange={handleChangeNewEmailForTo}
                options={options}
                maxMenuHeight={250}
                isDisabled={!isloaded}
              />
            )}
            {!isEdit && (
              <Select
                placeholder=""
                onChange={handleChangeNewEmailForCC}
                options={options}
                maxMenuHeight={250}
                isDisabled={!isLOEHide && signer === ''}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendToClientForm;
