/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import { RoleListData } from '../../layouts/experience';

type Props = {
  name: string;
  rolePinned: string;
  roles: RoleListData[];
  id: string;
  handleRolesModal: (companyName: string, roles: RoleListData[]) => void;
};

const CompanyCard = ({ name, roles }: Props) => {
  const leng: number = roles.length;
  return (
    <div className="company-card">
      <div className="company-name">{name}</div>
      {roles.map((item, index) => (
        <>
          {index % 2 == 0 && (
            <div className="row">
              <div className="company-col">
                {roles[index].name} {roles[index].count > 1 ? `(${roles[index].count})` : ''}
              </div>
              {index + 1 <= leng - 1 && (
                <div className="company-col">
                  {roles[index + 1].name} {roles[index + 1].count > 1 ? `(${roles[index + 1].count})` : ''}
                </div>
              )}
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default CompanyCard;
