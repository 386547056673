import React from 'react';
import classnames from 'classnames';
import Tooltip from 'react-simple-tooltip';

type Props = {
  children: React.ReactNode;
  value: React.ReactNode;
  fixed?: boolean;
  disabled?: boolean;
};

const Date = ({ children, value, fixed = false, disabled = false }: Props) => (
  <Tooltip
    content={value}
    placement="bottom"
    className={classnames('tooltip', { disabled })}
    fadeDuration={250}
    fixed={fixed}
  >
    {children}
  </Tooltip>
);

export default Date;
