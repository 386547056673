import React, { useEffect, useState } from 'react';
import Textarea from '../textarea';
import { SearchInfoProps, SelectOption } from '../types';
import AutoComplete from '../autoComplete';
import useApi, { Methods } from 'hooks/useApi';
import * as regions from 'constants/regions';

const emptyValue = '';

const SearchInformation = ({ value, onChange }: SearchInfoProps) => {
  const companyApi = useApi();
  const searchDetailsApi = useApi();
  const [companyName, setCompanyName] = useState<string>('');
  const [companyId, setCompanyId] = useState<string>('');
  const [jobItems, setJobItems] = useState([]);
  const [functionItems, setFunctionItems] = useState([]);
  const [industryItems, setindustryItems] = useState([]);
  const [leadPartnersItems, setleadPartnersItems] = useState([]);
  const [missingInfo, setMissingInfo] = useState<boolean>(false);

  const handleCompanyChange = (data: SelectOption) => {
    setCompanyName(data.name);
    setCompanyId(data.id);
    getCompanyById(data.id);
  };

  const regionOptions = [
    {
      id: regions.NORTH_AMERICA,
      name: regions.NORTH_AMERICA
    },
    {
      id: regions.LATIN_AMERICA,
      name: regions.LATIN_AMERICA
    },
    {
      id: regions.EASTERN_EUROPE,
      name: regions.EASTERN_EUROPE
    },
    {
      id: regions.WESTERN_EUROPE,
      name: regions.WESTERN_EUROPE
    },
    {
      id: regions.ASIA,
      name: regions.ASIA
    },
    {
      id: regions.MIDDLE_EAST_AMERICA,
      name: regions.MIDDLE_EAST_AMERICA
    }
  ];

  const handleSearchDetails = (data: SelectOption) => {
    if (data) {
      onChange({
        ...value,
        jobTitle: data.name
      });

      searchCompanyDetails(data.id);
    }
  };

  const handleLeadPartnerChange = (data: SelectOption) => {
    onChange({
      ...value,
      leadPartner: data
    });
  };

  const handleChange = (data: string, field?: string) => {
    onChange({
      ...value,
      [field!]: data
    });
  };

  const getCompanyById = (id: string) => {
    companyApi.fetchData(
      {
        endpoint: '/companies/details',
        method: Methods.POST
      },
      {
        companyId: id
      }
    );
  };

  const searchCompanyDetails = (id: string) => {
    searchDetailsApi.fetchData(
      {
        endpoint: '/companies/search/details',
        method: Methods.POST
      },
      {
        searchId: id
      }
    );
  };

  useEffect(() => {
    if (companyApi.response) {
      const { details } = companyApi.response;

      let filtered_Job_Titles: any = [];
      if (details?.items_jobs) {
        filtered_Job_Titles = details?.items_jobs.filter((title: any) => {
          return title.stage === 'BD';
        });
      }
      setJobItems(filtered_Job_Titles);
      setFunctionItems(details?.function);
      setindustryItems(details?.industry);
      setleadPartnersItems(details?.lead_partners);

      onChange({
        companyName: details?.companyName || companyName,
        companyId: details?.companyId || companyId
      });
    }
  }, [companyApi.response]);

  useEffect(() => {
    if (searchDetailsApi.response) {
      const { details } = searchDetailsApi.response;

      setFunctionItems(details?.function);
      setindustryItems(details?.industry);
      setleadPartnersItems(details?.lead_partners);

      onChange({
        ...value,
        searchGalaxyId: details?.search_galaxy_id || emptyValue,
        country: details?.country || emptyValue,
        function: details?.function?.[0] || emptyValue,
        industry: details?.industry?.[0] || emptyValue,
        region: details?.region || emptyValue,
        leadPartner: details?.lead_partners
      });
    }
  }, [searchDetailsApi.response]);

  useEffect(() => {
    if (companyApi.response || searchDetailsApi.response) {
      if (!value?.jobTitle || leadPartnersItems.length === 0) {
        setMissingInfo(true);
      } else {
        setMissingInfo(false);
      }
    }
  }, [value.jobTitle, leadPartnersItems.length, companyApi.response, searchDetailsApi.response]);

  return (
    <div className="search-info-container">
      {missingInfo && (
        <div className="message">
          <span className="body">
            * There is some missing information. Upload it on Galaxy to complete your SyncLink.
          </span>
        </div>
      )}

      <div className="search-info-form">
        <AutoComplete
          name="companyName"
          label="Company name"
          value={{ name: value?.companyName } || {}}
          onChange={obj => handleCompanyChange(obj)}
          endpoint="/companies/search"
          isMulti={false}
        />
        <AutoComplete
          name="jobTitle"
          label="Project title"
          value={{ name: value?.jobTitle } || {}}
          customOptions={jobItems}
          onChange={obj => handleSearchDetails(obj)}
          isMulti={false}
        />
        <AutoComplete
          name="function"
          label="Function"
          value={{ name: value?.function } || {}}
          customOptions={functionItems?.map(item => ({ id: item, name: item }))}
          onChange={obj => handleChange(obj?.name, 'function')}
          endpoint="/roles/search"
          isMulti={false}
        />
        <AutoComplete
          name="leadPartner"
          label="Lead Partner"
          value={value?.leadPartner}
          customOptions={leadPartnersItems?.map((item: any) => ({
            id: item.id,
            name: `${item.first_name} ${item.last_name}`
          }))}
          onChange={obj => handleLeadPartnerChange(obj)}
          isMulti
          nonRemoveable
        />
        <AutoComplete
          name="industry"
          label="Industry"
          value={{ name: value?.industry } || {}}
          customOptions={industryItems?.map(item => ({ id: item, name: item }))}
          onChange={obj => handleChange(obj?.name, 'industry')}
          endpoint="/industries/search"
          isMulti={false}
        />
        <AutoComplete
          name="region"
          label="Region"
          value={{ name: value?.region } || {}}
          customOptions={regionOptions?.map((item: any) => ({
            id: item?.id,
            name: item?.name
          }))}
          onChange={obj => handleChange(obj?.name, 'region')}
          isMulti={false}
        />
        <AutoComplete
          name="country"
          label="Country"
          value={{ name: value?.country } || {}}
          onChange={obj => handleChange(obj?.name, 'country')}
          endpoint="/countries/search"
          isMulti={false}
        />
        <Textarea label="Job Description" name="jobDescription" value={value?.jobDescription} onChange={handleChange} />
      </div>
    </div>
  );
};

export default SearchInformation;
