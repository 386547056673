import React, { useEffect, useState, ChangeEvent } from 'react';
import get from 'lodash.get';
import classnames from 'classnames';
import { ChevronDown, ChevronLeft, ChevronRight, Filter, MoreVertical, RefreshCw, Trash } from 'react-feather';
import { Menu as RMenu, MenuItem, MenuRadioGroup } from '@szhsin/react-menu';
import useApi, { Methods } from 'hooks/useApi';
import useDebounce from 'hooks/useDebounce';
import { Button, Checkbox, DateField, DeckStatus, DropdownButton, SearchInToolbar } from 'components';
import TableSlideId from './slideId';
import TableTags from './tags';
import { sortBy } from './constants';
import { ComponentList, Config, Props, Sort } from './types';

const RELOAD_DELAY = 5000;
const limits = [10, 20, 50, 100];

//const ColumnsSwitcher = () => <Menu className="column-switcher" />;

const DynamicCell = ({ type, value }: { type: ComponentList; value: any }) => {
  return (
    <React.Fragment>
      {
        {
          [ComponentList.TEXT]: <span>{value}</span>,
          [ComponentList.LIST]: <span>{!Array.isArray(value) ? '-' : value.join(', ')}</span>,
          [ComponentList.DATE]: <DateField value={value} />,
          [ComponentList.DECK_STATUS]: <DeckStatus value={value} />,
          [ComponentList.SEARCH_ID]: <TableSlideId value={value} />,
          [ComponentList.TAGS]: <TableTags value={value} />,
          [ComponentList.HTMLElement]: <Trash />
        }[type]
      }
    </React.Fragment>
  );
};

const Table = ({
  options,
  selected,
  extraFilters,
  onColumnChanged,
  workbench = '',
  queryParams,
  alternative = false,
  searchs = ''
}: Props) => {
  const {
    columns,
    sort,
    endpoint,
    menuButtons,
    searchable,
    data,
    itemsPerPage,
    selectAll,
    //selectOptionColumn = [],
    hideFooter = false
  } = options;
  const [config, setConfig] = useState<Config>({
    sort: sort as Sort,
    limit: itemsPerPage || limits[0],
    page: 1,
    filtersSelected: {}
  });
  const [defaultFilters, setDefaultFilters] = useState<any>();
  const [reloading, setReloading] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [columnSelects, setColumnSelects] = useState<any>({});
  const searchDebounced = useDebounce(search);
  const [searchFilterData, setSearchFilterData] = useState<any>();
  const { fetchData, response, loaded } = useApi();
  const totalPages = !response ? -1 : Math.ceil(response.total / response.limit);

  const handleSort = (column: string) => () => {
    const order =
      config.sort.column === column ? (config.sort.order === sortBy.ASC ? sortBy.DESC : sortBy.ASC) : sortBy.ASC;

    setConfig({ ...config, sort: { column, order } });
  };

  const handleFilter = (key: string, value: any) => {
    setConfig({
      ...config,
      page: 1,
      filtersSelected: {
        ...config.filtersSelected,
        [key]: value === -1 ? undefined : value
      }
    });
  };
  useEffect(() => {
    if (workbench === 'workbench') {
      if (endpoint) {
        fetchData({ endpoint, method: Methods.GET });
      }
    }
  }, [endpoint]);
  const getTable = (getFilters: boolean = false) => {
    let filter = Object.entries(config.filtersSelected)
      .map(e => ({ column: e[0], value: e[1] }))
      .filter(e => e.value !== undefined);

    if (extraFilters) {
      filter = [...filter, ...extraFilters];
    }

    if (searchDebounced) {
      filter = [...filter, { column: 'all', value: searchDebounced }];
    }

    if (endpoint && !workbench) {
      fetchData(
        { endpoint, method: Methods.POST },
        { getFilters, sort: config.sort, filter, page: config.page, limit: config.limit, params: queryParams }
      );
    }
  };

  const handleSelect = (row: any, checked: boolean) => {
    if (checked) {
      selected!.change([...selected!.value, row]);
    } else {
      selected!.change([...selected!.value.filter(e => e.id !== row.id)]);
    }
  };
  const handleRadioSelect = (row: any, e: any) => {
    if (e.target.checked) {
      selected!.change([{ ...row, value: e.target.value, checked: e.target.checked }]);
    }
  };
  const handleRefresh = () => {
    setReloading(true);
    getTable(false);

    setTimeout(() => {
      setReloading(false);
    }, RELOAD_DELAY);
  };

  const handleGoPrev = () => setConfig({ ...config, page: config.page - 1 });

  const handleGoNext = () => setConfig({ ...config, page: config.page + 1 });

  const setNewLimit = (k: number) => () => setConfig({ ...config, page: 1, limit: k });

  const toggleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      selected?.change(!data ? response.items : data);
    } else {
      selected?.change([]);
    }
  };

  useEffect(() => {
    !!endpoint && getTable(true);
  }, []);

  useEffect(() => {
    !!endpoint && loaded && getTable();
  }, [queryParams]);

  useEffect(() => {
    !!endpoint && loaded && getTable();
  }, [extraFilters]);

  useEffect(() => {
    loaded && getTable();
  }, [config]);

  useEffect(() => {
    loaded && getTable();
  }, [searchDebounced]);

  useEffect(() => {
    if (response && response.filters) {
      setDefaultFilters(response.filters);
    }
  }, [response, handleRadioSelect]);

  useEffect(() => {
    if (columnSelects) {
      const aux: any = {};

      for (const { id } of columns) {
        aux[id] = 'empty';
      }

      setColumnSelects(aux);
    }
  }, []);

  useEffect(() => {
    !!onColumnChanged && onColumnChanged(columnSelects);
  }, [columnSelects]);

  const searchWorkbenchList = () => {
    if (workbench === 'workbench') {
      if (searchs !== '') {
        const filterData = response?.items?.filter((item: any) => {
          return Object.values(item).join('').toLowerCase().includes(searchs.toLowerCase());
        });
        setSearchFilterData(filterData);
      } else setSearchFilterData(response?.items);
    }
  };
  useEffect(() => {
    searchWorkbenchList();
  }, [response, searchDebounced, workbench == 'workbench', searchs]);

  return (
    <>
      {searchable && <SearchInToolbar value={search} onChange={setSearch} />}

      <div className={classnames('table', { alternative })}>
        <div className="wrapper">
          <table className="body-2">
            <thead>
              <tr>
                {!!selected && (loaded || !!data) && (
                  <th className="check">
                    {selectAll ? (
                      <Checkbox
                        checked={(!data ? response.items : data).length === selected.value.length}
                        value="toggle-select"
                        onChange={toggleSelectAll}
                      />
                    ) : (
                      <> </>
                    )}
                  </th>
                )}
                {columns?.map(column => {
                  const { id, filter, width = 'auto', sortable = false } = column;
                  const header = 'header' in column ? column.header : undefined;

                  // @todo: mark as selected nested filters

                  return (
                    <th key={id} style={{ width, minWidth: width !== 'auto' ? width : undefined }}>
                      <div className="strong">
                        {!header ? <>{column.id}</> : <>{header}</>}
                        <div className="options">
                          {sortable && (
                            <ChevronDown
                              onClick={handleSort(id)}
                              className={classnames(
                                'sort',
                                { selected: config.sort.column === id },
                                config.sort.column === id ? config.sort.order : sortBy.ASC
                              )}
                            />
                          )}
                          {!!filter && !!defaultFilters && (
                            <RMenu
                              menuButton={
                                <Filter className={classnames('filter', { selected: !!config.filtersSelected[id] })} />
                              }
                              offsetX={16}
                              offsetY={8}
                              align="end"
                            >
                              <MenuRadioGroup
                                value={
                                  config.filtersSelected[filter.key || id]
                                    ? config.filtersSelected[filter.key || id]
                                    : -1
                                }
                                onRadioChange={e => handleFilter(filter.key || id, e.value)}
                              >
                                <MenuItem key={-1} type="radio" value={-1}>
                                  All
                                </MenuItem>
                                {defaultFilters[id].map((item: { id: string; label: string }, index: number) => (
                                  <MenuItem key={index} type="radio" value={item.id}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </MenuRadioGroup>
                            </RMenu>
                          )}
                        </div>
                      </div>
                    </th>
                  );
                })}
                {!!menuButtons && <th className="menu-buttons">{/*<ColumnsSwitcher />*/}</th>}
              </tr>
            </thead>

            <tbody>
              {!loaded && !data && (
                <tr className="loading">
                  <td>Loading...</td>
                </tr>
              )}
              {!!response && !response.items.length && (
                <tr className="loading">
                  <td>No items were found...</td>
                </tr>
              )}
              {(!!response || !!data) &&
                (!data
                  ? workbench === 'workbench' && searchFilterData !== undefined
                    ? searchFilterData
                    : response.items
                  : data
                ).map((row: any, indexRow: number) => (
                  <tr key={indexRow}>
                    {!!selected && (
                      <>
                        {workbench === 'workbench' ? (
                          <td style={{ width: '10px' }}>
                            <input
                              type="radio"
                              name="schedule-weekly-option"
                              value={row.id}
                              id={row.id}
                              onChange={e => handleRadioSelect(row, e)}
                            />
                          </td>
                        ) : (
                          <td className="check">
                            <Checkbox
                              value={row.id}
                              onChange={e => handleSelect(row, e.target.checked)}
                              checked={selected!.value.findIndex(e => e.id === row.id) > -1}
                            />
                          </td>
                        )}
                      </>
                    )}
                    {columns.map((column, indexCell) => (
                      <td key={indexCell}>
                        <DynamicCell type={column.type} value={get(row, column.id)} />
                      </td>
                    ))}
                    {!!menuButtons && (
                      <td className="menu-buttons">
                        <DropdownButton
                          id={row.id}
                          params={row}
                          isPortal
                          button={<MoreVertical />}
                          items={menuButtons}
                          align="end"
                          viewScroll="close"
                        />
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {!data && !hideFooter && (
          <footer>
            <div>
              <Button variant="alternative" small icon={<RefreshCw />} onClick={handleRefresh} disabled={reloading} />

              {!!selected && selected.value.length > 0 && (
                <div className="count-selected body-2">{selected.value.length} selected</div>
              )}
            </div>
            <div>
              {!!response && response.total > 0 && (
                <div className="info body-2">
                  {response.total} items - page {response.page} of {totalPages}
                </div>
              )}

              <DropdownButton
                button={<Button text={`Items per page: ${config.limit}`} variant="alternative" small />}
                items={limits.map(k => ({ text: `${k}`, onClick: setNewLimit(k) }))}
              />

              <Button
                variant="alternative"
                small
                icon={<ChevronLeft />}
                onClick={handleGoPrev}
                disabled={reloading || (!!response && response.page === 1)}
              />
              <Button
                variant="alternative"
                small
                icon={<ChevronRight />}
                onClick={handleGoNext}
                disabled={reloading || (!!response && response.page === totalPages) || totalPages === 0}
              />
            </div>
          </footer>
        )}
      </div>
    </>
  );
};

export default Table;
