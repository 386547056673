import React from 'react';
import useWindowSize from '@revolt-digital/use-window-size';
import classNames from 'classnames';
import { useModal } from 'hooks/useModal';
import { ModalsList } from '../../../constants/modals';
import { BREAKPOINT_MOBILE, BREAKPOINT_TABLET_LG, BREAKPOINT_TABLET_MD } from '../../../constants/breakpoints';
import Avatar from '../../shared/avatar';
import Carousel from '../../shared/carousel';
import Expansible from '../../../components/shared/expansible';
import Sanitized from '../../shared/sanitized';
import BGMosaic from '../../shared/bgMosaic';

type Members = {
  id: string;
  image: string;
  name: string;
  position: string;
};

type Props = {
  title: string;
  content: string;
  members: Members[];
  selected: boolean;
};

const breakpoints = {
  [0]: {
    slidesPerView: 2.2,
    spaceBetween: 10
  },
  [BREAKPOINT_MOBILE]: {
    slidesPerView: 3.5,
    spaceBetween: 10
  },
  [BREAKPOINT_TABLET_MD]: {
    slidesPerView: 4.5,
    spaceBetween: 10
  },
  [BREAKPOINT_TABLET_LG]: {
    slidesPerView: 5,
    spaceBetween: 10
  }
};

const LayoutTeam = ({ title, content, members }: Props) => {
  const modal = useModal();
  const { width } = useWindowSize();
  const showCarousel = width <= BREAKPOINT_TABLET_LG;

  const handleShowProfile = (index: number) => () => {
    modal.open(ModalsList.TEAM_MEMBERS, () => {}, { members, index });
  };

  console.log('members: ', members);

  return (
    <div className="layout-team">
      <BGMosaic />

      <div className="flex-wrapper">
        <div className="left">
          <h3>{title}</h3>
          <Expansible classes="body">
            <Sanitized html={content} />
          </Expansible>
        </div>

        {members?.length > 0 && (
          <div className="right">
            {!showCarousel ? (
              <div
                className={classNames('grid', {
                  'partner-side': window.partner
                })}
              >
                {/* {members.map(({ id, name, position }, index) => ( */}
                {members.map(({ id, image, name, position }, index) => (
                  <div key={index}>
                    <div className="avatar-wrapper" onClick={handleShowProfile(index)}>
                      {/* <Avatar url={image} data-event="click" data-action="member-detail" data-name={name} /> */}
                      {image == '' ? (
                        id.length > 3 ? (
                          <Avatar
                            url={`${process.env.REACT_APP_KGP_URL}/images?type=partner&id=${id}`}
                            data-event="click"
                            data-action="member-detail"
                            data-name={name}
                          />
                        ) : (
                          <Avatar
                            url={`${process.env.REACT_APP_FALLBACK_AVATAR}`}
                            data-event="click"
                            data-action="member-detail"
                            data-name={name}
                          />
                        )
                      ) : (
                        <Avatar url={image} data-event="click" data-action="member-detail" data-name={name} />
                      )}
                    </div>
                    <div className="details">
                      <div className="name">{name}</div>
                      <p className="body">{position}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <Carousel paginationType="bullets" dynamicBullets loop breakpoints={breakpoints}>
                {/* {members.map(({ id, name }, index) => ( */}
                {members.map(({ id, image, name }, index) => (
                  <div key={index}>
                    <div className="avatar-wrapper" onClick={handleShowProfile(index)}>
                      <Avatar url={image} data-event="click" data-action="member-detail" data-name={name} />
                      {image == '' ? (
                        id.length > 3 ? (
                          <Avatar
                            url={`${process.env.REACT_APP_KGP_URL}/images?type=partner&id=${id}`}
                            data-event="click"
                            data-action="member-detail"
                            data-name={name}
                          />
                        ) : (
                          <Avatar
                            url={`${process.env.REACT_APP_FALLBACK_AVATAR}`}
                            data-event="click"
                            data-action="member-detail"
                            data-name={name}
                          />
                        )
                      ) : (
                        <Avatar url={image} data-event="click" data-action="member-detail" data-name={name} />
                      )}
                    </div>
                  </div>
                ))}
              </Carousel>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LayoutTeam;
