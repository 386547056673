import React, { useState, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bell, BellOff } from 'react-feather';
import { ControlledMenu, MenuAlign } from '@szhsin/react-menu';
import { NotificationType } from 'hooks/useNotifications';
import useApi, { Methods } from 'hooks/useApi';
import { Tooltip, Button } from 'components';
import NotificationItem from './notificationItem';

type Props = {
  align?: MenuAlign;
  data: NotificationType[];
  setNotifications: (data: NotificationType[]) => void;
};

const PushNotifications = ({ align = 'start', data, setNotifications }: Props) => {
  const ref = useRef(null);
  const skipOpen = useRef(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const { fetchData } = useApi();

  const quantity = useMemo(() => data.filter(item => !item.read).length, [data]);

  const handleOpen = () => {
    !skipOpen.current && setOpen(true);
    const unreadIds = data.filter(item => !item.read).map(item => +item.id);

    setNotifications(data.map(item => ({ ...item, read: true })));

    // call api to set notifications as read
    if (unreadIds.length) {
      fetchData({ endpoint: '/notifications/read', method: Methods.POST }, { items: unreadIds });
    }
  };

  const handleOnClick = (payload: NotificationType) => () => {
    navigate(`/deck/${payload.deckId}`);
    setOpen(false);
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <Tooltip value="Notifications" disabled={open}>
        <Button
          variant="alternative"
          contrast={true}
          icon={<Bell />}
          badge={!quantity ? undefined : quantity}
          ref={ref}
          onClick={handleOpen}
        />
      </Tooltip>

      <ControlledMenu
        state={open ? 'open' : 'closed'}
        onClose={handleClose}
        anchorRef={ref}
        skipOpen={skipOpen}
        transition
        align={align}
        offsetX={-305}
        offsetY={0}
        viewScroll="initial"
        menuClassName="push-notification"
      >
        {data.length === 0 ? (
          <div className="empty-state">
            <BellOff />
            <h6>Nothing to see... Yet</h6>
            <p className="body-2">Come again later too see if there’s any notifications for you</p>
          </div>
        ) : (
          <>
            <div className="push-notification-header">
              <h3 className="body strong">Notifications</h3>
            </div>
            <div className="push-notification-body">
              {data
                .sort((a, b) => (a.date > b.date ? -1 : 1))
                .map((item, index) => (
                  <NotificationItem
                    key={`notification-${index}`}
                    title={item?.title}
                    body={item?.body}
                    username={item?.userName}
                    deckName={item?.deckName}
                    candidateName={item?.candidateName}
                    type={item.type}
                    onClick={handleOnClick(item)}
                  />
                ))}
            </div>
          </>
        )}
      </ControlledMenu>
    </>
  );
};

export default PushNotifications;
