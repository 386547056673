import { useEffect, useState } from 'react';
import { objectMap } from 'helpers/data';

const fillDefaultData = (schema: any) => (schema ? objectMap(schema, (item: any) => item.value) : null);

export default (schema?: any) => {
  const [extraParams, setExtraParams] = useState<any>();
  const [form, setForm] = useState<any>({
    valid: false,
    data: fillDefaultData(schema),
    schema
  });
  const [errors, setErrors] = useState<any>({});
  const [isChange, setIsChange] = useState<boolean>(false);

  const resetForm = () => {
    setForm({
      valid: false,
      data: fillDefaultData(schema),
      errors: {},
      schema
    });
  };

  // @todo: implement errors
  // @todo: implement pristine here

  const setSchema = (schema: any, data: any = {}) => {
    setForm({
      schema,
      valid: false,
      data: {
        ...fillDefaultData(schema),
        ...data
      }
    });
  };

  const setFormData = (key: string) => (value: any) => {
    setIsChange(true);
    setForm({
      ...form,
      data: { ...form.data, [key]: value }
    });
  };

  const setFullForm = (data: any) => {
    setForm({ ...form, data: { ...form.data, ...data } });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setFormError = (key: string) => (message: string) => {
    setForm({ ...form, valid: false });
    return setErrors((prevValue: any) => ({ ...prevValue, [key]: message }));
  };

  const validate = () => {
    const { schema, data } = form;
    let isFormValid: boolean = true;
    let formErrors: any = {};

    for (let key of Object.keys(schema)) {
      if (schema[key].joi === undefined) {
        isFormValid = false;
        break;
      }

      const value = data[key];
      const isValid = schema[key].match
        ? data[schema[key].match] === data[key]
        : !schema[key].joi.validate(value).error;

      if (!isValid) {
        isFormValid = false;
        formErrors[key] = schema[key].error;
      }
    }

    setForm({ ...form, valid: isFormValid });
    setErrors(formErrors);
  };

  useEffect(() => {
    if (form.data) {
      validate();
    }
  }, [form.data]);

  return {
    setExtraParams,
    setSchema,
    setFormData,
    setFullForm,
    resetForm,
    setIsChange,
    extraParams,
    schema: form.schema,
    valid: form.valid,
    data: form.data,
    errors,
    isChange
  };
};
