import joi from 'joi';
import { InputsEnum, TextInputTypes } from 'components/dynamicInputs/types';

export default {
  title: {
    value: 'Relevant Experience',
    label: 'Title',
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    limit: 30,
    joi: joi.string().required()
  },
  content: {
    value:
      'Although your specific challenges are unique, our global partners bring significant experience and depth of understanding in executing successful searches within your industry and functional requirements. Here are a few examples curated specific to your needs:',
    label: 'Description',
    input: InputsEnum.REACH_TEXT,
    limit: 350,
    joi: joi.string().required()
  },
  highlight: {
    value: '300+ C-level roles filled for the world’s top companies',
    label: 'Highlight',
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    limit: 50,
    joi: joi.string().required()
  },
  items: {
    value: [],
    label: 'Candidate',
    input: InputsEnum.INPUT_RELEVANT_EXPERIENCE_NEW,
    joi: joi.object().required()
  }
};
