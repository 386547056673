import React, { useEffect, useState, useRef } from 'react';
import { Trans } from 'components/common/trans';
import useWindowSize from '@revolt-digital/use-window-size';
import classnames from 'classnames';
import { useModal } from 'hooks/useModal';
import { ModalsList } from '../../../constants/modals';

type Props = {
  classes: string;
  children: React.ReactNode;
};

const Expansible = ({ classes, children }: Props) => {
  const ref = useRef<any>(null);
  const windowSize = useWindowSize();
  const [showMoreVisible, setShowMoreVisible] = useState(false);
  const modal = useModal();

  const handleShowMore = () => {
    if (!window.partner) {
      modal.open(ModalsList.SHOW_MORE, () => {}, { content: children, classes });
    }
  };

  const checkForShowMore = () => {
    const parentHeight = ref.current.clientHeight;
    const childHeight = ref.current.children[0].clientHeight;

    setShowMoreVisible(childHeight > parentHeight);
  };

  useEffect(() => {
    if (windowSize.width) {
      checkForShowMore();
    }
  }, [windowSize]);

  useEffect(() => {
    if (window.partner) {
      checkForShowMore();
    }
  }, [children]);

  return (
    <div ref={ref} className={classnames(classes, 'expansible')}>
      {children}
      {showMoreVisible && (
        <>
          <div className="hider" />
          <div className="show-more" onClick={handleShowMore}>
            <Trans>Show more</Trans>
          </div>
        </>
      )}
    </div>
  );
};

export default Expansible;
