import React from 'react';
import DiversityImage from 'assets/images/layouts/inputs/diversity-inclusion.svg';

const DiversityAndInclusionImage = () => {
  return (
    <div className="diversity-inclusion input-wrapper">
      <label>No edition available</label>
      <img src={DiversityImage} />
    </div>
  );
};

export default DiversityAndInclusionImage;
