import React, { useState } from 'react';
// import { Plus } from 'react-feather';
// import { useForm, useFieldArray } from 'react-hook-form';
// import { Trash } from 'react-feather';
import AutoComplete from 'components/dynamicInputs/autoComplete';
import { Check, X } from 'react-feather';
import { useToast } from 'hooks/useToast';
// import { SelectOption } from 'components/dynamicInputs/types';
// import { Input } from 'components';
// import { text } from 'stream/consumers';
// export type Props = {
//   title?: string;
// // };
// type FormValue = {
//   partners: {
//     name: string;
//     email: string;
//     phone: string;
//   }[];
// };

type Props = {
  values: any;
  onChange: any;
  title: string;
};

const AddPatner = ({ values, onChange, title }: Props) => {
  console.log('-----', title);
  // const partnerApi = useApi();
  const [partnerId, setPartnerId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [showForm, setShowForm] = useState<boolean>(false);
  const notify = useToast();
  // // const searchDetailsApi = useApi();
  // const [endpint, setEndpoint] = useState<string>('');
  // const [count, setCount] = useState<number>(0);
  // const [precompanyId, setPrecompanyId] = useState<string>('');

  const handleCompanyChange = (data: any) => {
    console.log(data);
    console.log(data.id);
    console.log(data.name);
    console.log(data.email);
    console.log(data.work_phone);
    setName(data.name);
    setEmail(data.email);
    setPhone(data.work_phone);
    setPartnerId(data.id);
  };
  const handleValues = () => {
    console.log('id', partnerId);
    console.log('name', name);
    console.log('email', email);
    console.log('phone', phone);
    if (name !== '') {
      const frozenObj = Object.freeze({ partner_id: partnerId, name: name, email: email, phone: phone });
      values = Object.freeze(values.concat(frozenObj));

      console.log('values ', values);
      setName('');
      setEmail('');
      setPhone('');
      setPartnerId('');
      setShowForm(false);
      onChange(values);
      console.log('from values', values);
    } else {
      notify('Select Partner');
    }
  };

  // const handleReset = () => {
  //   setName('');
  //   setEmail('');
  //   setPhone('');
  //   setPartnerId('');
  // };

  const handletoggel = () => {
    if (showForm) {
      setShowForm(false);
    } else {
      setShowForm(true);
    }
  };

  return (
    <div>
      {showForm && (
        <div className="contact-information">
          <div className="row m-0 p-0 d-flex gap-unset">
            <div className="row m-0 p-0 col-11 d-flex add-data">
              <div className="text-area-contain col-4 " style={{ width: '30%' }}>
                <AutoComplete
                  name="partnerName"
                  label=""
                  value={{ name: name } || {}}
                  onChange={obj => handleCompanyChange(obj)}
                  endpoint="/partners/search"
                  isMulti={false}
                />
              </div>
              <div className="text-area-contain col-4" style={{ width: '30%', marginTop: '2.25rem' }}>
                {/* <input name="email" value={email} type="text" /> */}
                <input name="email" value={email} type="text" onChange={e => setEmail(e.target.value)} />
                {/* <label className="text">Email</label> */}
              </div>
              <div className="text-area-contain col-4" style={{ width: '30%', marginTop: '2.25rem' }}>
                <input name="phone" value={phone} type="text" onChange={e => setPhone(e.target.value)} />

                {/* <label className="text">Phone</label> */}
              </div>
            </div>
            <div className="col-1 d-flex close-btn" style={{ width: '10%', marginTop: '1.0rem' }}>
              <button
                className="btn-title"
                type="button"
                onClick={() => {
                  handleValues();
                }}
              >
                <Check />
              </button>
              <button
                className="btn-title"
                type="button"
                onClick={() => {
                  handletoggel();
                }}
              >
                <X />
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="row d-flex btn-add">
        {/* <button onClick={handleValues}> */}
        <button onClick={handletoggel}>
          {/* <Plus /> */}
          <span className="body strong"> + Add {title} </span>
        </button>
      </div>
    </div>
  );
};

export default AddPatner;
