import React, { useEffect, useState } from 'react';
// import useApi from 'hooks/useApi';
import { Check, X } from 'react-feather';
import Select from 'react-select';
import useApi, { Methods } from 'hooks/useApi';
type Props = {
  values: any;
  onChange: any;
  title: string;
  companyId: string;
  galaxy_id: string;
};

const AddClient = ({ values, onChange, title, companyId, galaxy_id }: Props) => {
  const [id, setId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [count, setCount] = useState<number>(0);
  const [precompanyId, setPrecompanyId] = useState<string>('');
  const clientContactFetchApi = useApi();
  const [showForm, setShowForm] = useState<boolean>(false);

  const handleValues = () => {
    const frozenObj = Object.freeze({ client_id: id, name: name, email: email, phone: phone });
    values = Object.freeze(values.concat(frozenObj));
    console.log('values ', values);
    setName('');
    setEmail('');
    setPhone('');
    setId('');
    setShowForm(false);
    onChange(values);
  };

  useEffect(() => {
    // console.log('galaxy_id------', galaxy_id);
    // clientContactFetchApi.fetchData({
    //   endpoint: `/decks/partnerbyid/${galaxy_id}`,
    //   method: Methods.GET
    // });

    if (companyId !== '') {
      if (count == 0) {
        if (precompanyId !== companyId) {
          setCount(1);
          setPrecompanyId(companyId);
          clientContactFetchApi.fetchData({
            endpoint: `/decks/partnerbyid/${galaxy_id}`,
            method: Methods.GET
          });
        }
      }
    }
  });

  const [options, setOptions] = useState<any>();

  useEffect(() => {
    console.log(clientContactFetchApi.response);
    let temp = clientContactFetchApi.response?.clients.map((d: any) => ({
      value: { id: d.id, name: d.name, email: d.email, phone: d.phone },
      label: d.name
    }));
    setOptions(temp);
  }, [clientContactFetchApi.response]);

  const handleSelect = (e: any) => {
    console.log(e.value);
    setName(e.value.name);
    setId(e.value.id);
    if (e.value.email) {
      setEmail(e.value.email);
    } else {
      setEmail('');
    }
    if (e.value.phone) {
      setPhone(e.value.phone);
      console.log('phone value from client', e.value.phone);
    } else {
      setPhone('');
    }
  };

  const handletoggel = () => {
    setName('');
    setEmail('');
    setPhone('');
    setId('');
    if (showForm) {
      setShowForm(false);
    } else {
      setShowForm(true);
    }
  };

  return (
    <div>
      {showForm && (
        <div className="contact-information">
          <div className="row m-0 p-0 d-flex gap-unset">
            <div className="row m-0 p-0 col-11 d-flex add-data">
              <div className="text-area-contain col-4 " style={{ width: '30%', marginTop: '2.25rem' }}>
                <Select className="select-container" onChange={handleSelect} options={options} />
              </div>
              <div className="text-area-contain col-4" style={{ width: '30%', marginTop: '2.25rem' }}>
                {/* <input name="email" value={email} type="text" /> */}
                <input name="email" value={email} type="text" onChange={e => setEmail(e.target.value)} />
              </div>
              <div className="text-area-contain col-4" style={{ width: '30%', marginTop: '2.25rem' }}>
                {/* <input name="phone" value={phone} type="text" /> */}
                <input name="phone" value={phone} type="text" onChange={e => setPhone(e.target.value)} />
              </div>
            </div>
            <div className="col-1 d-flex close-btn" style={{ width: '10%', marginTop: '1.0rem' }}>
              <button
                className="btn-title"
                type="button"
                onClick={() => {
                  handleValues();
                }}
              >
                <Check />
              </button>
              <button
                className="btn-title"
                type="button"
                onClick={() => {
                  handletoggel();
                }}
              >
                <X />
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="row d-flex btn-add">
        <button onClick={handletoggel}>
          <span className="body strong"> + Add {title} </span>
        </button>
      </div>
    </div>
  );
};

export default AddClient;
