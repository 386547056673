export enum InputsEnum {
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  DIVERSITY_AND_INCLUSION = 'DIVERSITY_AND_INCLUSION',
  FILE_PICKER = 'FILE_PICKER',
  GLOBAL_REACH = 'GLOBAL_REACH',
  IMAGE_PICKER = 'IMAGE_PICKER',
  INPUT = 'INPUT',
  INPUT_CANDIDATE = 'INPUT_CANDIDATE',
  INPUT_PARTNER = 'INPUT_PARTNER',
  INPUT_RELEVANT_EXPERIENCE = 'INPUT_RELEVANT_EXPERIENCE',
  INPUT_RELEVANT_EXPERIENCE_NEW = 'INPUT_RELEVANT_EXPERIENCE_NEW',
  INPUT_SOURCE_ENVIRONMENT = 'INPUT_SOURCE_ENVIRONMENT',
  LAYOUT_PICKER = 'LAYOUT_PICKER',
  REACH_TEXT = 'REACH_TEXT',
  ROLES_LIST = 'ROLES_LIST',
  SEARCH_INFO = 'SEARCH_INFO',
  SHARE_DECK = 'SHARE_DECK',
  SEND_CLIENT = 'SEND_CLIENT',
  TAG_INPUT = 'TAG_INPUT',
  TEXTAREA = 'TEXTAREA',
  THEME_PICKER = 'THEME_PICKER',
  VIDEO_PICKER = 'VIDEO_PICKER'
}

export type CommonProps = {
  onChange: (value: any, name?: string) => void;
  onBlur?: () => void;
  label?: string;
};

export enum TextInputTypes {
  TEXT = 'text',
  NUMBER = 'number',
  EMAIL = 'email'
}

export type TextInputProps = {
  value: string | number | any[];
  type: TextInputTypes;
  name?: string;
  disabled?: boolean;
  limit?: number;
  placeholder?: string;
  autoComplete?: string;
} & CommonProps;

export type TextareaProps = {
  value: string;
  name?: string;
  placeholder?: string;
} & CommonProps;

export type LayoutProps = {
  value: string;
  unrepeatable: string[];
} & CommonProps;

export type ThemeProps = {
  value?: string;
} & CommonProps;

export type ReachTextProps = {
  value: string;
  placeholder?: string;
  limit?: number;
} & CommonProps;

export type AutoCompleteProps = {
  value: any;
  isMulti: boolean;
  endpoint?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  customOptions?: SelectOption[];
  limit?: number;
  nonRemoveable?: boolean;
} & CommonProps;

export type TagInputProps = {
  value?: string[];
} & CommonProps;

export type SearchInfoProps = {
  value?: any;
} & CommonProps;

export type brandingThemeFormProps = {
  value?: any;
  index: number;
} & CommonProps;

export type ClientContactProps = {
  value?: any;
} & CommonProps;

type PartnerContact = {
  id: string;
  name: string;
  email: string;
  work_phone: string;
};

export type PartnersContactProps = {
  value?: PartnerContact[];
} & CommonProps;

type RoleListData = {
  id: string;
  name: string;
  pinned: boolean;
  count: number;
  email?: string;
  subject?: string;
  title?: string;
  body?: string;
  headerbarColor?: string;
  primaryButtonColor?: string;
  titleTextColor?: string;
  bodyTextColor?: string;
  logoUrl?: string;
  fromEmail?: string;
  fromUserName?: string;
  ccPartnerList?: PartnerItem[];
  isEdit?: boolean;
};

export type PartnerItem = {
  name: string;
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  work_phone: string;
  mobile_phone: string;
  title: string;
  profile_image_id: string;
  bio: string;
  linkedin_url: string;
  is_active: boolean;
  personal_email: string;
  hiring_date: string;
  project_managers: string;
  kgp_number: string;
  permission: string;
};

export type RolesListProps = {
  value: RoleListData[];
} & CommonProps;

export type PartnersListProps = {
  value: {
    sharedUsers: PartnerItem[];
    newSharedUsers: PartnerItem[];
    saveActive: boolean;
  };
} & CommonProps;

export type InputCounterChangeProps = {
  id: string;
  operation: InputCounterOperation;
};

export type InputCandidateProps = {
  value: any[];
  type: TextInputTypes;
  candidatesFeedback: { candidate_id: string; type: 'like' | 'dislike' }[];
} & CommonProps;

export type InputPartnerProps = {
  value: any[];
  placeholder?: string;
  type: TextInputTypes;
} & CommonProps;

export type InputSourceEnvironmentProps = {
  value: any[];
  placeholder?: string;
  type: TextInputTypes;
} & CommonProps;

export type RoleType = {
  id: string;
  name: string;
  pinned: boolean;
  count: number;
};

export type RelevantExperienceValue = {
  [key: string]: any;
  id: string;
  label: string;
  companies: CompanyType[];
  subCategories: SubcategoryType[];
};

export type CompanyType = {
  [key: string]: any;
  id: string;
  name: string;
  image: string;
  roles: RoleType[];
};

export type SubcategoryType = {
  id: string;
  name: string;
  companies: CompanyType[];
};

export type InputRelevantExperienceProps = {
  value: RelevantExperienceValue[];
  placeholder?: string;
  type: TextInputTypes;
  slideType?: string;
} & CommonProps;

export type ImagePickerProps = {
  value: any;
} & CommonProps;

export enum InputCounterOperation {
  ADD = 'ADD',
  REMOVE = 'REMOVE'
}

export type SelectOption = {
  id: string;
  name: string;
};

export interface ContactSelectOption extends SelectOption {
  email: string;
  work_phone: string;
}

export type VideoPickerProps = {
  value: any;
} & CommonProps;

type FilePickerData = {
  file: any;
  isSigned: boolean;
  lastModified: number;
  name: string;
};

export type FilePickerProps = {
  value?: FilePickerData;
} & CommonProps;
