import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRefs } from 'hooks/useRefs';
import { useNotifications } from 'hooks/useNotifications';
import { Logo, MyProfileMenu, PushNotifications } from 'components';
import CustomTheme from '../customTheme';
import { Spinner } from 'galleryCrop/components/common';
import { LogoContext } from 'app';

const Toolbar = () => {
  const { toolbarMiddleRef } = useRefs();
  const navigate = useNavigate();
  const location = useLocation();
  const { notifications, setNotifications } = useNotifications();
  let logoUrl = localStorage.getItem('logoUrl');
  const [loaded, setLoaded] = useState(false);
  const { logo, setLogo } = useContext(LogoContext);
  const [reLoad, setReLoad] = useState('0');

  const handleNavigateHome = () => {
    if (location.pathname === '/') {
      return;
    }

    navigate('/');
  };

  const lightOrDark = (color: any) => {
    let r;
    let g;
    let b;
    if (color.match(/^rgb/)) {
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      color = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;

      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    let hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    if (hsp > 127.5) {
      document.documentElement.style.setProperty('--toolbar-button', '#000');
      // return 'light';
    } else {
      document.documentElement.style.setProperty('--toolbar-button', '#fff');
      // return 'dark';
    }
  };

  useEffect(() => {
    let element: any = document.getElementById('header-bar');
    //console.log('element: ',element);
    let bgColor = window.getComputedStyle(element, null).getPropertyValue('background-color');
    lightOrDark(bgColor);
  });

  const handleOnChange = (value: any) => {
    setReLoad('1');
    console.log('toolbar: ', value);
  };

  // The below 2 useEffect is used to re-render the component and logo So do not remove this code in future!!!!!
  useEffect(() => {
    console.log('reload: ', reLoad);
    if (reLoad === '1') {
      setReLoad('0');
    }
  }, [reLoad]);

  useEffect(() => {
    if (logo === '1') {
      setLogo('0');
    }
    setReLoad('1');
  });

  return (
    <div className="toolbar">
      <button onClick={handleNavigateHome}>
        {logoUrl === '' && <Logo />}
        {logoUrl !== '' && (
          <>
            <div style={{ display: !loaded ? 'block' : 'none' }}>
              <Spinner />
            </div>
            <div style={{ display: loaded ? 'block' : 'none' }}>
              <img onLoad={() => setLoaded(true)} src={logoUrl ? logoUrl : ''} />
            </div>
          </>
        )}
      </button>

      <div className="middle-slot" ref={toolbarMiddleRef} />

      <div className="end-slot">
        {location.pathname === '/' && (
          <>
            {' '}
            <PushNotifications data={notifications} setNotifications={setNotifications} />
            <CustomTheme onChange={(data: any) => handleOnChange(data)} />
          </>
        )}
        <MyProfileMenu />
      </div>

      <svg id="header-bar" className="background" viewBox="0 0 1366 68" fill="none">
        <path
          d="M1156.23 146.57L952.219 -65.3798C880.657 -139.728 762.373 -141.987 688.024 -70.4245L447.999 160.606L645.131 365.413C720.49 443.707 845.051 446.085 923.344 370.725L1156.23 146.57Z"
          fill="#32146E"
          fillOpacity="0.13"
        />
        <path
          d="M719 201L392.01 -77.1144C309.233 -147.519 185.054 -137.489 114.649 -54.7115L-100 197.66L226.99 475.774C309.767 546.179 433.946 536.149 504.351 453.371L719 201Z"
          fill="#DEC3D4"
          fillOpacity="0.05"
        />
        <path
          d="M1644.7 -17H1425.15C1290.31 -17 1181 92.3098 1181 227.15V446.696H1400.55C1535.39 446.696 1644.7 337.386 1644.7 202.546V-17Z"
          fill="#B4B3E6"
          opacity="0.2"
        />
      </svg>
    </div>
  );
};

export default Toolbar;
