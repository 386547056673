import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
// Test Config
// const firebaseConfig = {
//   apiKey: 'AIzaSyB_2O9XaUO2SPomEZHLN0TJYh4pK2YllkI',
//   authDomain: 'synclink-288b0.firebaseapp.com',
//   projectId: 'synclink-288b0',
//   storageBucket: 'synclink-288b0.appspot.com',
//   messagingSenderId: '903750221123',
//   appId: '1:903750221123:web:6fc75011284b3b789fc3f6',
//   measurementId: 'G-075GZRNFFP'
// };
// const vapidKey = 'BEV7HhJj0rbqDhPGKAl0ytUopR8zeBWs9di-1385FAgjAZrA1VmiUva4dLr6ybDml35CrayfOoaS7OZ1pwH6avU';

// Prod Config
const firebaseConfig = {
  apiKey: 'AIzaSyAdOckKRZ1Pak0z37Z0ns7Tg7R9NuuLYKc',
  authDomain: 'synclink-prod.firebaseapp.com',
  projectId: 'synclink-prod',
  storageBucket: 'synclink-prod.appspot.com',
  messagingSenderId: '746910545399',
  appId: '1:746910545399:web:469a823fece7578379eaf2',
  measurementId: 'G-FM2BLH185V'
};

const vapidKey = 'BBHRsCt4gKr1k9QwZKqAtDqCvo9w2wdtZQG8wVBrZRftxj3aw8_p6cm4cehFJ8DexNzi3t0ODr8z-Hib46c9u1Y';

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export { messaging, vapidKey };
