import React from 'react';

const Background = () => (
  <div className="background">
    <div className="top">
      <svg viewBox="0 0 436 446">
        <path d="M0 217.583C0 97.4155 97.4153 0 217.583 0H435.166V227.725C435.166 347.893 337.751 445.308 217.583 445.308H0V217.583Z" />
      </svg>
      <svg viewBox="0 0 436 446">
        <path d="M0 217.583C0 97.4155 97.4153 0 217.583 0H435.166V227.725C435.166 347.893 337.751 445.308 217.583 445.308H0V217.583Z" />
      </svg>
    </div>
    <div className="bottom">
      <svg viewBox="0 0 436 446">
        <path d="M0 217.583C0 97.4155 97.4153 0 217.583 0H435.166V227.725C435.166 347.893 337.751 445.308 217.583 445.308H0V217.583Z" />
      </svg>
      <svg viewBox="0 0 436 446">
        <path d="M0 217.583C0 97.4155 97.4153 0 217.583 0H435.166V227.725C435.166 347.893 337.751 445.308 217.583 445.308H0V217.583Z" />
      </svg>
    </div>
  </div>
);

export default Background;
