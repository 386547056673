import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import ReactQuill from 'react-quill';
import { Quill } from 'react-quill';
import { ReachTextProps } from '../types';

const TOOLBAR_OPTIONS = [['bold', 'italic', 'underline', 'link'], [{ list: 'bullet' }]];

const ReachText = ({ value, placeholder, onChange, label, limit }: ReachTextProps) => {
  const ref = useRef<any>();
  const [focused, setFocused] = useState(false);
  const [counter, setCounter] = useState(-1);
  const diff = limit ? limit - counter + 1 : -1;

  const handleChange = (data: string) => onChange(data);

  useEffect(() => {
    if (ref.current) {
      setCounter(ref.current.editor.getLength());
    }
  }, [value]);

  if (value === undefined) {
    return null;
  }

  return (
    <div className="reach-text-container input-wrapper">
      <label className="label">
        <div
          className={classNames('text', {
            focused
          })}
        >
          {label}
        </div>
      </label>
      <div onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}>
        <ReactQuill
          ref={ref}
          placeholder={placeholder}
          modules={{
            toolbar: {
              container: TOOLBAR_OPTIONS,
              formats: ['bullet'] // to clear paste
            }
          }}
          value={value}
          onChange={handleChange}
        />
      </div>
      {limit && counter > -1 && counter > 1 && (
        <>
          {limit - counter + 1 > 0 ? (
            <span className="body-2 limit-info">{diff} characters to reach the limit</span>
          ) : diff === 0 ? (
            <span className="body-2 limit-info">Limit reached</span>
          ) : (
            <span className="body-2 limit-error">Limit exceeded by {diff * -1} characters</span>
          )}
        </>
      )}
    </div>
  );
};

let Link = Quill.import('formats/link');
Link.sanitize = function (url: string) {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
};

export default ReachText;
