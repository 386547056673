import React, { useEffect } from 'react';
import useFormHandler from 'hooks/useFormHandler';
import { Button, DynamicForm } from 'components';
import schema from 'schemas/modals/partnersBio';
import Base from '../base';
import { Props } from '../types';

const PartnersBio = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.disabled = true;
    onClose(formHandler.data);
  };

  useEffect(() => {
    formHandler.setFullForm({
      bio: params?.bio || ''
    });
  }, []);

  return (
    <Base
      closing={closing}
      header={'Edit Partner’s Bio'}
      onClose={onClose}
      size="md"
      footer={<Button text={'save'} disabled={!formHandler.valid} onClick={handleClick} />}
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default PartnersBio;
