import React from 'react';
import { useNetworkState, usePrevious } from 'react-use';
import { useToast } from 'hooks/useToast';
import { Wifi, WifiOff } from 'react-feather';

const withNetworkStatus =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  props => {
    const state = useNetworkState();
    const prevState = usePrevious(state);
    const notify = useToast();

    React.useMemo(() => {
      if (!state.online && prevState?.online) {
        notify(`You're offline, changes could not be saved.`, <WifiOff />);
      }

      if (state.online && prevState?.online === false) {
        notify(`You're back online.`, <Wifi />);
      }
    }, [state.online, prevState]);

    return <Component {...props} />;
  };

export default withNetworkStatus;
