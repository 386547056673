import React, { FC } from 'react';
import CropImage from './components/cropImage';
import Gallery from './components/gallery';
import useGallery from './hooks/useGallery';
import { SelectedImageType } from './types';
import './styles/global.scss';

export interface Props {
  deckid: string;
  aspectRatio: string;
  onSelect: (image: SelectedImageType) => void;
  cropShape: 'rect' | 'round';
}

const GalleryCrop: FC<Props> = ({ deckid, aspectRatio, onSelect }) => {
  const {
    images,
    uploadImage,
    uploadImageLoading,
    deleteImage,
    currentImage,
    isEdition,
    setIsEdition,
    setEditImage,
    getImages,
    imageSelected,
    setImageSelected
  } = useGallery({ deckid, aspectRatio });

  if (isEdition) {
    return (
      <CropImage
        deckid={deckid}
        uploadImage={uploadImage}
        uploadImageLoading={uploadImageLoading}
        url={currentImage?.url + '?not-from-cache-please'!}
        name={currentImage?.fileName!}
        setIsEdition={setIsEdition}
      />
    );
  }

  return (
    <Gallery
      images={images}
      uploadImage={uploadImage}
      uploadImageLoading={uploadImageLoading}
      deleteImage={deleteImage}
      setEditImage={setEditImage}
      setIsEdition={setIsEdition}
      getImages={getImages}
      imageSelected={imageSelected}
      setImageSelected={setImageSelected}
      aspectRatio={aspectRatio}
      onSelect={onSelect}
    />
  );
};

export default GalleryCrop;
