import React, { useEffect, useState } from 'react';
import { File } from 'react-feather';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { Button, Table } from 'components';
import { ComponentList, TableOptions } from 'components/common/table/types';
import Base from '../base';
import { Props } from '../types';
import * as XLSX from 'xlsx';
import AutoComplete from 'components/dynamicInputs/autoComplete';

const ImportFromDocument = ({ closing, onClose, params }: Props) => {
  const [fileData, setFileData] = useState<any[]>([]);
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState<any[]>([]);
  const [table, setTable] = useState<any>();
  const [keyReferences, setKeyReferences] = useState<any>();
  const [validColumns, setValidColumns] = useState<boolean>(false);
  const [selectedSubcategory, setSelectedSubcategory] = useState<any>({});
  const [selectedCompany, setSelectedCompany] = useState<any>({});
  const [selectedJobRole, setSelectedJobRole] = useState<any>({});
  const [column, setColumn] = useState<any>([]);

  const onDrop = (file: any) => {
    if (file[0].type === 'text/csv') {
      Papa.parse(file[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results: any) {
          setFileData(results.data);
        }
      });
    } else {
      const reader = new FileReader();
      reader.onload = evt => {
        const bstr = evt && evt.target && evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const name = wb.SheetNames[0];
        const ws = wb.Sheets[name];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, {});
        setFileData(data);
      };
      reader.readAsBinaryString(file[0]);
    }
  };
  console.log(fileData, 'fileDataFileData FileData FileData ');
  useEffect(() => {
    if (fileData && typeof fileData[0] === 'object') {
      const columns = Object.keys(fileData[0]);
      setOptions(columns);
    }
  }, [fileData]);

  useEffect(() => {
    if (selectedSubcategory !== null && Object.keys(selectedSubcategory).length !== 0) {
      params.columns.push({ label: 'SubCategory', key: 'subCategory' });
    }
  }, [selectedSubcategory]);

  useEffect(() => {
    Object.keys(column).forEach(key => {
      column[key] = '';
    });
    for (let key in column) {
      console.log('Key :', key);
      // if imported file have company and Title in Columns
      if (key === selectedCompany.name) {
        column[key] = 'company_name'; // data of company and role in states by dropdown
      }
      if (key === selectedJobRole.name) {
        column[key] = 'role';
      }
      if (selectedSubcategory) {
        if (key === selectedSubcategory.name) {
          console.log('i am in key add');
          column[key] = 'subCategory';
        }
      }
    }
    console.log(selectedCompany, 'selectedCompanyselectedCompanyselectedCompany');

    setKeyReferences(column);
  }, [selectedCompany, selectedJobRole, column, selectedSubcategory]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'text/csv': ['.csv'], 'application/vnd.openxmlformats-officedocument.spreadsheetml': ['.xlsx'] },
    multiple: false
  });

  const handleStartAgain = () => {
    setFileData([]);
    setSelected([]);
    setTable(undefined);
    setKeyReferences(false);
    setValidColumns(false);
  };

  const handleSave = () => {
    const entries = Object.entries(keyReferences);
    const reference: any = {};
    const result: any[] = [];

    for (const mandatory of params.columns) {
      // @ts-ignore
      reference[mandatory.key] = entries.find(entry => entry[1] === mandatory.key)[0];
    }

    for (const item of selected) {
      const aux: any = {};

      for (const { key } of params.columns) {
        aux[key] = item[reference[key]];
      }

      result.push(aux);
    }

    onClose(result);
  };

  const handleClose = () => {
    onClose();
  };

  const onColumnChanged = (columns: any) => {
    setColumn(columns);
    setValidColumns(true);
  };

  useEffect(() => {
    if (fileData.length) {
      const tableOptions: TableOptions = {
        searchable: false,
        itemsPerPage: 100,
        hideFooter: true,
        columns: []
      };
      let payload: any = fileData;

      if (!fileData[0].id) {
        payload = fileData.map((item, index) => {
          return { id: index + 1, ...item };
        });
      }

      tableOptions.selectAll = true;
      tableOptions.data = payload;

      tableOptions.columns = Object.keys(payload[0]).map(id => {
        return { id, type: ComponentList.TEXT, sortable: false };
      });
      //i can set dropdown values

      setTable(tableOptions);
    }
  }, [fileData]);

  return (
    <Base
      className="modal-file-uploader"
      onClose={onClose}
      closing={closing}
      size="lg"
      header={params.modalTitle}
      footer={
        !table ? (
          <Button text="Close" onClick={handleClose} />
        ) : (
          <div className="table-buttons">
            <div>
              {selected.length === 0 ? (
                <span>Please, select one or more rows</span>
              ) : validColumns ? (
                <></>
              ) : (
                <span>Please, make sure Title and Company column data is there in uploaded file.</span>
              )}
            </div>
            <div>
              <Button text="Try another File" variant="secondary" onClick={handleStartAgain} />
              <Button text="Save" disabled={!selected.length || !validColumns} onClick={handleSave} />
            </div>
          </div>
        )
      }
    >
      {!table ? (
        <>
          <div className="header-title">
            <p>Make sure beforehand:</p>
            <div className="header-content">
              <ul>
                <li>.csv or .xlsx files only</li>
                <li>Column names should be in the first row</li>
                <li>Once you upload a file you can map the columns from the file </li>
              </ul>
            </div>
          </div>
          <p className="subTitle">Upload Files</p>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input className="input-zone" {...getInputProps()} />
            <div className="text-center">
              {isDragActive ? (
                <p>Release to drop the files here</p>
              ) : (
                <div>
                  <File />
                  <p>
                    Drag and drop files, or <strong>Browse</strong>
                  </p>
                  <p className="grey-font">.csv or .xlsx supported</p>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mapping-body">
            <div className="custom-mapping">
              <div className="mapping-title font-lighte">
                <h6>Synclink Custom Field Mappings</h6>
              </div>
              <div className="form-layout">
                <div className="container-form">
                  <div className="column field form-header">Fields</div>
                  <div className="column form-header">Mapping</div>
                </div>
                <div className="form-field-section">
                  <div className="container-form">
                    <div className="column font-lighter">
                      <div>SubCategory</div>
                    </div>
                    <div className="column">
                      {' '}
                      <AutoComplete
                        name="subcategory"
                        label=""
                        value={selectedSubcategory}
                        onChange={(value: string) => {
                          setSelectedSubcategory(value);
                          console.log(value);
                        }}
                        customOptions={options?.map((item: any, index: any) => ({
                          id: index,
                          name: item
                        }))}
                        isMulti={false}
                      />
                    </div>
                  </div>
                  <div className="container-form">
                    <div className="column font-lighter">
                      <div>Company</div>
                    </div>
                    <div className="column">
                      {' '}
                      <AutoComplete
                        name="company"
                        label=""
                        value={selectedCompany}
                        onChange={(value: string) => setSelectedCompany(value)}
                        customOptions={options?.map((item: any, index: any) => ({
                          id: index,
                          name: item
                        }))}
                        isMulti={false}
                      />
                    </div>
                  </div>
                  <div className="container-form ">
                    <div className="column font-lighter">
                      <div>Title</div>
                    </div>
                    <div className="column">
                      <AutoComplete
                        name="job"
                        label=""
                        value={selectedJobRole}
                        onChange={(value: string) => setSelectedJobRole(value)}
                        customOptions={options?.map((item: any, index: any) => ({
                          id: index,
                          name: item
                        }))}
                        isMulti={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mapped-table border-form">
              <Table
                alternative
                options={table}
                selected={{ value: selected, change: setSelected }}
                onColumnChanged={onColumnChanged}
              />
            </div>
          </div>
        </>
      )}
    </Base>
  );
};

export default ImportFromDocument;
