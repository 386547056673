import React, { SetStateAction } from 'react';
import { Portal } from 'react-portal';
import { Search } from 'react-feather';
import { useRefs } from 'hooks/useRefs';

type Props = {
  value: string;
  onChange: React.Dispatch<SetStateAction<string>>;
};

const SearchInToolbar = ({ value, onChange }: Props) => {
  const { toolbarMiddleRef } = useRefs();

  return (
    <Portal node={toolbarMiddleRef.current}>
      <div className="search-in-toolbar">
        <input type="search" placeholder="Search" value={value} onChange={({ target }) => onChange(target.value)} />
        <Search />
      </div>
    </Portal>
  );
};

export default SearchInToolbar;
