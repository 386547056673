import React from 'react';
import { Trans } from 'components/common/trans';
import { useModal } from 'hooks/useModal';
import { ModalsList } from '../../../constants/modals';
import Expansible from '../../../components/shared/expansible';
import PlayButton from '../../shared/playButton';
import Sanitized from '../../shared/sanitized';
import BGMosaic from '../../shared/bgMosaic';

type Signature = {
  name: string;
};

type Props = {
  title: string;
  content: string;
  video: {
    url: string;
    thumbnail: string;
  };
  signatures: Signature[];
  videoText: string;
  selected: boolean;
};

const LayoutSignature = ({ title, content, video, signatures, videoText }: Props) => {
  const modal = useModal();

  const handleVideoClick = () => {
    modal.open(ModalsList.VIDEO_MODAL, () => {}, { url: video.url, videoText });
  };

  const handleSignatureClick = (partner: any) => () => {
    modal.open(ModalsList.PARTNER_PROFILE, () => {}, partner);
  };

  return (
    <div className="layout-signature">
      <BGMosaic />

      <div className="flex-wrapper">
        <div className="info">
          <div className="wrapper">
            <div className="title-class">
              <div className="title-name">
                <h5 data-event="click">{title}</h5>
              </div>
            </div>
            <Expansible classes="content body">
              <Sanitized html={content} />
            </Expansible>

            <div className="signatures">
              <p className="body">
                <Trans>Regards</Trans>,
              </p>
              <div className="content">
                {signatures?.map((signature, index) => (
                  <div key={index}>
                    <span className="signature" onClick={handleSignatureClick(signature)}>
                      {signature.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="signature-video">
          <div className="video-container" data-event="click" data-action="play-video" onClick={handleVideoClick}>
            <img src={video?.thumbnail} />
            <div className="play-video">
              <PlayButton />
            </div>
            {!!videoText && (
              <div className="video-text">
                <h6>{videoText}</h6>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutSignature;
