import joi from 'joi';
import { InputsEnum } from 'components/dynamicInputs/types';

export default {
  rolesList: {
    value: [],
    input: InputsEnum.ROLES_LIST,
    joi: joi.array().min(1).required()
  }
};
