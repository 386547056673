import { useContext, useEffect, useCallback, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import customHistory from 'constants/browserHistory';
import { ModalsList } from 'constants/modals';
import { useModal } from 'hooks/useModal';

export function useBlocker(blocker: any, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = customHistory.block(tx => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        }
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

export default function useConfirm(when = true, callback: () => void) {
  const modal = useModal();
  const [confirm, setConfirm] = useState(false);

  const blocker = useCallback((tx: any) => {
    modal.open(ModalsList.CONFIRM_LEAVE_DECK, (response: any) => {
      if (!response?.save && !response?.leave) {
        return;
      }

      if (response.save && response.leave) {
        setConfirm(true);
      }

      tx.retry();
    });
  }, []);

  useBlocker(blocker, when);

  useEffect(() => {
    if (confirm) {
      callback();
      setConfirm(prev => !prev);
    }
  }, [confirm]);
}
