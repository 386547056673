import React from 'react';
import { Plus, Minus } from 'react-feather';
import { InputCounterChangeProps, InputCounterOperation } from 'components/dynamicInputs/types';

type Props = {
  id: string;
  name: string;
  value: string | number;
  onChange: (operation: InputCounterChangeProps) => void;
  max?: string | number;
  min?: string | number;
  step?: string | number;
};

const InputNumber = ({ id, name, value = 0, onChange, min = 0, max, step = 1 }: Props) => {
  const handleOperationClick = (operation: InputCounterOperation) => {
    if (value === min && operation === InputCounterOperation.REMOVE) {
      return;
    }

    if (value === max && operation === InputCounterOperation.ADD) {
      return;
    }

    onChange({
      id,
      operation
    });
  };

  return (
    <div className="number-input-group" id={id}>
      <button type="button" className="btn-input" onClick={() => handleOperationClick(InputCounterOperation.REMOVE)}>
        <Minus size="14" />
      </button>
      <input
        type="number"
        step={step}
        max={max}
        min={min}
        value={value}
        name={name}
        onChange={() => {}} // With this i can prevent users to change value by typing on the input
      />
      <button type="button" className="btn-input" onClick={() => handleOperationClick(InputCounterOperation.ADD)}>
        <Plus size="14" />
      </button>
    </div>
  );
};

export default InputNumber;
