import React from 'react';
import { Tabs as Wrapper, Tab, TabList, TabPanel } from 'react-tabs';

const Tabs = ({ data, forceRenderTabPanel, defaultIndex }: any) => (
  <Wrapper forceRenderTabPanel={forceRenderTabPanel} defaultIndex={defaultIndex}>
    <TabList>
      {data.map(({ title }: any, index: number) => (
        <Tab key={index}>{title}</Tab>
      ))}
    </TabList>
    {data.map(({ component }: any, index: number) => (
      <TabPanel key={index}>{component}</TabPanel>
    ))}
  </Wrapper>
);

export default Tabs;
