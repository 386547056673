import { useMemo } from 'react';

const useSupportedMimeType = () => {
  const mimeType = useMemo(() => {
    const mp4 = 'video/mp4';
    const webm = 'video/webm';

    const mimeType = MediaRecorder.isTypeSupported(mp4) ? mp4 : webm;

    const videoExtension = mimeType?.split('/')[1];

    return { mimeType, videoExtension };
  }, []);

  return mimeType;
};

export default useSupportedMimeType;
