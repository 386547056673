/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import BrandingThemeForm from 'components/dynamicInputs/brandingThemeForm';
import Button from 'components/common/button';
import { Plus } from 'react-feather';
import { Props } from '../types';
import { CommonProps } from 'components/dynamicInputs/types';
import useApi, { Methods } from 'hooks/useApi';
// import { LogoContext } from 'components/common/toolbar';
import { useLocalStorage } from '@rehooks/local-storage';
import { LogoContext } from 'app';

const BrandingTheme = ({ onChange }: CommonProps) => {
  const [selectedId, setSelectedId] = useState<number>(0);
  const [themes, setThemes] = useState<any>();
  // const {logo, addLogo} = useContext(LogoContext);
  const fetchThemes = useApi();
  const [user] = useLocalStorage('user', { id: 0 });
  const { logo, setLogo } = useContext(LogoContext);

  const temp = {
    themes: [
      {
        id: 1,
        name: 'Default Theme',
        isDefault: false,
        headerbarColor: '#4240c2',
        primaryButtonColor: '#0300ad',
        bodyTextColor: '#2a364c',
        titleTextColor: '#3d4dda',
        secondaryButtoncolor: '#0300ad',
        textFont: 'Satoshi',
        logoUrl: '',
        createdBy: ''
      }
    ]
  };

  useEffect(() => {
    //setThemes(temp);
    fetchThemes.fetchData({ endpoint: '/decks/getBrandingThemes', method: Methods.POST });
  }, []);

  useEffect(() => {
    // console.log("response: ",fetchThemes.response);
    if (fetchThemes.response !== undefined) {
      let themes: {
        id: any;
        name: any;
        isDefault: any;
        headerbarColor: any;
        primaryButtonColor: any;
        bodyTextColor: any;
        titleTextColor: any;
        secondaryButtoncolor: any;
        textFont: any;
        logoUrl: any;
        createdBy: any;
      }[] = [];
      fetchThemes.response?.branding?.map((key: any) => {
        // console.log(key);
        themes.push({
          id: key.id,
          name: key.theme_name,
          isDefault: key.default_themes,
          headerbarColor: key.header_bar_color,
          primaryButtonColor: key.primary_button_color,
          bodyTextColor: key.body_text_color,
          titleTextColor: key.title_text_color,
          secondaryButtoncolor: key.secondary_button_color,
          textFont: key.font,
          logoUrl: key.logo,
          createdBy: key.created_by
        });
      });
      const temp = { themes: themes };
      console.log(temp);
      setThemes(temp);
    }
  }, [fetchThemes.response]);

  const handleOnChange = (data: any) => {
    //setThemes(data);
    console.log('On change:========', data);
    for (const item of themes.themes) {
      if (item.id == data.id) {
        console.log(item.id);
        item.name = data.name;
        item.headerbarColor = data.headerbarColor;
        item.primaryButtonColor = data.primaryButtonColor;
        item.bodyTextColor = data.bodyTextColor;
        item.titleTextColor = data.titleTextColor;
        item.secondaryButtoncolor = data.secondaryButtoncolor;
        item.textFont = data.textFont;
        item.logoUrl = data.logoUrl;
        item.isDefault = data.isDefault;
        item.createdBy = data.createdBy;
        break;
      }
    }
    console.log(themes.themes);
    setThemes(themes);
    // TODO need to remove localstorage once backend is setup.
    // if (data.headerbarColor !== undefined && data.headerbarColor !== '') {
    //   document.documentElement.style.setProperty('--headeer-bar-color', data.headerbarColor);
    //   localStorage.setItem('headerbarColor', data.headerbarColor);
    // }
    // if (data.primaryButtonColor !== undefined && data.primaryButtonColor !== '') {
    //   document.documentElement.style.setProperty('--primary-button-color', data.primaryButtonColor);
    //   localStorage.setItem('primaryButtonColor', data.primaryButtonColor);
    // }
    // if (data.secondaryButtoncolor !== undefined && data.secondaryButtoncolor !== '') {
    //   document.documentElement.style.setProperty('--secondary-button-color', data.secondaryButtoncolor);
    //   localStorage.setItem('secondaryButtoncolor', data.secondaryButtoncolor);
    // }
    // if (data.titleTextColor !== undefined && data.titleTextColor !== '') {
    //   document.documentElement.style.setProperty('--title-text-color', data.titleTextColor);
    //   localStorage.setItem('titleTextColor', data.titleTextColor);
    // }
    // if (data.bodyTextColor !== undefined && data.bodyTextColor !== '') {
    //   document.documentElement.style.setProperty('--body-text-color', data.bodyTextColor);
    //   localStorage.setItem('bodyTextColor', data.bodyTextColor);
    // }

    if (data.textFont !== undefined && data.textFont !== '') {
      if (data.textFont === 'Satoshi') {
        document.documentElement.style.setProperty('--font', data.textFont);
      } else if (data.textFont === 'Bitter') {
        document.documentElement.style.setProperty('--font', 'Bitter');
      } else if (data.textFont === 'OpenSans') {
        document.documentElement.style.setProperty('--font', 'OpenSans');
      } else if (data.font === 'Roboto') {
        document.documentElement.style.setProperty('--font', 'Roboto');
      } else {
        document.documentElement.style.setProperty('--font', 'Satoshi');
      }
    }
    // console.log('logo url', data.logoUrl);
    // if (data.logoUrl !== undefined && data.logoUrl !== '') {
    //   localStorage.setItem('logoUrl', data.logoUrl);
    // } else {
    //   localStorage.setItem('logoUrl', '');
    // }
    // setLogo('1');
    onChange(data.logoUrl);
  };

  const handleAddTheme = () => {
    console.log(themes);
    let id = themes.themes.length;
    let temp = themes;
    temp.themes.push({
      id: 0,
      name: 'New Theme',
      isDefault: false,
      headerbarColor: '#ffffff',
      primaryButtonColor: '#ffffff',
      bodyTextColor: '#ffffff',
      titleTextColor: '#ffffff',
      secondaryButtoncolor: '#ffffff',
      textFont: 'Satoshi',
      logoUrl: '',
      createdBy: user.id
    });
    setThemes(temp);
    console.log('-------', selectedId);
    setSelectedId(id);
    console.log('-------', themes);
  };

  useEffect(() => {
    console.log('==========', themes);
  }, [selectedId]);

  return (
    <div className="search-information">
      <div className="row">
        <div className="label">
          <Button text={'Add Theme'} onClick={handleAddTheme} small={true} /> <br />
          {themes?.themes?.map((key: any, index: number) => (
            <div className="label-container" key={key.id} onClick={() => setSelectedId(index)}>
              {index == selectedId && <span className="selected-theme">{key.name}</span>}
              {index != selectedId && <span>{key.name}</span>}
            </div>
          ))}
        </div>
        <div className="value">
          {themes?.themes?.map(
            (key: any, index: number) =>
              index == selectedId && (
                // eslint-disable-next-line react/jsx-key
                <BrandingThemeForm
                  key={key.id}
                  value={key}
                  index={selectedId}
                  onChange={data => handleOnChange(data)}
                />
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default BrandingTheme;
