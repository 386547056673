import React from 'react';
import DragIcon from 'assets/icons/DragIcon';
import { Image, ChevronDown, ChevronUp, Trash2 } from 'react-feather';

type Props = {
  id: string;
  value: string;
  name: string;
  placeholder?: string;
  confirmName: string;
  children?: React.ReactNode;
  hasDragIcon?: boolean;
  showDropdown: (id: string) => boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  onBlur?: () => void;
  galleryModal?: (id: string) => void;
  confirmModal?: (id: string, name: string) => void;
  setDropdownId: (id: string) => void;
  isSubCategories?: boolean;
  isSubCategoriesCompany?: boolean;
  slideType?: string;
};

const InputDropdown = ({
  id,
  value,
  name,
  placeholder,
  confirmName,
  children,
  hasDragIcon,
  showDropdown,
  onChange,
  galleryModal,
  confirmModal,
  setDropdownId,
  isSubCategories,
  isSubCategoriesCompany,
  slideType
}: Props) => {
  return (
    <>
      <div
        className={
          isSubCategories
            ? 'input-dropdown subcategory'
            : isSubCategoriesCompany
            ? 'input-dropdown subcategory-company'
            : 'input-dropdown'
        }
      >
        <div className="actions">
          {!!hasDragIcon && <DragIcon />}
          {!!galleryModal && slideType === 'old' && <Image onClick={() => galleryModal(id)} />}
        </div>
        <input
          name={name}
          autoComplete="off"
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e, id)}
        />
        <div className="actions">
          <Trash2
            onClick={() => {
              confirmModal!(id, confirmName);
            }}
          />
          {showDropdown(id) ? (
            <ChevronUp onClick={() => setDropdownId!('')} />
          ) : (
            <ChevronDown onClick={() => setDropdownId!(id)} />
          )}
        </div>
      </div>
      {showDropdown(id) && children}
    </>
  );
};

export default InputDropdown;
