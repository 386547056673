import React from 'react';
import { Button } from 'components';
import { ChevronDown, Check } from 'react-feather';
import { Menu, MenuItem, MenuDivider } from '@szhsin/react-menu';
import { SelectOption } from '../../dynamicInputs/types';
import classNames from 'classnames';

interface actionOption {
  icon: React.ReactElement;
  name: string;
  id: string;
}

type Props = {
  value: string;
  onChange: (option: SelectOption) => void;
  options: SelectOption[];
  actionOptions?: actionOption[];
  onClickActionOption?: (action: string) => void;
  portalTarget?: string;
};

const SelectDropdown = ({ value, onChange, options, portalTarget, actionOptions, onClickActionOption }: Props) => {
  const handleSetOption = (option: SelectOption) => {
    onChange(option);
  };

  const portal = portalTarget ? { target: document.querySelector(portalTarget) } : undefined;

  return (
    <div className="select-dropdown">
      <Menu
        menuButton={<Button text={value} icon={<ChevronDown />} capitalize small iconOnRight variant="alternative" />}
        direction="bottom"
        align="end"
        menuClassName="select-dropdown"
        portal={portal}
      >
        <ul className="select-dropdown-body">
          {options.map(option => {
            const isSelected = value === option.id;
            return (
              <MenuItem key={`dropdown-option-${option.id}`} className="select-dropdown-item-wrapper">
                <div
                  role="button"
                  className={classNames('body-2 select-dropdown-item', { selected: isSelected })}
                  onClick={() => handleSetOption(option)}
                  onKeyPress={() => handleSetOption(option)}
                  tabIndex={0}
                >
                  {isSelected && <Check />}
                  {option.name}
                </div>
              </MenuItem>
            );
          })}
          {actionOptions && (
            <>
              <MenuDivider className="select-dropdown-divider" />
              {actionOptions.map((action, index) => (
                <MenuItem key={`dropdown-action-option-${index}`} className="select-dropdown-item-wrapper">
                  <div
                    role="button"
                    className="body-2 select-dropdown-item action-item"
                    onClick={() => onClickActionOption?.(action.id)}
                    onKeyPress={() => onClickActionOption?.(action.id)}
                    tabIndex={0}
                  >
                    {action.icon}
                    {action.name}
                  </div>
                </MenuItem>
              ))}
            </>
          )}
        </ul>
      </Menu>
    </div>
  );
};

export default React.memo(SelectDropdown);
