import React, { useEffect, useState } from 'react';
import {
  AutoComplete,
  DiversityAndInclusionImage,
  GlobalReach,
  ImagePicker,
  Input,
  InputCandidate,
  InputPartner,
  InputRelevantExperience,
  InputSourceEnvironment,
  LayoutPicker,
  ReachText,
  RolesList,
  SearchInformation,
  SendToClientForm,
  ShareDeckForm,
  TagInput,
  Textarea,
  ThemePicker,
  VideoPicker,
  FilePicker
} from 'components';
import { InputsEnum } from 'components/dynamicInputs/types';

interface Props {
  handler: any;
}

const DynamicForm = ({ handler }: Props) => {
  const [pristine, setPristine] = useState({});

  const onBlur = (key: string) => () => {
    setPristine({ ...pristine, [key]: false });
  };

  useEffect(() => {
    if (handler && handler.schema) {
      setPristine(Object.keys(handler.schema).reduce((acc, cur) => ({ ...acc, [cur]: true }), {}));
    }
  }, [handler]);

  if (!handler || handler.data === null) {
    return null;
  }

  return (
    <>
      {Object.keys(handler.schema).map(key => (
        <React.Fragment key={key}>
          {
            {
              [InputsEnum.GLOBAL_REACH]: <GlobalReach {...handler.schema[key]} value={handler.data[key]} />,
              [InputsEnum.AUTOCOMPLETE]: (
                <AutoComplete {...handler.schema[key]} value={handler.data[key]} onChange={handler.setFormData(key)} />
              ),
              [InputsEnum.DIVERSITY_AND_INCLUSION]: (
                <DiversityAndInclusionImage {...handler.schema[key]} value={handler.data[key]} />
              ),
              [InputsEnum.FILE_PICKER]: (
                <FilePicker {...handler.schema[key]} value={handler.data[key]} onChange={handler.setFormData(key)} />
              ),
              [InputsEnum.IMAGE_PICKER]: handler.data.title !== 'Inside Track' && (
                <ImagePicker {...handler.schema[key]} value={handler.data[key]} onChange={handler.setFormData(key)} />
              ),
              [InputsEnum.INPUT]: (
                <Input
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  onBlur={onBlur(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.INPUT_CANDIDATE]: (
                <InputCandidate
                  {...handler.schema[key]}
                  candidatesFeedback={handler?.extraParams?.candidatesFeedback}
                  value={handler.data[key]}
                  onBlur={onBlur(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.INPUT_PARTNER]: (
                <InputPartner
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  onBlur={onBlur(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.INPUT_RELEVANT_EXPERIENCE]: (
                <InputRelevantExperience
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  onBlur={onBlur(key)}
                  onChange={handler.setFormData(key)}
                  slideType="old"
                />
              ),
              [InputsEnum.INPUT_RELEVANT_EXPERIENCE_NEW]: (
                <InputRelevantExperience
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  onBlur={onBlur(key)}
                  onChange={handler.setFormData(key)}
                  slideType="new"
                />
              ),
              [InputsEnum.INPUT_SOURCE_ENVIRONMENT]: (
                <InputSourceEnvironment
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  onBlur={onBlur(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.LAYOUT_PICKER]: (
                <LayoutPicker {...handler.schema[key]} value={handler.data[key]} onChange={handler.setFormData(key)} />
              ),
              [InputsEnum.REACH_TEXT]: (
                <ReachText {...handler.schema[key]} value={handler.data[key]} onChange={handler.setFormData(key)} />
              ),
              [InputsEnum.TEXTAREA]: (
                <Textarea
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  onBlur={onBlur(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.THEME_PICKER]: <ThemePicker value={handler.data[key]} onChange={handler.setFormData(key)} />,
              [InputsEnum.VIDEO_PICKER]: (
                <VideoPicker {...handler.schema[key]} value={handler.data[key]} onChange={handler.setFormData(key)} />
              ),
              [InputsEnum.TAG_INPUT]: <TagInput value={handler.data[key]} onChange={handler.setFormData(key)} />,
              [InputsEnum.SEARCH_INFO]: (
                <SearchInformation value={handler.data[key]} onChange={handler.setFormData(key)} />
              ),
              [InputsEnum.ROLES_LIST]: <RolesList value={handler.data[key]} onChange={handler.setFormData(key)} />,
              [InputsEnum.SEND_CLIENT]: (
                <SendToClientForm value={handler.data[key]} onChange={handler.setFormData(key)} />
              ),
              [InputsEnum.SHARE_DECK]: <ShareDeckForm value={handler.data[key]} onChange={handler.setFormData(key)} />
            }[handler.schema[key].input as InputsEnum]
          }
        </React.Fragment>
      ))}
    </>
  );
};

export default DynamicForm;
