import React from 'react';
import useWindowSize from '@revolt-digital/use-window-size';
import { BREAKPOINT_TABLET_LG } from '../../../constants/breakpoints';
import Expansible from '../../../components/shared/expansible';
import Carousel from '../../shared/carousel';
import Sanitized from '../../shared/sanitized';
import SimpleCompanyCard from '../../shared/simpleCompanyCard';
import Shape1 from '../../shared/shape1';
import Shape2 from '../../shared/shape2';
import Shape3 from '../../shared/shape3';

type CardsArray = {
  image: string;
  name: string;
  position: string;
};

type Props = {
  title: string;
  content: string;
  highlight: string;
  items: CardsArray[];
};

const LayoutSourceEnvironment = ({ title, content, highlight, items }: Props) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= BREAKPOINT_TABLET_LG;

  return (
    <div className="layout-source-environment">
      <Shape1 />
      <Shape2 />
      <Shape3 />

      <div className="flex-wrapper">
        <div className="left">
          <div>
            <h3>{title}</h3>
            <Expansible classes="body light">
              <Sanitized html={content} />
            </Expansible>
          </div>
          <hr />
          <h5 className="light">{highlight}</h5>
        </div>

        <div className="right">
          {items?.length > 0 && (
            <Carousel
              paginationType="bullets"
              dynamicBullets
              slidesPerView={isMobile ? 2 : 3}
              grid={{ rows: 3, fill: 'row' }}
            >
              {items?.map((member, index) => (
                <SimpleCompanyCard key={index} data={member} />
              ))}
            </Carousel>
          )}
        </div>
      </div>
    </div>
  );
};

export default LayoutSourceEnvironment;
