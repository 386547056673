import React from 'react';
import { toast } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';

injectStyle();

const useToast = () => {
  const notify = (message: string, icon?: React.ReactNode) => {
    toast(message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
      theme: 'dark',
      icon: () => icon,
      role: 'alert'
    });
  };

  return notify;
};

export { useToast };
