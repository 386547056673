import React from 'react';
import { Button } from 'components';
import Base from '../base';
import { Props } from '../types';

const CandidatePositiveFeedback = ({ closing, onClose, params }: Props) => (
  <Base
    header="Client Feedback"
    closing={closing}
    onClose={onClose}
    size="md"
    footer={<Button text="Close" variant="secondary" onClick={onClose} />}
  >
    <h5>{params.candidateName} liked by the client</h5>
    <br />
    <p className="body-1">Comment: {params.comment || 'no comments were added'}</p>
  </Base>
);

export default CandidatePositiveFeedback;
