import React, { FC } from 'react';

type Props = {
  title?: string;
  children: React.ReactNode;
  buttons: React.ReactNode;
};

const Card: FC<Props> = ({ title, children, buttons }) => {
  return (
    <div className="crop-gallery-card">
      <div className="card-body">
        <h6 className="card-title">{title}</h6>
        {children}
      </div>
      <div className="card-buttons-container">{buttons}</div>
    </div>
  );
};

export default Card;
