import React from 'react';
import { Button } from 'components';
import reasons from 'constants/feedback';
import Base from '../base';
import { Props } from '../types';

const CandidateNegativeFeedback = ({ closing, onClose, params }: Props) => (
  <Base
    header="Client Feedback"
    closing={closing}
    onClose={onClose}
    size="md"
    footer={<Button text="Close" onClick={onClose} />}
  >
    <h5>{params.candidateName} liked by the client</h5>
    <br />
    {params.reasons.length > 0 && (
      <p>
        <strong>Reasons:</strong> {params.reasons.map((reason: any) => reasons[`${reason.reason_id}`]).join(', ')}
      </p>
    )}
    {!!params.comment && <p className="body-1">Reason: {params.comment}</p>}
  </Base>
);

export default CandidateNegativeFeedback;
