import React from 'react';

const InfoChip = ({ title, value, icon, secondary = false }: any) => {
  return (
    <div className="card">
      {secondary ? (
        <>
          <small>{title}</small>
          <p>{value}</p>
        </>
      ) : (
        <>
          <div className="card-data">
            {icon}
            <p>{value}</p>
          </div>
          <small>{title}</small>
        </>
      )}
    </div>
  );
};
export default InfoChip;
