import React from 'react';
import Base from '../base';
import { Props } from '../types';

const Experience = ({ closing, onClose }: Props) => {
  return (
    <Base closing={closing} onClose={onClose}>
      <div className="modal-experience">Experience</div>
    </Base>
  );
};

export default Experience;
