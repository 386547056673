import React from 'react';
import { RoleType } from 'components/dynamicInputs/types';

type Props = {
  items: RoleType[];
};

const Roles = ({ items }: Props) => {
  return (
    <div className="roles">
      <ul className="container">
        {items?.map((role, index) => (
          <li className="body-2" key={index}>
            <div>
              <span className="body-2">{role.name}</span>
              <span className="body-2">{role.count}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Roles;
