import React from 'react';
import ReactDOM from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import App from 'app';
import NotificationProvider from './hooks/useNotifications';
import customHistory from './constants/browserHistory';
import reportWebVitals from './reportWebVitals';
import './styles/global.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
window.partner = true;

// @todo: momentarily disabled because it affects the correct functioning of react-dnd
// @todo: remove as any when the router lib get updated
root.render(
  // <React.StrictMode>
  <HistoryRouter history={customHistory as any}>
    <NotificationProvider>
      <App />
    </NotificationProvider>
  </HistoryRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
