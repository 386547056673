import joi from 'joi';
import { InputsEnum } from 'components/dynamicInputs/types';

export default {
  bodyText: {
    value: '',
    label: 'Body Text',
    limit: 500,
    input: InputsEnum.REACH_TEXT,
    joi: joi.string().required()
  }
};
