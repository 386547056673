import React from 'react';
import { Edit2, MoreHorizontal, Plus, Trash2 } from 'react-feather';
import { Layouts } from 'constants/layouts';
import { ModalsList } from 'constants/modals';
import { Values } from 'hooks/useModal';
import { DropWrapper, DragWrapper, DropdownButton } from 'components';
import DragIcon from 'assets/icons/DragIcon';
import { SlideType } from 'types/deck';
import Slide from './slide';

type Props = {
  deck: any;
  id: string;
  name: string;
  slides: SlideType[];
  dragStartId: string;
  modal: Values;
};

const SlideSection = ({ deck, id, name, slides, dragStartId, modal }: Props) => {
  const handleAddSlide = () => {
    modal.open(
      ModalsList.CHOOSE_LAYOUT,
      (data: any) => {
        if (data) {
          deck.addSlide(id, data.layout, data.name);
        }
      },
      { unrepeatable: deck.unrepeatable }
    );
  };

  const handleEditSectionName = () => {
    modal.open(
      ModalsList.SECTION,
      (data: any) => {
        if (data) {
          deck.editSectionsName(id, data.name);
        }
      },
      { isEdit: true }
    );
  };

  const handleDeleteSection = () => {
    modal.open(
      ModalsList.DIALOG_CONFIRM,
      (confirmed: any) => {
        if (confirmed) {
          deck.removeSection(id);
        }
      },
      { title: `Delete Section` }
    );
  };

  const SectionMenu = [
    { text: 'Add slide', icon: <Plus />, onClick: handleAddSlide },
    { text: 'Edit section’s name', icon: <Edit2 />, onClick: () => handleEditSectionName() },
    { text: 'Delete', icon: <Trash2 />, onClick: () => handleDeleteSection() }
  ];

  return (
    <div className="slides-group">
      <div className="header">
        <div className="slide-name">
          <DragIcon />
          <span className="body">{name}</span>
        </div>
        <DropdownButton isPortal button={<MoreHorizontal />} items={SectionMenu} />
      </div>

      <DropWrapper droppableId={id} direction="vertical" dropZoneHeight={140}>
        {slides?.map((child, index) => {
          return (
            <DragWrapper key={child.uuid} draggableId={child.uuid} index={index} isDragDisabled={index === 0}>
              <Slide
                sectionId={id}
                deck={deck}
                child={child}
                imagePreview={Layouts[child.layoutId].previewImage}
                indexElement={index}
                dragStartId={dragStartId}
                modal={modal}
              />
            </DragWrapper>
          );
        })}
      </DropWrapper>
    </div>
  );
};

export default SlideSection;
