import joi from 'joi';
import { InputsEnum, TextInputTypes } from 'components/dynamicInputs/types';

export default {
  title: {
    value: '(Client Name)',
    label: 'Title',
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    limit: 40,
    joi: joi.string().required()
  },
  content: {
    value:
      '<p>Welcome to Kingsley Gate! This dynamic website has been designed exclusively for you to review our unique offerings. Scroll through to access our relevant experience, expertise, and credentials.&nbsp;</p><p><br></p><p>We look forward to working with (Company Name) to fulfill your executive recruiting needs.</p>',
    label: 'Description',
    input: InputsEnum.REACH_TEXT,
    limit: 800,
    joi: joi.string().required()
  },
  signatures: {
    value: [],
    label: 'Signatures (Max. 4)',
    input: InputsEnum.AUTOCOMPLETE,
    isMulti: true,
    limit: 4,
    options: [],
    endpoint: '/partners/search',
    joi: joi.object().required()
  },
  source: {
    value:
      'https://kgp-synclink-api-dev.s3.us-east-2.amazonaws.com/decks/33/Frame1000001973-1_504x581_72x83_0_1664305943867.jpg',
    label: 'Image',
    input: InputsEnum.IMAGE_PICKER,
    joi: joi.string().required()
  }
};
