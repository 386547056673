import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { Copy, BarChart2, Edit2, Eye, FileText, /*Droplet*/ Info, Share2 } from 'react-feather';
import { useLocalStorage } from '@rehooks/local-storage';
import { ModalsList } from 'constants/modals';
import { languages } from 'constants/locales';
import useApi, { Methods } from 'hooks/useApi';
import { useToast } from 'hooks/useToast';
import { useModal } from 'hooks/useModal';
import { Button, CommentsMenu, DropdownButton, Tooltip } from 'components';
import { LogoContext } from 'app';

type Props = {
  deck: any;
  slideSelected: any;
  onSave: () => Promise<unknown>;
  onChangeLanguageState: any;
  onChangeDeckState: any;
};

const DeckToolbar = ({ deck, onSave, slideSelected, onChangeLanguageState, onChangeDeckState }: Props) => {
  const modal = useModal();
  const langApi = useApi();
  const loadDeck = useApi();
  const getCommentApi = useApi();
  const sendCommentApi = useApi();
  const notify = useToast();
  const { id: deckId } = useParams();
  const [user] = useLocalStorage('user', { id: 0 });
  const loaded = !!deck.data;
  const { logo, setLogo } = useContext(LogoContext);
  const [languange, setLanguage] = useState('');
  const fetchDeck = useApi();

  console.log('Deck', deck);

  const handleDeckInformation = (defaultIndex?: number) => {
    console.log(logo);
    modal.open(
      ModalsList.DECK_INFORMATION,
      (data: any) => {
        if (data) {
          setLogo('1');
          deck.updateData({
            ...deck.data,
            sections: deck.sections,
            leadPartners: data.search.searchInformation.leadPartner,
            job_title: data.search.searchInformation.jobTitle
          });
          onChangeDeckState(true);
          fetchDeck.fetchData({
            endpoint: `/decks/${deckId}`
          });
        }
      },
      { deckId, defaultIndex }
    );
  };

  useEffect(() => {
    if (fetchDeck?.response) {
      deck.updateData(fetchDeck?.response);
      onChangeDeckState(false);
    }
  }, [fetchDeck?.response]);

  const handleDeckAnalytics = () => {
    modal.open(ModalsList.DECK_ANALYTICS, () => {}, { deckId, title: deck.data.job_title });
  };

  const handleDuplicate = () => {
    modal.open(ModalsList.DECK_DUPLICATE, () => {}, deckId);
  };

  const handleShare = () => {
    modal.open(ModalsList.SHARE_DECK, () => {}, deckId);
  };

  const handlePreview = () => {
    function openSyncLink() {
      window.open(`${process.env.REACT_APP_KGP}/deck/${deckId}/${user.id}?preview=true`, '_blank');
    }

    if (deck.hasChanges) {
      onSave().then(() => openSyncLink());
    } else {
      openSyncLink();
    }
  };

  const handleSendToClient = () => {
    deck.hasChanges && onSave();
    modal.open(ModalsList.SEND_CLIENT, () => {}, {
      deck_id: deckId,
      user_id: user.id,
      isEdit: false
    });
  };

  const setLang = (lang: string) => () => {
    setLanguage(lang);
    onChangeLanguageState(true);
    loadDeck.fetchData({ endpoint: `/decks/${deckId}?lang=${lang}` });
  };

  useEffect(() => {
    if (loadDeck?.response) {
      deck.updateData(loadDeck?.response);
      langApi.fetchData(
        {
          endpoint: '/decks/updateLanguage',
          method: Methods.POST
        },
        { deckid: +deckId!, lang: languange }
      );
    }
  }, [loadDeck?.response]);

  useEffect(() => {
    if (langApi.response) {
      onChangeLanguageState(false);
      onSave();
    }
  }, [langApi?.response]);

  const getComments = async () => {
    await getCommentApi.fetchData({
      endpoint: `/decks/comments/${deckId}`,
      method: Methods.GET
    });
  };

  useEffect(() => {
    if (getCommentApi.response) {
      console.log('Un Authoriseze ', getCommentApi.response);
    }
  }, [getCommentApi.response]);

  const sendComment = async (comment: string) => {
    await sendCommentApi.fetchData(
      {
        endpoint: `/decks/comment`,
        method: Methods.POST
      },
      { uuid: slideSelected.uuid, createdBy: user.id, comment }
    );

    await getComments();
  };

  const fileMenu = [
    { text: 'SyncLink analytics', icon: <BarChart2 />, onClick: handleDeckAnalytics },
    { text: 'SyncLink Information', icon: <Info />, onClick: () => handleDeckInformation() },
    { text: 'Duplicate it', icon: <Copy />, onClick: handleDuplicate },
    {
      text: 'Written in',
      subItems: languages.map(item => ({
        selected: deck.data && item.value === deck.data.language,
        text: item.label,
        onClick: setLang(item.value)
      }))
    }
  ];

  useEffect(() => {
    if (langApi.response) {
      notify('Deck base language updated');
    }
  }, [langApi.response]);

  useEffect(() => {
    getComments();
  }, []);

  return (
    <div className="deck-toolbar">
      <div className="info">
        <div className="deck-name">
          <span className="body">{deck?.data?.job_title || ' '}</span>
          {!!deck?.data?.job_title && (
            <Tooltip value="Rename">
              <Button
                variant="alternative"
                contrast
                small
                icon={<Edit2 />}
                onClick={() => handleDeckInformation(1)}
                disabled={!loaded}
              />
            </Tooltip>
          )}
        </div>
        <div
          className={classnames('menu-container', {
            disabled: !loaded || deck.saving,
            clean: !deck.hasChanges
          })}
        >
          <DropdownButton isPortal button={<span>File</span>} items={fileMenu} offsetX={-16} offsetY={8} />
          <span
            onClick={() => {
              onSave();
            }}
          >
            Save
          </span>
        </div>
      </div>
      <div className="buttons">
        <div className="buttons-inner">
          <Tooltip value="Comments">
            <CommentsMenu
              data={getCommentApi?.response?.items}
              sendComment={sendComment}
              slideSelected={slideSelected}
              loading={{ get: getCommentApi.isLoading, send: sendCommentApi.isLoading }}
              disabled={!loaded}
            />
          </Tooltip>
          <Tooltip value="Share">
            <Button variant="alternative" contrast={true} icon={<Share2 />} onClick={handleShare} disabled={!loaded} />
          </Tooltip>
          <Tooltip value="Preview">
            <Button
              variant="alternative"
              contrast={true}
              icon={<Eye />}
              onClick={handlePreview}
              disabled={!loaded || deck.saving}
            />
          </Tooltip>
        </div>
        <Button
          onClick={handleSendToClient}
          text="Send to client"
          variant="secondary"
          contrast={true}
          icon={<FileText />}
          disabled={!loaded || deck.saving}
        />
      </div>
    </div>
  );
};

export default DeckToolbar;
