import React from 'react';
import Button from 'components/common/button';
import Base from '../base';
import { Props } from '../types';

const DialogConfirm = ({ closing, onClose, params }: Props) => {
  return (
    <Base
      onClose={onClose}
      closing={closing}
      size="sm"
      footer={
        <>
          <Button text="Cancel" variant="secondary" onClick={() => onClose()} />
          <Button text="Delete" onClick={() => onClose(true)} />
        </>
      }
    >
      <div className="modal-dialog-confirm">
        <h6>{params.title}</h6>
        <p>This action cannot be undone</p>
      </div>
    </Base>
  );
};

export default DialogConfirm;
