import React from 'react';
import classnames from 'classnames';
import { useModal } from 'hooks/useModal';
import { ModalsList } from '../../../constants/modals';
import Expansible from '../../../components/shared/expansible';
import Sanitized from '../../shared/sanitized';
import PlayButton from '../../shared/playButton';
import BGMosaic from '../../shared/bgMosaic';

type Props = {
  title: string;
  content: string;
  source: string;
  media: 'image' | 'video';
  orientation: 'start' | 'end';
  video: {
    url: string;
    thumbnail: string;
  };
  videoText?: string;
  selected: boolean;
};

const LayoutMedia = ({ title, content, source, orientation, media, video, videoText }: Props) => {
  const modal = useModal();

  const handleClick = () => {
    modal.open(ModalsList.VIDEO_MODAL, () => {}, { url: video.url, videoText });
  };

  return (
    <div className={classnames('layout-media', media, orientation)}>
      {media === 'video' && <BGMosaic />}

      <div className={classnames('flex-wrapper', { reverse: orientation === 'end' })}>
        <div className="left">
          <h3>{title}</h3>
          <Expansible classes="body expansible">
            <Sanitized html={content} />
          </Expansible>
        </div>
        <div className="right">
          {media === 'image' ? (
            <div className="image-container">
              <img src={source} style={{ height: '100%' }} />
            </div>
          ) : (
            <div className="video">
              <div className="container" data-event="click" data-action="play-video" onClick={handleClick}>
                <img className="video-preview" src={video?.thumbnail} />
                <div className="play-video">
                  <PlayButton />
                </div>
                {!!videoText && (
                  <div className="video-text">
                    <h6>{videoText}</h6>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LayoutMedia;
