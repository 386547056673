import React from 'react';

type Props = {
  text: number | string;
};

const Badge = ({ text }: Props) => {
  return <span className="badge body-2">{text}</span>;
};

export default Badge;
