import React from 'react';

type Props = {
  data: { image: string; name: string; position: string };
};

const SimpleCompanyCard = ({ data }: Props) => {
  const { image, name } = data;

  return (
    <div className="simple-company-card">
      <div className="card" title={name} data-event="click" data-action="partner-card-detail">
        <div>{!image ? <div className="name">{name}</div> : <img src={image} />}</div>
      </div>
    </div>
  );
};

export default SimpleCompanyCard;
