import React, { useEffect } from 'react';
import schema from 'schemas/modals/shareDeck';
import useFormHandler from 'hooks/useFormHandler';
import { useToast } from 'hooks/useToast';
import useApi, { Methods } from 'hooks/useApi';
import { Button, DynamicForm } from 'components';
import Base from '../base';
import { Props } from '../types';
import useLocalStorage from '@rehooks/local-storage';

const ShareDeck = ({ closing, onClose, params }: Props) => {
  const notify = useToast();
  const saveApi = useApi();
  const shareApi = useApi();
  const getApi = useApi();
  const formHandler = useFormHandler(schema);
  const newUsers = formHandler.data?.shareDeck?.newSharedUsers?.length > 0;
  const saveActive = formHandler.data?.shareDeck?.saveActive;
  const isLoading = saveApi.isLoading || getApi.isLoading || shareApi.isLoading;
  const [user] = useLocalStorage<any>('user');

  const handleSend = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.disabled = true;
    const newSharedUsers = formHandler.data.shareDeck.newSharedUsers;

    shareApi.fetchData(
      { endpoint: '/decks/share', method: Methods.POST },
      {
        deckid: params,
        name: user.name,
        email: user.email,
        partners: newSharedUsers.map((user: any) => ({
          id: user.id,
          permission: user.permission,
          message: user.message
        }))
      }
    );
  };

  const handleSave = () => {
    const sharedUsers = formHandler.data.shareDeck.sharedUsers;

    saveApi.fetchData(
      { endpoint: '/decks/partners/save', method: Methods.POST },
      {
        deckid: params,
        partners: sharedUsers
          .filter((item: any) => item.permission !== 'owner')
          .map((user: any) => ({
            id: user.id,
            permission: user.permission
          }))
      }
    );
  };

  useEffect(() => {
    if (getApi.loaded) {
      formHandler.setFullForm({
        shareDeck: {
          sharedUsers: getApi.response.items.filter((e: any) => !!e), // @todo: remove it when Andres fix the bug of receiving array with a null inside
          newSharedUsers: [],
          saveActive: false
        }
      });
    }
  }, [getApi.loaded]);

  useEffect(() => {
    if (!shareApi.isLoading && shareApi.response) {
      notify('Deck shared');
      onClose();
    }
  }, [shareApi.isLoading]);

  useEffect(() => {
    if (!saveApi.isLoading && saveApi.response) {
      notify('Information saved');
    }
  }, [saveApi.isLoading]);

  useEffect(() => {
    getApi.fetchData({
      endpoint: `/decks/partners/${params}`,
      method: Methods.GET
    });
  }, []);

  return (
    <Base
      header="Share SyncLink"
      size="md"
      closing={closing}
      onClose={onClose}
      footer={
        newUsers ? (
          <Button text="Send" disabled={isLoading} onClick={handleSend} />
        ) : (
          <Button text="Save" disabled={!saveActive || isLoading} onClick={handleSave} />
        )
      }
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default ShareDeck;
