import React from 'react';
import classnames from 'classnames';
import Background from '../../../components/shared/background';

type Props = {
  title: string;
  selected: boolean;
  order?: number;
};

const LayoutCover = ({ title, selected, order }: Props) => (
  <div className={classnames('layout-cover', { selected })}>
    <Background />

    <div className={classnames('title-container', { first: Number(order) === 1 })}>
      <h2>{title}</h2>
    </div>

    {order === 1 && !window.partner && (
      <svg className="scroll-anim" viewBox="0 0 28 69">
        <rect x="1.86651" y="1.86651" width="24.267" height="39.4021" rx="12.1335" strokeWidth="3.73302" />
        <rect x="12.1075" y="8.32422" width="3.78378" height="7.56757" rx="1.89189" />
        <path
          d="M5.29773 50.3242L12.2671 57.2936C13.4334 58.4599 15.3243 58.4599 16.4905 57.2936L23.4599 50.3242"
          strokeWidth="3.73302"
          strokeLinecap="round"
        />
      </svg>
    )}
  </div>
);
export default LayoutCover;
