/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useState } from 'react';
import { VideoPlayer, Button, RecordingTimer, LoadingLogo } from 'components';
import { useReactMediaRecorder } from 'react-media-recorder';
import { MAX_RECORDING_TIME } from 'constants/dynamicInputs';
import VideoSnapshot from 'video-snapshot';
import Webcam from 'react-webcam'; // package just to display a preview (with not recording this a small package)
import useApi, { Methods } from 'hooks/useApi';
import useSupportedMimeType from 'hooks/useSupportedMimeType';
import Base from '../base';
import { Props } from '../types';
import classNames from 'classnames';

const titlesByStatus: { [key: string]: string } = {
  idle: 'Record your video presentation',
  recording: 'Recording...',
  stopped: 'Preview your presentation video'
};

let interval: ReturnType<typeof setInterval>;

const VideoPreview = React.memo(({ stream }: { stream: MediaStream | null }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) {
    return null;
  }

  return <video ref={videoRef} width="100%" autoPlay className="video-recorder-camera-previewer" />;
});

let completeDuration = 0;

const VideoRecorder = ({ closing, onClose, params }: Props) => {
  const [file, setFile] = useState<any>(null);
  const [videoPrev, setVideoPrev] = useState<any>(null);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [isLoading, setLoading] = useState(false);
  const { response, fetchData } = useApi();
  const [video, setVideo] = useState<Blob | null>(null);
  const { deckId, isRecord } = params;

  const inputRef = useRef<HTMLInputElement>({} as HTMLInputElement);

  const FileChange = (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    const fileBlob = new Blob([fileObj], { type: 'video/mp4' });
    if (!fileObj) {
      return;
    }
    event.target.value = null;
    let reader = new FileReader();
    reader.readAsDataURL(fileObj);
    reader.onloadend = () => {
      setVideoPrev(reader.result);
    };
    setFile(fileObj);
    getSnapshotFromVideo(fileBlob);
  };

  const onStopRecording = (_blobUrl: string, blob: Blob) => {
    setVideo(blob);
    getSnapshotFromVideo(blob);
  };

  const getSnapshotFromVideo = async (blob: Blob) => {
    const snapshoter = new VideoSnapshot(blob);
    const previewSrc = await snapshoter.takeSnapshot();
    setPreviewImage(previewSrc);
  };

  const { mimeType, videoExtension } = useSupportedMimeType();

  const { status, previewStream, mediaBlobUrl, startRecording, stopRecording, clearBlobUrl } = useReactMediaRecorder({
    video: true,
    askPermissionOnMount: false,
    onStop: onStopRecording
  });

  useEffect(() => {
    if (status === 'recording') {
      let time = 0;
      let startTime = Date.now();

      interval = setInterval(() => {
        if (time >= MAX_RECORDING_TIME) {
          stopRecording();
        }

        time++;
      }, 1000);

      return () => {
        completeDuration = (Date.now() - startTime) / 1000;
        clearInterval(interval);
      };
    }
  }, [status]);

  useEffect(() => {
    return () => {
      clearBlobUrl();
    };
  }, []);

  const handleCancelVideoRecord = () => {
    onClose?.();
  };

  const handleTryAgain = () => {
    clearBlobUrl();
    setVideo(null);
    setFile(null);
  };

  const getSignedUrl = async () => {
    setLoading(true);

    await fetchData(
      {
        endpoint: '/decks/video/upload_url',
        method: Methods.POST
      },
      {
        deckid: deckId,
        fileName: `${Date.now()}_${deckId}`,
        extension: file ? 'mp4' : videoExtension
      }
    );
  };

  const uploadVideo = async (uploadUrl: string, videoFile = video) => {
    const rawResponse = await fetch(uploadUrl, {
      method: Methods.PUT,
      headers: {
        'Content-Type': videoFile ? 'mp4' : mimeType
      },
      body: videoFile
    });

    getSnapshotFromVideo(videoFile!);
    setLoading(false);

    if (rawResponse.status === 200) {
      onClose?.({ urlVideo: response.urlVideo, urlImage: previewImage });
    }
  };

  useEffect(() => {
    if (response && response.urlUpload) {
      file ? uploadVideo(response.urlUpload, file) : uploadVideo(response.urlUpload);
    }
  }, [response]);

  return (
    <Base header={isRecord ? 'Video Recording' : 'Upload Video'} closing={closing} onClose={onClose} size="lg">
      {isLoading && (
        <div className={classNames('loading-cover', { isLoading })}>
          <LoadingLogo />
        </div>
      )}
      <div className="video-recorder">
        {isRecord ? (
          <>
            <h2 className="body video-recorder-title">{titlesByStatus[status]}</h2>
            {status === 'idle' && (
              <div>
                <Webcam width="100%" className="video-recorder-camera-previewer" audio={false} />
                <div className="video-recorder-control spaced">
                  <div>
                    <Button text="CANCEL" onClick={handleCancelVideoRecord} variant="secondary" />
                  </div>
                  <div>
                    <Button text="START RECORDING" onClick={startRecording} variant="primary" />
                  </div>
                </div>
              </div>
            )}

            {status === 'recording' && (
              <div>
                <VideoPreview stream={previewStream} />
                <div className="video-recorder-recording-time">
                  <RecordingTimer maxDurationTime={MAX_RECORDING_TIME} />
                </div>
                <div className="video-recorder-control centered">
                  <div>
                    <Button text="STOP" onClick={stopRecording} variant="primary" />
                  </div>
                </div>
              </div>
            )}
            {status === 'stopped' && mediaBlobUrl && (
              <div>
                <VideoPlayer videoUrl={mediaBlobUrl} videoDuration={completeDuration} />
                <div className="video-recorder-control spaced">
                  <div>
                    <Button text="Try Again" onClick={handleTryAgain} variant="secondary" disabled={isLoading} />
                  </div>
                  <div>
                    <Button text="Upload" onClick={getSignedUrl} variant="primary" disabled={isLoading} />
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="file-select">
            {file && videoPrev ? (
              <div>
                <VideoPlayer videoUrl={videoPrev} videoDuration={completeDuration} />
                <div className="video-recorder-control spaced">
                  <div>
                    <Button text="Try Again" onClick={handleTryAgain} variant="secondary" disabled={isLoading} />
                  </div>
                  <div>
                    <Button text="Upload" onClick={getSignedUrl} variant="primary" disabled={isLoading} />
                  </div>
                </div>
              </div>
            ) : (
              <div className="video-uploader">
                <label htmlFor="files" className="video-previewer">
                  Upload a mp4 video file
                </label>
                <input
                  style={{ display: 'none' }}
                  id="files"
                  hidden
                  className="video-previewer"
                  ref={inputRef}
                  type="file"
                  onChange={FileChange}
                  accept="video/mp4"
                />
                <Button
                  text="Select Video"
                  variant="primary"
                  onClick={() => {
                    inputRef.current.click();
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Base>
  );
};

export default VideoRecorder;
