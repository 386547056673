import React from 'react';
import classNames from 'classnames';
import { Img } from 'components';

type Props = {
  themes: any;
  onClick: (key: any) => void;
  selected: boolean;
};

const ThemeCard = ({ themes, onClick, selected }: Props) => {
  return (
    <div
      onClick={onClick}
      className={classNames('theme-card-partner', {
        selected
      })}
    >
      <div className="preview-image">
        <Img src={themes.previewImage} />
      </div>
      <div className="footer">
        <span>{themes.title}</span>
      </div>
    </div>
  );
};

export default ThemeCard;
