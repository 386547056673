import React from 'react';
import Base from '../base';
import { Props } from '../types';

const SelectTheme = ({ closing, onClose }: Props) => {
  return (
    <Base closing={closing} onClose={onClose}>
      <div className="modal-select-theme">Select Theme</div>
    </Base>
  );
};

export default SelectTheme;
