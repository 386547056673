import React from 'react';
import { Play } from 'react-feather';

const PlayButton = () => (
  <div className="play-button">
    <Play />
  </div>
);

export default PlayButton;
