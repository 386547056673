import React, { ChangeEvent, useEffect, useState } from 'react';
import { Plus } from 'react-feather';
import { v4 as uuidv4 } from 'uuid';
import Categories from './categories';
import { Button, InputDropdown } from 'components';
import { useModal } from 'hooks/useModal';
import { InputRelevantExperienceProps } from '../types';
import { ModalsList } from 'constants/modals';

const InputRelevantExperience = ({ type, value, onChange, onBlur, slideType }: InputRelevantExperienceProps) => {
  const modal = useModal();
  const [selectedId, setSelectedId] = useState<string>('');

  const showDropdown = (id: string) => selectedId === id;

  useEffect(() => {
    console.log('valu::::', slideType);
  });

  const addCategory = () => {
    onChange([...value, { id: uuidv4(), label: 'Category', companies: [], subCategories: [] }]);
  };

  const deleteCategory = (id: string) => {
    onChange(value.filter(item => item.id !== id));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    const aux = value.map(category => {
      if (category.id === id) {
        category[e.target.name] = e.target.value;
      }

      return category;
    });

    onChange(aux);
  };

  const confirmModal = (categoryId: string, categoryName: string) => {
    modal.open(
      ModalsList.DIALOG_CONFIRM,
      (confirmed: any) => {
        if (confirmed) {
          deleteCategory(categoryId);
        }
      },
      { title: `Delete the category ${categoryName}` }
    );
  };

  return (
    <div className="category-dropdown input-wrapper">
      <Button
        text="Add category"
        small
        variant="primary"
        icon={<Plus />}
        disabled={value.length >= 6}
        onClick={addCategory}
      />
      {value.map((category, index) => (
        <InputDropdown
          key={index}
          id={category.id}
          name="label"
          value={category.label}
          onBlur={onBlur}
          onChange={handleChange}
          confirmName={category.label}
          confirmModal={confirmModal}
          setDropdownId={setSelectedId}
          showDropdown={showDropdown}
          slideType={slideType}
        >
          <Categories
            currentCategory={category}
            value={value}
            type={type}
            onBlur={onBlur}
            onChange={onChange}
            slideType={slideType}
          />
        </InputDropdown>
      ))}
    </div>
  );
};

export default InputRelevantExperience;
