import { useCallback, useEffect, useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import clone from 'just-clone';
import isEqual from 'lodash.isequal';
import { useParams } from 'react-router-dom';
import { DropResult } from 'react-beautiful-dnd';
import { BasicLayouts, LayoutKeys, Layouts, unrepeatableLayouts } from 'constants/layouts';
import useFormHandler from 'hooks/useFormHandler';
import { useToast } from 'hooks/useToast';
import useApi, { Methods } from 'hooks/useApi';
import { DeckInformation, SlideType, ParentType, SelectedType, SectionType } from 'types/deck';
import {
  addToList,
  insert,
  objectToArray,
  arrayToObject,
  removeFromList,
  reorderList,
  removeDuplicates
} from 'helpers/data';

const getUnrepeatable = (sections: ParentType) => {
  return removeDuplicates(
    objectToArray(sections).reduce(
      (acc: string[], cur: SectionType) => [...acc, ...cur.slides.map((slide: SlideType) => slide.layoutId)],
      []
    )
  ).reduce((acc: string[], cur: string) => {
    if (unrepeatableLayouts.includes(cur as BasicLayouts)) {
      acc.push(cur);
    }

    return acc;
  }, []);
};

const getDataSections = (data: DeckInformation) => {
  const sections = Array.isArray(data.sections) ? data.sections : objectToArray(data.sections);
  const aux = sections
    .sort((a: any, b: any) => a.position - b.position)
    .map((section: any) => ({
      ...section,
      slides: section.slides.sort((a: any, b: any) => a.position - b.position)
    }));

  return arrayToObject(aux);
};

export default () => {
  const { id: deckId } = useParams();
  const apiSave = useApi();
  const apiGet = useApi();
  const notify = useToast();
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [data, setData] = useState<DeckInformation>();
  const formHandler = useFormHandler();
  const [sectionOrder, setSectionOrder] = useState<string[]>([]);
  const [sections, setSections] = useState<ParentType>({});
  const [selected, setSelected] = useState<SelectedType>({ sectionId: '', slideId: null });
  const [isChange, setIsChange] = useState<boolean>(false);

  const unrepeatable = useMemo(() => {
    return getUnrepeatable(sections);
  }, [sections]);

  const isAnySlideSelected = selected.slideId !== null;

  const selectSlide = (sectionId: string, slideId: string | null) => {
    setSelected({ sectionId, slideId });
  };

  const addSection = (name: string) => {
    const slide: SlideType = {
      uuid: uuidv4(),
      layoutId: BasicLayouts.COVER,
      name: 'Cover'
    };
    const sectionKey = objectToArray(sections).length;

    setSections({
      ...sections,
      [sectionKey]: {
        name,
        slides: [slide],
        position: sectionKey + 1,
        uuid: uuidv4()
      }
    });
  };

  const editSectionsName = (id: string, name: string) => {
    const aux = clone(sections);
    aux[id].name = name;
    setSections(aux);
  };

  const removeSection = (id: string) => {
    const aux = clone(sections);
    id === selected.sectionId && setSelected({ sectionId: '', slideId: null });
    delete aux[id];

    const updatedSectionsIndex = Object.keys(aux).reduce((acc: ParentType, key, index) => {
      acc[index] = aux[key];
      aux[key].position = index + 1;

      return acc;
    }, {});

    setSectionOrder([...sectionOrder.filter(e => e !== id)].map((_, i) => String(i)));
    setSections(updatedSectionsIndex);
  };

  const addSlide = (id: string, layoutId: LayoutKeys, name: string) => {
    const aux = clone(sections);
    aux[id].slides = [...aux[id].slides, { uuid: uuidv4(), layoutId, name, hide: false }];
    setSections(aux);
  };

  const editSlideName = (sectionId: string, layoutId: string, name: string) => {
    const aux = clone(sections);
    aux[sectionId].slides = aux[sectionId].slides.map(item => {
      if (item.uuid === layoutId) {
        item.name = name;
      }
      return item;
    });

    setSections(aux);
  };

  const addSlideByIndex = (index: number, id: string, layoutId: LayoutKeys, name: string) => {
    const aux = clone(sections);
    let content0 =
      '<p>Welcome to Kingsley Gate! This dynamic website has been designed exclusively for you to review our unique offerings. Scroll through to access our relevant experience, expertise, and credentials.&nbsp;</p><p><br></p><p>We look forward to working with (Company Name) to fulfill your executive recruiting needs.</p>';
    const content = content0.replace('(Company Name)', data?.company_name || '(Company Name)');
    // const sub = data?.job_title;
    // const sub1 = sub?.slice(0, -7);
    // const content = content1.replace('(Role Name)', sub1 || '(Role Name)');
    if (layoutId === 'TEXT_IMAGE_SIGNATURE') {
      console.log('aux', sections);
      const source =
        'https://kgp-synclink-api-dev.s3.us-east-2.amazonaws.com/decks/33/Frame1000001973-1_504x581_72x83_0_1664305943867.jpg';
      let title = `Welcome, ${data?.clients[0].name}`;
      let signatures = data?.leadPartners;
      aux[id].slides = insert(aux[id].slides, index, {
        uuid: uuidv4(),
        layoutId,
        name,
        hide: false,
        raw: { title: title, signatures: signatures, content: content, source: source }
      });
    } else if (layoutId === 'WELCOME_LETTER') {
      console.log('aux', sections);
      const video = {
        url: 'https://d46f6w3mzhkrh.cloudfront.net/decks/33/1665579681125_33.mp4',
        thumbnail: 'https://kgp-synclink-fonts.s3.us-east-2.amazonaws.com/assets/NewUmeshThumbnail.png'
      };
      const videoText = 'Hello';
      let title = `Welcome, ${data?.clients[0].name}`;
      let signatures = data?.leadPartners;
      aux[id].slides = insert(aux[id].slides, index, {
        uuid: uuidv4(),
        layoutId,
        name,
        hide: false,
        raw: { title: title, signatures: signatures, content: content, video: video, videoText: videoText }
      });
    } else {
      aux[id].slides = insert(aux[id].slides, index, { uuid: uuidv4(), layoutId, name, hide: false });
    }
    setSections(aux);
  };

  const duplicateSlide = (id: string, slide: SlideType) => {
    const aux = clone(sections);
    const duplicated = { ...slide, uuid: uuidv4() };
    aux[id].slides = [...aux[id].slides, duplicated];
    setSections(aux);
  };

  const toggleSlideVisibility = (sectionId: string, id: string) => {
    const aux = clone(sections);
    aux[sectionId].slides = aux[sectionId].slides.map(item => {
      if (item.uuid === id) {
        item.hide = !item.hide;
      }
      return item;
    });

    setSections(aux);
  };

  const removeSlide = (sectionId: string, id: string) => {
    const aux = clone(sections);
    aux[sectionId].slides = aux[sectionId].slides.filter(item => item.uuid !== id);
    setSections(aux);
    formHandler.resetForm();
  };

  const changeOrder = (result: DropResult) => {
    let from, to;

    if (!result.destination) {
      return;
    }

    from = result.source.index;
    to = result.destination.index;

    if (result.type === 'SECTION') {
      const aux = clone(sections);
      const ordered = reorderList(sectionOrder, from, to);
      const arraySections = objectToArray(aux);
      const orderedSections = arraySections.sort((a, b) => ordered.indexOf(a.id) - ordered.indexOf(b.id));
      const selectedIndex = orderedSections.findIndex(item => item.id === selected.sectionId);

      setSelected({ sectionId: String(selectedIndex), slideId: selected.slideId });
      setSections(arrayToObject(orderedSections.map((item, index) => ({ ...item, position: index + 1 }))));
    } else if (to !== 0) {
      const aux = clone(sections);
      const sourceList = aux[result.source.droppableId].slides;

      const [removedElement, newSourceList] = removeFromList(sourceList, from);
      let destinationList;
      const uuid = uuidv4();

      aux[result.source.droppableId].slides = newSourceList;
      destinationList = aux[result.destination.droppableId];

      removedElement.uuid = uuid;

      aux[result.destination.droppableId].slides = addToList(
        destinationList.slides,
        result.destination.index,
        removedElement
      );

      setSelected({ sectionId: String(destinationList.position! - 1), slideId: uuid });
      aux[result.destination.droppableId].slides.forEach((item, index) => (item.position = index + 1));

      setSections(aux);
    }
  };

  const getSelectedSlide = () => {
    if (!isAnySlideSelected) {
      return;
    }

    return sections[selected.sectionId]!.slides.find(e => e.uuid === selected.slideId);
  };

  const getSelectedLayoutType = () => {
    const slide = getSelectedSlide();
    return slide ? Layouts[slide.layoutId].title : '';
  };

  const setSelectedSlideForm = () => {
    const slide = getSelectedSlide();

    if (!slide) {
      setSelected({ sectionId: '', slideId: null });
      return;
    }

    formHandler.setSchema(Layouts[slide.layoutId].schema, slide.raw);
  };

  const saveSlideRaw = () => {
    const aux = clone(sections);

    aux[selected.sectionId].slides.map(slide => {
      if (slide.uuid === selected.slideId) {
        slide.raw = formHandler.data;
      }
      return slide;
    });

    setSections(aux);
  };

  const fetchDeck = () => {
    apiGet.fetchData({ endpoint: `/decks/${deckId}` });
  };

  const save = useCallback(() => {
    const saveDeck = async (resolve: (flag: boolean) => void, reject: Function) => {
      try {
        await apiSave.fetchData(
          { endpoint: '/decks/update', method: Methods.POST },
          {
            deckid: +deckId!,
            sections: Object.entries(sections).map(([uuid, section]) => ({
              uuid,
              ...section
            }))
          }
        );

        setData({ ...data, sections } as DeckInformation);
        notify('SyncLink saved!');
        setIsChange(false);
        resolve(true);
      } catch (error) {
        notify('Something went wrong');
        reject();
      }
    };

    return new Promise(saveDeck);
  }, [sections]);

  const updateData = (data: any) => {
    setData(data);
  };

  useEffect(() => {
    setSectionOrder(Object.keys(sections).map(key => key));
  }, [sections]);

  useEffect(() => {
    if (isAnySlideSelected) {
      setSelectedSlideForm();
    }
  }, [selected]);

  useEffect(() => {
    if (formHandler.data) {
      saveSlideRaw();
    }
  }, [formHandler.data]);

  useEffect(() => {
    if (formHandler.isChange) {
      setIsChange(true);
      formHandler.setIsChange(false);
    }
  }, [formHandler.isChange]);

  useEffect(() => {
    if (data && data.sections) {
      setSections(getDataSections(data));
    }
  }, [data]);

  useEffect(() => {
    if (apiGet.response) {
      setData(apiGet.response);
      formHandler.setExtraParams({
        candidatesFeedback: apiGet.response.candidatesFeedback
      });
    }
  }, [apiGet.response]);

  useEffect(() => {
    if (Object.keys(sections).length && data) {
      setHasChanges(!isEqual(sections, getDataSections(data)));
      if (isChange) {
        setHasChanges(true);
      }
    }
  }, [sections]);

  return {
    data,
    sectionOrder,
    sections,
    selected,
    selectSlide,
    addSection,
    editSectionsName,
    removeSection,
    addSlide,
    editSlideName,
    removeSlide,
    hasChanges,
    toggleSlideVisibility,
    addSlideByIndex,
    duplicateSlide,
    getSelectedSlide,
    getSelectedLayoutType,
    formHandler,
    fetchDeck,
    changeOrder,
    isAnySlideSelected,
    save,
    saving: apiSave.isLoading,
    updateData,
    unrepeatable
  };
};
