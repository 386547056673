import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ArrowDown, ArrowUp, Plus } from 'react-feather';
import { DropResult } from 'react-beautiful-dnd';
import { ModalsList } from 'constants/modals';
import { useModal } from 'hooks/useModal';
import { reorderList } from 'helpers/data';
import Button from 'components/common/button';
import { CommonProps, CompanyType, RelevantExperienceValue, SubcategoryType, TextInputTypes } from '../types';
import Companies from './companies';
import SubCategories from './subCategories';
import clone from 'just-clone';

type CompanyProps = {
  currentCategory: RelevantExperienceValue;
  value: RelevantExperienceValue[];
  type: TextInputTypes;
  slideType?: string;
} & CommonProps;

enum CategoryStatus {
  EMPTY = 'EMPTY',
  SUBCATEGORY = 'SUBCATEGORY',
  COMPANY = 'COMPANY'
}

const aspectRatio = '193/70';
const shape = 'rect';

const Categories = ({ currentCategory, onChange, value, slideType }: CompanyProps) => {
  const modal = useModal();
  const params = useParams();
  const [status, setStatus] = useState<string | null>(null);
  const [sorting, setSorting] = useState<string>('');
  const [sortingType, setSortingType] = useState<string>('');

  useEffect(() => {
    console.log('cant slide type: ', slideType);
  });
  useEffect(() => {
    const { companies, subCategories } = currentCategory;

    if (!companies?.length && !subCategories?.length) {
      setStatus(CategoryStatus.EMPTY);
    } else if (currentCategory.companies.length) {
      setStatus(CategoryStatus.COMPANY);
    } else {
      setStatus(CategoryStatus.SUBCATEGORY);
    }
  }, [value]);
  const categoryItems = useMemo(
    () =>
      value.map(category => {
        if (category?.id === currentCategory.id) {
          if (category?.companies?.length) {
            return { items: currentCategory.companies, status: CategoryStatus.COMPANY };
          }

          if (category?.subCategories?.length) {
            return { items: currentCategory.subCategories, status: CategoryStatus.SUBCATEGORY };
          }
        }
        return { items: [], status: CategoryStatus.EMPTY };
      }),
    [value]
  );

  const addCompany = () => {
    const aux = value.map(category => {
      if (category.id === currentCategory.id) {
        category.companies = [...category.companies, { id: uuidv4(), name: '', image: '', roles: [] }];
      }
      return category;
    });

    onChange(aux);
  };

  const addSubcategory = () => {
    const aux = value.map(category => {
      if (category.id === currentCategory.id) {
        return {
          ...category,
          subCategories: [
            ...category.subCategories,
            {
              id: uuidv4(),
              name: '',
              companies: []
            }
          ]
        };
      }
      return category;
    });

    onChange(aux);
  };
  const importFromWorkBench = () => {
    modal.open(ModalsList.IMPORT_WORKBENCH, async (data: any) => {
      if (data) {
        const aux = value.map(category => {
          if (category.id === currentCategory.id) {
            const filterData = data
              .filter((e: any) => {
                return !category.companies.find(c => c.id === e.company_id);
              })
              .map((company: any) => ({
                id: company.company_id,
                name: company.company.name,
                image: company.image ? company.image : null,
                roles: company.job_title.map((item: any) => ({
                  id: uuidv4(),
                  name: item,
                  pinned: false,
                  count: 1
                }))
              }));
            category = {
              ...category,
              companies: [...category.companies, ...filterData]
            };
          }
          return category;
        });

        onChange(aux);
      }
    });
  };

  const importFromDocument = () => {
    modal.open(
      ModalsList.IMPORT_FROM_DOCUMENT,
      (data: any[]) => {
        if (data) {
          const companies = data.reduce((acc, cur) => {
            const index = acc.findIndex((e: any) => e.name === cur.company_name);
            const role = {
              id: uuidv4(),
              name: cur.role,
              pinned: false,
              count: 1
            };

            if (index > -1) {
              acc[index].roles.push(role);
            } else {
              if (cur.subCategory) {
                acc.push({
                  id: uuidv4(),
                  name: cur.company_name,
                  image: '',
                  roles: [role],
                  subCategory: cur.subCategory
                });
              } else {
                acc.push({ id: uuidv4(), name: cur.company_name, image: '', roles: [role] });
              }
            }

            return acc;
          }, []);

          const groupBySubCategory = (companies: any) => {
            const subCategoryGroup: any[] = [];
            companies.forEach((company: any) => {
              const { subCategory } = company;
              const existedSubCategory = subCategoryGroup.find(cur => cur.name === subCategory);
              if (existedSubCategory) {
                delete company[subCategory];
                existedSubCategory.companies.push(company);
              } else {
                const { subCategory, ...rest } = company;
                const newSubCategory = {
                  id: uuidv4(),
                  name: subCategory,
                  companies: [rest]
                };
                subCategoryGroup.push(newSubCategory);
              }
            });
            return subCategoryGroup;
          };
          const subCategoriedCompany: any = groupBySubCategory(companies);

          const aux = clone(value).map(category => {
            if (category.id === currentCategory.id) {
              console.log(companies, 'companies in aux');

              if (companies[0]?.subCategory) {
                subCategoriedCompany.forEach((sub: any) => {
                  const existedSubCategory = category.subCategories.find(cur => cur.name === sub.name);
                  if (existedSubCategory) {
                    existedSubCategory.companies.push(...sub.companies);
                  } else {
                    category.subCategories.push(sub);
                  }
                });
              } else {
                category = { ...category, companies: [...category.companies, ...companies] };
              }
            }

            return category;
          });

          onChange(aux);
        }
      },
      {
        modalTitle: 'Import Relevant Experience from Document',
        columns: [
          { label: 'Company name', key: 'company_name' },
          { label: 'Role', key: 'role' }
        ]
      }
    );
  };

  const getRolesById = useCallback(
    (id: string) => currentCategory?.companies.filter(company => company.id === id),
    [currentCategory?.companies]
  );

  const changeCompanyName = (e: ChangeEvent<HTMLInputElement>, companyId: string) => {
    const aux = value?.map(category => {
      if (category.id === currentCategory.id) {
        category.companies = category.companies.map(company => {
          if (company.id === companyId) {
            company[e.target.name] = e.target.value;
          }

          return company;
        });
      }

      return category;
    });

    onChange(aux);
  };

  const addRolesToCompany = (companyId: string) => {
    modal.open(
      ModalsList.ADD_ROLES,
      (data: any) => {
        if (data) {
          const aux = value.map(category => {
            category = {
              ...category,
              companies: category.companies.map(company =>
                company.id === companyId ? { ...company, roles: data.rolesList } : company
              )
            };

            return category;
          });

          onChange(aux);
        }
      },
      { data: getRolesById(companyId) }
    );
  };

  const deleteCompany = (companyId: string) => {
    const aux = value.map(category => {
      if (category.id === currentCategory.id) {
        category = { ...category, companies: category.companies.filter(company => company.id !== companyId) };
      }

      return category;
    });

    onChange(aux);
  };

  const selectCompanyImage = (companyId: string) => {
    modal.open(
      ModalsList.GALLERY,
      (data: any) => {
        if (data) {
          const aux = value?.map(category => {
            if (category.id === currentCategory.id) {
              category = {
                ...category,
                companies: category.companies.map(company => {
                  if (company.id === companyId) {
                    company = { ...company, image: data.url };
                  }
                  return company;
                })
              };
            }
            return category;
          });

          onChange(aux);
        }
      },
      { aspectRatio, shape, deckid: params?.id }
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const orderedList = value.map(category => {
      if (category.id === currentCategory.id) {
        category.companies = reorderList(category.companies, result.source.index, result.destination!.index);
      }
      return category;
    });

    onChange(orderedList);
  };

  useEffect(() => {
    if (currentCategory.companies.length && sorting !== '') {
      const aux = value?.map(category => {
        if (category.id === currentCategory.id) {
          category.companies = category.companies.sort((a: any, b: any) => {
            let fa = a.name.toLowerCase(),
              fb = b.name.toLowerCase();

            if (fa < fb) {
              return sorting === 'ascending' ? -1 : 1;
            }
            if (fa > fb) {
              return sorting === 'ascending' ? 1 : -1;
            }
            return 0;
          });
        }
        return category;
      });
      onChange(aux);
      sorting === 'ascending' ? setSortingType('decending') : setSortingType('ascending');
    }
  }, [sorting]);

  return (
    <>
      {(CategoryStatus.SUBCATEGORY === status || CategoryStatus.EMPTY === status) && (
        <Button text="Add subcategory" tiny small variant="secondary" icon={<Plus />} onClick={addSubcategory} />
      )}

      {(CategoryStatus.COMPANY === status || CategoryStatus.EMPTY === status) && (
        <Button text="Add company" tiny small variant="secondary" icon={<Plus />} onClick={addCompany} />
      )}

      {(CategoryStatus.COMPANY === status || CategoryStatus.EMPTY === status) && (
        <div>
          <div className="imports">
            <Button text="Import from Workbench" variant="secondary" onClick={importFromWorkBench} tiny />
            {/* <Button text="Import from Galaxy" variant="secondary" onClick={importFromGalaxy} tiny />*/}
            <Button text="Import from Document" variant="secondary" onClick={importFromDocument} tiny />
          </div>
          <div className="imports">
            <Button
              text="Sort"
              variant="secondary"
              icon={sortingType === '' ? <ArrowUp /> : sortingType === 'ascending' ? <ArrowUp /> : <ArrowDown />}
              onClick={() =>
                sortingType === ''
                  ? setSorting('ascending')
                  : sortingType === 'ascending'
                  ? setSorting('ascending')
                  : setSorting('decending')
              }
              tiny
            />
            {/* <Button text="Sort" variant="secondary" icon={<ArrowUp />} onClick={() => setSorting('decending')} tiny /> */}
          </div>
        </div>
      )}
      {!!status &&
        categoryItems?.map((category, index) => {
          if (category?.status === CategoryStatus.SUBCATEGORY) {
            return (
              <SubCategories
                key={index}
                currentCategory={currentCategory}
                subcategoryList={category.items as SubcategoryType[]}
                onChange={onChange}
                value={value}
                slideType={slideType}
              />
            );
          }

          if (category?.status === CategoryStatus.COMPANY) {
            return (
              <Companies
                key={index}
                companyList={category.items as CompanyType[]}
                changeCompanyName={changeCompanyName}
                deleteCompany={deleteCompany}
                addRoles={addRolesToCompany}
                selectCompanyImage={selectCompanyImage}
                type={TextInputTypes.TEXT}
                onDragEnd={onDragEnd}
                slideType={slideType}
              />
            );
          }

          return null;
        })}
    </>
  );
};
export default Categories;
