import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const LineChart = ({ data, min, max }: any) => {
  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Mins'
        },
        min,
        max
      }
    }
  };

  return (
    <Line
      options={options}
      data={{
        labels: data?.labels,
        datasets: [
          {
            data: data?.data,
            borderColor: '#4F4CC6'
          }
        ]
      }}
    />
  );
};

export default LineChart;
