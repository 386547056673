import React from 'react';
import { useParams } from 'react-router-dom';
import { Image, Trash2, Plus } from 'react-feather';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Button, DragWrapper, DropWrapper, AutoComplete } from 'components';
import { useModal } from 'hooks/useModal';
import { ModalsList } from 'constants/modals';
import DragIcon from 'assets/icons/DragIcon';
import { InputSourceEnvironmentProps } from '../types';
import { reorderList } from 'helpers/data';
// import { useLocalStorage } from '@rehooks/local-storage';
import axios from 'axios';

const aspectRatio = '13/6';
const shape = 'rect';

const InputSourceEnvironment = ({ value: companies, onChange, onBlur }: InputSourceEnvironmentProps) => {
  const modal = useModal();
  const params = useParams();
  // const [token] = useLocalStorage('token');
  // const [images, setImages] = useState<any>();
  type SelectOption = {
    logo: any;
    id: string;
    name: string;
  };

  const addCompany = () => {
    onChange([...companies.filter(el => el.name !== ''), { id: uuidv4(), name: '', image: '' }]);
  };

  const deleteCompany = (id: string) => {
    onChange(companies.filter(item => item.id !== id));
  };

  const profilePicData = async (id: any) => {
    const images = `${process.env.REACT_APP_KGP_URL}/images?type=company&id=${id}`;
    const result: any = await axios.get(images);
    if (result.status === 200) {
      return result;
    } else if (result.status === 404) {
      return null;
    }
  };

  const handleChange = async (selected: SelectOption, id: any) => {
    const itExists = companies.some((companies: any) => companies.id === selected.id);
    if (itExists) {
      onChange(companies);
      return;
    }
    if (selected.logo) {
      const result = await profilePicData(selected.id);
      const temp = companies.filter(el => el.id !== id);
      onChange([
        ...temp,
        {
          id: selected.id,
          name: selected.name,
          image: result.request.responseURL
        }
      ]);
    } else {
      const temp = companies.filter(el => el.id !== id);
      onChange([
        ...temp,
        {
          id: selected.id,
          name: selected.name,
          image: selected.logo
        }
      ]);
    }
  };

  const handleGalleryModal = (id: string) => {
    modal.open(
      ModalsList.GALLERY,
      (data: any) => {
        if (data) {
          const aux = companies.map(company => {
            if (company.id === id) {
              company.image = data.url;
            }

            return company;
          });

          onChange(aux);
        }
      },
      { aspectRatio, shape, deckid: params.id }
    );
  };

  const confirmModal = (item: any) => {
    modal.open(
      ModalsList.DIALOG_CONFIRM,
      (confirmed: any) => {
        if (confirmed) {
          deleteCompany(item.id);
        }
      },
      { title: `Delete company ${item.name}` }
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const orderedList = reorderList(companies, result.source.index, result.destination.index);
    onChange(orderedList);
  };

  return (
    <>
      <div className="input-wrapper">
        {/* <Button text="Import from document" variant="secondary" small onClick={handleImportFromDocument} /> */}

        <div className="input-source-dropdown">
          <DragDropContext onDragEnd={onDragEnd}>
            <DropWrapper droppableId="company-src-list-droppable" type="INPUT_SRC_COMPANY" direction="vertical">
              {companies.map((item, index) => (
                <DragWrapper key={item.id} draggableId={item.id} index={index}>
                  <div className="input" key={index}>
                    <div className="actions">
                      <DragIcon />
                      <Image onClick={() => handleGalleryModal(item.id)} />
                    </div>
                    <AutoComplete
                      name="name"
                      label="Company"
                      placeholder="Company"
                      value={{ name: item.name }}
                      onChange={data => handleChange(data, item.id)}
                      onBlur={onBlur}
                      endpoint="/companies/search"
                      isMulti={false}
                    />
                    <div className="actions">
                      <Trash2 onClick={() => confirmModal(item)} />
                    </div>
                  </div>
                </DragWrapper>
              ))}
            </DropWrapper>
          </DragDropContext>
        </div>
        <Button text="Add company" small variant="secondary" icon={<Plus />} onClick={() => addCompany()} />
      </div>
    </>
  );
};

export default InputSourceEnvironment;
