import joi from 'joi';
import { InputsEnum } from 'components/dynamicInputs/types';

export default {
  shareDeck: {
    value: {
      sharedUsers: [],
      newSharedUsers: [],
      saveActive: false
    },
    input: InputsEnum.SHARE_DECK,
    joi: joi.array().min(1).required()
  }
};
