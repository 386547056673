/* eslint-disable react/react-in-jsx-scope */
import { useModal } from 'hooks/useModal';
import { ModalsList } from '../../../constants/modals';
import Expansible from 'clientSide/components/shared/expansible';
import Sanitized from 'clientSide/components/shared/sanitized';
import PlayButton from 'clientSide/components/shared/playButton';
import BGMosaic from 'clientSide/components/shared/bgMosaic';
import { Trans } from 'components/common/trans';

type Props = {
  title: string;
  content: string;
  // media: 'video';
  // orientation: 'start' | 'end';
  video: {
    url: string;
    thumbnail: string;
  };
  videoText?: string;
  selected: boolean;
};

const LayoutProcess = ({ title, content, video, videoText }: Props) => {
  const modal = useModal();

  const handleVideoClick = () => {
    modal.open(ModalsList.VIDEO_MODAL, () => {}, { url: video.url, videoText });
  };

  const handleClickLearnMore = () => {
    modal.open(ModalsList.FIT_PROCESS);
  };

  return (
    <div className="layout-signature">
      <BGMosaic />

      <div className="flex-wrapper">
        <div className="info">
          <div className="wrapper">
            <div className="title-class">
              <div className="title-name">
                <h3 data-event="click">{title}</h3>
              </div>
            </div>
            <Expansible classes="content body">
              <Sanitized html={content} />
            </Expansible>
            <div className="text-row learn-more">
              <span className="body" onClick={handleClickLearnMore} data-event="click" data-action="learn-more">
                <Trans>Learn more</Trans>
              </span>
            </div>
          </div>
        </div>
        <div className="signature-video">
          <div className="video-container" data-event="click" data-action="play-video" onClick={handleVideoClick}>
            <img src={video?.thumbnail} />
            <div className="play-video">
              <PlayButton />
            </div>
            {!!videoText && (
              <div className="video-text">
                <h6>{videoText}</h6>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutProcess;
