export const SHARE_PERMISSIONS = [
  {
    name: 'Owner',
    id: 'owner'
  },
  {
    name: 'Editor',
    id: 'editor'
  },
  {
    name: 'Viewer',
    id: 'viewer'
  },
  {
    name: 'Approver',
    id: 'approver'
  }
];
