import joi from 'joi';
import { InputsEnum, TextInputTypes } from 'components/dynamicInputs/types';

export default {
  title: {
    value: 'Diversity and Inclusion ',
    label: 'Title',
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    limit: 60,
    joi: joi.string().required()
  },
  content: {
    value:
      '<p>Kingsley Gate Partners excels at helping companies build diverse, world-class leadership teams populated with professionals who offer differentiated experiences, functional expertise, and vision.</p><p>We lead by example. Our firm is built on the idea of diversity. It is not just what we do, it is who we are.</p>',
    label: 'Description',
    input: InputsEnum.REACH_TEXT,
    limit: 800,
    joi: joi.string().required()
  },
  source: {
    value: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/assets/diversity.svg`,
    label: 'Image',
    input: InputsEnum.DIVERSITY_AND_INCLUSION,
    joi: joi.object().required()
  },
  orientation: {
    value: 'start'
  },
  media: {
    value: 'image'
  }
};
