import React from 'react';

const Logo = () => (
  <svg className="logo" viewBox="0 0 33 32">
    <path d="M24.7502 0C24.7502 4.92793 21.1305 8.92281 16.6654 8.92281C12.2003 8.92281 8.58057 4.92793 8.58057 0" />
    <path d="M8.79009 32C8.79009 27.0721 12.4098 23.0772 16.8749 23.0772C21.34 23.0772 24.9597 27.0721 24.9597 32" />
    <path d="M-4.92602e-07 8.92353C5.28524 8.92353 9.56978 12.2985 9.56978 16.4618C9.56978 20.625 5.28524 24 0 24" />
    <path d="M33 23.803C27.7148 23.803 23.4302 20.428 23.4302 16.2648C23.4302 12.1015 27.7148 8.72656 33 8.72656" />
  </svg>
);

export default Logo;
