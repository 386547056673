import { useEffect, useState } from 'react';

const RIPPLING_TIME = 600;

export default function useRipple(ref: any) {
  const [rippling] = useState<boolean>(false);

  useEffect(() => {
    const handleClick = () => {
      ref.current.classList.add('rippling');

      setTimeout(() => {
        ref.current && ref.current.classList.remove('rippling');
      }, RIPPLING_TIME);
    };

    ref.current.addEventListener('click', handleClick);

    return () => {
      ref.current && ref.current.removeEventListener('click', handleClick);
    };
  }, [ref]);

  return rippling;
}
